<div id="container">
    <form [formGroup]="formGroup">
        <app-tabs>
            <app-tab title="Nutzungen">
                <div class="row-component" id="row-nutzung">
                    <app-input-info-delete
                        id="input-nutzung"
                        [data]="{ placeholder: 'Nutzung', controlName: 'nutzung', required: false }"
                        formControlName="nutzungen"
                    ></app-input-info-delete>
                    <button class="primary" (click)="addNutzung()">Hinzufügen</button>
                </div>
                <div class="item-row">
                    <div class="list-title">Alle Nutzungen</div>
                    <div class="divider">.</div>
                    <div *ngFor="let item of newItemsNutzung; index as i">
                        <div class="content-list">
                            <div class="item">
                                <label>{{ item.name }}</label>
                            </div>
                            <div>
                                <button class="onlytext btn-noback" (click)="removeItem(i, 'nutzung')">
                                    <mat-icon class="icnClose">close</mat-icon>
                                </button>
                            </div>
                        </div>
                        <div class="divider"></div>
                    </div>
                    <div *ngFor="let item of nutzungsarten; index as i">
                        <div class="item">
                            <label>{{ item.name }}</label>
                        </div>
                        <div class="divider" *ngIf="nutzungsarten.length - 1 !== i"></div>
                    </div>
                </div>
            </app-tab>
            <app-tab title="Objektarten">
                <div class="row-component" id="row-objektarten">
                    <app-select
                        [height]="40"
                        [options]="nutzungenSelectOption"
                        (selectionChange)="setSelect($event, 'objektartenNutzung')"
                        [placeholder]="'Nutzung'"
                    >
                        <ng-container defaultText>Nutzung wählen</ng-container>
                    </app-select>
                    <app-input-info-delete
                        id="input-objektart"
                        [data]="{ placeholder: 'Objektart', controlName: 'objektart', required: false }"
                        formControlName="objektartenObjektart"
                    ></app-input-info-delete>
                    <button class="primary" (click)="addObjektart()">Hinzufügen</button>
                </div>
                <div class="item-row">
                    <div class="list-title">Alle Objektarten</div>
                    <div class="divider"></div>
                    <div *ngFor="let item of newItemsObjektart as newObjektart; index as i">
                        <div class="content-list">
                            <div class="item">
                                <label class="labelNutzungName">{{ item.collectionNutzungsart.name }}</label
                                ><label class="labelObjektartName">{{ item.name }}</label>
                            </div>
                            <div>
                                <button class="onlytext btn-noback" (click)="removeItem(i, 'objektart')">
                                    <mat-icon class="icnClose">close</mat-icon>
                                </button>
                            </div>
                        </div>
                        <div class="divider"></div>
                    </div>
                    <div *ngFor="let item of objektarten; index as i">
                        <div class="item">
                            <label class="labelNutzungName">{{ item.collectionNutzungsart.name }}</label
                            ><label class="labelObjektartName">{{ item.name }}</label>
                        </div>
                        <div class="divider" *ngIf="objektarten.length - 1 !== i"></div>
                    </div>
                </div>
            </app-tab>
        </app-tabs>

        <!--Button Row-->
        <div class="row-component" id="btns">
            <button (click)="submit()" type="submit" class="primary btns" id="btn-submit">
                {{ data.primaryBtnTitle || 'Speichern' }}
            </button>
            <button (click)="cancel()" class="default btns">Abbrechen</button>
        </div>
    </form>
</div>
