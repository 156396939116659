interface IAddressDetails {
  country: string;
  countryCode: string;
  road: string;
  city: string;
  town: string;
  village: string;
  county: string;
  postcode: string;
  houseNumber: string;
  state: string;
  subUrb: string;
  borough: string;
  cityDistrict: string;
  // Custom Properties for string combinations
  cityName: string;
  streetWithNumber: string;
  addressString: string;
  cityWithZip: string;
}

export class AddressDetails implements IAddressDetails {
  borough: string;
  city: string;
  cityDistrict: string;
  country: string;
  countryCode: string;
  county: string;
  houseNumber: string;
  postcode: string;
  road: string;
  state: string;
  subUrb: string;
  town: string;
  village: string;

  cityName: string;
  streetWithNumber: string;
  cityWithZip: string;

  addressString: string;

  constructor(address: IAddressDetails) {
    Object.assign(this, address);

    let addressString = '';
    let streetWithNumber = '';
    let cityWithZip = '';

    if (address.road) {
      const road = address.road || '';
      streetWithNumber += road;
      addressString += road;

      if (address.houseNumber) {
        const houseNumber = address.houseNumber || '';
        streetWithNumber += ` ${houseNumber}`;
        addressString += ` ${houseNumber}`;
      }
    }

    this.cityName = address.village || address.town || address.city;
    if (address.road && (address.postcode || this.cityName)) {
      addressString += ', ';
    }

    if (address.postcode && this.cityName) {
      cityWithZip += address.postcode ? `${address.postcode} ${this.cityName}` : '';
      addressString += cityWithZip;
    }

    this.cityWithZip = cityWithZip;
    this.streetWithNumber = streetWithNumber;
    this.addressString = addressString;
  }
}

export interface IOSMNode {
  address: IAddressDetails;
  distance: number;
  boundingBox: number[];
  class: string;
  displayName: string;
  icon: string;
  importance: number;
  lat: number;
  lon: number;
  SVG: string;
  type: string;
}
