export enum VermietungsSituation {
   IstEigengenutzt = 1,
   WirdUStFreiVermietet = 2
};

export type VermietungsSituationName = {
   [key in VermietungsSituation]: string;
};

export const VermietungsSituationNames: VermietungsSituationName = {
   [VermietungsSituation.IstEigengenutzt]: 'ist eigengenutzt',
   [VermietungsSituation.WirdUStFreiVermietet]: 'wird USt.-frei vermietet'
};