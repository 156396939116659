import { Pipe, PipeTransform } from '@angular/core';
import { IPlaceHolderParams } from '../../core/interfaces/PlaceHolder';

const defaultParams: IPlaceHolderParams = {
    placeHolderText: '-',
    suffix: ''
};

@Pipe({
    name: 'placeholder',
    pure: true
})
export class Placeholder implements PipeTransform {
    transform(value: unknown, incomingParams: IPlaceHolderParams = {}): string {
        const params: IPlaceHolderParams = { ...defaultParams, ...incomingParams };

        if (value != null) {
            return `${value} ${params.suffix}`;
        }

        return params.placeHolderText;
    }
}
