import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nullText'
})
export class NullTextPipe implements PipeTransform {

  transform(value: unknown, outputText: string): unknown {
    return value ? value : outputText;
  }

}
