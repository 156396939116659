import { Control, DomUtil } from 'leaflet';
import { isEqual, isObject, reduce } from 'lodash';
import { Appraisal } from '../../appraisal-detail/models/Appraisal';

export function tryParseInt(id: string): number | null {
    return id != null || !isNaN(+id) ? parseInt(id, 10) : null;
}

export function average(numbers: number[]): number {
    numbers ??= [];
    return numbers.reduce((a, b) => a + b) / numbers.length;
}

export function getAvgOfAppraisalsByProp(key: string, appraisals: Appraisal[]): number {
    if (appraisals.every((a) => a[key] == null)) {
        return 0;
    }

    const nonNullAppraisals = (appraisals || []).filter((a) => a[key] != null);
    const sum = nonNullAppraisals.reduce((acc, cur) => {
        const val = cur[key] < 0 ? 0 : cur[key];
        return acc + val;
    }, 0);

    return nonNullAppraisals.length > 0 ? Math.round(sum / nonNullAppraisals.length) : 0;
}

/**
 * Deep diff between two object, using lodash and returns a new object with key and value which differs from base object
 * @param object new object
 * @param base old object
 * @param ignoreKeys if you want to ignore specific keys while checking equality
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const difference = (object: object, base: object, ignoreKeys: string[] = []) => {
    return reduce(
        object,
        // eslint-disable-next-line @typescript-eslint/ban-types
        (result: object, value: object, key: string) => {
            if (!isEqual(value, base[key]) && !ignoreKeys.includes(key)) {
                result[key] = isObject(value) && isObject(base[key]) ? difference(value, base[key], ignoreKeys) : value;
            }
            return result;
        },
        {}
    );
};

export function createCustomControl(id: string): (new (...args: any[]) => any) & typeof Control {
    return Control.extend({
        onAdd() {
            return DomUtil.get(id);
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onRemove() {}
    });
}
