import { OidcClientSettings } from 'oidc-client';
import { environmentDefaults, OidcDefaults } from './environment.defaults';

// const DS_CDN_IMG_URL = 'https://cdne-kenstone-datasuite-images-dev.azureedge.net/image_${ID}_${SIZE}.webp';
// const DS_CLIENT_ID = 'b5519f03-cb3d-4522-a6d8-984894b34a73';
const DATASUITE_DEFAULT_IMG_URL = '/assets/icons/default.jpg';
const DATASUITE_CDN_IMG_URL = 'https://cdne-kenstone-datasuite-images-dev.azureedge.net/image_${ID}_${SIZE}.webp';

export const buildSpecificImgUrl = (id: number, size: 'big' | 'thumbnail'): string =>
    DATASUITE_CDN_IMG_URL.replace('${ID}', id.toString()).replace('${SIZE}', size);

export const environment = {
    production: false,
    baseApiUrl: 'https://api.datasuite-dev.kenstone.de/api/',
    baseGraphQLUrl: 'https://graphql.datasuite-dev.kenstone.de/graphql',
    baseImageUrl: DATASUITE_CDN_IMG_URL,
    defaultImgUrl: DATASUITE_DEFAULT_IMG_URL,
    oidcDiscoverUrl:
        'https://login.microsoftonline.com/538f6bd6-decc-4d22-b13e-1559196d7f82/v2.0/.well-known/openid-configuration',
    oidcClientId: 'b5519f03-cb3d-4522-a6d8-984894b34a73',
    oidcRedirectUrl: `${window.location.origin}/auth/callback`,
    ...environmentDefaults
};

export const oidcClientSettings: OidcClientSettings = {
    authority: environment.oidcDiscoverUrl,
    client_id: environment.oidcClientId,
    redirect_uri: environment.oidcRedirectUrl,
    scope: `api://${environment.oidcClientId}/Api.View`,
    post_logout_redirect_uri: window.location.origin,
    ...OidcDefaults
};
