import { Pipe, PipeTransform } from '@angular/core';
import { Baujahr } from '../interfaces/AppraisalFilterTypes';

@Pipe({
    name: 'gibtBaujahreRange'
})
export class GibtBaujahreRangePipe implements PipeTransform {
    transform(value: Baujahr[], avg: number, placeholder = '-'): string {
        if (value == null || value.length === 0) {
            return placeholder;
        }

        const vals = new Set([...value.map((b) => b.baujahrValue)]);
        return vals.size > 1
            ? `${Math.min(...vals)} - ${Math.max(...vals)} ${avg > 0 ? `(Ø${avg})` : ''}`
            : `${[...vals][0]}`;
    }
}
