import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import { Region } from 'src/app/appraisal-textgenerator/interfaces/BrickTypesProductionCosts';
import { BrickProductionCosts } from 'src/app/appraisal-textgenerator/interfaces/BrickTypes';
import { BrickNavigationService } from 'src/app/appraisal-textgenerator/services/brick-navigation.service';
import { BrickService } from 'src/app/appraisal-textgenerator/services/brick.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { BreadcrumbDefault, BreadcrumbsService } from 'src/app/core/services/breadcrumbs.service';

@Component({
   selector: 'app-brick-production-costs-edit',
   templateUrl: './brick-production-costs-edit.component.html',
   styleUrls: ['./brick-production-costs-edit.component.scss']
})
export class BrickProductionCostsEditComponent implements OnInit {

   form = this.fb.group({
      region: ['', Validators.required],
   });

   appraisalTextId: number;
   brickData: BrickProductionCosts;
   regions: Region[];
   regionsFiltered$: Observable<Region[]>;

   constructor(
      private activatedRoute: ActivatedRoute,
      private fb: FormBuilder,
      private brickService: BrickService,
      private brickNavigationService: BrickNavigationService,
      private snackbarService: SnackbarService,
      private breadcrumbsService: BreadcrumbsService
   ) { }

   ngOnInit(): void {
      this.appraisalTextId = this.activatedRoute.snapshot.params.appraisalTextId
      this.brickData = this.activatedRoute.snapshot.data['brickData'];

      this.loadAllRegions();

      this.regionsFiltered$ = this.form.controls.region.valueChanges
         .pipe(
            // startWith(this.brickData?.region?.name), // initial value
            distinctUntilChanged(),
            filter((value) => typeof value === 'string'),
            map(value => this.filterRegions(value || ''))
         );

      this.breadcrumbsService.Apply([
         { ...BreadcrumbDefault.TextgeneratorList },
         { ...this.brickNavigationService.getOverviewBreadcrumb(this.appraisalTextId, this.activatedRoute.parent.snapshot.url) },
         { label: 'Herstellungskosten' }
      ]);
   }

   displayRegion(region: Region): string {
      return region && region.id ? region.name : '';
   }

   loadAllRegions(): void {
      this.brickService.loadAllRegions()
         .pipe(map(res => res.data))
         .subscribe(
            data => {
               this.regions = data as Region[];
               this.initRegion();
            }
         );
   }

   // make regionsFiltered$ emit initially
   private initRegion() {
      if (this.brickData?.region == null) {
         this.form.controls.region.setValue('');
      }
      else {
         this.filterRegions(this.brickData?.region.name);
      }
   }

   private filterRegions(searchTerm: string): Region[] {
      const filterValue = searchTerm.toLowerCase();
      return this.regions?.filter(region => region.name.toLowerCase().includes(filterValue));
   }

   cancel(): void {
      this.brickNavigationService.navigateToOverview(this.appraisalTextId, this.activatedRoute.parent.snapshot.url);
   }

   save(): void {
      if (this.form.valid) {

         const clientType: string = this.brickNavigationService.getClientType(this.activatedRoute.parent.snapshot.url);
         this.brickService.updateBrickData(this.appraisalTextId, clientType, this.brickData)
            .subscribe();

         this.snackbarService.showSuccess('Speichern erfolgreich');
      }
   }

   saveAndExit(): void {
      if (this.form.valid) {
         this.save();
         this.brickNavigationService.navigateToOverview(this.appraisalTextId, this.activatedRoute.parent.snapshot.url, true); // with reload
      }
   }
}
