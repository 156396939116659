<div class="layer-control" id="custom-layer">
    <div
        (mouseleave)="showLayerSelect = false"
        (mouseover)="showLayerSelect = true"
        *ngIf="showLayerSelect"
        class="layer-select"
    >
        <app-radio-group
            (currentSelectionChange)="onLayerChange($event)"
            [(currentSelection)]="currentSelection"
            [options]="selectOptions"
        >
        </app-radio-group>
    </div>
    <button
        (mouseout)="showLayerSelect = false"
        (mouseover)="showLayerSelect = true"
        *ngIf="!showLayerSelect"
        class="default"
    >
        <fa-icon [icon]="faLayerGroup"></fa-icon>
    </button>
</div>
