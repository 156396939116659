<input
  [formControl]="valueControl"
  [max]="max"
  [min]="min"
  [step]="step"
  class="default slider"
  name="slider"
  type="range"
>
<div class="info-row">
  <output [style.left]="position" [value]="valueControl.value + 'km'" class="moving-label"
          for="slider"></output>
  <div class="fixed-values row-component space-between">
    <span *ngIf="valueControl.value > minValueShowMin">{{min}}</span>
    <span *ngIf="valueControl.value <= minValueShowMin"></span>
    <span *ngIf="valueControl.value < maxValueShowMax">{{max}}</span>
    <span *ngIf="valueControl.value >= maxValueShowMax"></span>
  </div>

</div>
