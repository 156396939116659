import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  constructor(
    public loadingService: LoadingService,
    private router: Router) {
  }

  ngOnInit(): void {
    // show loading indicator on router transitions
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.loadingService.setLoading(true);
      }
      else if (event instanceof NavigationEnd) {
        this.loadingService.setLoading(false);
      }
    });
  }

}
