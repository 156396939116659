import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LatLng } from 'leaflet';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AddressDetails, IOSMNode } from '../../shared/models/IOSMNode';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class GeoService {
    constructor(private apiService: ApiService) {}

    getOSMNodesByAddress(address: string, center: LatLng): Observable<IOSMNode[]> {
        const params = new HttpParams()
            .set('address', address)
            .set('lat', center.lat.toString())
            .set('lng', center.lng.toString());

        return this.apiService
            .get<IOSMNode[]>(`${environment.addressUrl}`, params)
            .pipe(map((data) => (data || []).map((o) => ({ ...o, address: new AddressDetails(o.address) }))));
    }
}
