<div class="grid">
  <div class="distance">
    <fa-icon [icon]="faMapMarker"></fa-icon>
    <span>
      {{appraisal.entfernung}}km
    </span>
  </div>
  <div class="street">{{appraisal.strasse}}</div>
  <div class="city">{{appraisal.ort}}</div>
</div>
