<div id="container">
    <div id="content">
        <form [formGroup]="formGroup" *ngIf="this.data.content as collection">
            <app-input-info-delete
                [data]="{ placeholder: 'Titel', controlName: 'titel', required: true }"
                formControlName="titel"
            ></app-input-info-delete>
            <div class="spacer"></div>
            <app-datepicker
                id="pickerHerausgabedatum"
                [data]="{ placeholder: 'Herausgabedatum', controlName: 'herausgabedatum', required: data.kategorie === 0 }"
                formControlName="herausgabedatum"
            ></app-datepicker>
            <div class="spacer"></div>
            <div class="row-component">
                <app-select
                    [height]="40"
                    [options]="DropQuartal"
                    [showRequired]="false"
                    (selectionChange)="setSelect($event, 'quartal')"
                    [placeholder]="'Quartal'"
                >
                    <ng-container defaultText>Quartal</ng-container>
                </app-select>
            </div>
            <div class="row-component">
                <div id="box-container">
                    <!-- Button File -->
                    <div *ngIf="showBoxFile" class="box-button-container">
                        <div class="box-button" id="box-button-file" *ngIf="showBoxUrl">
                            <input type="file" id="file" (change)="handleFileInput($event.target.files)" />
                            <div id="btn-content-file">
                                <img src="../../assets/icons/file-upload.png" class="upload-icon" /><br />
                                <span>Datei hochladen</span><br />
                                Datei hier herziehen oder klicken
                            </div>
                        </div>
                        <div
                            class="box-button-filled"
                            *ngIf="!showBoxUrl"
                            [ngClass]="{ 'validation-error': formGroup.get('uploadFile').invalid }"
                        >
                            <div class="row-component reset-box space-between">
                                <label></label>
                                <button (click)="reset()" class="onlytext btn-reset">
                                    <img src="../../assets/icons/cancel.svg" class="img-cancel" />
                                </button>
                            </div>
                            <br />
                            <img
                                *ngIf="formGroup.get('uploadFile').valid"
                                src="../../assets/icons/file-upload-correct.png"
                                class="response-icon"
                            /><br />
                            <img
                                *ngIf="formGroup.get('uploadFile').invalid"
                                src="../../assets/icons/file-upload-wrong.png"
                                class="response-icon"
                            /><br />
                            <div class="text-box">
                                {{ this.formGroup.get('uploadFile').value.name }}
                            </div>
                            <br />
                            <div *ngIf="formGroup.get('uploadFile').invalid" class="validation-error">
                                Dateiformat wird nicht akzeptiert <br />
                                Bitte fügen Sie nur PDF oder Excel Dokumente hinzu
                            </div>
                        </div>
                    </div>
                    <!-- Button URL -->
                    <div *ngIf="showBoxUrl" class="box-button-container">
                        <div
                            class="box-button"
                            (click)="copyUrlFromClipboard($event)"
                            id="box-button-url"
                            *ngIf="showBoxFile"
                        >
                            <div id="btn-content-url">
                                <img src="../../assets/icons/link-upload.png" class="upload-icon" /><br />
                                <span>Link einfügen</span><br />
                                Hier klicken, um Link einzufügen
                            </div>
                        </div>
                        <div class="box-button-filled" *ngIf="!showBoxFile">
                            <div class="row-component reset-box space-between">
                                <label></label>
                                <button (click)="reset()" class="onlytext btn-reset vertical-center">
                                    <img src="../../assets/icons/cancel.svg" class="img-cancel" />
                                </button>
                            </div>
                            <br />
                            <img
                                *ngIf="formGroup.get('uploadUrl').valid"
                                src="../../assets/icons/url-upload-correct.png"
                                class="response-icon"
                            /><br />
                            <img
                                *ngIf="formGroup.get('uploadUrl').invalid"
                                src="../../assets/icons/url-upload-wrong.png"
                                class="response-icon"
                            /><br />
                            <div class="text-box">{{ this.formGroup.get('uploadUrl').value }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Button Row-->
            <div id="btns">
                <button
                    (click)="submit(collection.id)"
                    class="primary form-buttons"
                    type="submit"
                    [disabled]="!formGroup.valid"
                >
                    Speichern
                </button>
                <button (click)="cancel()" class="default form-buttons">Abbrechen</button>
            </div>
        </form>
    </div>
    <div id="loader" *ngIf="loading"><mat-spinner [diameter]="30"></mat-spinner></div>
</div>
