import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DialogForm } from '../../../../models/Dialog';

@Component({
    selector: 'app-dialog-upload-rating',
    templateUrl: './dialog-upload-rating.component.html',
    styleUrls: ['./dialog-upload-rating.component.scss']
})
export class DialogUploadRatingComponent implements OnInit, OnDestroy {
    @Input() data: DialogForm;
    @Output() onChange = new EventEmitter<any>();
    formGroup: UntypedFormGroup;
    contentSub: Subscription;

    ngOnInit(): void {
        this.formGroup = new UntypedFormGroup({
            rating: new UntypedFormControl(null, Validators.required)
        });
        this.contentSub = this.data.content.subscribe((b) => this.setRating(b.bewertungCurrentUser));
    }

    submit(): void {
        this.onChange.emit({ rating: this.formGroup.get('rating').value });
    }

    cancel(): void {
        this.onChange.emit(null);
    }

    setRating(value: number): void {
        this.formGroup.get('rating').setValue(value);
    }

    ngOnDestroy(): void {
        this.contentSub.unsubscribe();
    }
}
