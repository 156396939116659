import { Pipe, PipeTransform } from '@angular/core';
import { Source } from 'src/app/appraisal-textgenerator/interfaces/BrickTypesMacroLocation';

@Pipe({
   name: 'sourceDisplay'
})
export class SourceDisplayPipe implements PipeTransform {

   transform(source: Source): string {
      var notAvailableText = source.quelleVerfuegbarFlag ? '' : '. Diese Kennzahl ist in der Quelle nicht mehr vorhanden und könnte somit nicht mehr aktuell sein.';
      return `Quelle: ${source.anbieter}${notAvailableText}`;
   }

}
