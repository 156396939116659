<div class="row-component">
    <form [formGroup]="formGroup">
        <textarea formControlName="text" placeholder="Mindestens 10 Zeichen" id="textfield"></textarea>
    </form>
</div>

<!--Button Row-->
<div id="btns">
    <button (click)="submit()" class="primary" type="submit" [disabled]="!formGroup.valid">
        {{ data.primaryBtnTitle || 'Speichern' }}
    </button>
    <button (click)="cancel()" class="default">Abbrechen</button>
</div>
