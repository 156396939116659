<div id="container" *ngIf="collection$ | async as collection; else nocollection">
    <div class="row-component title">
        <h3>
            {{ collection.titel }}
            <span *ngIf="showRating && rating !== null" id="rating"
                ><mat-icon id="star" aria-hidden="false" aria-label="star">star</mat-icon> {{ rating }}</span
            >
        </h3>
        <div>
            <button
                class="default"
                *ngIf="showRating"
                (click)="openDialog(DialogContentType.UploadRating, collection.id, this.collection$)"
            >
                Bewerten
            </button>
            <button *ngIf="isDataUploader" (click)="editCollection(collection.id)" class="default space-left">
                Bearbeiten
            </button>
            <button
                class="default space-left"
                (click)="openDialog(DialogContentType.UploadFeedback, collection.id, null)"
            >
                Problem melden
            </button>
        </div>
    </div>

    <div id="boxes">
        <div class="box" *ngIf="collection.collectionNutzungContainers?.length > 0">
            <div class="box-title2">
                <div>
                    <h5>Nutzungen</h5>
                </div>
            </div>
            <div class="box-content horizontal" id="content-nutzungen" *ngFor="let nutzung of sortedNutzungen">
                <div class="vertical content-lage-box">
                    <div>
                        <div class="box-title">
                            <b>{{ nutzung.nutzungsart }}</b>
                        </div>
                        <div class="box-subtitle" *ngFor="let objektart of nutzung.objektarten">{{ objektart }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="box">
            <div class="box-title2">
                <div class="row-component space-between vertical-center">
                    <h5>Dokumente</h5>
                    <button *ngIf="isDataUploader"
                        class="transparent btn-box"
                        (click)="openDialog(DialogContentType.UploadFile, collection.id, collection)"
                    >
                        +
                    </button>
                </div>
            </div>
            <div *ngIf="files.length > 0; else nofiles" class="content-scrollable">
                <div *ngFor="let file of files">
                    <div class="box-documents">
                        <div class="box-documents" (click)="documentClicked(file)">
                            <img
                                src="../../../assets/icons/{{
                                    MimeTypes[file.mimeType]?.toLowerCase() || 'nomime'
                                }}.png"
                                class="doc-icon"
                            />
                            <div class="box-doc">
                                <div class="box-title">{{ file.titel }}</div>
                                <div class="box-subtitle">
                                    {{ file.herausgabedatum | date: 'dd.MM.yyyy' }} - {{ file.aufrufe }} Aufrufe
                                </div>
                            </div>
                        </div>
                        <div>
                            <button
                                mat-icon-button
                                extended
                                *ngIf="file.mimeType !== urlType"
                                (click)="downloadFile(file)"
                            >
                                <mat-icon class="download-button">download</mat-icon>
                            </button>
                            <button
                                *ngIf="this.isDataUploader"
                                mat-icon-button
                                extended
                                (click)="documentDeleteClicked(file)"
                            >
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #nofiles>
                <div id="nofiles">Keine Dokumente vorhanden</div>
            </ng-template>
        </div>

        <div class="box">
            <div class="box-title2">
                <div class="row-component space-between vertical-center">
                    <h5>Kategorie</h5>
                    <button
                        (click)="openLink(url)"
                        class="transparent btn-box"
                        *ngIf="collection.herausgeber?.url as url"
                    >
                        >
                    </button>
                </div>
            </div>
            <div class="box-content horizontal content-scrollable">
                <div class="box-section">
                    {{ collectionKategorie[collection.kategorie]?.label }}
                    <h5 class="subtitle">Herausgeber</h5>
                    {{ collection.herausgeber?.name || 'Kein Herausgeber' }}
                    <h5 class="subtitle">Publikationsturnus</h5>
                    {{
                        collectionPublikationsturnus[collection.publikationsturnus]?.label || 'Kein Publikationsturnus'
                    }}
                    <div *ngIf="collection.herausgeber?.url as url">
                        <h5 class="subtitle">Researchbereich Herausgeber</h5>
                        <a (click)="openLink(url)">{{ url.slice(0, 40) }}...</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="box" *ngIf="collection.beschreibung !== null">
            <div class="box-title2">
                <div>
                    <h5>Beschreibung</h5>
                </div>
            </div>
            <div class="box-content horizontal content-scrollable">
                <div class="text">
                    {{ collection.beschreibung }}
                </div>
            </div>
        </div>

        <div class="box" *ngIf="collection.hinweis !== null">
            <div class="box-title2">
                <div>
                    <h5>Hinweis</h5>
                </div>
            </div>
            <div class="box-content horizontal content-scrollable">
                <div class="text">
                    {{ collection.hinweis }}
                </div>
            </div>
        </div>

        <div class="box" *ngIf="collection.collectionLagen?.length > 0">
            <div class="box-title2">
                <div>
                    <h5>Lage</h5>
                </div>
            </div>
            <div class="box-content horizontal content-scrollable">
                <div class="vertical content-lage-box" *ngFor="let lage of collection.collectionLagen">
                    <div><img src="../../../assets/icons/pin-grey.png" class="pin-icon" /></div>
                    <div>
                        <div class="box-subtitle" *ngIf="lage.countryWide">Deutschlandweit</div>
                        <div class="box-subtitle">{{ lage.bundesland?.name }}</div>
                        <div class="box-subtitle">{{ lage.landkreis?.name }}</div>
                        <div class="box-subtitle">{{ lage.gemeinde?.name }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="box" *ngIf="collection.feedback?.length > 0">
            <div class="box-title2">
                <div>
                    <h5>Feedback</h5>
                </div>
            </div>
            <div class="box-content horizontal content-scrollable">
                <div class="vertical content-lage-box" *ngFor="let entry of feedback; let i = index">
                    <div
                        class="feedback"
                        [ngClass]="{ pointer: entry.status === 1 && isDataUploader }"
                        (click)="closeFeedback(entry, i)"
                    >
                        <div class="feedback-header">
                            <div>
                                <b>{{ entry.createdByName }}</b>
                                <span>
                                    am {{ entry.createdOn | date: 'dd.MM.yyyy' }} um
                                    {{ entry.createdOn | date: 'HH:mm' }} Uhr</span
                                >
                            </div>

                            <mat-icon class="green" *ngIf="entry.status === 2">check_circle</mat-icon>
                            <mat-icon color="warn" *ngIf="entry.status === 1">warning</mat-icon>
                        </div>

                        <span>{{ entry.description }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="bottom"></div>
</div>
<ng-template #nocollection>Keine Daten gefunden</ng-template>
