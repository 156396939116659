import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SigninRequest } from 'oidc-client';
import { Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, filter, switchMap, tap } from 'rxjs/operators';
import { HttpStatus } from '../enums/HttpStatus';
import { AuthService } from '../guards/auth.service';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
   constructor(private authService: AuthService) { }

   intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
      return next.handle(request).pipe(
         catchError((err: HttpErrorResponse) => {

            // error: unauthorized
            if (err.status == HttpStatus.UNAUTHORIZED) {
               return of(err.status).pipe(
                  filter((status) => status === HttpStatus.UNAUTHORIZED),
                  concatMap(() => this.authService.startSignInProcess()),
                  filter((x) => !!x),
                  tap((signInRequest: SigninRequest) => window.location.assign(signInRequest.url)), // redirect to OpenId Provider
                  switchMap(() => throwError('Unauthorized'))
               )
            }

            // all other errors
            return throwError(err);
         })
      );
   }
}
