import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {SnackbarService} from '../../../../../core/services/snackbar.service';
import {HerausgeberDialogResult, HerausgeberItem} from "../../../../../collection-upload/models/Collection";

@Component({
    selector: 'app-dialog-upload-item-herausgeber',
    templateUrl: './dialog-upload-item-herausgeber.component.html',
    styleUrls: ['./dialog-upload-item-herausgeber.component.scss']
})
export class DialogUploadItemHerausgeberComponent implements OnInit {
    @Output() closed = new EventEmitter<HerausgeberDialogResult>();
    @Input() data: { [key: string]: any; content: Observable<HerausgeberItem[]> };
    newHerausgeberItems: HerausgeberItem[] = [];
    existingHerausgeberItems: HerausgeberItem[];
    editedItems: { [key: string]: HerausgeberItem } = {};
    areEditedItemsValid = false;

    urlAdded = false;
    formGroup: FormGroup;

    constructor(private snackbarService: SnackbarService) {
    }

    ngOnInit(): void {
        this.formGroup = new FormGroup({
            name: new FormControl(null, Validators.required),
            url: new FormControl(null)
        });

        this.data.content.subscribe((c) => (this.existingHerausgeberItems = c));
    }

    submit(): void {
        this.closed.emit({
            newEntries: this.newHerausgeberItems,
            edited: Object.values(this.editedItems)
        });
    }

    cancel(): void {
        this.closed.emit(null);
    }

    addItem(): void {
        const url = this.formGroup.get('url').value;
        const name = this.formGroup.get('name').value;
        if (name != null) {
            const item = {name: name, url: url};
            if (
                this.existingHerausgeberItems.filter((h) => h.name === item.name).length > 0 ||
                this.newHerausgeberItems.filter((h) => h.name === item.name).length > 0
            ) {
                this.snackbarService.showWarning('Dieser Herausgeber ist bereits vorhanden!');
            } else {
                this.newHerausgeberItems.push(item);
            }
            this.formGroup.get('name').setValue(null);
            this.urlAdded = false;
            this.formGroup.get('url').setValue(null);
        } else {
            this.snackbarService.showWarning('Herausgebername darf nicht leer sein!');
        }
    }

    removeItem(index: number): void {
        this.newHerausgeberItems.splice(index, 1);
    }

    editItem(item: HerausgeberItem, index: number): void {
        this.editedItems = {
            ...this.editedItems,
            [item.id]: {...item}
        };
        this.existingHerausgeberItems[index].editMode = true;
        this.validateEditedItems();
    }

    validateEditedItems(): void {
        if (Object.values(this.editedItems).length == 0) {
            this.areEditedItemsValid = false;
            return;
        }
        for (const v of Object.values(this.editedItems)) {
            if (!v.name) {
                this.areEditedItemsValid = false;
                return;
            }
        }
        this.areEditedItemsValid = true;
    }

    revertEditItem(item: HerausgeberItem, index: number): void {
        this.existingHerausgeberItems[index].editMode = false;
        delete this.editedItems[item.id];
        this.validateEditedItems();
    }

    setCurrentUrl(event: Event): void {
        this.copyUrlFromClipboard(event);
        this.urlAdded = true;
    }

    clearCurrentUrl(): void {
        this.urlAdded = false;
        this.formGroup.get('url').setValue(null);
    }

    copyUrlFromClipboard(event: Event): void {
        navigator.clipboard
            .readText()
            .then((text) => {
                this.formGroup.get('url').setValue(text);
            })
            .catch((error) => {
                console.error('Cannot read clipboard text: ', error);
            });
    }
}
