import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GutachtenErstellungTyp, GutachtenErstellungTypNames } from 'src/app/appraisal-textgenerator/enums/GutachtenErstellungTyp';
import { BrickConstructionLoads } from 'src/app/appraisal-textgenerator/interfaces/BrickTypes';
import { BrickNavigationService } from 'src/app/appraisal-textgenerator/services/brick-navigation.service';
import { BrickService } from 'src/app/appraisal-textgenerator/services/brick.service';
import { FormService } from 'src/app/appraisal-textgenerator/services/form.service';
import { BreadcrumbDefault, BreadcrumbsService } from 'src/app/core/services/breadcrumbs.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
   selector: 'app-brick-construction-loads-edit',
   templateUrl: './brick-construction-loads-edit.component.html',
   styleUrls: ['./brick-construction-loads-edit.component.scss']
})
export class BrickConstructionLoadsEditComponent implements OnInit {

   brickData: BrickConstructionLoads;
   appraisalTextId: number;

   form = this.fb.group({
      inBayern: [{ value: null }, Validators.required],
      baulastenAuskunftVorhanden: [{ value: null, disabled: true }, Validators.required],
      baulastenVorhanden: [{ value: null, disabled: true }, Validators.required],
      wertrelevanz: [{ value: null, disabled: true }, Validators.required],
      gutachterlicheEinschaetzung: [{ value: '', disabled: true }, Validators.required],
      baulastKurztext: [{ value: '', disabled: true }, Validators.required],
      gutachtenOhneBaulastenauskunftErstellenMoeglich: [{ value: '', disabled: true }, Validators.required]
   });


   public GutachtenErstellungTypNames = GutachtenErstellungTypNames;
   public gutachtenErstellungsOptions = Object.values(GutachtenErstellungTyp).filter((v) => !isNaN(Number(v)));

   constructor(
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private fb: FormBuilder,
      private brickService: BrickService,
      private brickNavigationService: BrickNavigationService,
      private formService: FormService,
      private snackbarService: SnackbarService,
      private breadcrumbsService: BreadcrumbsService
   ) { }

   ngOnInit(): void {
      this.appraisalTextId = this.activatedRoute.snapshot.params.appraisalTextId;
      this.brickData = this.activatedRoute.snapshot.data['brickData'];
      this.formConditions();

      this.breadcrumbsService.Apply([
         { ...BreadcrumbDefault.TextgeneratorList },
         { ...this.brickNavigationService.getOverviewBreadcrumb(this.appraisalTextId, this.activatedRoute.parent.snapshot.url) },
         { label: 'Baulasten' }
      ]);
   }

   cancel(): void {
      this.brickNavigationService.navigateToOverview(this.appraisalTextId, this.activatedRoute.parent.snapshot.url);
   }

   save(): void {
      if (this.form.valid) {
         const clientType: string = this.brickNavigationService.getClientType(this.activatedRoute.parent.snapshot.url);
         this.brickService.updateBrickData(this.appraisalTextId, clientType, this.brickData)
            .subscribe();

         this.snackbarService.showSuccess('Speichern erfolgreich');
      }
   }

   saveAndExit(): void {
      if (this.form.valid) {
         this.save();
         this.brickNavigationService.navigateToOverview(this.appraisalTextId, this.activatedRoute.parent.snapshot.url, true); // with reload
      }
   }

   formConditions() {
      this.form.controls.inBayern.valueChanges.subscribe((value) => {
         this.formService.handleCondition(value == false, this.form.controls.baulastenAuskunftVorhanden, true);
      });

      this.form.controls.baulastenAuskunftVorhanden.valueChanges.subscribe((value) => {
         this.formService.handleCondition(value, this.form.controls.baulastenVorhanden, true);
         this.formService.handleCondition(
            this.form.controls.inBayern.value == false && value == false,
            this.form.controls.gutachtenOhneBaulastenauskunftErstellenMoeglich,
            true
         );
      });

      this.form.controls.baulastenVorhanden.valueChanges.subscribe((value) => {
         this.formService.handleCondition(value, this.form.controls.wertrelevanz, true);
      });

      this.form.controls.wertrelevanz.valueChanges.subscribe((value) => {
         this.formService.handleCondition(value != null, this.form.controls.baulastKurztext, true);
         this.formService.handleCondition(value, this.form.controls.gutachterlicheEinschaetzung, true);
      });

   }

}
