<p class="mt-5 display-6">Herstellungskosten</p>

<app-card class="mt-4">
   <div body>
      <form [formGroup]="form">
         <div class="row">
            <mat-form-field class="col-md-6">
               <mat-label>Region</mat-label>
               <input type="text" matInput formControlName="region" [matAutocomplete]="auto" [(ngModel)]="brickData.region">
               <mat-autocomplete requireSelection #auto="matAutocomplete" [displayWith]="displayRegion">
                  <mat-option *ngFor="let region of regionsFiltered$ | async" [value]="region">
                     {{region.name}}
                  </mat-option>
               </mat-autocomplete>
            </mat-form-field>
            <mat-form-field class="col-md">
               <mat-label>Regionalfaktor</mat-label>
               <input matInput [value]="brickData.region?.regionalFaktor ?? null" disabled="true">
            </mat-form-field>
            <mat-form-field class="col-md">
               <mat-label>MwSt.</mat-label>
               <input matInput [value]="brickData.mehrWertSteuerProzent + '%'" disabled="true">
            </mat-form-field>
         </div>
         <div class="row">
            <mat-form-field class="col-md">
               <mat-label>Stand BPI aktuell</mat-label>
               <input matInput [value]="brickData.bpiStandAktuell.caption" disabled="true">
            </mat-form-field>
            <mat-form-field class="col-md">
               <mat-label>Stand BPI bei Veröffentlichung</mat-label>
               <input matInput [value]="brickData.bpiStandBeiVeroeffentlichung.caption" disabled="true">
            </mat-form-field>
         </div>

         <div class="hstack gap-2 justify-content-end mt-4">
            <button class="btn btn-light" (click)="cancel()">Abbrechen</button>
            <button class="btn btn-primary" (click)="save()">Speichern</button>
            <button class="btn btn-light" (click)="saveAndExit()">Speichern & Schließen</button>
         </div>
      </form>
   </div>
</app-card>