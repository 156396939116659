import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSkeletonLoaderConfigTheme } from 'ngx-skeleton-loader';
import { SnackbarService } from '../../../../../core/services/snackbar.service';
import { Appraisal, AppraisalCompareViewOptions } from '../../../../models/Appraisal';

@Component({
   selector: 'app-appraisal-info-column',
   templateUrl: './appraisal-info-column.component.html',
   styleUrls: ['./appraisal-info-column.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppraisalInfoColumnComponent implements OnInit {
   @Input() appraisal: Appraisal;
   @Input() comparableAppraisals: Appraisal[];

   @Input() viewOptions: AppraisalCompareViewOptions | null;


   @Output() idChange: EventEmitter<number> = new EventEmitter();

   boxTheme: NgxSkeletonLoaderConfigTheme = {
      width: '100%',
      height: '33vw',
      'max-height': '460px'
   };

   constructor(private breakPointObserver: BreakpointObserver, private snackbarService: SnackbarService) {
      const isBigScreen = breakPointObserver.isMatched('(max-width: 1000px)');
      const isSmallScreen = breakPointObserver.isMatched('(max-width: 500px)');

      if (isBigScreen) {
         this.boxTheme.height = '50vw';
      }

      if (isSmallScreen) {
         this.boxTheme = {
            ...this.boxTheme,
            height: '100vw',
            'max-height': '1000px'
         };
      }
   }

   onSelect(id: number): void {
      this.idChange.emit(id);
   }

   showSnackbar(message: string) {
      this.snackbarService.showInfo(message);
   }

   ngOnInit(): void {
      console.log(this.comparableAppraisals);
   }
}
