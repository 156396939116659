import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../core/services/api.service';
import { SelectOptionBoolean, SelectOptionNumber } from '../../shared/components/radio-group/SelectOption';
import {
    Auftraggeber,
    Auftragsart,
    AusstattungsQualitaet,
    BaujahresKlasse,
    Besonderheiten,
    EnergieeffizienzKlasse,
    LageQualitaet,
    NachhaltigkeitsZertifikat,
    NutzungsArt,
    ObjektArt,
    Objektzustand
} from '../interfaces/AppraisalFilterTypes';
import { RequestData } from '../models/Appraisal';

const ExtIntOptions: SelectOptionBoolean[] = [
    {
        label: 'Intern',
        value: true
    },
    {
        label: 'Extern',
        value: false
    }
];

export const EigennutzungOptions: SelectOptionNumber[] = [
    {
        label: 'Eigennutzung',
        value: 1
    },
    {
        label: 'Fremdnutzung',
        value: 2
    },
    {
        label: 'Fremd- und Eigennutzung',
        value: 3
    }
];

export const NutzungsartenCombineOptions: SelectOptionNumber[] = [
    {
        label: 'Oder',
        value: 1
    },
    {
        label: 'Und',
        value: 2
    }
];

@Injectable({
    providedIn: 'root'
})
export class SidebarService {
    private currentAddress$: BehaviorSubject<string> = new BehaviorSubject('');
    private currentFreitextObjektArt$: BehaviorSubject<string> = new BehaviorSubject(null);
    private currentLoraId$: BehaviorSubject<string> = new BehaviorSubject(null);

    private objektArten$: BehaviorSubject<ObjektArt[]> = new BehaviorSubject([]);
    private nutzungsArten$: BehaviorSubject<NutzungsArt[]> = new BehaviorSubject([]);
    private lageQualitaeten$: BehaviorSubject<LageQualitaet[]> = new BehaviorSubject([]);
    private baujahresKlassen$: BehaviorSubject<BaujahresKlasse[]> = new BehaviorSubject([]);
    private ausstattungsQualitaeten$: BehaviorSubject<AusstattungsQualitaet[]> = new BehaviorSubject([]);
    private nachhaltigkeitszertifikate$: BehaviorSubject<NachhaltigkeitsZertifikat[]> = new BehaviorSubject([]);
    private energieeffizienzKlassen$: BehaviorSubject<EnergieeffizienzKlasse[]> = new BehaviorSubject([]);
    private besonderheiten$: BehaviorSubject<Besonderheiten[]> = new BehaviorSubject([]);
    private objektzustand$: BehaviorSubject<Objektzustand[]> = new BehaviorSubject([]);
    private auftragsarten$: BehaviorSubject<Auftragsart[]> = new BehaviorSubject([]);
    private auftraggeber$: BehaviorSubject<Auftraggeber[]> = new BehaviorSubject([]);

    constructor(private apiService: ApiService) {
        this.fetchFilterOptions();
    }

    get currentAddress(): Observable<string> {
        return this.currentAddress$.asObservable();
    }

    get currentFreitextObjektArt(): Observable<string> {
        return this.currentFreitextObjektArt$.asObservable();
    }

    get currentLoraId(): Observable<string> {
        return this.currentLoraId$.asObservable();
    }

    get objektArten(): Observable<ObjektArt[]> {
        return this.objektArten$.asObservable();
    }

    get lageQualitaeten(): Observable<LageQualitaet[]> {
        return this.lageQualitaeten$.asObservable();
    }

    get ausstattungsQualitaeten(): Observable<AusstattungsQualitaet[]> {
        return this.ausstattungsQualitaeten$.asObservable();
    }

    get nutzungsArten(): Observable<NutzungsArt[]> {
        return this.nutzungsArten$.asObservable();
    }

    get baujahresKlassen(): Observable<BaujahresKlasse[]> {
        return this.baujahresKlassen$.asObservable();
    }

    get nachhaltigkeitszertifikate(): Observable<NachhaltigkeitsZertifikat[]> {
        return this.nachhaltigkeitszertifikate$.asObservable();
    }

    get extIntAsSelectOption(): Observable<SelectOptionBoolean[]> {
        return of(ExtIntOptions);
    }

    get eigennutzungAsSelectOption(): Observable<SelectOptionNumber[]> {
        return of(EigennutzungOptions);
    }

    get auftragsarten(): Observable<Auftragsart[]> {
        return this.auftragsarten$.asObservable();
    }

    get auftraggeber(): Observable<Auftraggeber[]> {
        return this.auftraggeber$.asObservable();
    }

    get energieeffizienzKlassenAsSelectOption(): Observable<SelectOptionNumber[]> {
        return this.energieeffizienzKlassen$.asObservable().pipe(
            map((n) =>
                n.map((x) => ({
                    value: x.id,
                    label: x.id === 1 ? 'Keine Angabe' : `Klasse ${x.displayText}`
                }))
            )
        );
    }

    get nutzungsArtenCombineOption(): Observable<SelectOptionNumber[]> {
        return of(NutzungsartenCombineOptions);
    }

    get besonderheitenAsSelectOption(): Observable<SelectOptionNumber[]> {
        return this.besonderheiten$
            .asObservable()
            .pipe(map((v) => v.map((x) => ({ value: x.id, label: x.bezeichnung }))));
    }

    get objektzustandAsSelectOption(): Observable<SelectOptionNumber[]> {
        return this.objektzustand$
            .asObservable()
            .pipe(map((n) => n.map((x) => ({ value: x.id, label: x.bezeichnung }))));
    }

    setAddress(address: string): void {
        this.currentAddress$.next(address);
    }

    setFreitextObjektArt(text: string): void {
        this.currentFreitextObjektArt$.next(text);
    }

    setLoraId(text: string): void {
        this.currentLoraId$.next(text);
    }

    async fetchFilterOptions(): Promise<void> {
        const objektArten = await this.fetch<ObjektArt>(environment.objektArtUrl);
        this.objektArten$.next(objektArten);

        const nutzungsArten = await this.fetch<NutzungsArt>(environment.nutzungsArtUrl);
        this.nutzungsArten$.next(nutzungsArten);

        const baujahresKlassen = await this.fetch<BaujahresKlasse>(environment.baujahresKlassenUrl);
        this.baujahresKlassen$.next(baujahresKlassen);

        const lageQualitaeten = await this.fetch<LageQualitaet>(environment.lageQualitaetenUrl);
        this.lageQualitaeten$.next(lageQualitaeten);

        const auftragsarten = await this.fetch<Auftragsart>(environment.auftragsartenUrl);
        this.auftragsarten$.next(auftragsarten);

        const auftraggeber = await this.fetch<Auftraggeber>(environment.auftraggeberUrl);
        this.auftraggeber$.next(auftraggeber);

        const ausstatungsQualitaeten = await this.fetch<AusstattungsQualitaet>(environment.ausstatungsQualitaetenUrl);
        this.ausstattungsQualitaeten$.next(ausstatungsQualitaeten);

        const nachhaltikeitsZertifikate = await this.fetch<NachhaltigkeitsZertifikat>(
            environment.nachhaltikeitsZertifikatUrl
        );
        this.nachhaltigkeitszertifikate$.next(nachhaltikeitsZertifikate);

        const energieeffizienzKlassen = await this.fetch<EnergieeffizienzKlasse>(environment.energieeffizienzKlasseUrl);
        this.energieeffizienzKlassen$.next(energieeffizienzKlassen);

        const besonderheiten = await this.fetch<Besonderheiten>(environment.besonderheitenUrl);
        this.besonderheiten$.next(besonderheiten);

        const objektZustaende = await this.fetch<Objektzustand>(environment.objektzustandUrl);
        this.objektzustand$.next(objektZustaende);
    }

    private async fetch<T>(url: string): Promise<T[]> {
        return this.apiService
            .get<RequestData<T[]>>(url)
            .pipe(map(({ data }) => data))
            .toPromise();
    }
}
