import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-dialog-upload-textfield',
    templateUrl: './dialog-upload-textfield.component.html',
    styleUrls: ['./dialog-upload-textfield.component.scss']
})
export class DialogUploadTextfieldComponent implements OnInit {
    @ViewChild('textfield') textarea;
    @Output() closed = new EventEmitter<any>();
    @Input() data;

    formGroup: UntypedFormGroup;

    ngOnInit(): void {
        this.formGroup = new UntypedFormGroup({
            contentType: new UntypedFormControl(this.data.contentType.toLowerCase()),
            text: new UntypedFormControl(this.data.content || null, [Validators.minLength(10), Validators.required])
        });

        this.textarea?.nativeElement.focus();
    }

    submit(): void {
        this.closed.emit(this.formGroup.value);
    }

    cancel(): void {
        this.closed.emit(null);
    }
}
