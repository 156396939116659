<div #top></div>

<div id="container" class="root">
    <app-upload-list-sidebar id="sidebar"></app-upload-list-sidebar>
    <div *ngIf="collectionService.noFilter" class="info">
        <span>Bitte filtern, um Ergebnisse anzuzeigen</span>
    </div>
    <div *ngIf="!dataSource.data.length && !collectionService.noFilter && !loading" class="info">
        <span>Keine Ergebnisse für den gewünschten Filter</span>
    </div>

    <!--    <span class="text">Aus exakter Adresse</span>-->

    <div id="table-container" *ngIf="dataSource.data.length && !collectionService.noFilter">
        <table [dataSource]="dataSource" mat-table matSort (matSortChange)="sortData($event)">


            <ng-container matColumnDef="titel">
                <th *matHeaderCellDef mat-header-cell mat-sort-header="titel">
                    <span>Titel</span>
                </th>
                <td *matCellDef="let element" mat-cell>{{ element.titel | placeholder }}</td>
            </ng-container>

            <ng-container matColumnDef="herausgeberName">
                <th *matHeaderCellDef mat-header-cell mat-sort-header="herausgeberName">
                    <span>Herausgeber</span>
                </th>
                <td *matCellDef="let element" mat-cell>{{ element.herausgeberName | placeholder }}</td>
            </ng-container>

            <ng-container matColumnDef="publikationsturnus">
                <th *matHeaderCellDef mat-header-cell mat-sort-header="publikationsturnus">
                    <span>Publ.turnus</span>
                </th>
                <td *matCellDef="let element" mat-cell>
                    {{ element.publikationsturnus | select: Publikationsturnus }}
                </td>
            </ng-container>

            <ng-container matColumnDef="publikationsdatum">
                <th *matHeaderCellDef mat-header-cell mat-sort-header="publikationsdatum">
                    <span>Herausgabedatum</span>
                </th>
                <td *matCellDef="let element" mat-cell>{{ element.publikationsdatum | date }}</td>
            </ng-container>

            <ng-container matColumnDef="nutzungen">
                <th *matHeaderCellDef mat-header-cell mat-sort-header="nutzungen">
                    <span>Nutzung</span>
                </th>
                <td *matCellDef="let element" mat-cell>
                    {{ this.currentNutzungsarten$ | async | getNutzungsartById: element.nutzungen }}
                </td>
            </ng-container>

            <ng-container matColumnDef="lagen">
                <th *matHeaderCellDef mat-header-cell mat-sort-header="lagen">
                    <span>Lage</span>
                </th>
                <td *matCellDef="let element" mat-cell>{{ element.lagen | gibtUploadListText }}</td>
            </ng-container>

            <ng-container matColumnDef="kategorie">
                <th *matHeaderCellDef mat-header-cell mat-sort-header="kategorie">
                    <span>Kategorie</span>
                </th>
                <td *matCellDef="let element" mat-cell>{{ element.kategorie | select: Kategorie }}</td>
            </ng-container>

            <ng-container matColumnDef="bewertungAverage">
                <th *matHeaderCellDef mat-header-cell mat-sort-header="bewertungAverage">
                    <span>Bewertung</span>
                </th>
                <td *matCellDef="let element" mat-cell>
                    <span *ngIf="element.bewertungAverage" id="rating"
                    ><mat-icon id="star" aria-hidden="false" aria-label="star">star</mat-icon>
                        {{ element.bewertungAverage }}</span
                    >
                </td>
            </ng-container>

            <ng-container matColumnDef="subHeader">
                <th *matHeaderCellDef class="row-component space-between table-sub-header" mat-header-cell>
                    <span class="text">{{resultHint}}</span>
                </th>
            </ng-container>

            <tr *matHeaderRowDef="['subHeader']" aria-colspan="{{ displayedColumns.length }}" mat-header-row></tr>

            <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
            <tr
                *matRowDef="let row; columns: displayedColumns"
                [routerLink]="['../collection/', row.id]"
                class="hover pointer"
                mat-row
            ></tr>


        </table>

        <mat-paginator
            (page)="onPaginationChange($event)"
            [length]="pagination.total"
            [pageSizeOptions]="[10, 15, 20, 50, 100]"
            [pageIndex]="pagination.page"
            [pageSize]="pagination.size"
        >
        </mat-paginator>
    </div>
</div>
