export enum KaufpreisQualitaet {
    KeineAngabe,
    NotariellerVertrag,
    Angebot,
    EndverhandelterPreis,
    Zwangsversteigerung,
    AussergewoehnlicheVerhaeltnisse
}

export type KaufpreisQualitaetName = {
    [key in KaufpreisQualitaet]: string;
};

export const KaufpreisQualitaetNames: KaufpreisQualitaetName = {
    [KaufpreisQualitaet.KeineAngabe]: 'Keine Angabe',
    [KaufpreisQualitaet.NotariellerVertrag]: 'Notarieller Vertrag',
    [KaufpreisQualitaet.Angebot]: 'Angebot',
    [KaufpreisQualitaet.EndverhandelterPreis]: 'Endverhandelter Preis',
    [KaufpreisQualitaet.Zwangsversteigerung]: 'Zwangsversteigerung',
    [KaufpreisQualitaet.AussergewoehnlicheVerhaeltnisse]: 'Außergewöhnliche Verhältnisse'
};
