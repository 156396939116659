import { Component, Input, OnInit } from '@angular/core';
import { Baustein } from '../../interfaces/AppraisalTextTypes';
import { ActivatedRoute, Router } from '@angular/router';
import { BrickNavigationService } from '../../services/brick-navigation.service';

@Component({
   selector: 'app-bricks',
   templateUrl: './bricks.component.html',
   styleUrls: ['./bricks.component.scss']
})
export class BricksComponent implements OnInit {

   @Input() bricks: Baustein[];

   selectedIndex: number = 0;

   constructor(
      private router: Router,
      private route: ActivatedRoute,
      private brickNavigationService: BrickNavigationService
   ) { }

   ngOnInit(): void {
      // select brick tab by query parameter
      const selectedBrick = this.route.snapshot.queryParamMap.get('selectedBrick');
      if (selectedBrick) {
         this.bricks.forEach((brick, index) => {
            if (brick.clientType === selectedBrick) {
               this.selectedIndex = index;
               return; // break loop
            }
         });
      }
   }

   selectedIndexChange(selectedIndex: number): void {
      // change query params according to selected tab
      const selectedBrick = this.bricks[selectedIndex];

      this.router.navigate(
         [],
         {
            relativeTo: this.route,
            queryParams: { selectedBrick: selectedBrick.clientType },
            queryParamsHandling: 'merge'
         }
      );
   }

   navigateToBrickEdit(brick: Baustein) {
      var brickEditSegment = this.brickNavigationService.getEditSegment(brick.clientType);
      this.router.navigate([brickEditSegment], { relativeTo: this.route });
   }

}
