import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppraisalText, Baustein } from '../../interfaces/AppraisalTextTypes';
import { WertbestimmendesVerfahrenNames } from '../../enums/WertbestimmendesVerfahren';
import { DownloadService } from '../../services/download.service';
import saveAs from 'file-saver';
import { map } from 'rxjs/operators';
import { BreadcrumbDefault, BreadcrumbsService } from 'src/app/core/services/breadcrumbs.service';

@Component({
   selector: 'app-text-detail',
   templateUrl: './text-detail.component.html',
   styleUrls: ['./text-detail.component.scss']
})
export class TextDetailComponent implements OnInit {

   public WertbestimmendesVerfahrenNames = WertbestimmendesVerfahrenNames;
   appraisalText: AppraisalText
   bricksData: Baustein[] = []

   constructor(
      private route: ActivatedRoute,
      private downloadService: DownloadService,
      private breadcrumbsService: BreadcrumbsService
   ) {
   }

   ngOnInit(): void {
      this.route.data
         .pipe(map(data => data['appraisalText']))
         .subscribe(value => {
            this.appraisalText = value;
            this.bricksData = this.appraisalText.vorlage.bausteine;
            // console.log(this.appraisalText);
         });

      this.breadcrumbsService.Apply([
         { ...BreadcrumbDefault.TextgeneratorList },
         { ...BreadcrumbDefault.TextgeneratorDetailLabel }
      ]);
   }

   downloadWord(): void {

      this.downloadService.getWord(this.appraisalText.id)
         .subscribe(
            (data) => {
               saveAs(data, `${this.appraisalText.loraAuftragsnummer}.docx`);
            }
         );
   }

}
