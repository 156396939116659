import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UploadCollectionComponent} from './components/upload-collection/upload-collection.component';
import {UploadCreateComponent} from './components/upload-create/upload-create.component';
import {UploadListComponent} from './components/upload-list/upload-list.component';
import {UploaderOnlyGuard} from "./guards/uploader-only.guard";

const routes: Routes = [
    {
        path: '',
        data: {
            title: 'Datenupload'
        },
        children: [
            {
                path: '',
                redirectTo: 'list',
                pathMatch: 'full'
            },
            {
                path: 'create',
                canActivate: [UploaderOnlyGuard],
                component: UploadCreateComponent,
                data: {
                    label: 'Sammlung erstellen'
                }
            },
            {
                path: 'create/:id',
                canActivate: [UploaderOnlyGuard],
                component: UploadCreateComponent,
                data: {
                    label: 'Sammlung bearbeiten'
                }
            },
            {
                path: 'collection/:id',
                component: UploadCollectionComponent,
                data: {
                    title: 'Sammlung'
                }
            },
            {
                path: 'list',
                component: UploadListComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UploadRoutingModule {
}
