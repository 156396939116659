<div class="div-content">
    <div *ngIf="appraisal.datenQuelle === 2">
        <span>Giks Gutachten werden hier noch nicht aufgeführt.</span>
    </div>
    <ng-container *ngIf="appraisal.datenQuelle === 1">
        <b>Wohnen</b><span></span>

        <span>Mietfläche</span>
        <span>
        <app-number-display [value]="appraisal.wohnflaeche" [placeHolderParams]="{ suffix: 'm²' }"></app-number-display>
        </span>

        <span>Ist-Miete ∅</span>
        <app-number-display
            [decimalPlaces]="2"
            [value]="appraisal.mieteIstWohnenDurchschnitt"
            [placeHolderParams]="{ suffix: '€/ m²' }"
        ></app-number-display>

        <span>Liegenschaftszinssatz ∅</span>

        <app-number-display
            [decimalPlaces]="1"
            [value]="appraisal.liegenschaftzinsWohnenDurchschnitt"
            [placeHolderParams]="{ suffix: '%' }"
        ></app-number-display>

        <b>Büro</b><span></span>

        <span>Mietfläche</span
        ><span>
        <app-number-display
            [value]="appraisal.nutzflaecheBuero"
            [placeHolderParams]="{ suffix: 'm²' }"
        ></app-number-display>
    </span>

        <span>Ist-Miete ∅</span>
        <app-number-display
            [decimalPlaces]="2"
            [value]="appraisal.mieteIstBueroDurchschnitt"
            [placeHolderParams]="{ suffix: '€/ m²' }"
        ></app-number-display>
        <span>Liegenschaftszinssatz ∅</span>

        <app-number-display
            [decimalPlaces]="1"
            [value]="appraisal.liegenschaftzinsBueroDurchschnitt"
            [placeHolderParams]="{ suffix: '%' }"
        ></app-number-display>

        <b>Handel</b><span></span>

        <span>Mietfläche</span
        ><span>
        <app-number-display
            [value]="appraisal.nutzflaecheHandel"
            [placeHolderParams]="{ suffix: 'm²' }"
        ></app-number-display>
    </span>

        <span>Ist-Miete ∅</span>
        <app-number-display
            [decimalPlaces]="2"
            [value]="appraisal.mieteIstHandelDurchschnitt"
            [placeHolderParams]="{ suffix: '€/ m²' }"
        ></app-number-display>
        <span>Liegenschaftszinssatz ∅</span>

        <app-number-display
            [decimalPlaces]="1"
            [value]="appraisal.liegenschaftzinsHandelDurchschnitt"
            [placeHolderParams]="{ suffix: '%' }"
        ></app-number-display>

        <b>Sonstiges</b><span></span>

        <span>Mietfläche</span
        ><span>
        <app-number-display
            [value]="appraisal.nutzflaecheSonstiges"
            [placeHolderParams]="{ suffix: 'm²' }"
        ></app-number-display>
    </span>

        <span>Ist-Miete ∅</span>
        <app-number-display
            [decimalPlaces]="2"
            [value]="appraisal.mieteIstSonstigesDurchschnitt"
            [placeHolderParams]="{ suffix: '€/ m²' }"
        ></app-number-display>
        <span>Liegenschaftszinssatz ∅</span>

        <app-number-display
            [decimalPlaces]="1"
            [value]="appraisal.liegenschaftzinsSonstigesDurchschnitt"
            [placeHolderParams]="{ suffix: '%' }"
        ></app-number-display>
    </ng-container>
</div>
