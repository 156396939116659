import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { BrickMacroLocation } from 'src/app/appraisal-textgenerator/interfaces/BrickTypes';

@Component({
   selector: 'app-brick-macro-location-display',
   templateUrl: './brick-macro-location-display.component.html',
   styleUrls: ['./brick-macro-location-display.component.scss']
})
export class BrickMacroLocationDisplayComponent implements OnInit {

   @Input()
   data: BrickMacroLocation;

   @ViewChild(MatAccordion)
   accordion: MatAccordion;

   constructor() { }

   ngOnInit(): void {
      // console.log(this.data);
   }

}
