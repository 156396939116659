import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LatLng } from 'leaflet';
import _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { DefaultFilter, ObjektArt } from '../../../appraisal-detail/interfaces/AppraisalFilterTypes';
import { AppraisalFilter } from '../../../appraisal-detail/models/Appraisal';
import { AppraisalService } from '../../../appraisal-detail/services/appraisal.service';
import { SidebarService } from '../../../appraisal-detail/services/sidebar.service';
import config from '../../../core/config/config';
import { GeoService } from '../../../core/services/geo.service';
import { MapService } from '../../../core/services/map.service';
import { SnackbarService } from '../../../core/services/snackbar.service';
import { tryParseInt } from '../../../core/utils/utils';
import { SelectOptionBoolean, SelectOptionNumber } from '../../../shared/components/radio-group/SelectOption';
import { IOSMNode } from '../../../shared/models/IOSMNode';

function mapDefaultFilterAsSelect() {
   return map((v: DefaultFilter[]) => v.map((x) => ({ value: x.id, label: x.name })));
}

@Component({
   selector: 'app-filter-sidebar',
   templateUrl: './filter-sidebar.component.html',
   styleUrls: ['./filter-sidebar.component.scss']
})
export class FilterSidebarComponent implements OnInit, OnDestroy {
   @Input() isOpen: boolean;
   @ViewChild('perimeter') perimeterInput: ElementRef;

   /*Temporary var*/
   erbbaurechtActive: boolean;

   extIntOptions$: Observable<SelectOptionBoolean[]>;
   eigennutzungOptions$: Observable<SelectOptionNumber[]>;
   objektArten$: Observable<SelectOptionNumber[]>;
   objektUnterArten$: Observable<SelectOptionNumber[]>;
   nutzungsArten$: Observable<SelectOptionNumber[]>;
   nutzungsArtenCombineOptions$: Observable<SelectOptionNumber[]>;
   lageQualitaeten$: Observable<SelectOptionNumber[]>;
   baujahresKlassen$: Observable<SelectOptionNumber[]>;
   ausstattungsQualitaeten$: Observable<SelectOptionNumber[]>;
   availableNodes$: Observable<IOSMNode[]>;
   nachhaltigkeitsZertifikate$: Observable<SelectOptionNumber[]>;
   energieeffizienzKlassen$: Observable<SelectOptionNumber[]>;
   besonderheiten$: Observable<SelectOptionNumber[]>;
   objektzustand$: Observable<SelectOptionNumber[]>;
   auftragsarten$: Observable<SelectOptionNumber[]>;
   auftraggeber$: Observable<SelectOptionNumber[]>;

   filtersActive$: Observable<boolean>;

   currentAddress$: Observable<string>;
   currentRadius$: Observable<number>;
   currentAddressValid$: Observable<boolean>;

   currentFilter$: Observable<AppraisalFilter>;
   currentUserPos$: Observable<LatLng>;

   private erbbaurechtSub: Subscription;
   private freitextOjektArtSub: Subscription;

   private loraIdSub: Subscription;

   constructor(
      private appraisalService: AppraisalService,
      private mapService: MapService,
      private geoService: GeoService,
      private sidebarService: SidebarService,
      private snackbarService: SnackbarService
   ) {
   }

   ngOnInit(): void {
      this.currentUserPos$ = this.mapService.getUserPos();

      this.availableNodes$ = this.sidebarService.currentAddress.pipe(
         debounceTime(300),
         tap(
            (v) =>
               v === '' &&
               this.appraisalService.addFilter({
                  strasse: '',
                  hausnummer: '',
                  ort: '',
                  plz: '',
                  perimeter: config.defaultPerimeter
               })
         ),
         withLatestFrom(this.mapService.centerCoords),
         switchMap(([address, centerCoords]) => this.geoService.getOSMNodesByAddress(address, centerCoords))
      );

      this.objektArten$ = this.sidebarService.objektArten.pipe(mapDefaultFilterAsSelect());
      this.nutzungsArten$ = this.sidebarService.nutzungsArten.pipe(mapDefaultFilterAsSelect());
      this.lageQualitaeten$ = this.sidebarService.lageQualitaeten.pipe(mapDefaultFilterAsSelect());
      this.auftragsarten$ = this.sidebarService.auftragsarten.pipe(mapDefaultFilterAsSelect());
      this.auftraggeber$ = this.sidebarService.auftraggeber.pipe(
         map((a) => a.map((x) => ({ value: x.id, label: x.loraAuftraggeberName })))
      );
      this.baujahresKlassen$ = this.sidebarService.baujahresKlassen.pipe(mapDefaultFilterAsSelect());
      this.ausstattungsQualitaeten$ = this.sidebarService.ausstattungsQualitaeten.pipe(mapDefaultFilterAsSelect());
      this.nachhaltigkeitsZertifikate$ = this.sidebarService.nachhaltigkeitszertifikate.pipe(
         mapDefaultFilterAsSelect()
      );
      this.nutzungsArtenCombineOptions$ = this.sidebarService.nutzungsArtenCombineOption;
      this.extIntOptions$ = this.sidebarService.extIntAsSelectOption;
      this.energieeffizienzKlassen$ = this.sidebarService.energieeffizienzKlassenAsSelectOption;
      this.eigennutzungOptions$ = this.sidebarService.eigennutzungAsSelectOption;
      this.besonderheiten$ = this.sidebarService.besonderheitenAsSelectOption;
      this.objektzustand$ = this.sidebarService.objektzustandAsSelectOption;

      this.currentAddress$ = this.sidebarService.currentAddress;
      this.currentAddressValid$ = this.sidebarService.currentAddress.pipe(map((v) => v && v.trim().length > 0));

      this.initFilterObservables();
   }

   ngOnDestroy() {
      this.erbbaurechtSub?.unsubscribe();
      this.freitextOjektArtSub?.unsubscribe();
      this.loraIdSub?.unsubscribe();
   }

   onAddressValueSelected(node: IOSMNode): void {
      const address = node.address.addressString || node.displayName;
      this.sidebarService.setAddress(address);

      const street = node.address.road || '';
      const houseNumber = node.address.houseNumber || '';
      const city = node.address.cityName || '';
      const postcode = node.address.postcode || '';

      this.appraisalService.addFilter({
         strasse: street,
         hausnummer: houseNumber,
         ort: city,
         plz: postcode,
         lat: node.lat,
         lon: node.lon
      });

      this.mapService.setRadius(config.defaultPerimeter);
   }

   onAddressInputChange(val: string): void {
      this.sidebarService.setAddress(val);
   }

   onFreitextObjektArtChanged(value: string): void {
      const isValidString = value != null && value.length > 0;
      value = isValidString ? value : null;
      this.sidebarService.setFreitextObjektArt(value);
   }

   onLoraIdChanged(value: string): void {
      const isValidString = value != null && value.length > 0;
      value = isValidString ? value : null;
      this.sidebarService.setLoraId(value);
   }

   onErbbaurechtSelected(): void {
      this.onSelectionChanged(
         'besonderheitenIds',
         this.erbbaurechtActive ? [] : [{ value: 1, label: 'Erbbaurecht vorhanden' }]
      );
   }

   addFilter(filter: Partial<AppraisalFilter>): void {
      this.appraisalService.addFilter(filter);
   }

   onRangeChanged(key: keyof AppraisalFilter, val: string): void {
      this.addFilter({ [key]: tryParseInt(val) });
   }

   onSelectionChanged(key: keyof AppraisalFilter, options: SelectOptionNumber[]): void {
      if (options != null) {
         this.addFilter({ [key]: options.map((o) => +o.value) });
      }
   }

   onReset(): void {
      this.sidebarService.setAddress(null);
      const userPos = this.mapService.userPos;
      this.appraisalService.resetFilters({ lat: userPos.lat, lon: userPos.lng });
      this.mapService.setCenterCoords(userPos);
      this.mapService.setRadius(config.defaultPerimeter);
   }

   onRadiusChanged(perimeter: string): void {
      let per = Number(perimeter);
      if (per > 500) {
         per = 500.0;
         this.snackbarService.showWarning('Umkreis darf maximal 500km betragen!');
      }

      if (per <= 0) {
         per = 1;
         this.snackbarService.showWarning('Umkreis muss größer 0km sein!');
      }
      this.perimeterInput.nativeElement.value = String(per);
      this.mapService.setRadius(per);
      this.appraisalService.addFilter({ perimeter: per });
   }

   private initFilterObservables(): void {
      this.objektArten$ = this.sidebarService.objektArten.pipe(mapDefaultFilterAsSelect());
      this.nutzungsArten$ = this.sidebarService.nutzungsArten.pipe(mapDefaultFilterAsSelect());
      this.lageQualitaeten$ = this.sidebarService.lageQualitaeten.pipe(mapDefaultFilterAsSelect());
      this.baujahresKlassen$ = this.sidebarService.baujahresKlassen.pipe(mapDefaultFilterAsSelect());
      this.ausstattungsQualitaeten$ = this.sidebarService.ausstattungsQualitaeten.pipe(mapDefaultFilterAsSelect());
      this.nachhaltigkeitsZertifikate$ = this.sidebarService.nachhaltigkeitszertifikate.pipe(
         mapDefaultFilterAsSelect()
      );
      this.nutzungsArtenCombineOptions$ = this.sidebarService.nutzungsArtenCombineOption;
      this.extIntOptions$ = this.sidebarService.extIntAsSelectOption;
      this.energieeffizienzKlassen$ = this.sidebarService.energieeffizienzKlassenAsSelectOption;
      this.eigennutzungOptions$ = this.sidebarService.eigennutzungAsSelectOption;
      this.besonderheiten$ = this.sidebarService.besonderheitenAsSelectOption;
      this.objektzustand$ = this.sidebarService.objektzustandAsSelectOption;
      this.currentAddress$ = this.sidebarService.currentAddress;
      this.currentRadius$ = this.mapService.radius;
      this.currentFilter$ = this.appraisalService.currentFilter;
      this.filtersActive$ = this.appraisalService.checkFiltersActive();

      // Erbbaurrecht = 1
      this.erbbaurechtSub = this.currentFilter$
         .pipe(map(({ besonderheitenIds }) => besonderheitenIds.includes(1)))
         .subscribe((isActive) => (this.erbbaurechtActive = isActive));

      const unterArten$ = this.appraisalService.currentFilter.pipe(
         map(({ objektArtIds }) => objektArtIds),
         distinctUntilChanged(),
         withLatestFrom(this.sidebarService.objektArten),
         map(([selected, all]: [number[], ObjektArt[]]) => {
            if (selected.length == 0) {
               return _.flatMap(all.map((a: ObjektArt) => a.objektUnterArten));
            }
            const matching: number[] = selected.filter((art) => all.map((a) => a.id).includes(art));
            const objektArten: ObjektArt[] = all.filter((a) => matching.includes(a.id));
            return _.flatMap(objektArten.map((a) => a.objektUnterArten));
         })
      );


      this.objektUnterArten$ = unterArten$.pipe(
         mapDefaultFilterAsSelect()
      );

      this.freitextOjektArtSub = this.sidebarService.currentFreitextObjektArt
         .pipe(debounceTime(300))
         .subscribe((text) => this.addFilter({ freitextObjektArt: text }));

      this.loraIdSub = this.sidebarService.currentLoraId
         .pipe(debounceTime(300))
         .subscribe((text) => this.addFilter({ loraAuftragsNummer: text }));
   }
}
