<div class="row mt-4">
    <div class="col-md">
        <app-field label="Objekt in Bayern">
            {{data.inBayern | booleanToLanguage}}
        </app-field>
    </div>
    <div class="col-md" *ngIf="!data.inBayern">
        <app-field label="Baulastenauskunft vorhanden">
            {{data.baulastenAuskunftVorhanden | booleanToLanguage}}
        </app-field>
    </div>
    <div class="col-md" *ngIf="!data.inBayern && !data.baulastenAuskunftVorhanden">
        <app-field label="Gutachten ohne Baulastenauskunft erstellen?">
            {{GutachtenErstellungsTypNames[data.gutachtenOhneBaulastenauskunftErstellenMoeglich || '']}}
        </app-field>
    </div>
</div>
<div class="row mt-4" *ngIf="data.baulastenAuskunftVorhanden || data.baulastenVorhanden">
    <div class="col-md" *ngIf="data.baulastenAuskunftVorhanden">
        <app-field label="Baulasten vorhanden">
            {{data.baulastenVorhanden | booleanToLanguage}}
        </app-field>
    </div>
    <div class="col-md" *ngIf="data.baulastenVorhanden">
        <app-field label="Wertrelevanz?">
            {{data.wertrelevanz | booleanToLanguage}}
        </app-field>
    </div>
</div>
<div class="row mt-4" *ngIf="data.baulastenVorhanden || data.wertrelevanz">
    <div class="col-md" *ngIf="data.baulastenVorhanden">
        <app-field label="Text der Baulast in Kurzform">
            {{data.baulastKurztext}}
        </app-field>
    </div>
    <div class="col-md" *ngIf="data.wertrelevanz">
        <app-field label="Gutacherterliche Einschätzung der Baulast">
            {{data.gutachterlicheEinschaetzung}}
        </app-field>
    </div>
</div>