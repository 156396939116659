import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Appraisal } from '../models/Appraisal';

@Injectable({
    providedIn: 'root'
})
export class AppraisalCompareService {
    private _comparableAppraisalIds: BehaviorSubject<number[]> = new BehaviorSubject([]);
    private _currentSelection: BehaviorSubject<Appraisal[]> = new BehaviorSubject([]);
    private _comparableAppraisals: BehaviorSubject<Appraisal[]> = new BehaviorSubject([]);

    get currentSelection(): Observable<Appraisal[]> {
        return this._currentSelection.asObservable();
    }

    get comparableAppraisalIds(): Observable<number[]> {
        return this._comparableAppraisalIds.asObservable();
    }

    get comparableAppraisals(): Observable<Appraisal[]> {
        return this._comparableAppraisals.asObservable();
    }

    setComparableAppraisals(appraisals: Appraisal[]) {
        this._comparableAppraisals.next(appraisals);
    }

    initCurrentSelection(amount: number): void {
        const newIdSelection = this._comparableAppraisalIds.getValue().slice(0, amount);
        const appraisalSelection = newIdSelection.map((id: number) =>
            this._comparableAppraisals.getValue().find((a: Appraisal) => a.id === id)
        );

        this._currentSelection.next(appraisalSelection);
    }

    addOrRemoveAppraisal(appraisalId: number): void {
        if (this._comparableAppraisalIds.getValue().includes(appraisalId)) {
            this._comparableAppraisalIds.next([
                ...this._comparableAppraisalIds.getValue().filter((id) => id !== appraisalId)
            ]);
            this._comparableAppraisals.next([
                ...this._comparableAppraisals.getValue().filter((app) => app.id !== appraisalId)
            ]);
        } else {
            this._comparableAppraisalIds.next(
                Array.from(new Set([...this._comparableAppraisalIds.getValue(), appraisalId]))
            );
        }
    }

    replaceCurrentSelectedByIndex(appraisalId: number, i: number): void {
        const appraisalSelection: Appraisal[] = this._currentSelection.getValue();
        const newAppraisalForIndex = this._comparableAppraisals.value.find((a: Appraisal) => a.id === appraisalId);
        appraisalSelection.splice(i, 1, newAppraisalForIndex);
        this._currentSelection.next(appraisalSelection);
    }

    clearCompareBasket(): void {
        this._comparableAppraisalIds.next([]);
        this._comparableAppraisals.next([]);
        this._currentSelection.next([]);
    }
}
