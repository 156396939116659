import { LeafletControlLayersConfig } from '@asymmetrik/ngx-leaflet';
import { CircleMarkerOptions, LatLng, latLng, MapOptions, tileLayer } from 'leaflet';

// if app has no permission to get geo information of the user, the default center will be used
export const DEFAULT_CENTER_COORDS = latLng(50.138343, 8.57888);
export const DEFAULT_CENTER_CIRCLE_OPTIONS: CircleMarkerOptions = {
    radius: 100,
    fill: false,
    color: '#FF0000',
    fillOpacity: 0.7,
    weight: 2
};

export const DefaultMapOptions: MapOptions = {
    worldCopyJump: false,
    zoom: 20,
    zoomControl: false,
    layers: [
        tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            noWrap: true,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        })
    ],
    closePopupOnClick: true,
    scrollWheelZoom: true,
    doubleClickZoom: false
};

// with baselayers you enable the layer control of the map, so you can switch between different layer
export const DefaultLayersControl: LeafletControlLayersConfig = {
    baseLayers: {
        'Street Map': tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 20,
            noWrap: true,
            attribution: 'Default Map'
        })
    },
    overlays: {}
};

export const WORLD_BOUNDS = [
    // covers the (mercator projection) world
    new LatLng(85, 180),
    new LatLng(85, 90),
    new LatLng(85, 0),
    new LatLng(85, -90),
    new LatLng(85, -180),
    new LatLng(0, -180),
    new LatLng(-85, -180),
    new LatLng(-85, -90),
    new LatLng(-85, 0),
    new LatLng(-85, 90),
    new LatLng(-85, 180),
    new LatLng(0, 180),
    new LatLng(85, 180)
];

function numberToRad(num: number): number {
    return (num * Math.PI) / 180;
}

function numberToDeg(num: number): number {
    return (num * 180) / Math.PI;
}

export function calculateDestinationPoint(lat, lng, brng, dist): LatLng {
    dist = dist / 6371;
    brng = numberToRad(brng);

    const lat1 = numberToRad(lat),
        lon1 = numberToRad(lng);

    const lat2 = Math.asin(Math.sin(lat1) * Math.cos(dist) + Math.cos(lat1) * Math.sin(dist) * Math.cos(brng));

    const lon2 =
        lon1 +
        Math.atan2(Math.sin(brng) * Math.sin(dist) * Math.cos(lat1), Math.cos(dist) - Math.sin(lat1) * Math.sin(lat2));

    if (isNaN(lat2) || isNaN(lon2)) {
        return null;
    }

    return new LatLng(numberToDeg(lat2), numberToDeg(lon2));
}
