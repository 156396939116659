<p class="mt-5 display-6">Baurecht</p>

<app-card class="mt-4">
   <div body>
      <form [formGroup]="form">
         <mat-form-field class="col-md-4">
            <mat-label>Bebauungsplan</mat-label>
            <mat-select formControlName="bebauungsPlan" [(ngModel)]="brickData.bebauungsPlan">
               <mat-option *ngFor="let option of bebauungsPlanOptions" [value]="option">
                  {{BebauungsPlanNames[option]}}
               </mat-option>
            </mat-select>
         </mat-form-field>
         <div class="row">
            <mat-form-field class="col-md">
               <mat-label>Titel Bebauungsplan</mat-label>
               <input matInput formControlName="titelBebauungsplan" [(ngModel)]="brickData.bebauungsplanTitel">
            </mat-form-field>
            <mat-form-field class="col-md">
               <mat-label>Datum Inkrafttreten Bebauungsplan</mat-label>
               <input matInput [matDatepicker]="picker" formControlName="datumBebauungsplan"
                  [(ngModel)]="brickData.bebauungsplanDatum">
               <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
               <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="col-md">
               <mat-label>Festsetzungen</mat-label>
               <textarea matInput formControlName="festsetzungen" [(ngModel)]="brickData.festsetzungen"></textarea>
            </mat-form-field>
         </div>
         <div class="row">
            <mat-form-field class="col-md-4">
               <mat-label>Baugenehmigung</mat-label>
               <mat-select formControlName="baugenehmigung" [(ngModel)]="brickData.baugenehmigung">
                  <mat-option *ngFor="let option of baugenehmigungOptions" [value]="option">
                     {{BaugenehmigungNames[option]}}
                  </mat-option>
               </mat-select>
            </mat-form-field>
            <mat-form-field class="col-md-4">
               <mat-label>Gebrauchsabnahmenachweis</mat-label>
               <mat-select formControlName="gebrauchsAbnahmeNachweis" [(ngModel)]="brickData.gebrauchsAbnahmeNachweis">
                  <mat-option *ngFor="let option of gebrauchsAbnahmeNachweisOptions" [value]="option">
                     {{GebrauchsAbnahmeNachweisNames[option]}}
                  </mat-option>
               </mat-select>
            </mat-form-field>
         </div>
         <div class="hstack gap-2 justify-content-end mt-4">
            <button class="btn btn-light" (click)="cancel()">Abbrechen</button>
            <button class="btn btn-primary" (click)="save()">Speichern</button>
            <button class="btn btn-light" (click)="saveAndExit()">Speichern & Schließen</button>
         </div>
      </form>
   </div>
</app-card>