import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(private apiService: ApiService) { }

  getWord(id: number): Observable<Blob> {

    const url = `${environment.appraisalTextDownloadUrl}/${id}`;
    console.log(url);
    return this.apiService.get<Blob>(url, null, 'blob');
    // return this.apiService.get<RequestData<Blob>>(url);
    // return this.apiService.get<Blob>(environment.fileUrl + '/ExcelAppraisals', null, 'blob');
  }

  // fetchExcelFile(ids: number[]): Observable<Blob> {
  //   const httpParams = new HttpParams({
  //     fromObject: {
  //       ids: ids.map(String)
  //     }
  //   });
  //   return this.apiService.get<Blob>(environment.fileUrl + '/ExcelAppraisals', httpParams, 'blob');
  // }
}
