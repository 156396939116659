import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListViewComponent } from './components/list-view/list-view.component';
import { MapViewComponent } from './components/map-view/map-view.component';
import { SegmentSearchComponent } from './components/segment-search/segment-search.component';

const routes: Routes = [
    {
        path: '',
        component: SegmentSearchComponent,
        data: {
            title: 'Informationen finden'
        },
        children: [
            {
                path: '',
                redirectTo: 'map',
                pathMatch: 'full'
            },
            {
                path: 'map',
                component: MapViewComponent,
                data: {
                    label: 'Karte'
                }
            },
            {
                path: 'list',
                component: ListViewComponent,
                data: {
                    label: 'Liste'
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SearchRoutingModule {}
