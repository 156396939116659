import {Component, Input} from '@angular/core';
import {MimeTypes} from "../../core/enums/MimeTypes";
import {ICollectionListView} from "../../collection-upload/models/Collection";

@Component({
    selector: 'app-collection-sidebar-entry',
    templateUrl: './collection-sidebar-entry.component.html',
    styleUrls: ['./collection-sidebar-entry.component.scss']
})
export class CollectionSidebarEntryComponent {
    @Input() data: ICollectionListView[];
    @Input() isOpen: boolean;
    @Input() title: string;
    protected readonly MimeTypes = MimeTypes;

    constructor() {
    }

    

}
