import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { Appraisal } from '../../appraisal-detail/models/Appraisal';
import { AppraisalCompareService } from '../../appraisal-detail/services/appraisal-compare.service';
import { AppraisalService } from '../../appraisal-detail/services/appraisal.service';

@Injectable({ providedIn: 'root' })
export class AppraisalCompareResolver implements Resolve<Appraisal[]> {
    constructor(private appraisalService: AppraisalService, private appraisalCmpService: AppraisalCompareService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<Appraisal[]> | Promise<Appraisal[]> | Appraisal[] {
        return this.appraisalCmpService.comparableAppraisalIds.pipe(
            take(1),
            switchMap((ids) => this.appraisalService.getAppraisalsById(ids))
        );
    }
}
