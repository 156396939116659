import { Injectable } from '@angular/core';
import { OidcClient, SigninRequest } from 'oidc-client';
import { from, Observable, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { oidcClientSettings } from '../../../environments/environment';
import { Destroyable } from '../../shared/directives/destroyable';
import { getToken } from 'codelyzer/angular/styles/cssLexer';
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
    providedIn: 'root'
})
export class AuthService extends Destroyable {
    public startSignInProcess(): Observable<SigninRequest> {
        const client = new OidcClient(oidcClientSettings);

        return from(client.createSigninRequest()).pipe(
            catchError(() => {
                return of(null);
            })
        );
    }

    public startLogoutProcess() {
        localStorage.clear();
        const client = new OidcClient(oidcClientSettings);
        from(client.createSignoutRequest())
            .pipe(takeUntil(this._destroyed$))
            .subscribe(({ url }) => (window.location.href = url));
    }

    public setToken(token: string): void {
        localStorage.setItem('token', token);
    }

    public getToken(): string {
        return localStorage.getItem('token');
    }

    public isUploader(): boolean {
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(this.getToken());
        return decodedToken?.roles?.includes("file_uploader");
    }
}
