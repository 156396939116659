<ng-container *ngIf="appraisal$ | async as appraisal">
   <div class="frame">
      <div class="header">
         <h3>
            <span (click)="showSnackbar('ID wurde kopiert!')" [cdkCopyToClipboard]="appraisal | getAppraisalId">{{
               appraisal | getAppraisalId }} <button class="default">ID kopieren</button></span>
         </h3>
      </div>
      <div class="content-component">
         <div class="box">
            <app-img [id]="appraisal.id" type="big"></app-img>
         </div>
         <div class="box">
            <app-appraisal-box [title]="'Objektbeschreibung'">
               <app-appraisal-objektbeschreibung [appraisal]="appraisal"></app-appraisal-objektbeschreibung>
            </app-appraisal-box>
         </div>
         <!--            <div class="box">-->
         <!--                <app-appraisal-box [title]="'Nach Nutzungsart'">-->
         <!--                    <app-appraisal-nutzungsart [appraisal]="appraisal"></app-appraisal-nutzungsart>-->
         <!--                </app-appraisal-box>-->
         <!--            </div>-->
         <div class="box">
            <app-appraisal-box [title]="'Ausstattung/Lage'">
               <app-appraisal-ausstattung-lage [appraisal]="appraisal"></app-appraisal-ausstattung-lage>
            </app-appraisal-box>
         </div>
         <div class="box" *ngIf="appraisal.besonderheiten.length">
            <app-appraisal-box [title]="'Besonderheiten'">
               <app-appraisal-besonderheiten [besonderheiten]="appraisal.besonderheiten"></app-appraisal-besonderheiten>
            </app-appraisal-box>
         </div>
         <div class="box" *ngIf="appraisal.fazit as content">
            <app-appraisal-box [title]="'Fazit'" [copytext]="content">
               <span [innerHTML]="content"></span>
            </app-appraisal-box>
         </div>
         <div class="box" *ngIf="appraisal.lage?.mikrolage as content">
            <app-appraisal-box [title]="'Mikrolage'" [copytext]="content">
               <span [innerHTML]="content"></span>
            </app-appraisal-box>
         </div>
         <div class="box" *ngIf="appraisal.lage?.makrolage as content">
            <app-appraisal-box [title]="'Makrolage'" [copytext]="content">
               <span [innerHTML]="content"></span>
            </app-appraisal-box>
         </div>
         <div class="box" *ngIf="appraisal.lage?.lageAllgemein as content">
            <app-appraisal-box [title]="'Lage Allgemein'" [copytext]="content">
               <span [innerHTML]="content"></span>
            </app-appraisal-box>
         </div>
         <div class="box" *ngIf="appraisal.lage?.vermietungsmarkt as content">
            <app-appraisal-box [title]="'Vermietungsmarkt'" [copytext]="content">
               <span [innerHTML]="content"></span>
            </app-appraisal-box>
         </div>
         <div class="box" *ngIf="appraisal.lage?.investmentmarkt as content">
            <app-appraisal-box [title]="'Investmentmarkt'" [copytext]="content">
               <span [innerHTML]="content"></span>
            </app-appraisal-box>
         </div>
         <div class="box" *ngIf="appraisal.lage?.immobilienmarkt as content">
            <app-appraisal-box [title]="'Immobilienmarkt'" [copytext]="content">
               <span [innerHTML]="content"></span>
            </app-appraisal-box>
         </div>
      </div>
   </div>
</ng-container>