import {HttpParams} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {DocumentNode} from 'graphql';
import {LatLng} from 'leaflet';
import _ from 'lodash';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, finalize, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import config from '../../core/config/config';
import {ApiService} from '../../core/services/api.service';
import {GraphqlService} from '../../core/services/graphql.service';
import {LoadingService} from '../../core/services/loading.service';
import {difference} from '../../core/utils/utils';
import {AppraisalQueries} from '../../shared/graph-ql/Query';
import {Pagination} from '../../shared/models/Pagination';
import {
    Appraisal,
    AppraisalFilter,
    AppraisalUploadSorting,
    Entwicklung,
    IAppraisalListView,
    IAppraisalMapPopupView,
    IAppraisalMapView,
    IPaginationResult,
    PagedData,
    RequestData
} from '../models/Appraisal';

// Thought: Maybe lat lon should not be part of the filter, because it only indirectly indicates which appraisals to load
const InitialFilter: AppraisalFilter = {
    freitextObjektArt: null,
    ausstattungsQualitaetIds: [],
    baujahresKlasseIds: [],
    lageQualitaetIds: [],
    lat: null,
    lon: null,
    nutzungsArtIds: [],
    nutzungsArtenCombineId: 1,
    objektUnterArtIds: [],
    minFlaeche: null,
    maxFlaeche: null,
    minWertErmittlung: null,
    maxWertErmittlung: null,
    minBaujahr: null,
    maxBaujahr: null,
    minFiktivesBaujahr: null,
    maxFiktivesBaujahr: null,
    minMittleresModernisierungsjahr: null,
    maxMittleresModernisierungsjahr: null,
    minSanierungsjahr: null,
    maxSanierungsjahr: null,
    objektArtIds: [],
    strasse: '',
    hausnummer: '',
    ort: '',
    plz: '',
    perimeter: config.defaultPerimeter,
    isInternal: null,
    eigennutzung: null,
    nachhaltigkeitszertifikatIds: [],
    energieeffizienzKlasseIds: [],
    besonderheitenIds: [],
    keineBesonderheiten: false,
    objektzustandIds: [],
    auftragsartIds: [],
    auftraggeberIds: [],
    loraAuftragsNummer: null
};

@Injectable({
    providedIn: 'root'
})
export class AppraisalService implements OnDestroy {
    private currentAppraisalPage$: BehaviorSubject<PagedData<IAppraisalListView>> = new BehaviorSubject(null);
    private currentAppraisalsForMapView$: BehaviorSubject<IAppraisalMapView[]> = new BehaviorSubject([]);

    private filterSubject$: BehaviorSubject<AppraisalFilter> = new BehaviorSubject(InitialFilter);
    private listPageFilterSubject$: BehaviorSubject<Pagination> = new BehaviorSubject(
        new Pagination(Pagination.FirstPageIndex, 200)
    );
    private mapPageFilterSubject$: BehaviorSubject<Pagination> = new BehaviorSubject(
        new Pagination(Pagination.FirstPageIndex, 1000)
    );

    private mapPaginationResult: BehaviorSubject<IPaginationResult<IAppraisalMapView>> = new BehaviorSubject(null);

    private sortSubject$: BehaviorSubject<AppraisalUploadSorting | null> = new BehaviorSubject(null);

    private filterSub: Subscription;
    private mapFilterSub: Subscription;

    constructor(
        private apiService: ApiService,
        private gqlService: GraphqlService,
        private loadingService: LoadingService
    ) {
    }

    get currentFilter(): Observable<AppraisalFilter> {
        return this.filterSubject$.asObservable().pipe(shareReplay(1));
    }

    get appraisalsFiltered(): Observable<PagedData<IAppraisalListView>> {
        return this.currentAppraisalPage$.asObservable().pipe(filter((x) => !!x));
    }

    get appraisalsFilteredMapView(): Observable<IAppraisalMapView[]> {
        return this.currentAppraisalsForMapView$.asObservable();
    }

    get paginationResultMapView(): Observable<IPaginationResult<IAppraisalMapView>> {
        return this.mapPaginationResult.asObservable();
    }

    get listPageFilterChanges(): Observable<Pagination> {
        return this.listPageFilterSubject$
            .asObservable()
            .pipe(distinctUntilChanged((prev, curr) => prev.page === curr.page && prev.size === curr.size));
    }

    ngOnDestroy(): void {
        this.filterSub?.unsubscribe();
    }

    getAppraisalsFiltered<T>(
        appraisalFilter: {},
        pageFilter: {},
        query: DocumentNode,
        sorting?: AppraisalUploadSorting
    ): Observable<PagedData<T>> {
        let variables = {
            filter: appraisalFilter,
            pageOptions: pageFilter
        };

        if (sorting) {
            variables['sortOptions'] = {
                orderBy: sorting.active,
                sortDirection: sorting.direction
            };
        }
        return this.gqlService.watchQuery(query, {...variables}).pipe(map(({data}) => data?.appraisals));
    }

    getEntwicklungen(years: number): Observable<Entwicklung[]> {
        this.loadingService.setLoading(true);
        const yearsAgo = new Date();
        yearsAgo.setMonth(0, 1);
        yearsAgo.setFullYear(yearsAgo.getFullYear() - years);
        const filterYears = {
            maxKaufpreisDatum: new Date(),
            minKaufpreisDatum: yearsAgo
        };

        return this.gqlService.watchQuery(AppraisalQueries.getAppraisalsForEntwicklungen, {filter: filterYears}).pipe(
            tap(() => this.loadingService.setLoading(false)),
            map(({data}) => data?.entwicklungen || [])
        );
    }

    getAppraisalById(id: number): Observable<Appraisal> {
        return this.getAppraisalsWithDistance<Appraisal>(`${environment.appraisalUrl}/${id}`);
    }

    getAppraisalsById(ids: number[]): Observable<Appraisal[]> {
        const params = new HttpParams({
            fromObject: {
                ids: ids.map(String)
            }
        });

        return this.getAppraisalsWithDistance<Appraisal[]>(environment.appraisalsByIdUrl, params);
    }

    getAppraisalForMapById(id: number): Observable<IAppraisalMapPopupView> {
        this.loadingService.setLoading(true);
        return this.gqlService.query(AppraisalQueries.getAppraisalForMapView, {id}).pipe(
            map(({data}) => data.appraisal),
            finalize(() => this.loadingService.setLoading(false))
        );
    }

    addFilter(appraisalFilter: Partial<AppraisalFilter>): void {
        this.filterSubject$.next({...this.filterSubject$.getValue(), ...appraisalFilter});
    }

    resetFilters(defaults: Partial<AppraisalFilter>): void {
        this.addFilter({...InitialFilter, ...defaults});
    }

    checkFiltersActive(): Observable<boolean> {
        return this.currentFilter.pipe(
            map((appFilter) => {
                const diff = difference(appFilter, InitialFilter, ['lat', 'lon']);
                return Object.keys(diff).length > 0;
            })
        );
    }

    updateListPagination(page: number, size: number): void {
        this.listPageFilterSubject$.next(new Pagination(page, size));
    }

    updateSorting(sort: AppraisalUploadSorting): void {
        this.sortSubject$.next(sort);
    }

    get currentSorting(): Observable<AppraisalUploadSorting> {
        return this.sortSubject$.asObservable().pipe(shareReplay(1));
    }

    listToFilterChangesListView(): void {
        if (this.filterSub) {
            return;
        }

        const filterObs = combineLatest([
            this.currentFilter.pipe(
                tap(() => {
                    const pagination = this.listPageFilterSubject$.getValue();
                    this.updateListPagination(Pagination.FirstPageIndex, pagination.size);
                })
            ),
            this.listPageFilterChanges,
            this.currentSorting
        ]);

        const filterObsWithAppraisal = this.attachAppraisalFetchPipe(
            filterObs,
            AppraisalQueries.getAppraisalsForListView
        );

        this.filterSub = filterObsWithAppraisal.subscribe((pData: PagedData<IAppraisalListView>) =>
            this.currentAppraisalPage$.next(pData)
        );
    }

    listToFilterChangesMapView(): void {
        if (this.mapFilterSub) {
            return;
        }

        const filterObs = combineLatest([this.currentFilter, this.mapPageFilterSubject$.asObservable()]);

        const filterObsWithAppraisal = this.attachAppraisalFetchPipe(
            filterObs,
            AppraisalQueries.getAppraisalsForMapView
        );

        this.mapFilterSub = filterObsWithAppraisal.subscribe(
            ({data, ...paginationInfo}: PagedData<IAppraisalMapView>) => {
                this.currentAppraisalsForMapView$.next(data || []);
                this.mapPaginationResult.next(paginationInfo);
            }
        );
    }

    private attachAppraisalFetchPipe<T>(filterObs: Observable<any>, query: DocumentNode): Observable<any> {
        return filterObs.pipe(
            distinctUntilChanged((prev, current) => _.isEqual(prev, current)),
            filter(([appraisalFilter, _]) => appraisalFilter.lat != null && appraisalFilter.lon != null),
            tap(() => this.loadingService.setLoading(true)),
            switchMap(([appraisalFilter, pagination, sort]: [AppraisalFilter, Pagination, AppraisalUploadSorting | undefined]) =>
                this.getAppraisalsFiltered<T>(appraisalFilter, pagination.toApi(), query, sort)
            ),
            tap(() => this.loadingService.setLoading(false))
        );
    }

    /**
     * This function fetches a single or multiple appraisals with distance depending on url and type
     * @param url
     * @param params
     * @private
     */
    private getAppraisalsWithDistance<T>(url: string, params: HttpParams = new HttpParams()): Observable<T> {
        const coordString = localStorage.getItem('coords');
        const coords = JSON.parse(coordString) as LatLng;

        if (coords != null) {
            params = params.append('lat', coords.lat.toString()).append('lng', coords.lng.toString());
        }

        this.loadingService.setLoading(true);
        return this.apiService.get<RequestData<T>>(url, params).pipe(
            map(({data}) => data),
            finalize(() => this.loadingService.setLoading(false))
        );
    }
}
