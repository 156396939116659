<div class="div-content">
    <b>Adresse</b>
    <span>
        {{ appraisal.plz | placeholder }} {{ appraisal.ort | placeholder }} <br />{{ appraisal.strasse | placeholder }}
        {{ appraisal.hausnummer | placeholder }}
    </span>
    <b>{{ appraisal.objektUnterArt ? 'Objektunterart' : 'Objektart' }}</b>
    <span>{{ appraisal.objektUnterArt?.name || appraisal.objektArt.name }}</span>
    <!--         <span *ngIf="appraisal-detail-boxes.objektUnterArt !== null">{{ appraisal-detail-boxes.objektUnterArt?.name | placeholder }}</span>-->
    <b>Marktwert</b>
    <span>
        <app-number-display [value]="appraisal.marktwert" [placeHolderParams]="{ suffix: '€' }"></app-number-display>
    </span>
    <b>Marktwert in €/m²</b>
    <span>
        <app-number-display
            [value]="appraisal.marktwertProQuadratmeter"
            [placeHolderParams]="{ suffix: '€/m²' }"
        ></app-number-display>
    </span>
    <b>Rohertragsvervielfältiger</b>
    <span>
        <ng-container>
            {{ appraisal.rohertragsvervielfaeltiger | number: '1.1-1' }}
        </ng-container>

        <!--            <app-number-display [value]="appraisal-detail-boxes.rohertragsvervielfaeltiger" [decimalPlaces]="1"></app-number-display>-->
    </span>
    <b>Gutachter</b> <span>{{ appraisal.gutachter | placeholder }}</span> <b>Bewertungsstichtag</b>
    <span>{{ appraisal.bewertungsstichtag | date: 'dd.M.yyyy' | placeholder }}</span>
    <b>Auftraggeber</b>
    <span>{{ appraisal.auftraggeber?.loraAuftraggeberName | placeholder }}</span>
    <b>Auftragsart</b>
    <span>{{ appraisal.auftragsart?.name | placeholder }}</span>
    <b>Gesamtmietfläche</b>
    <span>
        <app-number-display
            [value]="appraisal.wohnNutzflaeche"
            [placeHolderParams]="{ suffix: 'm²' }"
        ></app-number-display>
    </span>
    <b>Grundstücksfläche</b>
    <span>
        <app-number-display
            [value]="appraisal.summeFlaechen"
            [placeHolderParams]="{ suffix: 'm²' }"
        ></app-number-display>
    </span>
    <b>Restnutzungsdauer</b>
    <span>{{ appraisal.restnutzungsdauer | gibtRndRange: appraisal.restnutzungsdauerFlaechenGewichtet }}</span>
    <b>Kaufpreis</b>
    <span>
        <app-number-display [value]="appraisal.kaufpreis" [placeHolderParams]="{ suffix: '€' }"></app-number-display>
    </span>
    <b>Kaufpreis Datum</b>
    <span>{{ appraisal.kaufpreisDatum | date: 'dd.M.yyyy' | placeholder }}</span>
    <b>Kaufpreis Qualität</b>
    <span>{{ KaufpreisQualitaetNames[appraisal.kaufpreisQualitaet] || '' }}</span>
</div>
