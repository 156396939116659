import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import * as L from 'leaflet';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { IAppraisalMapView } from '../../../../appraisal-detail/models/Appraisal';
import { AppraisalService } from '../../../../appraisal-detail/services/appraisal.service';
import { MapService } from '../../../../core/services/map.service';
import { DEFAULT_CENTER_CIRCLE_OPTIONS } from '../../../../core/utils/map.utils';
import { CustomCountControl } from './controls/CustomControls';

@Component({
   selector: 'app-map',
   templateUrl: './map.component.html',
   styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {
   map: L.Map;

   mapOptions: L.MapOptions;

   markerClusterOptions: L.MarkerClusterGroupOptions = {
      animateAddingMarkers: true,
      animate: true,
      showCoverageOnHover: false,
      chunkedLoading: true
   };

   centerCoords: L.LatLng;

   markerClusterGroup: L.MarkerClusterGroup;
   zoom$: number;

   countCustomControl: L.Control;
   totalRecordsFound$: Observable<number>;

   marker$: Observable<L.Layer[]>;

   private radius = 0;
   private currentCenterLayer: L.Circle;
   private polygon: L.Polygon;

   private coordSubscription: Subscription;

   constructor(
      private mapService: MapService,
      private appraisalService: AppraisalService,
      private zone: NgZone
   ) { }

   ngOnInit() {
      this.mapOptions = this.mapService.mapOptions;
      this.zoom$ = this.mapService.zoom;

      this.coordSubscription = combineLatest([
         this.mapService.radius.pipe(distinctUntilChanged(), debounceTime(200)),
         this.mapService.centerCoords.pipe(distinctUntilChanged())
      ]).subscribe(([radius, position]) => {
         this.radius = radius;
         this.centerCoords = position;
         this.repositionCenter(position, radius);
      });

      this.marker$ = this.appraisalService.appraisalsFilteredMapView.pipe(
         map((appraisals: IAppraisalMapView[]) =>
            appraisals.map((appraisal) => this.mapService.createAppraisalMarker(appraisal))
         )
      );

      this.totalRecordsFound$ = this.appraisalService.paginationResultMapView.pipe(
         filter((o) => !!o),
         map((result) => result?.totalRecords || 0)
      );
   }

   ngOnDestroy(): void {
      this.coordSubscription?.unsubscribe();

      this.map.removeControl(this.countCustomControl);
   }

   mapReady(lMap: L.Map): void {
      this.map = lMap;

      this.countCustomControl = new CustomCountControl({ position: 'topright' }).addTo(lMap);

      // Adds Map Click event handler
      this.onMapClick();
   }

   markerClusterReady(group: L.MarkerClusterGroup) {
      this.markerClusterGroup = group;
   }

   setCenter(coords: L.LatLng): void {
      this.mapService.setCenterCoords(coords);
   }

   setZoom(zoom: number): void {
      this.mapService.setZoom(zoom);
   }

   private onMapClick(): void {
      this.map.on('click', (e: L.LeafletMouseEvent) => {
         // is need for angular to detect changes happening on the osm map
         // all events happening on the map run outside of angular change detection zone
         this.zone.run(() => {
            this.setCenter(new L.LatLng(e.latlng.lat, e.latlng.lng));
            this.appraisalService.addFilter({ lat: e.latlng.lat, lon: e.latlng.lng });
         });
      });
   }

   // creates a polygon that darkens the map, if a radius is selected
   private repositionPolygon(center: L.LatLng, radius: number): void {
      this.removeCustomLayerIfNeeded(this.polygon);

      if (radius === 0) {
         return;
      }

      const polygonOptions: L.PolylineOptions = { fillColor: '#000', color: '#616161', fillOpacity: 0.3 };
      const [worldbounds, circlebounds] = this.mapService.getOverlayPolygonBounds(center, radius);
      this.polygon = new L.Polygon([worldbounds, circlebounds], polygonOptions).addTo(this.map);
      const marker = circlebounds.map((x) => new L.Marker(x));
      const group = new L.FeatureGroup(marker);
      // moves the map to a specific latlng value
      this.map.fitBounds(group.getBounds());
   }

   // creates a small circle that determines the center of a the map
   private repositionCenter(center: L.LatLng, radius: number): void {
      this.removeCustomLayerIfNeeded(this.currentCenterLayer);
      this.currentCenterLayer = this.mapService.createCircle(center, DEFAULT_CENTER_CIRCLE_OPTIONS).addTo(this.map);

      this.repositionPolygon(center, radius);
   }

   private removeCustomLayerIfNeeded(layer: L.Layer): void {
      if (!!layer && this.map.hasLayer(layer)) {
         this.map.removeLayer(layer);
      }
   }
}
