<mat-accordion>
   <mat-expansion-panel [expanded]="true" class="mt-4 shadow-sm">
      <mat-expansion-panel-header class="bg-light">
         <mat-panel-title>Allgemein</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row gap-2 mt-4">
         <app-field label="Art" class="col-md">
            {{data.gutachtenTextObjektUnterArt.gutachtenTextObjektArt.name}}
         </app-field>
         <app-field label="Unterart" class="col-md">
            {{data.gutachtenTextObjektUnterArt.name}}
         </app-field>
         <app-field label="Kategorie" class="col-md">
            {{data.gutachtenTextObjektUnterArt.gutachtenTextObjektKategorie.name}}
         </app-field>
         <app-field label="USt. Art" class="col-md">
            {{UmsatzSteuerArtNames[data.umsatzSteuerArt || ''] }}
         </app-field>
      </div>
   </mat-expansion-panel>
</mat-accordion>

<mat-accordion>
   <mat-expansion-panel class="mt-4 shadow-sm" [expanded]="true">
      <mat-expansion-panel-header class="bg-light">
         <mat-panel-title>Baukosten</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row mt-4 gap-2">
         <app-field label="Bezugseinheit" class="col-md">
            {{data.bezugsEinheit.name + ' (' + data.bezugsEinheit.kuerzel + ')'}}
         </app-field>
         <app-field label="BPI aktuell" class="col-md">
            {{data.bpiAktuell | number:'':'de-DE'}}
         </app-field>
         <app-field label="BPI bei Veröffentlichung" class="col-md">
            {{data.bpiBeiVeroeffentlichung | number:'':'de-DE'}}
         </app-field>
         <app-field label="Anpassung" class="col-md">
            {{data.bpiAnpassung | number:'':'de-DE'}}
         </app-field>
      </div>
      <div class="row mt-4">
         <div class="col-md">
            <table class="table table-sm table-hover w-auto">
               <thead>
                  <tr class="text-center">
                     <th></th>
                     <th>Mittelwert</th>
                     <th>von</th>
                     <th>bis</th>
                  </tr>
               </thead>
               <tbody class="text-center">
                  <tr>
                     <td class="text-start">ohne Anpassungen</td>
                     <td>{{data.bezugsEinheitMittelWert | number:'':'de-DE'}}</td>
                     <td>{{data.bezugsEinheitVon | number:'':'de-DE'}}</td>
                     <td>{{data.bezugsEinheitBis | number:'':'de-DE'}}</td>
                  </tr>
                  <tr>
                     <td class="text-start">regionalisiert, an BPI angepasst, brutto</td>
                     <td>{{data.bezugsEinheitMittelWertInklUSt | number:'':'de-DE'}}</td>
                     <td>{{data.bezugsEinheitVonInklUSt | number:'':'de-DE'}}</td>
                     <td>{{data.bezugsEinheitBisInklUSt | number:'':'de-DE'}}</td>
                  </tr>
                  <tr *ngIf="data.umsatzSteuerArt !== umsatzSteuerArt.UStBefreit">
                     <td class="text-start">regionalisiert, an BPI angepasst, netto</td>
                     <td>{{data.bezugsEinheitMittelWertOhneUSt | number:'':'de-DE'}}</td>
                     <td>{{data.bezugsEinheitVonOhneUSt | number:'':'de-DE'}}</td>
                     <td>{{data.bezugsEinheitBisOhneUSt | number:'':'de-DE'}}</td>
                  </tr>
               </tbody>
            </table>
         </div>
      </div>
   </mat-expansion-panel>
</mat-accordion>

<mat-accordion>
   <mat-expansion-panel class="mt-4 shadow-sm">
      <mat-expansion-panel-header class="bg-light">
         <mat-panel-title>Stellplätze</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row mt-4 gap-2">
         <app-field label="Außenstellplatz vorhanden" class="col-md">
            {{data.aussenStellplatzVorhanden | booleanToLanguage}}
         </app-field>
         <app-field *ngIf="data.aussenStellplatzVorhanden" label="Stellplatz Art" class="col-md">
            {{PkwStellplatzArtNames[data.pkwStellplatzArt || ''] }}
         </app-field>
         <app-field *ngIf="data.aussenStellplatzVorhanden" label="Kosten pro Stellplatz" class="col-md">
            {{data.kostenProStellplatz | currency:'EUR':symbol}}
         </app-field>
      </div>
   </mat-expansion-panel>
</mat-accordion>

<mat-accordion class="container">
   <mat-expansion-panel class="shadow-sm">
      <mat-expansion-panel-header class="bg-light">
         <mat-panel-title>Zusatz</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row mt-4 gap-2">
         <app-field label="Vermietungssituation" class="col-md" *ngIf="data.umsatzSteuerArt === umsatzSteuerArt.UStBefreit">
            {{VermietungsSituationNames[data.vermietungsSituation || ''] }}
         </app-field>
         <app-field label="Einordnung Kostenansatz in Referenzspanne - Wohnen" class="col-md" *ngIf="data.umsatzSteuerArt !== umsatzSteuerArt.ZzglUSt">
            {{BauKostenSpanneNames[data.einordnungBaukostenSpanneWohnen || ''] }}
         </app-field>
         <app-field label="Einordnung Kostenansatz in Referenzspanne - Gewerbe" class="col-md" *ngIf="data.vermietungsSituation !== vermietungsSituation.IstEigengenutzt">
            {{BauKostenSpanneNames[data.einordnungBaukostenSpanneGewerbe || ''] }})
         </app-field>
      </div>
   </mat-expansion-panel>
</mat-accordion>