import {
    Auftraggeber,
    Auftragsart,
    AusstattungsQualitaet,
    Baujahr,
    Besonderheiten,
    Lage,
    ObjektArt,
    Objektzustand,
    Restnutzungsdauer
} from '../interfaces/AppraisalFilterTypes';
import { KaufpreisQualitaet } from '../../core/enums/KaufpreisQualitaet';
import { SortDirection } from '../../collection-upload/models/Collection';

export type Appraisal = Partial<{
    id: number;
    title: string;
    objektArt: ObjektArt;
    objektUnterArt: ObjektArt;
    marktwert: number;
    laengengrad: number;
    breitengrad: number;
    strasse: string;
    hausnummer: string;
    ort: string;
    entfernung: number;
    plz: string;
    auftraggeber: Auftraggeber;
    wertErtrag: number;
    wertEinfluesse: boolean;
    ausstattungsQualitaet: AusstattungsQualitaet;
    besonderheiten: Besonderheiten[];
    bild: string;
    bewertungsstichtag: Date;
    baujahr: Baujahr[];
    baujahrFlaechengewichtet: number;
    flaeche: number;
    bodenwert: number;
    wohnNutzflaeche: number;
    anzahlStellplaetze: number;
    fazit: string;
    erbbaurechtVorhanden: boolean;
    bautraegermassnahmeVorhanden: boolean;
    denkmalschutzVorhanden: boolean;
    zustandswertVorhanden: boolean;
    liquidationswertVorhanden: boolean;
    marktanpassungVorhanden: boolean;
    anzahlMieteinheiten: number;
    leerstandStrukturellVorhanden: boolean;
    leerstandTemporaerVorhanden: boolean;
    leerstandGesamtVorhanden: boolean;
    angesetzteBewirtschaftungskosten: number;
    rohertragsvervielfaeltiger: number;
    gutachter: string;
    restnutzungsdauer: Restnutzungsdauer[];
    restnutzungsdauerFlaechenGewichtet: number;
    nichtRentierlicherBodenwertVorhanden: boolean;
    marktwertProQuadratmeter: number;
    mieteDurchschnitt: number;
    liegenschaftszinsDurchschnitt: number;
    kaufpreis: number;
    kaufpreisProQm: number;
    kaufpreisDatum: Date;
    kaufpreisQualitaet: KaufpreisQualitaet;
    bodenwertanteil: number;
    bodenwertAnsatzDurchschnitt: number;
    sachwertfaktor: number;
    lage: Lage;
    mieteIstWohnenDurchschnitt: number;
    mieteIstGewerbeDurchschnitt: number;
    mieteIstBueroDurchschnitt: number;
    mieteIstHandelDurchschnitt: number;
    mieteIstLagerDurchschnitt: number;
    mieteIstSonstigesDurchschnitt: number;
    mieteAnsatzWohnenDurchschnitt: number;
    mieteAnsatzGewerbeDurchschnitt: number;
    mieteAnsatzBueroDurchschnitt: number;
    mieteAnsatzSonstigesDurchschnitt: number;
    mieteAnsatzHandelDurchschnitt: number;
    mieteAnsatzStellplaetzeGewerbeDurchschnitt: number;
    mieteAnsatzStellplaetzeWohnenDurchschnitt: number;
    nutzflaeche: number;
    nutzflaecheBuero: number;
    nutzflaecheLager: number;
    nutzflaecheHandel: number;
    nutzflaecheSonstiges: number;
    wohnflaeche: number;
    liegenschaftzinsWohnenDurchschnitt: number;
    liegenschaftzinsGewerbeDurchschnitt: number;
    liegenschaftzinsBueroDurchschnitt: number;
    liegenschaftzinsHandelDurchschnitt: number;
    liegenschaftzinsSonstigesDurchschnitt: number;
    baukostenWohnflaecheDurchschnitt: number;
    kaufpreisHauptnutzungProQm: number;
    angemessenerWertStellplaetzeDurchschnitt: number;
    angemessenerWertHauptnutzungDurchschnitt: number;
    kaufpreisStellplaetzeInEuroProStueck: number;
    objektzustand: Objektzustand;
    renditeIst: number;
    renditeAnsatz: number;
    loraAuftragsNummer: string;
    datenQuelle: number;
    giksId: string;
    fiktivesBaujahr: number;
    summeFlaechen: number;
    auftragsart: Auftragsart;
    nettoanfangsrenditeMwt: number;
    mieteIstStellplaetzeGesamtDurchschnitt: number;
    mieteAnsatzStellplaetzeGesamtDurchschnitt: number;
    mieteAnsatzDurchschnitt: number;
}>;

export interface IAppraisalListView {
    gutachtenId: number;
    bewertungsstichtag: Date;
    entfernung: number;
    marktwert: number;
    marktwertProQuadratmeter: number;
    auftraggeberName: string;
    besonderheitenVorhanden: boolean;
    baujahr: Baujahr[];
    baujahrFlaechengewichtet: number;
    auftragsart: Auftragsart;
    ausstattungsQualitaetName: string;
    lageQualitaetName: string;
    objektUnterArtName: string;
    ort: string;
    pLZ: string;
    strasse: string;
    hausnummer: string;
}

export interface IAppraisalMapPopupView {
    gutachtenId: number;
    bewertungsstichtag: Date;
    entfernung: number;
    marktwert: number;
    auftraggeber: Auftraggeber;
    objektArt: IObjektart;
    objektUnterArt: IObjektartunterart;
    marktwertProQuadratmeter: number;
    baujahr: Baujahr[];
    baujahrFlaechengewichtet: number;
    wohnNutzflaeche: number;
    auftragsart: Auftragsart;
    loraAuftragsNummer: string;
    giksId: string;
}

export interface IObjektart {
    name: string;
    id: number;
}

export interface IObjektartunterart {
    name: string;
    id: number;
}

export interface IAppraisalMapView {
    gutachtenId: number;
    laengengrad: number;
    breitengrad: number;
}

export type Entwicklung = Partial<{
    id: number;
    marktwert: number;
    rohertragsvervielfaeltiger: number;
    marktwertProQuadratmeter: number;
    kaufpreis: number;
    kaufpreisProQm: number;
    kaufpreisDatum: Date;
    renditeIst: number;
    renditeAnsatz: number;
}>;

export interface AppraisalFilter {
    strasse: string;
    hausnummer: string;
    ort: string;
    plz: string;
    objektArtIds: number[];
    objektUnterArtIds: number[];
    nutzungsArtIds: number[];
    nutzungsArtenCombineId: number;
    lageQualitaetIds: number[];
    baujahresKlasseIds: number[];
    minBaujahr: number;
    maxBaujahr: number;
    minFiktivesBaujahr: number;
    maxFiktivesBaujahr: number;
    minMittleresModernisierungsjahr: number;
    maxMittleresModernisierungsjahr: number;
    minSanierungsjahr: number;
    maxSanierungsjahr: number;
    ausstattungsQualitaetIds: number[];
    minFlaeche: number;
    maxFlaeche: number;
    minWertErmittlung: number;
    maxWertErmittlung: number;
    lat: number;
    lon: number;
    perimeter: number;
    energieeffizienzKlasseIds: number[];
    isInternal: boolean;
    eigennutzung: number;
    nachhaltigkeitszertifikatIds: number[];
    besonderheitenIds: number[];
    keineBesonderheiten: boolean;
    objektzustandIds: number[];
    auftragsartIds: number[];
    auftraggeberIds: number[];
    freitextObjektArt: string;
    loraAuftragsNummer: string;
}

export interface PageFilter {
    pageSize: number;
    pageNumber: number;
}

export interface RequestData<T> {
    errors: string[];
    message: string;
    data: T;
    succeeded: boolean;
}

export interface PagedData<T> {
    data: T[];
    totalRecords: number;
    totalPages: number;
    size: number;
    page: number;
    pageSize: number;
    pageNumber: number;
}

export type IPaginationResult<T> = Omit<PagedData<T>, 'data'>;

export type SortProp = {
    prop: string;
    direction: 'asc' | 'desc';
};

export interface AppraisalUploadSorting {
    active: keyof IAppraisalListView;
    direction: SortDirection;
}


export interface AppraisalCompareViewOptions {
    fazit: boolean;
    mikrolage: boolean;
    makrolage: boolean;
    lageAllgemein: boolean;
    vermietungsmarkt: boolean;
    investmentmarkt: boolean;
    immobilienmarkt: boolean;
    besonderheiten: boolean;
    nutzungsart: boolean;
}
