import { Component, OnInit } from '@angular/core';
import { SnackbarService } from '../../services/snackbar.service';
import { SnackData } from '../../types/Snackbar';
import { tap } from 'rxjs/operators';

@Component({
   selector: 'app-snackbar',
   templateUrl: './snackbar.component.html',
   styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {   
   snacks: SnackData[] = [];

   constructor(public snackbarService: SnackbarService) { }

   ngOnInit(): void {
      this.snackbarService.snackbar$
         .pipe(tap(console.log))
         .subscribe((data) => {
            this.snacks.push(data);
         });
   }
}
