import { PageFilter } from '../../appraisal-detail/models/Appraisal';

export class Pagination {
    static readonly FirstPageIndex = 1;

    private _page: number;
    private _size: number;
    private _total: number;

    constructor(page: number, size: number, total: number = 0) {
        this._page = page;
        this._size = size;
        this._total = total;
    }

    static default = (): Pagination => new Pagination(0, 0, 0);

    get size(): number {
        return this._size;
    }

    set size(size: number) {
        this._size = size;
    }

    get page(): number {
        return this._page;
    }

    set page(page: number) {
        this._page = page;
    }

    get total(): number {
        return this._total;
    }

    set total(total: number) {
        this._total = total;
    }

    updatePageInfo(page: number, size: number, total: number) {
        this.page = page;
        this.size = size;
        this.total = total;
    }

    public next() {
        this._page++;
    }

    public previous() {
        this._page--;
    }

    public isEmpty(): boolean {
        return this._size === 0;
    }

    public reset() {
        this._page = 1;
    }

    public toApi(): PageFilter {
        return { pageSize: this._size, pageNumber: this._page };
    }
}
