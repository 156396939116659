import {Component, Input} from '@angular/core';
import {MimeTypes} from '../../../../core/enums/MimeTypes';
import {SnackbarService} from '../../../../core/services/snackbar.service';
import {CollectionFileShort} from '../../../models/Collection';
import {PDFDocumentProxy} from "ng2-pdf-viewer";
import {LoadingService} from "../../../../core/services/loading.service";

@Component({
    selector: 'app-upload-collection-preview',
    templateUrl: './upload-collection-preview.component.html',
    styleUrls: ['./upload-collection-preview.component.scss']
})
export class UploadCollectionPreviewComponent {
    MimeTypes = MimeTypes;
    ZOOM = 0.7;

    @Input() file: CollectionFileShort;

    constructor(private snackbarService: SnackbarService,
                private loadingService: LoadingService) {
    }

    zoomIn() {
        if (this.ZOOM < 0.8) {
            this.ZOOM = this.ZOOM += 0.1;
        }
    }

    zoomOut() {
        if (this.ZOOM > 0.5) {
            this.ZOOM = this.ZOOM -= 0.1;
        }
    }

    callBackFn(pdf: PDFDocumentProxy) {
        this.loadingService.setLoading(false);
    }

    openLink() {
        if (this.file.url.startsWith('https://' || 'http://')) {
            window.open(this.file.url);
            return;
        }
        const message = 'Text in die Zwischenablage kopiert, da ungültiges URL Format.';
        this.snackbarService.showError(message);
    }
}
