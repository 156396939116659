import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BrickMacroLocation } from 'src/app/appraisal-textgenerator/interfaces/BrickTypes';

@Component({
   selector: 'app-brick-macro-location-detail',
   templateUrl: './brick-macro-location-detail.component.html',
   styleUrls: ['./brick-macro-location-detail.component.scss']
})
export class BrickMacroLocationDetailComponent implements OnInit {

   @Input()
   data: BrickMacroLocation;
   appraisalTextId: number;

   constructor(
      private activatedRoute: ActivatedRoute
   ) { }

   ngOnInit(): void {
      this.appraisalTextId = this.activatedRoute.snapshot.params.appraisalTextId;
   }

}
