import { Component, Input } from '@angular/core';
import { SnackbarService } from '../../../../core/services/snackbar.service';

@Component({
    selector: 'app-appraisal-box',
    templateUrl: './appraisal-box.component.html',
    styleUrls: ['./appraisal-box.component.scss', './appraisal-standards.scss']
})
export class AppraisalBoxComponent {
    @Input() title: string;
    @Input() copytext: string;

    constructor(private snackbarService: SnackbarService) {}

    showSnackbar(message: string) {
        this.snackbarService.showInfo(message);
    }
}
