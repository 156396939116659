import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { Control, Layer, Map, TileLayer } from 'leaflet';
import { DefaultLayersControl } from '../../../../../../core/utils/map.utils';
import { SelectOptionString } from '../../../../../../shared/components/radio-group/SelectOption';
import { CustomLayerControl } from '../CustomControls';

@Component({
    selector: 'app-layer-control',
    templateUrl: './layer-control.component.html',
    styleUrls: ['./layer-control.component.scss']
})
export class LayerControlComponent implements OnInit, OnDestroy {
    currentSelection: SelectOptionString[] = [];
    selectOptions: SelectOptionString[] = [];
    baseLayers: { [name: string]: Layer } = {};
    customLayer: Control;
    showLayerSelect = false;

    faLayerGroup = faLayerGroup;

    private currentLayer: Layer;

    constructor(private cdR: ChangeDetectorRef) {}

    private _map: Map;

    get map(): Map {
        return this._map;
    }

    @Input() set map(map: Map) {
        if (map) {
            this._map = map;
            this.customLayer = new CustomLayerControl({ position: 'bottomright' }).addTo(map);
        }
    }

    ngOnInit(): void {
        this.baseLayers = DefaultLayersControl.baseLayers;
        this.selectOptions = Object.entries(this.baseLayers).map(([key]) => ({ label: key, value: key }));
        this.currentSelection = [this.selectOptions[0]];
    }

    ngOnDestroy() {
        this._map.removeControl(this.customLayer);
    }

    onLayerChange(selectedLayer: SelectOptionString[]): void {
        const layer: TileLayer = this.baseLayers[selectedLayer[0]?.value] as TileLayer;

        if (this.currentLayer) {
            this._map.removeLayer(this.currentLayer);
        }

        if (!layer) {
            return;
        }

        this.currentLayer = layer;
        this._map.addLayer(layer);
        this.map.setZoom(17);
        this.cdR.detectChanges();
    }
}
