<div class="container">
    <form [formGroup]="formGroup">
        <h3>Sammlung {{ showEditMode ? 'bearbeiten' : 'erstellen' }}</h3>
        <div class="box">
            <div class="column-component">
                <span class="title">Allgemein</span>
                <div class="spacer"></div>
                <!--
                <input type="email" matInput formControlName="titel" placeholder="Ex. pat@example.com" />
                     -->
                <app-input-info-delete
                    [data]="{ placeholder: 'Titel', controlName: 'titel', required: true }"
                    formControlName="titel"
                ></app-input-info-delete>

                <div class="row-component">
                    <button class="light btnTextfield" (click)="openDialog(DialogContentType.UploadBeschreibung, 'Ok')">
                        {{ buttonTexts.beschreibung }}
                    </button>
                </div>
                <div class="row-component">
                    <app-select
                        [height]="40"
                        [options]="DropKategorie"
                        (selectionChange)="setSelect($event, 'kategorie')"
                        [showRequired]="true"
                        [placeholder]="'Kategorie'"
                        [selection]="dropKategorieCurrentSelection"
                    >
                        <ng-container defaultText>Kategorie</ng-container>
                    </app-select>
                </div>
            </div>

            <div class="column-component">
                <span class="title">Quelle</span>
                <div class="row-component">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Herausgeber*</mat-label>
                        <input
                            type="text"
                            placeholder="Herausgeber"
                            matInput
                            [matAutocomplete]="herausgeber"
                            [formControl]="herausgeberControl"
                        />
                        <mat-autocomplete
                            [panelWidth]="'500px'"
                            [displayWith]="displayFn.bind(this)"
                            autoActiveFirstOption
                            #herausgeber="matAutocomplete"
                            (optionSelected)="setHerausgeber($event)"
                        >
                            <mat-option
                                *ngFor="let herausgeber of filteredHerausgeberSelectOption$ | async"
                                [value]="herausgeber.value"
                            >
                                <span>{{ herausgeber.label }}</span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <button
                        class="default btnEditItems"
                        (click)="openDialog(DialogContentType.UploadAddItemHerausgeber, 'Speichern')"
                    >
                        <img src="../../assets/icons/edit.png" class="iconEditItem" />
                    </button>
                </div>
                <div class="row-component">
                    <app-select
                        [height]="40"
                        [showRequired]="true"
                        [options]="DropPublikationsturnus"
                        (selectionChange)="setSelect($event, 'publikationsturnus')"
                        [placeholder]="'Publikationsturnus'"
                        [selection]="dropPublikationsturnusCurrentSelection"
                    >
                        <ng-container defaultText>Publikationsturnus</ng-container>
                    </app-select>
                </div>

                <div class="row-component">
                    <app-select
                        [height]="40"
                        [options]="DropKostenpflichtig"
                        [showRequired]="this.formGroup.get('kategorie').value === 0"
                        (selectionChange)="setSelect($event, 'kostenpflichtig')"
                        [placeholder]="'Lizenz'"
                        [selection]="dropKategorieKostenpflichtigSelection"
                    >
                        <ng-container defaultText>Lizenz</ng-container>
                    </app-select>
                </div>
                <div class="row-component">
                    <button class="light btnTextfield" (click)="openDialog(DialogContentType.UploadHint, 'Ok')">
                        {{ buttonTexts.hinweis }}
                    </button>
                </div>
                <div *ngIf="this.formGroup && this.formGroup.get('kategorie').value !== 0">
                    <app-datepicker
                        id="pickerHerausgabedatum"
                        [data]="{ placeholder: 'Herausgabedatum', controlName: 'herausgabedatum', required: false }"
                    ></app-datepicker>
                </div>
            </div>

            <div class="column-component">
                <span class="title">{{ this.formGroup.get('kategorie').value > 0 ? 'Nutzung' : 'Nutzung *' }}</span>
                <div *ngFor="let nutz of nutzung.value; index as indexOfelement">
                    <div class="row-component">
                        <button
                            class="light btn-edit"
                            (click)="openDialog(DialogContentType.UploadNutzung, 'Ok', indexOfelement)"
                        >
                            <div class="btn-edit-text">
                                <span>{{ nutz.nutzungsartId | select: nutzungsartenSelectOption }}</span>
                                <span *ngIf="nutz.objektartId">
                                    , {{ nutz.objektartId | select: objektartenSelectOption }}
                                </span>
                            </div>
                            <mat-icon
                                class="btn-edit-icon"
                                id="btn-edit-icon-nutzung"
                                (click)="deleteItem($event, 'nutzung', indexOfelement)"
                                >close
                            </mat-icon>
                        </button>
                    </div>
                </div>
                <div class="row-component">
                    <button class="default" (click)="openDialog(DialogContentType.UploadNutzung, 'Ok')">
                        Nutzung hinzufügen
                    </button>
                    <button
                        class="default btnEditItems"
                        (click)="openDialog(DialogContentType.UploadAddItemNutzung, 'Speichern')"
                    >
                        <img src="../../assets/icons/edit.png" class="iconEditItem" />
                    </button>
                </div>
            </div>

            <div class="column-component">
                <span class="title">{{ this.formGroup.get('kategorie').value > 0 ? 'Lage' : 'Lage *' }}</span>
                <div *ngFor="let lag of lage?.value; index as indexOfelement">
                    <div class="row-component">
                        <button
                            class="light btn-edit"
                            (click)="openDialog(DialogContentType.UploadLage, 'Ok', indexOfelement)"
                        >
                            <div class="btn-edit-text">
                                <span *ngIf="lag?.countryWide">Deutschlandweit</span>
                                <span *ngIf="lag?.bundesland?.name"> {{ lag?.bundesland?.name }}</span>
                                <span *ngIf="lag?.landkreis?.name"> {{ lag?.landkreis?.name }}</span>
                                <span *ngIf="lag?.gemeinde?.name">, {{ lag.gemeinde.name }}</span>
                            </div>
                            <mat-icon
                                class="btn-edit-icon"
                                id="btn-edit-icon-lage"
                                (click)="deleteItem($event, 'lage', indexOfelement)"
                                >close
                            </mat-icon>
                        </button>
                    </div>
                </div>
                <div class="row-component">
                    <button class="default" (click)="openDialog(DialogContentType.UploadLage, 'Ok')">
                        Lage hinzufügen
                    </button>
                </div>
            </div>
        </div>

        <div class="row-component">
            <button (click)="submit()" class="primary" [disabled]="!formGroup.valid || loading" type="button">
                Speichern
            </button>
        </div>
    </form>
    <div id="bottom"></div>
</div>
