import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, UntypedFormGroup, ValidatorFn} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {Observable} from 'rxjs';
import {map, startWith, tap} from 'rxjs/operators';
import {
    IBundeslandData,
    IGemeindeData,
    IGemeindeKennzifferData,
    ILandkreisData,
    ILocationItem,
    IPlzData
} from '../../../../interfaces/LocationData';
import {checkValidSelection} from '../../../../../core/utils/validators';

@Component({
    selector: 'app-dialog-upload-lage',
    templateUrl: './dialog-upload-lage.component.html',
    styleUrls: ['./dialog-upload-lage.component.scss']
})
export class DialogUploadLageComponent implements OnInit {
    @Output() closed = new EventEmitter<any>();
    @Input() data;

    formGroup: UntypedFormGroup;
    lage: UntypedFormGroup;

    bundeslaender: IBundeslandData[];
    landkreise: ILandkreisData[];
    plzs: IPlzData[];
    gemeinden: IGemeindeData[];

    filteredBundeslandOptions: Observable<IBundeslandData[]>;
    filteredLandkreisOptions: Observable<ILandkreisData[]>;
    filteredGemeindeOptions: Observable<IGemeindeData[]>;
    filteredPlzOptions: Observable<IPlzData[]>;
    filteredGemeindekennzifferOptions: Observable<IGemeindeKennzifferData[]>;

    selectedGemeinde: IGemeindeData;
    currentPlz: IPlzData;
    currentLandkreis: ILandkreisData;

    populateLocationDialogData: ILocationItem;

    ngOnInit(): void {
        if (this.data.content != null) {
            this.bundeslaender = this.data.content.locationData.bundeslaender;
            this.landkreise = this.data.content.locationData.landkreise;
            this.plzs = this.data.content.locationData.plzGemeinde;
            this.gemeinden = this.data.content.locationData.gemeinden;
        }

        if (this.data.content?.value != null) {
            this.populateLocationDialogData = <ILocationItem>{
                bundesland: this.data.content.value.bundesland,
                landkreis: this.data.content.value.landkreis,
                gemeinde: this.data.content.value.gemeinde,
                postleitzahl: this.plzs.find((plz) => plz.id === this.data.content.value.gemeinde?.id),
                gemeindekennziffer: this.gemeinden.find(
                    (gemeinde) => gemeinde.id === this.data.content.value.gemeinde?.id
                )?.kreisGemeindeSchluessel,
                countryWide: this.data.content.value.countryWide
            };
        }

        const bundeslandOrCountryWideValidator: ValidatorFn = (fg: UntypedFormGroup) => {
            const countryWide = fg.get('countryWide').value;
            const bundesland = fg.get('bundesland').value;
            return countryWide || !!bundesland
                ? null
                : {bundeslandOrcountryWide: true};
        };

        this.formGroup = new UntypedFormGroup(
            {
                bundesland: new FormControl(this.populateLocationDialogData?.bundesland?.name, [
                    checkValidSelection(() => this.bundeslaender)
                ]),
                landkreis: new FormControl(this.populateLocationDialogData?.landkreis?.name, [
                    checkValidSelection((group) =>
                        this.filterCountiesByState(group?.get('bundesland')?.value, this.bundeslaender, this.landkreise)
                    )
                ]),
                gemeinde: new FormControl(this.populateLocationDialogData?.gemeinde?.name, [
                    checkValidSelection(() => this.gemeinden)
                ]),
                postleitzahl: new FormControl(
                    this.populateLocationDialogData?.postleitzahl?.plz,
                    checkValidSelection(() => this.plzs.map((plz) => ({name: plz.plz})))
                ),
                gemeindekennziffer: new FormControl(this.populateLocationDialogData?.gemeindekennziffer),
                countryWide: new FormControl(this.populateLocationDialogData?.countryWide)
            },
            {
                validators: [bundeslandOrCountryWideValidator]
            }
        );


        this.filteredBundeslandOptions = this.formGroup.get('bundesland').valueChanges.pipe(
            startWith(''),
            map((value) => this.filterBundeslaender(value || ''))
        );
        this.filteredLandkreisOptions = this.formGroup.get('landkreis').valueChanges.pipe(
            tap((n) => {
                if (
                    this.formGroup.get('landkreis').value?.length > 3 &&
                    this.currentLandkreis != this.formGroup.get('landkreis').value
                ) {
                    this.currentLandkreis = this.formGroup.get('landkreis').value;
                    this.setBundeslandByLandkreisName(this.formGroup.get('landkreis').value);
                }
            }),
            startWith({}),
            map(() =>
                this.filterLandkreise(
                    this.formGroup.get('bundesland').value,
                    this.formGroup.get('landkreis').value
                ).slice(0, 100)
            )
        );

        this.filteredGemeindeOptions = this.formGroup.get('gemeinde').valueChanges.pipe(
            startWith({}),
            map(() =>
                this.filterGemeinden(this.formGroup.get('landkreis').value, this.formGroup.get('gemeinde').value).slice(
                    0,
                    100
                )
            )
        );

        this.filteredGemeindekennzifferOptions = this.formGroup.get('gemeindekennziffer').valueChanges.pipe(
            startWith(''),
            map((g) =>
                this.gemeinden
                    .filter((g) => g.kreisGemeindeSchluessel === this.formGroup.get('gemeindekennziffer').value)
                    .map((g) => ({kreisGemeindeSchluessel: g.kreisGemeindeSchluessel}))
            )
        );

        this.filteredPlzOptions = this.formGroup.valueChanges.pipe(
            tap((n) => {
                if (
                    this.formGroup.get('postleitzahl').value?.length === 5 &&
                    this.currentPlz != this.formGroup.get('postleitzahl').value
                ) {
                    this.currentPlz = this.formGroup.get('postleitzahl').value;
                    this.setGemeindeByPlz(this.formGroup.get('postleitzahl').value);
                }
            }),
            startWith({}),
            map(() =>
                this.formGroup.get('postleitzahl').value?.length === 5
                    ? this.filterPostalCodes(
                        this.formGroup.get('landkreis').value,
                        this.formGroup.get('postleitzahl').value
                    )
                    : []
            )
        );
    }

    onOptionSelected(event: MatAutocompleteSelectedEvent): void {
        const value: IGemeindeData = event.option.value;
        this.formGroup.get('gemeinde').setValue(value.name);
        this.formGroup.get('gemeindekennziffer').setValue(value.kreisGemeindeSchluessel);
        this.setPlzByGemeinde(value.id);
        this.setLandkreisByGemeinde(value.landkreisId);
    }

    private setGemeindeByPlz(plz: string) {
        const allPlz: IPlzData[] = this.plzs.filter((pc) => pc.plz === plz);

        if (allPlz.length > 1 && this.formGroup.get('gemeinde').value !== null) {
            // dont override existing gemeinde when multiple are found, unless gemeinde is empty
            return;
        }
        const filteredPlz = allPlz.length > 0 ? allPlz[0] : null;
        const filteredGemeinde = this.gemeinden.find((commune) => commune.id === filteredPlz?.id);
        if (filteredGemeinde) {
            this.formGroup.get('gemeinde').setValue(filteredGemeinde.name);
            this.setLandkreisByGemeinde(filteredGemeinde.landkreisId);
            this.setBundeslandByLandkreisId(filteredGemeinde.landkreisId);
            this.setGemeindekennzifferByGemeinde(filteredGemeinde);
        }
    }

    private setPlzByGemeinde(gemeindeId: number) {
        const filteredPlz = this.plzs.find((plz) => plz.id === gemeindeId);
        this.formGroup.get('postleitzahl').setValue(filteredPlz?.plz);
    }

    private setGemeindekennzifferByGemeinde(gemeinde: IGemeindeData) {
        this.formGroup.get('gemeindekennziffer').setValue(gemeinde.kreisGemeindeSchluessel);
    }

    private setLandkreisByGemeinde(landkreisId: number) {
        const filteredLandkreis = this.landkreise.find((c) => c.id === landkreisId)?.name;
        this.formGroup.get('landkreis').setValue(filteredLandkreis);
    }

    private setBundeslandByLandkreisName(landkreis: string) {
        const filteredLandkreis: ILandkreisData = this.landkreise.find((lk) => lk.name === landkreis);
        this.setBundeslandByLandkreis(filteredLandkreis);
    }

    private setBundeslandByLandkreisId(landkreisId: number) {
        const filteredLandkreis: ILandkreisData = this.landkreise.find((lk) => lk.id === landkreisId);
        this.setBundeslandByLandkreis(filteredLandkreis);
    }

    private setBundeslandByLandkreis(landkreis: ILandkreisData) {
        const filteredBundeland = this.bundeslaender.find((c) => c.id === landkreis?.bundeslandId)?.name;
        this.formGroup.get('bundesland').setValue(filteredBundeland);
    }

    private filterBundeslaender(value: string): IBundeslandData[] {
        const filterValue = value.toLowerCase();
        this.filterCountiesByState(value, this.bundeslaender, this.landkreise);
        return filterValue.length > 0
            ? this.bundeslaender?.filter((option) => option.name.toLowerCase().includes(filterValue))
            : [];
    }

    private filterLandkreise(state: string, county: string): ILandkreisData[] {
        const filterValue = county?.toLowerCase() || '';

        const counties = this.filterCountiesByState(state, this.bundeslaender, this.landkreise);

        return filterValue.length > 0
            ? counties.filter((option) => option.name.toLowerCase().includes(filterValue))
            : [];
    }

    private filterCountiesByState(
        state: string,
        states: IBundeslandData[],
        counties: ILandkreisData[]
    ): ILandkreisData[] {
        if (state == null) {
            return counties;
        }
        const selectedState = states.find((o) => o.name === state);
        return counties.filter((o) => o.bundeslandId === selectedState?.id);
    }

    private filterGemeinden(landkreis: string, gemeinde: string): IGemeindeData[] {
        if (typeof gemeinde !== 'string') {
            return [];
        }
        const filterValue = gemeinde?.toLowerCase() || '';
        // const gemeinden = this.filterCommuneByCounty(landkreis, this.landkreise, this.gemeinden);

        return filterValue.length > 0
            ? this.gemeinden.filter((option) => option.name?.toLowerCase().includes(filterValue))
            : [];
    }

    trackCounty(index: number, item: ILandkreisData) {
        return item.id;
    }

    private filterCommuneByCounty(
        county: string,
        counties: ILandkreisData[],
        communes: IGemeindeData[]
    ): IGemeindeData[] {
        if (county == null) {
            return communes;
        }
        const selectedCounty = counties.find((o) => o.name === county);
        return communes.filter((commune) => commune.landkreisId === selectedCounty?.id);
    }

    private filterPostalCodes(county: string, postalCode: string): IPlzData[] {
        const filterValue = postalCode?.toLowerCase() || '';
        return this.plzs.filter((option) => option.plz.toLowerCase().startsWith(filterValue));
    }

    submit(): void {
        // Since there is no auto validation on submit, we check the validity of each dialog form field manually before submitting.
        // Just in case a county or city that is not part of the selected state or county gets selected
        Object.values(this.formGroup.controls).forEach((o) => o.updateValueAndValidity());
        if (this.formGroup.valid) {
            this.closed.emit({
                formResult: this.formGroup.value,
                selectedLocationData: this.getLocationDataFromInput(),
                index: this.data.content.index >= 0 ? this.data.content.index : null
            });
        }
    }

    cancel(): void {
        this.closed.emit(null);
    }

    getLocationDataFromInput() {
        // find selected locations
        const selectedState = this.bundeslaender.find((state) => state.name === this.formGroup.get('bundesland').value);
        const selectedCounty = this.landkreise.find((county) => county.name === this.formGroup.get('landkreis').value);
        const selectedCommune = this.gemeinden.find(
            (gemeinde) => gemeinde.name === this.formGroup.get('gemeinde').value
        );

        const selectedPostalCode = this.plzs.find(
            (postalCode) => postalCode.plz === this.formGroup.get('postleitzahl').value
        );

        const selectedMunicipalCode = this.formGroup.get('gemeindekennziffer').value;

        // find selectedCommune based on input
        if (selectedCommune) {
            this.selectedGemeinde = this.gemeinden.find((commune) => selectedCommune.id === commune.id);

            return {
                bundesland: selectedState,
                landkreis: selectedCounty,
                gemeinde: this.selectedGemeinde,
                postleitzahl: this.currentPlz,
                gemeindekennziffer: selectedMunicipalCode,
                countryWide: this.formGroup.get('countryWide').value
            };
        }

        if (!selectedCommune && !selectedMunicipalCode && selectedPostalCode) {
            this.selectedGemeinde = this.formGroup.get('gemeinde').value;

            return {
                bundesland: selectedState,
                landkreis: selectedCounty,
                gemeinde: this.selectedGemeinde,
                postleitzahl: this.currentPlz,
                gemeindekennziffer: null,
                countryWide: this.formGroup.get('countryWide').value
            };
        }
        return {
            bundesland: selectedState,
            landkreis: selectedCounty,
            gemeinde: null,
            postleitzahl: null,
            gemeindekennziffer: null,
            countryWide: this.formGroup.get('countryWide').value
        };
    }
}
