import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { Injector, LOCALE_ID, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppraisalModule } from './appraisal-detail/appraisal.module';
import { PopupComponent } from './appraisal-search/components/map-view/map/popup/popup.component';
import { SearchModule } from './appraisal-search/search.module';
import { UploadModule } from './collection-upload/upload.module';
import { CoreModule } from './core/core.module';
import { AuthorizationInterceptor } from './core/interceptors/authorization.interceptor';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { DashboardComponent } from './dashboard/components/dashboard/dashboard.component';
import { LoadingComponent} from './shared/components/loading/loading.component';
import { GraphQlModule } from './shared/graph-ql/graphql.module';
import { SharedModule } from './shared/shared.module';
import { TextgeneratorModule } from './appraisal-textgenerator/textgenerator.module';

registerLocaleData(localeDe);

@NgModule({
    declarations: [AppComponent, DashboardComponent, LoadingComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        MatSidenavModule,
        MatProgressBarModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AppraisalModule,
        SearchModule,
        UploadModule,
        SharedModule,
        GraphQlModule,
        CoreModule,
        TextgeneratorModule
    ],
    providers: [
        { provide: 'API_BASE_URL', useValue: environment.baseApiUrl },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: 'de-DE' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private library: FaIconLibrary, private injector: Injector) {
        const PopupElement = createCustomElement(PopupComponent, { injector });
        // need to display a component into a map marker
        customElements.define('popup-element', PopupElement);
    }
}
