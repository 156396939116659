import { Component, Input, OnInit } from '@angular/core';
import { BrickConstructionLaw } from 'src/app/appraisal-textgenerator/interfaces/BrickTypes';
import { BaugenehmigungNames } from 'src/app/appraisal-textgenerator/enums/Baugenehmigung';
import { GebrauchsAbnahmeNachweisNames } from 'src/app/appraisal-textgenerator/enums/GebrauchsAbnahmeNachweis';
import { BebauungsPlanNames } from 'src/app/appraisal-textgenerator/enums/BebauungsPlan';
//import { BrickConstructionLawEditComponent } from '../brick-construction-law-edit/brick-construction-law-edit.component';

@Component({
  selector: 'app-brick-construction-law-detail',
  templateUrl: './brick-construction-law-detail.component.html',
  styleUrls: ['./brick-construction-law-detail.component.scss']
})
export class BrickConstructionLawDetailComponent implements OnInit {

  @Input()
  data: BrickConstructionLaw;
  
  public BaugenehmigungNames = BaugenehmigungNames;
  public GebrauchsAbnahmeNachweisNames = GebrauchsAbnahmeNachweisNames;
  public BebauungsPlanNames = BebauungsPlanNames;

  constructor() { }

  ngOnInit(): void {
  }

}
