import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Toast } from 'bootstrap';
import { SnackData } from '../../types/Snackbar';

@Component({
   selector: 'app-snack',
   templateUrl: './snack.component.html',
   styleUrls: ['./snack.component.scss']
})
export class SnackComponent implements OnInit {

   @Input()
   data: SnackData;

   @ViewChild('snack',{static:true}) snackElement!: ElementRef<HTMLDivElement>;

   constructor() { }

   ngOnInit(): void {
      const toast = new Toast(this.snackElement.nativeElement, this.data.options);
      toast.show();
   }

}
