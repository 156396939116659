<div [ngClass]="isOpen ? 'open' : 'closed'" class="left-sidebar column-component">
    <ng-container *ngIf="currentFilter$ | async as currentFilter">
        <div class="search-box">
            <div class="reset">
                <a (click)="onReset()" *ngIf="filtersActive$ | async" class="reset-button">Filter zurücksetzen</a>
            </div>
            <h5 class="sidebar-title title">Adresse</h5>
            <app-autocomplete
                (onSelect)="onAddressValueSelected($event)"
                (valueChanged)="onAddressInputChange($event)"
                [availableNodes]="availableNodes$ | async"
                [value]="currentAddress$ | async"
                placeholder="Adresse eingeben"
            >
            </app-autocomplete>
            <h5 *ngIf="currentAddressValid$ | async" class="sidebar-title title">Umkreis</h5>
            <div *ngIf="currentAddressValid$ | async" class="row-component centered">
                <input
                    #perimeter
                    (blur)="onRadiusChanged($event.target.value)"
                    (keyup.enter)="onRadiusChanged($event.target.value)"
                    [disabled]="!(currentAddressValid$ | async)"
                    [value]="currentRadius$ | async"
                    class="default input-full-width"
                    onfocus="this.value=''"
                    placeholder="größer 0km und max. 500km"
                    type="number"
                />
            </div>
        </div>
        <div class="divider range"></div>
        <app-expansion-panel>
            <ng-container header>
                <span class="title">Objektart</span>
                <span *ngIf="false" class="filter-active">Filter Aktiv</span>
            </ng-container>
            <ng-container content>

                <app-multi-select
                    (currentSelectionChange)="onSelectionChanged('objektArtIds', $event)"
                    [currentSelectionIds]="currentFilter.objektArtIds"
                    [options]="objektArten$ | async"
                >
                    <ng-container defaultText>Objektart wählen</ng-container>
                </app-multi-select>

                <ng-container *ngIf="(objektUnterArten$ | async)?.length && currentFilter.objektArtIds.length">
                    <h5 class="sidebar-title title">Objektunterarten</h5>
                    <app-multi-select
                        (currentSelectionChange)="onSelectionChanged('objektUnterArtIds', $event)"
                        [currentSelectionIds]="currentFilter.objektUnterArtIds"
                        [options]="(objektUnterArten$ | async) || []"
                    >
                        <ng-container defaultText>Objektunterart wählen</ng-container>
                    </app-multi-select>
                </ng-container>
                <h5 class="sidebar-title title">Objektart Freitext</h5>
                <input
                    [ngModel]="currentFilter.freitextObjektArt"
                    [ngModelOptions]="{ updateOn: 'blur' }"
                    (keyup.enter)="onFreitextObjektArtChanged($event.target.value); $event.preventDefault()"
                    (ngModelChange)="onFreitextObjektArtChanged($event)"
                    (reset)="onFreitextObjektArtChanged(null)"
                    class="default"
                    type="search"
                />
            </ng-container>
        </app-expansion-panel>
        <div class="divider"></div>
        <app-expansion-panel>
            <ng-container header>
                <span class="title">Nutzung</span>
                <span *ngIf="false" class="filter-active">Filter Aktiv</span>
            </ng-container>
            <ng-container content>
                <div class="inrow">
                    <app-multi-select
                        (currentSelectionChange)="onSelectionChanged('nutzungsArtIds', $event)"
                        [currentSelectionIds]="currentFilter.nutzungsArtIds"
                        [options]="nutzungsArten$ | async"
                    >
                        <ng-container defaultText>Nutzungsart wählen</ng-container>
                    </app-multi-select>
                    <app-select
                        (selectionChange)="addFilter({ nutzungsArtenCombineId: $event })"
                        [isResettable]="false"
                        [options]="nutzungsArtenCombineOptions$ | async"
                        [selection]="currentFilter.nutzungsArtenCombineId"
                    >
                    </app-select>
                </div>
                <div class="spacer"></div>
                <ng-container content>
                    <app-select
                        (selectionChange)="addFilter({ eigennutzung: $event })"
                        [options]="eigennutzungOptions$ | async"
                        [selection]="currentFilter.eigennutzung"
                    >
                        <ng-container defaultText>Eigen-/Fremdnutz. wählen</ng-container>
                    </app-select>
                </ng-container>
            </ng-container>
        </app-expansion-panel>
        <div class="divider"></div>
        <app-expansion-panel>
            <ng-container header>
                <span class="title">Auftragsart</span>
                <span *ngIf="false" class="filter-active">Filter Aktiv</span>
            </ng-container>
            <ng-container content>
                <app-multi-select
                    (currentSelectionChange)="onSelectionChanged('auftragsartIds', $event)"
                    [currentSelectionIds]="currentFilter.auftragsartIds"
                    [options]="auftragsarten$ | async"
                    class="reverse"
                >
                    <ng-container defaultText>Auftragsart wählen</ng-container>
                </app-multi-select>
            </ng-container>
        </app-expansion-panel>
        <div class="divider"></div>
        <app-expansion-panel>
            <ng-container header>
                <span class="title">Auftraggeber</span>
                <span *ngIf="false" class="filter-active">Filter Aktiv</span>
            </ng-container>
            <ng-container content>
                <app-multi-select
                    (currentSelectionChange)="onSelectionChanged('auftraggeberIds', $event)"
                    [currentSelectionIds]="currentFilter.auftraggeberIds"
                    [options]="auftraggeber$ | async"
                    class="reverse"
                >
                    <ng-container defaultText>Auftraggeber wählen</ng-container>
                </app-multi-select>
            </ng-container>
        </app-expansion-panel>
        <div class="divider"></div>
        <app-expansion-panel>
            <ng-container header>
                <span class="title">Baujahr</span>
                <span *ngIf="false" class="filter-active">Filter Aktiv</span>
            </ng-container>
            <ng-container content>
                <div class="row-component centered">
                    <input
                        (blur)="onRangeChanged('minBaujahr', $event.target.value)"
                        [value]="currentFilter.minBaujahr"
                        class="default input-small text-center"
                        min="0"
                        placeholder="von Jahr"
                        type="number"
                    />
                    <input
                        (blur)="onRangeChanged('maxBaujahr', $event.target.value)"
                        [value]="currentFilter.maxBaujahr"
                        class="default input-small text-center"
                        min="0"
                        placeholder="bis Jahr"
                        type="number"
                    />
                </div>
                <h5 class="sidebar-title title">Baujahresklasse</h5>
                <app-multi-select
                    (currentSelectionChange)="onSelectionChanged('baujahresKlasseIds', $event)"
                    [currentSelectionIds]="currentFilter.baujahresKlasseIds"
                    [options]="baujahresKlassen$ | async"
                    rowClass="multi-row"
                >
                    <ng-container defaultText>Baujahresklassen wählen</ng-container>
                </app-multi-select>
            </ng-container>
        </app-expansion-panel>
        <div class="divider"></div>
        <app-expansion-panel>
            <ng-container header>
                <span class="title">Jahr der Wertermittlung</span>
                <span *ngIf="false" class="filter-active">Filter Aktiv</span>
            </ng-container>
            <ng-container content>
                <div class="row-component centered">
                    <input
                        (blur)="onRangeChanged('minWertErmittlung', $event.target.value)"
                        [value]="currentFilter.minWertErmittlung"
                        class="default input-small text-center"
                        max="2200"
                        min="1900"
                        placeholder="von Jahr"
                        type="number"
                    />
                    <input
                        (blur)="onRangeChanged('maxWertErmittlung', $event.target.value)"
                        [value]="currentFilter.maxWertErmittlung"
                        class="default input-small text-center"
                        max="2200"
                        min="1900"
                        placeholder="bis Jahr"
                        type="number"
                    />
                </div>
            </ng-container>
        </app-expansion-panel>
        <div class="divider"></div>
        <app-expansion-panel>
            <ng-container header>
                <span class="title">Extern/Intern</span>
                <span *ngIf="false" class="filter-active">Filter Aktiv</span>
            </ng-container>
            <ng-container content>
                <app-select
                    (selectionChange)="addFilter({ isInternal: $event })"
                    [options]="extIntOptions$ | async"
                    [selection]="currentFilter.isInternal"
                >
                    <ng-container defaultText>Extern/Intern wählen</ng-container>
                </app-select>
            </ng-container>
        </app-expansion-panel>
        <div class="divider"></div>
        <app-expansion-panel>
            <ng-container header>
                <span class="title">Objektgröße nach Fläche</span>
                <span *ngIf="false" class="filter-active">Filter Aktiv</span>
            </ng-container>
            <ng-container content>
                <div class="row-component centered">
                    <input
                        (blur)="onRangeChanged('minFlaeche', $event.target.value)"
                        [value]="currentFilter.minFlaeche"
                        class="default input-small text-center"
                        min="0"
                        placeholder="von m²"
                        type="number"
                    />
                    <input
                        (blur)="onRangeChanged('maxFlaeche', $event.target.value)"
                        [value]="currentFilter.maxFlaeche"
                        class="default input-small text-center"
                        min="0"
                        placeholder="bis m²"
                        type="number"
                    />
                </div>
            </ng-container>
        </app-expansion-panel>
        <div class="divider"></div>
        <app-expansion-panel>
            <ng-container header>
                <span class="title">Fiktives Baujahr</span>
                <span *ngIf="false" class="filter-active">Filter Aktiv</span>
            </ng-container>
            <ng-container content>
                <div class="row-component centered">
                    <input
                        (blur)="onRangeChanged('minFiktivesBaujahr', $event.target.value)"
                        [value]="currentFilter.minFiktivesBaujahr"
                        class="default input-small text-center"
                        min="0"
                        placeholder="von Jahr"
                        type="number"
                    />
                    <input
                        (blur)="onRangeChanged('maxFiktivesBaujahr', $event.target.value)"
                        [value]="currentFilter.maxFiktivesBaujahr"
                        class="default input-small text-center"
                        min="0"
                        placeholder="bis Jahr"
                        type="number"
                    />
                </div>
            </ng-container>
        </app-expansion-panel>
        <div class="divider"></div>
        <app-expansion-panel>
            <ng-container header>
                <span class="title">Lagequalität</span>
                <span *ngIf="false" class="filter-active">Filter Aktiv</span>
            </ng-container>
            <ng-container content>
                <app-multi-select
                    (currentSelectionChange)="onSelectionChanged('lageQualitaetIds', $event)"
                    [currentSelectionIds]="currentFilter.lageQualitaetIds"
                    [options]="lageQualitaeten$ | async"
                    class="reverse"
                >
                    <ng-container defaultText>Lagequalität wählen</ng-container>
                </app-multi-select>
            </ng-container>
        </app-expansion-panel>
        <div class="divider"></div>
        <app-expansion-panel>
            <ng-container header>
                <span class="title">Ausstattungsqualität</span>
                <span *ngIf="false" class="filter-active">Filter Aktiv</span>
            </ng-container>
            <ng-container content>
                <app-multi-select
                    (currentSelectionChange)="onSelectionChanged('ausstattungsQualitaetIds', $event)"
                    [currentSelectionIds]="currentFilter.ausstattungsQualitaetIds"
                    [options]="ausstattungsQualitaeten$ | async"
                    rowClass="multi-row"
                >
                    <ng-container defaultText>Ausstattung wählen</ng-container>
                </app-multi-select>
            </ng-container>
        </app-expansion-panel>
        <div class="divider"></div>
        <app-expansion-panel>
            <ng-container header>
                <span class="title">Objektzustand</span>
                <span *ngIf="false" class="filter-active">Filter Aktiv</span>
            </ng-container>
            <ng-container content>
                <app-multi-select
                    (currentSelectionChange)="onSelectionChanged('objektzustandIds', $event)"
                    [currentSelectionIds]="currentFilter.objektzustandIds"
                    [options]="objektzustand$ | async"
                    class="reverse"
                >
                    <ng-container defaultText>Objektzustand wählen</ng-container>
                </app-multi-select>
            </ng-container>
        </app-expansion-panel>
        <div class="divider"></div>
        <app-expansion-panel>
            <ng-container header>
                <span class="title">Mittleres Modernisierungsjahr</span>
                <span *ngIf="false" class="filter-active">Filter Aktiv</span>
            </ng-container>
            <ng-container content>
                <div class="row-component centered">
                    <input
                        (blur)="onRangeChanged('minMittleresModernisierungsjahr', $event.target.value)"
                        [value]="currentFilter.minMittleresModernisierungsjahr"
                        class="default input-small text-center"
                        min="0"
                        placeholder="von Jahr"
                        type="number"
                    />
                    <input
                        (blur)="onRangeChanged('maxMittleresModernisierungsjahr', $event.target.value)"
                        [value]="currentFilter.maxMittleresModernisierungsjahr"
                        class="default input-small text-center"
                        min="0"
                        placeholder="bis Jahr"
                        type="number"
                    />
                </div>
            </ng-container>
        </app-expansion-panel>
        <div class="divider"></div>
        <app-expansion-panel>
            <ng-container header>
                <span class="title">Sanierungsjahr</span>
                <span *ngIf="false" class="filter-active">Filter Aktiv</span>
            </ng-container>
            <ng-container content>
                <div class="row-component centered">
                    <input
                        (blur)="onRangeChanged('minSanierungsjahr', $event.target.value)"
                        [value]="currentFilter.minSanierungsjahr"
                        class="default input-small text-center"
                        min="0"
                        placeholder="von Jahr"
                        type="number"
                    />
                    <input
                        (blur)="onRangeChanged('maxSanierungsjahr', $event.target.value)"
                        [value]="currentFilter.maxSanierungsjahr"
                        class="default input-small text-center"
                        min="0"
                        placeholder="bis Jahr"
                        type="number"
                    />
                </div>
            </ng-container>
        </app-expansion-panel>
        <div class="divider"></div>
        <app-expansion-panel>
            <ng-container header>
                <span class="title">Nachhaltigkeitszertifikat</span>
                <span *ngIf="false" class="filter-active">Filter Aktiv</span>
            </ng-container>
            <ng-container content>
                <app-multi-select
                    (currentSelectionChange)="onSelectionChanged('nachhaltigkeitszertifikatIds', $event)"
                    [currentSelectionIds]="currentFilter.nachhaltigkeitszertifikatIds"
                    [options]="nachhaltigkeitsZertifikate$ | async"
                    class="reverse"
                >
                    <ng-container defaultText>Nachhaltigkeitszert. wählen</ng-container>
                </app-multi-select>
            </ng-container>
        </app-expansion-panel>
        <div class="divider"></div>
        <app-expansion-panel>
            <ng-container header>
                <span class="title">Energieeffizienzklasse</span>
                <span *ngIf="false" class="filter-active">Filter Aktiv</span>
            </ng-container>
            <ng-container content>
                <app-multi-select
                    (currentSelectionChange)="onSelectionChanged('energieeffizienzKlasseIds', $event)"
                    [currentSelectionIds]="currentFilter.energieeffizienzKlasseIds"
                    [options]="energieeffizienzKlassen$ | async"
                    class="reverse"
                >
                    <ng-container defaultText>Energieeffizienzkl. wählen</ng-container>
                </app-multi-select>
            </ng-container>
        </app-expansion-panel>
        <div class="divider"></div>
        <app-expansion-panel>
            <ng-container header>
                <span class="title">Besonderheiten</span>
                <span *ngIf="false" class="filter-active">Filter Aktiv</span>
            </ng-container>
            <ng-container content>
                <app-radio-button
                    (currentSelectionChange)="onErbbaurechtSelected()"
                    [checked]="erbbaurechtActive"
                    class="reverse"
                >
                    <ng-container label>Erbbaurecht vorhanden</ng-container>
                </app-radio-button>
            </ng-container>
        </app-expansion-panel>

        <div class="divider"></div>
        <app-expansion-panel>
            <ng-container header>
                <span class="title">Lora-ID</span>
            </ng-container>
            <ng-container content>
                <input
                    [ngModel]="currentFilter.loraAuftragsNummer"
                    [ngModelOptions]="{ updateOn: 'blur' }"
                    (keyup.enter)="onLoraIdChanged($event.target.value); $event.preventDefault()"
                    (ngModelChange)="onLoraIdChanged($event)"
                    (reset)="onLoraIdChanged(null)"
                    placeholder="Lora Id"
                    class="default"
                    type="search"
                />
            </ng-container>
        </app-expansion-panel>
    </ng-container>
</div>
