import { Component, Input, OnInit } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-autocomplete-forms',
    templateUrl: './autocomplete-forms.component.html',
    styleUrls: ['./autocomplete-forms.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AutocompleteFormsComponent,
            multi: true
        }
    ]
})
export class AutocompleteFormsComponent implements OnInit, ControlValueAccessor {
    @Input() placeholder = '';
    @Input() options = [];

    formGroup: UntypedFormGroup;
    formControl: UntypedFormControl;
    filteredDropdown: Observable<string[]>;

    value = '';
    onChange: (value: string) => void;

    ngOnInit(): void {
        this.formGroup = new UntypedFormGroup({
            formControl: (this.formControl = new UntypedFormControl(null, [
                this.validateAutocomplete(),
                Validators.required
            ]))
        });

        this.filteredDropdown = this.formControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filter(value || ''))
        );
    }

    validateAutocomplete(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) {
                return null;
            }
            return this.options.includes(control.value) ? null : { notValid: true };
        };
    }

    private _filter(value: string): string[] {
        const filterValue = this._normalizeValue(value);
        return this.options.filter((street) => this._normalizeValue(street).includes(filterValue));
    }

    private _normalizeValue(value: string): string {
        return value.toLowerCase().replace(/\s/g, '');
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {}

    setDisabledState(isDisabled: boolean): void {}

    writeValue(obj: string): void {
        this.value = obj;
        this.formControl.setValue(obj);
    }

    inputChange(change: string): void {
        this.writeValue(change);
        this.value = change;
        this.onChange(this.value);
    }
}
