import { Component, Input, OnInit } from '@angular/core';
import { BrickConstructionLoads } from 'src/app/appraisal-textgenerator/interfaces/BrickTypes';
import { GutachtenErstellungTypNames } from 'src/app/appraisal-textgenerator/enums/GutachtenErstellungTyp'


@Component({
  selector: 'app-brick-construction-loads-detail',
  templateUrl: './brick-construction-loads-detail.component.html',
  styleUrls: ['./brick-construction-loads-detail.component.scss']
})
export class BrickConstructionLoadsDetailComponent implements OnInit {

  @Input()
  data: BrickConstructionLoads;

  public GutachtenErstellungsTypNames = GutachtenErstellungTypNames;

  constructor() { }

  ngOnInit(): void {
  }

}
