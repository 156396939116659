// types
export enum SnackSeverityType {
   Info = 1,
   Success = 2,
   Warning = 3,
   Error = 4
};

export class SnackOptions {
   severityType: SnackSeverityType;
   panelClasses: string[];
   iconClass: string;
   // bootstrap toast options
   delay: number;
   autohide: boolean;
}

export type SnackDefaultsArray = {
   [key in SnackSeverityType]: SnackOptions;
};

export class SnackData {
   message: string;
   options: SnackOptions;
}

// default snacks
export const SnackInfo: SnackOptions = {
   severityType: SnackSeverityType.Info,
   iconClass: 'ms-2 fs-5 fa-light fa-circle-info',
   panelClasses: ['text-white', 'bg-info-custom '],
   delay: 5000,
   autohide: true,
}

export const SnackSuccess: SnackOptions = {
   severityType: SnackSeverityType.Success,
   iconClass: 'ms-2 fs-5 fa-light fa-circle-check',
   panelClasses: ['text-white', 'bg-success-custom'],
   delay: 5000,
   autohide: true,
}

export const SnackWarning: SnackOptions = {
   severityType: SnackSeverityType.Warning,
   iconClass: 'ms-2 fs-5 fa-light fa-triangle-exclamation',
   panelClasses: ['text-white', 'bg-warning'],
   delay: 5000,
   autohide: true,
}

export const SnackError: SnackOptions = {
   severityType: SnackSeverityType.Error,
   iconClass: 'ms-2 fs-5 fa-light fa-circle-exclamation',
   panelClasses: ['text-white', 'bg-danger-custom'],
   delay: 10000,
   autohide: true,
}

// for simple use
export const SnackDefaults: SnackDefaultsArray = {
   [SnackSeverityType.Info]: SnackInfo,
   [SnackSeverityType.Success]: SnackSuccess,
   [SnackSeverityType.Warning]: SnackWarning,
   [SnackSeverityType.Error]: SnackError
}