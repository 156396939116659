<p class="mt-5 display-6">Objekt {{ data.objektNummer}} bearbeiten</p>

<app-card class="mt-4">
    <div body>
        <form [formGroup]="form">
            <app-card>
                <div header>Allgemein</div>
                <div body>
                    <div class="row gap-2">
                        <mat-form-field class="col-md-4">
                            <mat-label>Art</mat-label>
                            <mat-select formControlName="gutachtenTextObjektArt">
                                <mat-option *ngFor="let option of gutachtenTextObjectTypes" [value]="option">
                                    {{option.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="col-md">
                            <mat-label>Unterart</mat-label>
                            <mat-select formControlName="gutachtenTextObjektUnterArt">
                                <mat-option *ngFor="let option of objectSubTypesFiltered" [value]="option">
                                    {{option.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="col-md">
                            <mat-label>Kategorie</mat-label>
                            <input matInput formControlName="gutachtenTextObjektKategorie">
                        </mat-form-field>
                    </div>
                    <div class="row gap-2">
                        <mat-form-field class="col-md-4">
                            <mat-label>USt. Art</mat-label>
                            <mat-select formControlName="umsatzSteuerArt">
                                <mat-option *ngFor="let option of umsatzSteuerArtArray" [value]="option">
                                    {{umsatzSteuerArtNames[option]}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </app-card>

            <app-card class="mt-4">
                <div header>Baukosten</div>
                <div body>
                    <div class="row gap-2">
                        <div class="col-md">
                            <mat-form-field class="col-12" *ngIf="referenceUnits">
                                <mat-label>Bezugseinheit</mat-label>
                                <mat-select formControlName="bezugsEinheit">
                                    <mat-option *ngFor="let referenceUnit of referenceUnits" [value]="referenceUnit">
                                        {{referenceUnit.name + ' (' + referenceUnit.kuerzel + ')'}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="col-12" *ngIf="this.form.controls.bezugsEinheit.value.name=='Nutzeinheit'">
                                <mat-error>Daten sind nicht vollständig</mat-error>
                            </div>
                        </div>
                        <mat-form-field class="col-md">
                            <mat-label>BPI aktuell</mat-label>
                            <input matInput [value]="excerptConstructionPriceIndices.bpiAktuell | number: '':'de-DE'"
                                disabled="true">
                        </mat-form-field>
                        <mat-form-field class="col-md">
                            <mat-label>BPI bei Veröffentlichung</mat-label>
                            <input matInput
                                [value]="excerptConstructionPriceIndices.bpiBeiVeroeffentlichung | number: '':'de-DE'"
                                disabled="true">
                        </mat-form-field>
                        <mat-form-field class="col-md">
                            <mat-label>Anpassung</mat-label>
                            <input matInput [value]="excerptConstructionPriceIndices.bpiAnpassung | number: '':'de-DE'"
                                disabled="true">
                        </mat-form-field>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md">
                            <table class="table table-sm table-hover w-auto">
                                <thead>
                                    <tr class="text-center">
                                        <th></th>
                                        <th>Mittelwert</th>
                                        <th>von</th>
                                        <th>bis</th>
                                    </tr>
                                </thead>
                                <tbody class="text-center">
                                    <tr>
                                        <td class="text-start">ohne Anpassungen</td>
                                        <td>{{this.displayNumber(this.excerptConstructionCosts?.bezugsEinheitMittelWert)}}
                                        </td>
                                        <td>{{this.displayNumber(this.excerptConstructionCosts?.bezugsEinheitVon)}}</td>
                                        <td>{{this.displayNumber(this.excerptConstructionCosts?.bezugsEinheitBis)}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-start">regionalisiert, an BPI angepasst, brutto</td>
                                        <td>{{this.displayNumber(this.excerptConstructionCosts?.bezugsEinheitMittelWertInklUSt)}}
                                        </td>
                                        <td>{{this.displayNumber(this.excerptConstructionCosts?.bezugsEinheitVonInklUSt)}}
                                        </td>
                                        <td>{{this.displayNumber(this.excerptConstructionCosts?.bezugsEinheitBisInklUSt)}}
                                        </td>
                                    </tr>
                                    <tr
                                        *ngIf="this.form.controls.umsatzSteuerArt?.value !== UmsatzSteuerArt.UStBefreit">
                                        <td class="text-start">regionalisiert, an BPI angepasst, netto</td>
                                        <td>{{this.displayNumber(this.excerptConstructionCosts?.bezugsEinheitMittelWertOhneUSt)}}
                                        </td>
                                        <td>{{this.displayNumber(this.excerptConstructionCosts?.bezugsEinheitVonOhneUSt)}}
                                        </td>
                                        <td>{{this.displayNumber(this.excerptConstructionCosts?.bezugsEinheitBisOhneUSt)}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </app-card>

            <app-card class="mt-4">
                <div header>Stellplätze</div>
                <div body>
                    <div class="row">
                        <mat-form-field class="col-md">
                            <mat-label>Außenstellplatz vorhanden?</mat-label>
                            <mat-select formControlName="aussenStellplatzVorhanden"
                                [(ngModel)]="data.aussenStellplatzVorhanden">
                                <mat-option [value]="true">Ja</mat-option>
                                <mat-option [value]="false">Nein</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="col-md">
                            <mat-label>Stellplatz Art</mat-label>
                            <mat-select formControlName="pkwStellplatzArt" class="d-flex flex-column"
                                [(ngModel)]="data.pkwStellplatzArt">
                                <mat-option *ngFor="let option of pkwStellplatzArtArray" [value]="option">
                                    {{pkwStellplatzArtNames[option]}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="col-md">
                            <mat-label>Kosten pro Stellplatz</mat-label>
                            <input matInput formControlName="kostenProStellplatz"
                                [(ngModel)]="data.kostenProStellplatz" />
                        </mat-form-field>
                    </div>
                </div>
            </app-card>

            <app-card class="mt-4">
                <div header>Zusatz</div>
                <div body>
                    <div class="row">
                        <mat-form-field class="col-md">
                            <mat-label>Vermietungssituation</mat-label>
                            <mat-select formControlName="vermietungsSituation" class="d-flex flex-column"
                                [(ngModel)]="data.vermietungsSituation">
                                <mat-option *ngFor="let option of vermietungsSituationArray" [value]="option">
                                    {{vermietungsSituationNames[option]}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="col-md">
                            <mat-label>Einordnung Kostenansatz in Referenzspanne - Wohnen</mat-label>

                            <mat-select formControlName="einordnungBaukostenSpanneWohnen" class="d-flex flex-column"
                                [(ngModel)]="data.einordnungBaukostenSpanneWohnen">
                                <mat-option *ngFor="let option of bauKostenSpanneArray" [value]="option">
                                    {{bauKostenSpanneNames[option]}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="col-md">
                            <mat-label>Einordnung Kostenansatz in Referenzspanne - Gewerbe</mat-label>
                            <mat-select formControlName="einordnungBaukostenSpanneGewerbe" class="d-flex flex-column"
                                [(ngModel)]="data.einordnungBaukostenSpanneGewerbe">
                                <mat-option *ngFor="let option of bauKostenSpanneArray" [value]="option">
                                    {{bauKostenSpanneNames[option]}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </app-card>

            <div class="hstack gap-2 justify-content-end mt-4">
                <button class="btn btn-light" (click)="cancel()">Abbrechen</button>
                <button class="btn btn-primary" (click)="save()">Speichern</button>
                <button class="btn btn-light" (click)="saveAndExit()">Speichern & Schließen</button>
            </div>
        </form>
    </div>
</app-card>