import { Component, Input, OnInit } from '@angular/core';
import { BauKostenSpanneNames } from 'src/app/appraisal-textgenerator/enums/BauKostenSpanne';
import { PkwStellplatzArtNames } from 'src/app/appraisal-textgenerator/enums/PkwStellplatzArt';
import { UmsatzSteuerArt, UmsatzSteuerArtNames } from 'src/app/appraisal-textgenerator/enums/UmsatzSteuerArt';
import { VermietungsSituation, VermietungsSituationNames } from 'src/app/appraisal-textgenerator/enums/VermietungsSituation';
import { BrickProductionCostsObject } from 'src/app/appraisal-textgenerator/interfaces/BrickTypesProductionCosts';

@Component({
  selector: 'app-brick-production-costs-object-detail',
  templateUrl: './brick-production-costs-object-detail.component.html',
  styleUrls: ['./brick-production-costs-object-detail.component.scss']
})
export class BrickProductionCostsObjectDetailComponent implements OnInit {

  @Input()
  data: BrickProductionCostsObject;

  vermietungsSituation: typeof VermietungsSituation = VermietungsSituation;
  umsatzSteuerArt: typeof UmsatzSteuerArt = UmsatzSteuerArt;
  public UmsatzSteuerArtNames = UmsatzSteuerArtNames;
  public PkwStellplatzArtNames = PkwStellplatzArtNames;
  public VermietungsSituationNames = VermietungsSituationNames;
  public BauKostenSpanneNames = BauKostenSpanneNames;

  constructor() { }

  ngOnInit(): void {
  }

}