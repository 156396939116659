import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {debounceTime, map, shareReplay, switchMap} from 'rxjs/operators';
import {AppraisalFilter} from '../../appraisal-detail/models/Appraisal';
import {AppraisalService} from '../../appraisal-detail/services/appraisal.service';
import {CollectionMapFilter, IMapCollection} from '../../collection-upload/models/Collection';
import {CollectionService} from '../../collection-upload/services/collection.service';

@Component({
    selector: 'app-collection-sidebar',
    templateUrl: './collection-sidebar.component.html',
    styleUrls: ['./collection-sidebar.component.scss']
})
export class CollectionSidebarComponent implements OnInit {
    public collections$: Observable<IMapCollection>;
    @Input() isOpen: boolean;

    constructor(private appraisalService: AppraisalService, private collectionService: CollectionService) {
    }

    ngOnInit(): void {
        this.collections$ = this.appraisalService.currentFilter.pipe(
            debounceTime(300),
            map((appraisalFilter: AppraisalFilter) => appraisalFilter as CollectionMapFilter),
            switchMap((filter: CollectionMapFilter) => this.collectionService.getMapCollectionsFiltered(filter)),
            shareReplay(1)
        );
    }
}
