import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { SharedModule } from "../shared/shared.module";
import { MatTableModule } from '@angular/material/table'
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { TextgeneratorRoutingModule } from './textgenerator-routing.module';
import { TextListComponent } from './components/text-list/text-list.component';
import { TextDetailComponent } from './components/text-detail/text-detail.component';
import { CreateDialogComponent } from './components/create-dialog/create-dialog.component';
import { FieldComponent } from './components/field/field.component';
import { CardComponent } from './components/card/card.component';
import { BrickConstructionLoadsEditComponent } from './components/bricks/brick-construction-loads-edit/brick-construction-loads-edit.component';
import { BrickConstructionLoadsDetailComponent } from './components/bricks/brick-construction-loads-detail/brick-construction-loads-detail.component';
import { TextgeneratorComponent } from './textgenerator.component';
import { BricksComponent } from './components/bricks/bricks.component';
import { BrickConstructionLawDetailComponent } from './components/bricks/brick-construction-law-detail/brick-construction-law-detail.component';
import { BrickConstructionLawEditComponent } from './components/bricks/brick-construction-law-edit/brick-construction-law-edit.component';
import { BrickProductionCostsEditComponent } from './components/bricks/brick-production-costs-edit/brick-production-costs-edit.component';
import { BrickProductionCostsDetailComponent } from './components/bricks/brick-production-costs-detail/brick-production-costs-detail.component';
import { BrickProductionCostsObjectDetailComponent } from './components/bricks/brick-production-costs-object-detail/brick-production-costs-object-detail.component';
import { BrickProductionCostsObjectEditComponent } from './components/bricks/brick-production-costs-object-edit/brick-production-costs-object-edit.component';
import { BrickProductionCostsObjectCreateComponent } from './components/bricks/brick-production-costs-object-create/brick-production-costs-object-create.component';
import { DeleteDialogComponent } from './components/delete-dialog/delete-dialog.component';
import { BrickNotesComponent } from './components/bricks/brick-notes/brick-notes.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { BrickMacroLocationDetailComponent } from './components/bricks/brick-macro-location-detail/brick-macro-location-detail.component';
import { BrickMacroLocationEditComponent } from './components/bricks/brick-macro-location-edit/brick-macro-location-edit.component';
import { BrickMacroLocationDisplayComponent } from './components/bricks/brick-macro-location-display/brick-macro-location-display.component';

@NgModule({
   declarations: [
      TextListComponent,
      TextDetailComponent,
      CreateDialogComponent,
      TextgeneratorComponent,
      FieldComponent,
      CardComponent,
      BricksComponent,
      BrickConstructionLoadsEditComponent,
      BrickConstructionLoadsDetailComponent,
      BrickConstructionLawDetailComponent,
      BrickConstructionLawEditComponent,
      BrickProductionCostsEditComponent,
      BrickProductionCostsDetailComponent,
      BrickProductionCostsObjectDetailComponent,
      BrickProductionCostsObjectEditComponent,
      BrickProductionCostsObjectCreateComponent,
      DeleteDialogComponent,
      BrickNotesComponent,
      BrickMacroLocationDetailComponent,
      BrickMacroLocationEditComponent,
      BrickMacroLocationDisplayComponent
   ],
   imports: [
      MatTooltipModule,
      CommonModule,
      SharedModule,
      MatTableModule,
      MatButtonModule,
      TextgeneratorRoutingModule,
      MatDialogModule,
      ReactiveFormsModule,
      MatFormFieldModule,
      MatInputModule,
      MatSelectModule,
      MatAutocompleteModule,
      MatCardModule,
      MatGridListModule,
      MatRadioModule,
      MatTabsModule,
      MatExpansionModule,
      MatIconModule,
      MatBadgeModule,
      FormsModule
   ],
   exports: [
   ],
   providers: [
      DecimalPipe,
      { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
      { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
      { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
   ]
})
export class TextgeneratorModule { }
