import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    AbstractControl,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import {CollectionService} from '../../../../../collection-upload/services/collection.service';
import {UploadDropdownFileQuartal} from '../../../../../collection-upload/UploadDropdownData';
import {MimeTypes} from '../../../../../core/enums/MimeTypes';
import {DialogContentType} from '../../../../models/Dialog';
import {CollectionFile} from "../../../../../collection-upload/models/Collection";

@Component({
    selector: 'app-dialog-upload-file',
    templateUrl: './dialog-upload-file.component.html',
    styleUrls: ['./dialog-upload-file.component.scss']
})
export class DialogUploadFileComponent implements OnInit {
    DialogContentType = DialogContentType;
    @Input() data: any;
    @Output() closed = new EventEmitter<any>();
    formGroup: UntypedFormGroup;
    url: string;
    fileToUpload: File | null = null;
    showBoxFile = true;
    showBoxUrl = true;
    loading = false;
    selectedQuarter: number;

    DropQuartal = UploadDropdownFileQuartal;

    constructor(private collectionService: CollectionService) {
    }

    ngOnInit(): void {
        this.formGroup = new UntypedFormGroup(
            {
                titel: new UntypedFormControl('', [Validators.required, Validators.maxLength(60)]),
                herausgabedatum: new UntypedFormControl(null, this.data.content.kategorie > 0 ? [] : Validators.required),
                uploadFile: new UntypedFormControl(null, this.validateFileExtension),
                uploadUrl: new UntypedFormControl(null)
            },
            {
                validators: [this.checkFileOrUrlFilled('uploadFile', 'uploadUrl')]
            }
        );
    }

    setSelect(value: number, name: string): void {
        if (name === 'quartal') {
            this.selectedQuarter = value;
        }
    }

    submit(id: string): void {
        this.loading = true;
        setTimeout(function () {
            this.loading = false;
        }, 10000);

        if (this.formGroup.get('uploadUrl').value != null) {
            this.uploadUrl(id);
        } else {
            this.uploadFile(id);
        }
    }

    cancel(): void {
        this.closed.emit(null);
    }

    copyUrlFromClipboard(event: Event) {
        navigator.clipboard
            .readText()
            .then((text) => {
                this.url = text;
                this.formGroup.get('uploadUrl').setValue(text);
                this.formGroup.get('uploadFile').setValue(null);
            })
            .catch((error) => {
                console.error('Cannot read clipboard text: ', error);
            });
        this.showBoxFile = false;
    }

    uploadUrl(id: string) {
        let formData: any = new FormData();
        formData.append('collectionId', id);
        formData.append('urlTitle', this.formGroup.get('titel').value);
        formData.append('url', this.returnValidUrlOrString(this.formGroup.get('uploadUrl').value));
        formData.append('urlReleasedate', this.formGroup.get('herausgabedatum').value.toISOString());
        formData.append('urlQuartal', this.selectedQuarter ? this.selectedQuarter : '');
        this.collectionService.addUrl(formData).subscribe((result) => {
            this.closed.emit({
                titel: this.formGroup.get('titel').value,
                herausgabedatum: this.formGroup.get('herausgabedatum').value,
                type: MimeTypes.URL,
                id: result.data,
                url: this.formGroup.get('uploadUrl').value
            });
        });
    }

    uploadFile(id: string) {
        let formData: any = new FormData();
        formData.append('collectionId', id);
        formData.append('fileTitle', this.formGroup.get('titel').value);
        formData.append('uploadFile', this.fileToUpload);
        formData.append('fileReleasedate', this.formGroup.get('herausgabedatum').value?.toISOString() || '');
        formData.append('fileQuartal', this.selectedQuarter ? this.selectedQuarter : '');

        this.collectionService.addFile(formData).subscribe((result: CollectionFile) => {
            this.closed.emit({
                titel: this.formGroup.get('titel').value,
                herausgabedatum: this.formGroup.get('herausgabedatum').value,
                type: this.formGroup.get('uploadFile').value.type,
                id: result.id,
                originalFileName: result.originalFileName,
                fileExtension: result.fileExtension
            });
        });
    }

    returnValidUrlOrString(input: string): string {
        const validUrlRegExp = new RegExp(
            '(http(s)?):\\/\\/[(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&\\/\\/=]*)'
        );
        const urlStartsWithWwwRegExp = new RegExp(
            'www\\.[a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&\\/\\/=]*)'
        );
        const urlStartsWithoutWwwRegExp = new RegExp('[a-zA-Z0-9@:%._\\\\+~#=]{2,256}\\.[a-z]{2,3}$');

        // check if input starts with http or https ://
        if (validUrlRegExp.test(input)) {
            return input;
        }
        // check if link is in Format www.example.com - and add https://
        if (urlStartsWithWwwRegExp.test(input)) {
            return 'https://' + input;
        }
        // check if link is in Format example.com - and add https://www.
        if (urlStartsWithoutWwwRegExp.test(input)) {
            return 'https://www.' + input;
        }

        return input;
    }

    checkFileOrUrlFilled(controlNameA: string, controlNameB: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const correct = control.get(controlNameA)?.value != null || control.get(controlNameB)?.value != null;
            return correct ? null : {notFilled: {value: control.value}};
        };
    }

    validateFileExtension(control: AbstractControl): ValidationErrors | null {
        if (control.value) {
            const validFileFormats = new RegExp(/.pdf$|.xlsx$|.xls$/);
            const fileName = control.value?.name;
            const correct = validFileFormats.test(fileName);
            return correct ? null : {notFilled: {value: control.value}};
        }
        return null;
    }

    handleFileInput(files: FileList) {
        const file = files.item(0);
        this.fileToUpload = file;
        this.formGroup.get('uploadFile').setValue(file);
        this.formGroup.get('uploadUrl').setValue(null);
        this.showBoxUrl = false;
    }

    reset() {
        this.showBoxFile = true;
        this.showBoxUrl = true;
        this.formGroup.get('uploadUrl').setValue(null);
        this.formGroup.get('uploadFile').setValue(null);
    }
}
