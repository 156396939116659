import { Component, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

@Component({
    selector: 'app-input-info-delete',
    templateUrl: './input-info-delete.component.html',
    styleUrls: ['./input-info-delete.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: InputInfoDeleteComponent,
            multi: true
        }
    ]
})
export class InputInfoDeleteComponent implements ControlValueAccessor {
    @Input() data: { placeholder: string; controlName: string; required: boolean };

    formGroup: FormControl;

    inputAvailable = false;
    onChange = (value: string) => {};

    constructor() {
        this.formGroup = new FormControl('', Validators.required);
    }

    setInput(value: string, callOnChange: boolean = true) {
        this.formGroup.setValue(value);
        this.inputAvailable = !!value;
        callOnChange && this.onChange(value);
    }

    inputClear(): void {
        this.setInput('');
    }

    writeValue(value: string): void {
        this.setInput(value, false);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {}

    setDisabledState(isDisabled: boolean): void {}
}
