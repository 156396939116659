import { Component, Input } from '@angular/core';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { IOSMNode } from '../../../models/IOSMNode';

@Component({
    selector: 'app-location-list-item',
    templateUrl: './location-list-item.component.html',
    styleUrls: ['./location-list-item.component.scss']
})
export class LocationListItemComponent {
    faMapMarker = faMapMarkerAlt;
    @Input() node: IOSMNode;
}
