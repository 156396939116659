import { Component, Input } from '@angular/core';
import { buildSpecificImgUrl, environment } from '../../../../environments/environment';

@Component({
    selector: 'app-img',
    templateUrl: './img.component.html',
    styleUrls: ['./img.component.scss']
})
export class ImgComponent {
    src: string;
    defaultImgSrc: string = environment.defaultImgUrl;
    @Input() type: 'big' | 'thumbnail';
    @Input() rounded = false;
    @Input() size: number;

    @Input() set id(id: number) {
        this.src = buildSpecificImgUrl(id, this.type);
    }
}
