import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppraisalService } from './appraisal-detail/services/appraisal.service';
import { LoadingService } from './core/services/loading.service';
import { MapService } from './core/services/map.service';
import { SidebarNavigationService } from './core/services/sidebar-navigation.service';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
   title = 'Datasuite';

   isLoading$: Observable<boolean>;
   private userPosSub: Subscription;

   @ViewChild(MatDrawer)
   private drawer: MatDrawer;

   constructor(
      private sidebarService: SidebarNavigationService,
      private loadingService: LoadingService,
      private mapService: MapService,
      private appraisalService: AppraisalService
   ) { }

   ngOnInit(): void {
      this.isLoading$ = this.loadingService.isLoading$.pipe(debounceTime(50));

      this.userPosSub = this.mapService.getUserPos().subscribe((pos) => {
         this.mapService.setCenterCoords(pos);
         this.appraisalService.addFilter({ lat: pos.lat, lon: pos.lng });
         this.mapService.setUserPos(pos);
      });
   }

   ngAfterViewInit(): void {
      this.sidebarService.handleEvents(this.drawer);
   }

   ngOnDestroy(): void {
      this.userPosSub?.unsubscribe();
   }
}
