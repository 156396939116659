import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BarNotification } from '../enums/BarNotificaton';

@Injectable({
    providedIn: 'root'
})
export class NotificationbarService {
    private notificationSubject = new Subject<any>();
    public notificationState = this.notificationSubject.asObservable();

    show(message: string, type?: BarNotification) {
        this.notificationSubject.next({
            show: true,
            message,
            type
        });
    }
}
