export enum Baugenehmigung {
   LiegtNichtVor = 1,
   LiegtVor = 2,
   VorOrtEinsehbar = 3
};

export type BaugenehmigungName = {
   [key in Baugenehmigung]: string;
};

export const BaugenehmigungNames: BaugenehmigungName = {
   [Baugenehmigung.LiegtNichtVor]: 'liegt nicht vor',
   [Baugenehmigung.LiegtVor]: 'liegt vor',
   [Baugenehmigung.VorOrtEinsehbar]: 'vor Ort einsehbar'
};