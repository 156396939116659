import { Pipe, PipeTransform } from '@angular/core';
import { DatenQuelle } from '../../core/enums/DatenQuelle';
import { Appraisal } from '../models/Appraisal';

@Pipe({
    name: 'getAppraisalId'
})
export class GetAppraisalIdPipe implements PipeTransform {
    transform(appraisal: Appraisal): string {
        const noId = 'Gutachten ohne ID';

        switch (appraisal.datenQuelle) {
            case DatenQuelle.LORA: {
                return appraisal.loraAuftragsNummer || noId;
            }
            case DatenQuelle.GIKS: {
                return appraisal.giksId || noId;
            }
            case DatenQuelle.HYBRID: {
                return appraisal.loraAuftragsNummer || appraisal.giksId || noId;
            }
            case DatenQuelle.DIGATUS: {
                return appraisal.id.toString() || noId;
            }
            default: {
                return noId;
            }
        }
    }
}
