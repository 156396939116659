import {Pipe, PipeTransform} from '@angular/core';
import {CollectionNutzungsart} from '../models/Collection';

@Pipe({
    name: 'getNutzungsartById'
})
export class GetNutzungsartByIdPipe implements PipeTransform {
    transform(
        filterIds: number[],
        elementNutzungen: CollectionNutzungsart[]
    ): string {
        if (filterIds.length == 0) {
            return elementNutzungen.map(n => n.name).join(', ');
        }
        return [...new Set(elementNutzungen
            .filter(n => filterIds.includes(n.id))
            .map(n => n.name))].join(', ');
    }
}
