import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ApolloLink, DefaultOptions, InMemoryCache } from '@apollo/client/core';
import { Apollo, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '../../../environments/environment';
import { GraphqlService } from '../../core/services/graphql.service';

const defaultOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
    },
    mutate: {
        errorPolicy: 'all'
    }
};

@NgModule({
    imports: [CommonModule, ApolloModule]
})
export class GraphQlModule {
    constructor(apollo: Apollo, httpLink: HttpLink, graphQlService: GraphqlService) {
        apollo.createDefault({
            link: ApolloLink.from([
                graphQlService.getApolloAuth(),
                graphQlService.getApolloOnError(),
                httpLink.create({ uri: environment.baseGraphQLUrl })
            ]),
            cache: new InMemoryCache(),
            defaultOptions
        });
    }
}
