import { Component, Input } from '@angular/core';
import { Segment } from './Segment';

@Component({
    selector: 'app-segmented-control',
    templateUrl: './segmented-control.component.html',
    styleUrls: ['./segmented-control.component.scss']
})
export class SegmentedControlComponent {
    @Input() segments: Segment[];
}
