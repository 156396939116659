import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogContentType } from '../../../models/Dialog';

@Component({
    selector: 'app-dialog-form',
    templateUrl: './dialog-form.component.html',
    styleUrls: ['./dialog-form.component.scss']
})
export class DialogFormComponent {
    DialogContentType = DialogContentType;
    @Input() data: any;
    @Output() closeDialog: EventEmitter<any> = new EventEmitter();

    onClose(value: any): void {
        this.closeDialog.emit(value);
    }
}
