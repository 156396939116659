import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'app-dialog-feedback',
    templateUrl: './dialog-feedback.component.html',
    styleUrls: ['./dialog-feedback.component.scss']
})
export class DialogFeedbackComponent implements OnInit {

    @Output() closed = new EventEmitter<any>();

    @Input() data: any;

    @Output() closeDialog: EventEmitter<any> = new EventEmitter();

    public form = new UntypedFormGroup({
        description: new UntypedFormControl('', [Validators.required])
    });

    submit(): void {
        this.closed.emit(this.form.getRawValue());
    }

    cancel(): void {
        this.closed.emit(null);
    }

    constructor() {
    }

    ngOnInit(): void {
        console.log(this.data);
    }

}
