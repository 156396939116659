import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppraisalCompareResolver } from '../shared/resolver/appraisal-compare.resolver';
import { AppraisalResolver } from '../shared/resolver/appraisal.resolver';
import { AppraisalTableCmpComponent } from './components/chart-table/appraisal-table/appraisal-table-cmp/appraisal-table-cmp.component';
import { AppraisalTableDetailComponent } from './components/chart-table/appraisal-table/appraisal-table-detail/appraisal-table-detail.component';
import { AppraisalCmpViewComponent } from './components/overview/appraisal-compare/appraisal-cmp-view.component';
import { AppraisalInfoViewComponent } from './components/overview/appraisal-detail/appraisal-info-view.component';
import { SegmentCompareComponent } from './components/segment-compare/segment-compare.component';
import { SegmentDetailComponent } from './components/segment-detail/segment-detail.component';

const routes: Routes = [
    {
        path: 'compare',
        component: SegmentCompareComponent,
        data: {
            title: 'Gutachtenvergleich'
        },
        children: [
            {
                path: '',
                redirectTo: 'overview',
                pathMatch: 'full'
            },
            {
                path: 'overview',
                component: AppraisalCmpViewComponent,
                data: {
                    label: 'Übersicht'
                },
                resolve: {
                    appraisals: AppraisalCompareResolver
                }
            },
            {
                path: 'table',
                component: AppraisalTableCmpComponent,
                data: {
                    label: 'Tabelle'
                }
            }
        ]
    },
    {
        path: 'detail/:id',
        component: SegmentDetailComponent,
        data: {
            title: 'Gutachteninfos'
        },
        resolve: {
            appraisal: AppraisalResolver
        },
        children: [
            {
                path: '',
                redirectTo: 'overview',
                pathMatch: 'full'
            },
            {
                path: 'overview',
                component: AppraisalInfoViewComponent,
                data: {
                    label: 'Übersicht'
                }
            },
            {
                path: 'table',
                component: AppraisalTableDetailComponent,
                data: {
                    label: 'Tabelle'
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AppraisalRoutingModule {}
