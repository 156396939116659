import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {

  objekt: string
  titel: string

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DeleteDialogComponent>,
    ) { 
      
    }

  ngOnInit(): void {
    this.objekt = this.data.objekt;
    this.titel = this.data.objektTitel;
  }



  close() {
    this.dialogRef.close();
  }

  delete()
  {
    this.dialogRef.close('deleted');
  }
}
