import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-dialog-html',
    templateUrl: './dialog-html.component.html',
    styleUrls: ['./dialog-html.component.scss']
})
export class DialogHtmlComponent {
    @Input() text: string;
}
