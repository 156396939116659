import { Component, OnInit } from '@angular/core';
import { CollectionFile } from '../../models/Collection';
import { BreadcrumbDefault, BreadcrumbsService } from 'src/app/core/services/breadcrumbs.service';

@Component({
   selector: 'app-upload-collection',
   templateUrl: './upload-collection.component.html',
   styleUrls: ['./upload-collection.component.scss']
})
export class UploadCollectionComponent implements OnInit {
   selectedFile: CollectionFile = null;

   constructor(
      private breadcrumbsService: BreadcrumbsService
   ) { }

   ngOnInit(): void {
      this.breadcrumbsService.Apply([
         { ...BreadcrumbDefault.UploadList },
         { ...BreadcrumbDefault.CollectionLabel }
      ]);
   }

   selectFile(file: CollectionFile) {
      this.selectedFile = file;
   }
}
