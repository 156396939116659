export enum GebrauchsAbnahmeNachweis {
   LiegtNichtVor = 1,
   LiegtVor = 2
};

export type GebrauchsAbnahmeNachweisName = {
   [key in GebrauchsAbnahmeNachweis]: string;
};

export const GebrauchsAbnahmeNachweisNames: GebrauchsAbnahmeNachweisName = {
   [GebrauchsAbnahmeNachweis.LiegtNichtVor]: 'liegt nicht vor',
   [GebrauchsAbnahmeNachweis.LiegtVor]: 'liegt vor'
};