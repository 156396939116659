import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {IPlaceHolderParams} from '../../../core/interfaces/PlaceHolder';

@Component({
    selector: 'app-number-display',
    templateUrl: './number-display.component.html',
    styleUrls: ['./number-display.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumberDisplayComponent {
    displayValue: number | null | undefined;

    @Input() set value(val: number | null | undefined) {
        if ((val != null && val > 0) || (val === 0 && this.displayZero)) {
            this.displayValue = val;
        }
    }

    @Input() decimalPlaces = 0;
    @Input() displayZero = false;

    @Input() placeHolderParams: IPlaceHolderParams | undefined;
}
