<div id="table-container">
    <form [formGroup]="formGroup">
        <mat-form-field appearance="fill" class="datePicker">
            <mat-label>{{ data.placeholder }}</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="{{ data.controlName }}" />
            <mat-datepicker-toggle matIconSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon
                    ><span class="material-symbols-outlined"> calendar_month </span></mat-icon
                >
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </form>
</div>
