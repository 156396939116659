import { Component, OnInit } from '@angular/core';
import * as html2pdf from 'html2pdf.js';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppraisalCompareService } from 'src/app/appraisal-detail/services/appraisal-compare.service';
import { BarNotification } from '../../../../../core/enums/BarNotificaton';
import { DatenQuelle } from '../../../../../core/enums/DatenQuelle';
import { FileService } from '../../../../../core/services/file.service';
import { NotificationbarService } from '../../../../../core/services/notificationbar.service';
import { SnackbarService } from '../../../../../core/services/snackbar.service';
import { Appraisal } from '../../../../models/Appraisal';
import { BreadcrumbDefault, BreadcrumbsService } from 'src/app/core/services/breadcrumbs.service';

@Component({
   selector: 'app-appraisal-table-cmp',
   templateUrl: './appraisal-table-cmp.component.html',
   styleUrls: ['./appraisal-table-cmp.component.scss', '../appraisal-table-view-shared.scss']
})
export class AppraisalTableCmpComponent implements OnInit {
   appraisals$: Observable<Appraisal[]>;
   contentWidth: number;
   reps: number[];
   switchAnsatzSelected = true;
   showSwitchAnsatz = false;
   showScatters = false;
   showHistograms = true;

   constructor(
      private appraisalCmpService: AppraisalCompareService,
      private fileService: FileService,
      private snackbarService: SnackbarService,
      private notificationbarService: NotificationbarService,
      private breadcrumbsService: BreadcrumbsService
   ) { }

   ngOnInit(): void {
      this.appraisals$ = this.appraisalCmpService.comparableAppraisals.pipe(
         tap((appraisals) => {
            if (appraisals.some((app: Appraisal) => app.datenQuelle === DatenQuelle.GIKS)) {
               this.showNotificationbar(
                  'Auswahl enthält Giks-Gutachten mit BGF-Werten, welche die Charts und Tabelle verfälschen können!',
                  BarNotification.DEFAULT
               );
            }
         })
      );

      this.breadcrumbsService.Apply([
         { ...BreadcrumbDefault.SearchList },
         { label: 'Vergleich Tabelle' }
      ]);
   }

   setAnsatzButtonShowing(showBtn: boolean) {
      this.showSwitchAnsatz = showBtn;
   }

   onExcelDownloadClicked(appraisals: Appraisal[]) {
      this.snackbarService.showInfo('Excel wird heruntergeladen...');
      const ids = appraisals.map((x) => x.id);
      this.fileService
         .fetchExcelFile(ids)
         .subscribe((x) => this.fileService.downloadExcelFile(x, 'DataSuite Gutachtenvergleich'));
   }

   showNotificationbar(message: string, type: BarNotification) {
      this.notificationbarService.show(message, type);
   }

   download(count: number) {
      this.snackbarService.showInfo('PDF wird heruntergeladen...');
      const element = window.document.getElementById('pdf-cmp');
      element.style.height = 'auto';
      const spacer = window.document.getElementById('pdf-spacer-cmp');
      spacer.style.height = '3000px';
      const frame = window.document.getElementById('frame-cmp');
      frame.style.overflow = 'hidden';
      const opt = {
         margin: 1,
         filename: `DataSuite ${count} Objekte im Vergleich.pdf`,
         image: { type: 'jpeg', quality: 1 },
         html2canvas: {
            dpi: 192,
            scale: 4,
            letterRendering: true,
            useCORS: true
         },
         jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait' }
      };
      html2pdf().from(element).set(opt).save();

      setTimeout(() => {
         element.style.height = '0px';
         spacer.style.height = '0px';
         frame.style.overflow = 'auto';
      }, 1000);
   }
}
