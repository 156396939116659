<p class="mt-5 display-6">Gutachtentext Liste</p>

<div class="hstack gap-2 justify-content-end">
   <i *ngIf="options" class="fa-regular fa-circle-question fs-5"
      [matTooltip]="'Max. ' + options?.createCountUserLimit + ' Einträge erlaubt. Alte löschen, um neue zu erstellen.'"></i>
   <button (click)="onCreate()" [disabled]="!createAllowed"
      [ngClass]="{'btn': true, 'btn-primary': createAllowed, 'btn-light': !createAllowed}">
      Neu
   </button>
</div>
<div id="table-container" class="root">
   <table mat-table [dataSource]="appraisalTexts" class="table-default mat-elevation-z8">

      <ng-container matColumnDef="auftragsnummer">
         <th *matHeaderCellDef mat-header-cell> Auftragsnummer </th>
         <td *matCellDef="let element" mat-cell>{{ element.loraAuftragsnummer }}</td>
      </ng-container>

      <ng-container matColumnDef="auftraggeber">
         <th *matHeaderCellDef mat-header-cell> Auftraggeber </th>
         <td *matCellDef="let element" mat-cell>{{ element.auftraggeber.loraAuftraggeberName }}</td>
      </ng-container>

      <ng-container matColumnDef="auftragsart">
         <th *matHeaderCellDef mat-header-cell> Auftragsart </th>
         <td *matCellDef="let element" mat-cell>{{ element.auftragsart.name }}</td>
      </ng-container>

      <ng-container matColumnDef="wertbestimmendesVerfahren">
         <th *matHeaderCellDef mat-header-cell> Verfahren </th>
         <td *matCellDef="let element" mat-cell>{{ WertbestimmendesVerfahrenNames[element.wertbestimmendesVerfahren ||
            ''] }}</td>
      </ng-container>

      <ng-container matColumnDef="createdOn">
         <th *matHeaderCellDef mat-header-cell> Erstellt am </th>
         <td *matCellDef="let element" mat-cell>{{ element.createdOn | date:'yyyy-MM-dd' }}</td>
      </ng-container>

      <ng-container matColumnDef="createdBy">
         <th *matHeaderCellDef mat-header-cell> Erstellt von </th>
         <td *matCellDef="let element" mat-cell>{{ element.createdByUser.email }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
         <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
         <td mat-cell *matCellDef="let element">
            <div class="hstack gap-2">
               <button class="btn btn btn-light ms-auto" (click)="onDelete(element)">Löschen</button>
               <button class="btn btn btn-light" [routerLink]="[element.id]">Bearbeiten</button>
            </div>
         </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let myRowData; columns: displayedColumns"></tr>

   </table>
</div>