export enum UmsatzSteuerArt {
   UStBefreit = 1,
   ZzglUSt = 2,
   Gemischt = 3
};

export type UmsatzSteuerArtName = {
   [key in UmsatzSteuerArt]: string;
};

export const UmsatzSteuerArtNames: UmsatzSteuerArtName = {
   [UmsatzSteuerArt.UStBefreit]: 'USt.-befreit',
   [UmsatzSteuerArt.ZzglUSt]: 'zzgl. USt.',
   [UmsatzSteuerArt.Gemischt]: 'Gemischt'
};