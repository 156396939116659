import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';
import { CollectionLageShort } from '../../collection-upload/models/Collection';
import { CollectionService } from '../../collection-upload/services/collection.service';

@Pipe({
    name: 'gibtUploadListText'
})
export class GibtUploadListTextPipe implements PipeTransform {
    constructor(private collectionService: CollectionService) {}

    transform(lage: CollectionLageShort[]): string {
        const sortDirection = this.collectionService.sortingSubject$.value.direction;
        if (lage != null) {
            const callbackfn = (o: CollectionLageShort) => [
                o.lage.gemeinde?.name,
                o.lage.landkreis?.name,
                o.lage.bundesland?.name,
                ...[o.lage.countryWide ? 'Deutschlandweit' : null]
            ];

            const x = _.flatMap(lage.map(callbackfn))
                .filter((o) => o != null)
                .sort((a, b) => a.localeCompare(b));

            if (sortDirection === 'desc') {
                x.reverse();
            }
            return x.length > 0 ? x[0] : '';
        } else {
            return '';
        }
    }
}
