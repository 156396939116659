import { GenericSelectOption } from '../shared/components/radio-group/SelectOption';

export const UploadDropdownKostenpflichtig: GenericSelectOption<number>[] = [
    { value: 0, label: 'Kostenpflichtig' },
    { value: 1, label: 'Kostenfrei' }
];

export const UploadDropdownKategorie: GenericSelectOption<number>[] = [
    { value: 0, label: 'Marktdaten' },
    { value: 1, label: 'Zusatzinfos' },
    { value: 2, label: 'Studien' },
    { value: 3, label: 'Seminare' },
    { value: 4, label: 'Kennzahlen / Statistiken' },
    { value: 5, label: 'Fachliteratur' }
];

export const UploadDropdownPublikaionsturnus: GenericSelectOption<number>[] = [
    { value: 0, label: 'Unregelmäßig' },
    { value: 1, label: 'Einmalig' },
    { value: 2, label: 'Monatlich' },
    { value: 3, label: 'Vierteljährlich' },
    { value: 4, label: 'Halbjährlich' },
    { value: 5, label: 'Jährlich' },
    { value: 6, label: 'Mehrjährig' }
];

export const UploadDropdownFileQuartal: GenericSelectOption<number>[] = [
    { value: 1, label: 'Quartal 1' },
    { value: 2, label: 'Quartal 2' },
    { value: 3, label: 'Quartal 3' },
    { value: 4, label: 'Quartal 4' },
    { value: 5, label: 'Halbjahr 1' },
    { value: 6, label: 'Halbjahr 2' }
];
