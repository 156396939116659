import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { SearchModule } from '../appraisal-search/search.module';
import { DragDropDirective } from '../shared/directives/drag-drop.directive';
import { SharedModule } from '../shared/shared.module';
import { UploadCollectionPreviewComponent } from './components/upload-collection/upload-collection-preview/upload-collection-preview.component';
import { UploadCollectionComponent } from './components/upload-collection/upload-collection.component';
import { UploadCreateComponent } from './components/upload-create/upload-create.component';
import { UploadRoutingModule } from './upload-routing.module';
import {MatAutocompleteModule} from "@angular/material/autocomplete";

const declarations = [DragDropDirective, UploadCreateComponent, UploadCollectionComponent];

@NgModule({
    declarations: [declarations, UploadCollectionPreviewComponent],
    imports: [
        CommonModule,
        NgxExtendedPdfViewerModule,
        UploadRoutingModule,
        SharedModule,
        SearchModule,
        PdfViewerModule,
        ReactiveFormsModule,
        MatIconModule,
        ClipboardModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatAutocompleteModule
    ],
    exports: [...declarations],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
    ]
})
export class UploadModule {}
