import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {AppraisalCompareService} from '../../../appraisal-detail/services/appraisal-compare.service';
import {RouteTransform} from '../../../shared/routetransform/RouteTransform';

@Component({
    selector: 'app-segment-appraisal-detail-search',
    templateUrl: './segment-search.component.html',
    styleUrls: ['./segment-search.component.scss']
})
export class SegmentSearchComponent extends RouteTransform implements OnDestroy, OnInit {
    comparableAppraisalCount: number;
    leftSidebarOpen = true;
    rightSidebarOpen = false;

    faTimesCircle = faTimesCircle;

    private cmpCountSub: Subscription;

    constructor(private appraisalCmpService: AppraisalCompareService, route: ActivatedRoute, router: Router) {
        super(route, router);
    }

    ngOnInit(): void {
        this.cmpCountSub = this.appraisalCmpService.comparableAppraisalIds
            .pipe(map((ids: number[]) => ids.length))
            .subscribe((count) => (this.comparableAppraisalCount = count));
    }

    ngOnDestroy(): void {
        this.cmpCountSub?.unsubscribe();
    }

    clearCompareBasket(): void {
        this.appraisalCmpService.clearCompareBasket();
    }
}
