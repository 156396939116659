<div *ngIf="appraisals$ | async as appraisals" class="frame" id="frame-cmp">
    <div class="header">
        <h3>Marktvergleich</h3>
        <div *ngIf="(showHistograms || showScatters) && showSwitchAnsatz">
            <button
                (click)="switchAnsatzSelected = true"
                [ngClass]="switchAnsatzSelected ? 'active-btn' : 'inactive-btn'"
                class="default btn-switch btn-ansatz"
            >
                &#160;Ansatz
            </button>
            <button
                (click)="switchAnsatzSelected = false"
                [ngClass]="switchAnsatzSelected ? 'inactive-btn' : 'active-btn'"
                class="default btn-switch btn-ist"
            >
                &#160;&#160;&#160;Ist&#160;&#160;&#160;
            </button>
        </div>
        <div data-html2canvas-ignore="true">
            <!-- under construction
            <button (click)="download(appraisals.length)" class="primary pdf-button">PDF-Export</button>
            -->
            <button (click)="onExcelDownloadClicked(appraisals)" class="primary">Excel-Export</button>
        </div>
    </div>

    <app-appraisal-chart-view
        [appraisals]="appraisals"
        [showHistograms]="showHistograms"
        [showScatters]="showScatters"
        [switchAnsatzActive]="switchAnsatzSelected"
        (showSwitchAnsatzIst)="setAnsatzButtonShowing($event)"
    ></app-appraisal-chart-view>

    <div class="table">
        <app-appraisal-table-view [appraisals]="appraisals"></app-appraisal-table-view>
    </div>
    <div id="pdf-spacer-cmp"></div>
    <div id="pdf-cmp">
        <div class="header">
            <h3>Marktvergleich</h3>
        </div>
        <app-appraisal-chart-view
            [appraisals]="appraisals"
            [showHistograms]="showHistograms"
            [showScatters]="showScatters"
            [pdfStyle]="true"
            [switchAnsatzActive]="switchAnsatzSelected"
            (showSwitchAnsatzIst)="setAnsatzButtonShowing($event)"
        ></app-appraisal-chart-view>
        <app-appraisal-table-view [appraisals]="appraisals" [pdfStyle]="true"></app-appraisal-table-view>
    </div>
</div>
