<canvas
    [chartType]="type"
    [colors]="colors"
    [datasets]="chartData?.data.datasets"
    [labels]="chartData?.data?.labels || []"
    [legend]="enableLegend"
    [options]="chartOptions"
    [plugins]="plugins"
    [style.height]="height"
    [style.width]="width"
    baseChart
>
</canvas>
