import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CollectionNutzungsart, CollectionObjektart } from '../../../../../collection-upload/models/Collection';
import { checkValidSelection } from '../../../../../core/utils/validators';

@Component({
    selector: 'app-dialog-upload-nutzung',
    templateUrl: './dialog-upload-nutzung.component.html',
    styleUrls: ['./dialog-upload-nutzung.component.scss']
})
export class DialogUploadNutzungComponent implements OnInit {
    @Output() closed = new EventEmitter<any>();
    @Input() data;

    objektartenActive = false;

    formGroup: UntypedFormGroup;
    nutzungsartOptions: CollectionNutzungsart[] = [];
    objektartenOptions: CollectionObjektart[] = [];

    prepopulateNutzungsart: CollectionNutzungsart;
    prepopulateObjektart: CollectionObjektart;

    filteredNutzungOptions: Observable<CollectionNutzungsart[]>;
    filteredObjektArtOptions: Observable<CollectionObjektart[]>;

    savedObjektarten: string[] = [];
    separatorKeysCodes: number[] = [ENTER, COMMA];

    ngOnInit(): void {
        this.nutzungsartOptions = this.data.content.nutzungObjektartContainer.nutzungsarten;
        this.objektartenOptions = this.data.content.nutzungObjektartContainer.objektarten;

        if (this.data.content.value) {
            this.prepopulateNutzungsart = {
                id: Number(this.data.content.value.nutzungsartId),
                name: this.nutzungsartOptions.find(
                    (nutzungsart) => nutzungsart.id === this.data.content.value.nutzungsartId
                ).name
            };
            this.prepopulateObjektart = {
                id: Number(this.data.content.value.objektartId),
                name: this.objektartenOptions.find((objektart) => objektart.id === this.data.content.value.objektartId)
                    ?.name,
                collectionNutzungsart: this.prepopulateNutzungsart
            };
            this.objektartenActive = true;
        }
        if (this.prepopulateObjektart?.name) {
            this.savedObjektarten.push(this.prepopulateObjektart?.name);
        }

        this.formGroup = new UntypedFormGroup({
            nutzungsart: new UntypedFormControl(this.prepopulateNutzungsart?.name, [
                Validators.required,
                checkValidSelection(() => this.nutzungsartOptions)
            ]),
            objektart: new UntypedFormControl(
                this.prepopulateObjektart?.name,
                checkValidSelection((group) =>
                    this.filterObjectCategoriesByUsage(
                        group?.get('nutzungsart').value,
                        this.nutzungsartOptions,
                        this.objektartenOptions
                    )
                )
            )
        });

        this.filteredNutzungOptions = this.formGroup.get('nutzungsart').valueChanges.pipe(
            startWith(''),
            map((value) => this.filterNutzungsart(value || ''))
        );
        this.filteredObjektArtOptions = this.formGroup.valueChanges.pipe(
            startWith(''),
            map(() =>
                this.filterObjektArt(
                    this.formGroup.get('nutzungsart').value,
                    this.formGroup.get('objektart').value?.name
                )
            )
        );
    }

    resetNutzung() {
        this.formGroup.get('nutzungsart').setValue(null);
        this.formGroup.get('objektart').setValue(null);
        this.objektartenActive = false;
        this.savedObjektarten = [];
    }

    private filterNutzungsart(value: string): CollectionNutzungsart[] {
        const filterValue = value.toLowerCase();

        this.objektartenActive = this.prepopulateObjektart ? true : value != '';

        return this.nutzungsartOptions.filter((option) => option.name.toLowerCase().includes(filterValue));
    }

    private filterObjektArt(nutzung: string, objektart: string): CollectionObjektart[] {
        const filterValue = objektart ? objektart?.toLowerCase() : '';

        const objektarten = this.filterObjectCategoriesByUsage(
            nutzung,
            this.nutzungsartOptions,
            this.objektartenOptions
        );

        return objektarten.filter((option) => option.name.toLowerCase().includes(filterValue));
    }

    filterObjectCategoriesByUsage(nutzung: string, nutzungen: any[], objektarten: any[]) {
        if (nutzung == null) {
            return objektarten;
        }
        const selectedNutzung = nutzungen.find((o) => o.name === nutzung);
        return objektarten.filter((o) => o.collectionNutzungsart.id === selectedNutzung?.id);
    }

    submit(): void {
        // Since there is no auto validation on submit, we check the validity of each dialog form field manually before submitting.
        // Just in case a object category which is not part of the selected usage gets selected
        Object.values(this.formGroup.controls).forEach((o) => o.updateValueAndValidity());
        if (this.formGroup.valid) {
            const selectedObjektarten = this.getObjectTypeIds(this.savedObjektarten);
            const selectedNutzungsart: number = this.nutzungsartOptions.find(
                (n) => n.name === this.formGroup.get('nutzungsart').value
            ).id;
            const nutzungenData: { nutzungsartId: number; objektartId: number }[] = selectedObjektarten
                ? selectedObjektarten.map((currentObjektart) => ({
                      nutzungsartId: selectedNutzungsart,
                      objektartId: currentObjektart
                  }))
                : [{ nutzungsartId: selectedNutzungsart, objektartId: null }];

            this.closed.emit({
                selectedNutzungenData: nutzungenData,
                index: this.data.content.index >= 0 ? this.data.content.index : null
            });
        }
    }

    getObjectTypeIds(selectedObjectTypes: string[]): number[] {
        let objectTypesWithIds = this.objektartenOptions.filter((objectType) =>
            selectedObjectTypes.includes(objectType.name)
        );

        const selectedObjectTypesIds = objectTypesWithIds.map((objectType) => objectType.id);

        if (selectedObjectTypesIds && selectedObjectTypesIds.length > 0) {
            return selectedObjectTypesIds;
        }

        return null;
    }

    add(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();

        if (this.checkValidChipSelection(value)) {
            this.savedObjektarten.push(value);
        }

        // Clear the input value
        event.chipInput?.clear();

        this.formGroup.get('objektart').setValue(null);
    }

    remove(fruit: string): void {
        const index = this.savedObjektarten.indexOf(fruit);

        if (index >= 0) {
            this.savedObjektarten.splice(index, 1);
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        if (this.checkValidChipSelection(event.option.value)) {
            this.savedObjektarten.push(event.option.viewValue);
        }

        this.formGroup.get('objektart').setValue(null);
    }

    checkValidChipSelection(value: string): boolean {
        return (
            this.filterObjektArt(this.formGroup.get('nutzungsart').value, value).find(
                (objType) => objType.name === value
            ) && !this.savedObjektarten.find((objType) => objType === value)
        );
    }

    cancel(): void {
        this.closed.emit(null);
    }
}
