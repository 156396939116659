import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { OidcClient } from 'oidc-client';
import { oidcClientSettings } from '../../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class OidcCallbackGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService) {}

    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        const client = new OidcClient(oidcClientSettings);

        // request id_token (finish code flow)
        const response = await client.processSigninResponse();

        // redirect to error page on error
        if (response?.error != null && response?.error != '') {
            return this.router.parseUrl(
                `/auth/oidc/failed?error=${response.error}&errorDescription=${response.error_description}`
            );
        }

        // depending on what scopes you ask you have to save access or id token
        this.authService.setToken(response.access_token);

        return this.router.parseUrl('');
    }
}
