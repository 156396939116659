import { Component, Input } from '@angular/core';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { Appraisal } from '../../../../appraisal-detail/models/Appraisal';

@Component({
    selector: 'app-grid-list-item',
    templateUrl: './grid-list-item.component.html',
    styleUrls: ['./grid-list-item.component.scss']
})
export class GridListItemComponent {
    faMapMarker = faMapMarkerAlt;
    @Input() appraisal: Appraisal;
}
