<div *ngIf="appraisal$ | async as appraisal" class="frame" id="frame-detail">
    <div class="header">
        <h3>
            <span (click)="showSnackbar('ID wurde kopiert!')" [cdkCopyToClipboard]="appraisal | getAppraisalId"
                >{{ appraisal | getAppraisalId }} <button class="default">ID kopieren</button></span
            >
        </h3>
        <div *ngIf="(showHistograms || showScatters) && showSwitchAnsatz">
            <button
                (click)="switchAnsatz = true"
                [ngClass]="switchAnsatz ? 'active-btn' : 'inactive-btn'"
                class="default btn-switch btn-ansatz"
            >
                &#160;Ansatz
            </button>
            <button
                (click)="switchAnsatz = false"
                [ngClass]="switchAnsatz ? 'inactive-btn' : 'active-btn'"
                class="default btn-switch btn-ist"
            >
                &#160;&#160;&#160;Ist&#160;&#160;&#160;
            </button>
        </div>
        <div data-html2canvas-ignore="true">
            <!-- under construction
            <button (click)="download(appraisal-detail-boxes.id)" class="primary pdf-button">PDF-Export</button>
            -->
            <button (click)="onExcelDownloadClicked(appraisal.id)" class="primary">Excel-Export</button>
        </div>
    </div>
    <app-appraisal-chart-view
        [appraisals]="[appraisal]"
        [showHistograms]="showHistograms"
        [showScatters]="showScatters"
        [switchAnsatzActive]="switchAnsatz"
        (showSwitchAnsatzIst)="setAnsatzButtonShowing($event)"
    ></app-appraisal-chart-view>
    <div class="table">
        <app-appraisal-table-view [appraisals]="[appraisal]"></app-appraisal-table-view>
    </div>
    <div id="pdf-spacer-detail"></div>
    <div id="pdf-detail">
        <div class="header">
            <h3>DS-Id {{ appraisal.id }}</h3>
        </div>
        <app-appraisal-chart-view
            [appraisals]="[appraisal]"
            [showHistograms]="showHistograms"
            [showScatters]="showScatters"
            [pdfStyle]="true"
            [switchAnsatzActive]="switchAnsatz"
            (showSwitchAnsatzIst)="setAnsatzButtonShowing($event)"
        ></app-appraisal-chart-view>
        <app-appraisal-table-view [appraisals]="[appraisal]" [pdfStyle]="true"></app-appraisal-table-view>
    </div>
</div>
