import { Injectable } from '@angular/core';
import { SnackOptions, SnackDefaults, SnackSeverityType, SnackData } from '../types/Snackbar';
import { Subject } from 'rxjs';

@Injectable({
   providedIn: 'root'
})

export class SnackbarService {

   private snackbarSubject = new Subject<SnackData>(); // for emitting snacks (snack data)
   snackbar$ = this.snackbarSubject.asObservable(); // for snackbar component to subscribe to emitted snacks

   constructor() { }

   show(
      message: string,
      type: SnackSeverityType,
      options: any
   ) {
      const defaultOptions: SnackOptions = SnackDefaults[type];
      // console.log(defaultOptions);

      // we take default options and override those properties which are specified in "options"
      const mergedOptions = {
         ...defaultOptions,
         ...options
      };
      // console.log(mergedOptions);

      const data: SnackData = {
         message: message,
         options: mergedOptions
      };

      this.snackbarSubject.next(data);
   }

   showInfo(message: string, options?: any) {
      this.show(message, SnackSeverityType.Info, options);
   }

   showSuccess(message: string, options?: any) {
      this.show(message, SnackSeverityType.Success, options);
   }

   showWarning(message: string, options?: any) {
      this.show(message, SnackSeverityType.Warning, options);
   }

   showError(message: string, options?: any) {
      this.show(message, SnackSeverityType.Error, options);
   }
}
