import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteTransform } from '../../../shared/routetransform/RouteTransform';
import { SidebarService } from '../../services/sidebar.service';

@Component({
    selector: 'app-segment-compare',
    templateUrl: './segment-compare.component.html',
    styleUrls: ['./segment-compare.component.scss']
})
export class SegmentCompareComponent extends RouteTransform {
    constructor(private sidebarService: SidebarService, route: ActivatedRoute, router: Router) {
        super(route, router);
    }
}
