import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BebauungsPlan, BebauungsPlanNames } from 'src/app/appraisal-textgenerator/enums/BebauungsPlan';
import { Baugenehmigung, BaugenehmigungNames } from 'src/app/appraisal-textgenerator/enums/Baugenehmigung';
import { GebrauchsAbnahmeNachweis, GebrauchsAbnahmeNachweisNames } from 'src/app/appraisal-textgenerator/enums/GebrauchsAbnahmeNachweis';
import { BrickConstructionLaw } from 'src/app/appraisal-textgenerator/interfaces/BrickTypes';
import { BrickService } from 'src/app/appraisal-textgenerator/services/brick.service';
import { BrickNavigationService } from 'src/app/appraisal-textgenerator/services/brick-navigation.service';
import { FormService } from 'src/app/appraisal-textgenerator/services/form.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { BreadcrumbDefault, BreadcrumbsService } from 'src/app/core/services/breadcrumbs.service';

@Component({
   selector: 'app-brick-construction-law-edit',
   templateUrl: './brick-construction-law-edit.component.html',
   styleUrls: ['./brick-construction-law-edit.component.scss']
})
export class BrickConstructionLawEditComponent implements OnInit {

   brickData: BrickConstructionLaw;
   appraisalTextId: number;

   form = this.fb.group({
      bebauungsPlan: [{ value: null }, Validators.required],
      titelBebauungsplan: [{ value: '', disabled: true }, Validators.required],
      datumBebauungsplan: [{ value: '', disabled: true }, Validators.required],
      festsetzungen: [{ value: '', disabled: true }, Validators.required],
      baugenehmigung: [{ value: '' }, Validators.required],
      gebrauchsAbnahmeNachweis: [{ value: '' }, Validators.required],
   });

   public BebauungsPlanNames = BebauungsPlanNames;
   public bebauungsPlanOptions = Object.values(BebauungsPlan).filter((v) => !isNaN(Number(v)));
   public BaugenehmigungNames = BaugenehmigungNames;
   public baugenehmigungOptions = Object.values(Baugenehmigung).filter((v) => !isNaN(Number(v)));
   public GebrauchsAbnahmeNachweisNames = GebrauchsAbnahmeNachweisNames;
   public gebrauchsAbnahmeNachweisOptions = Object.values(GebrauchsAbnahmeNachweis).filter((v) => !isNaN(Number(v)));

   constructor(
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private fb: FormBuilder,
      private brickService: BrickService,
      private brickNavigationService: BrickNavigationService,
      private formService: FormService,
      private snackbarService: SnackbarService,
      private breadcrumbsService: BreadcrumbsService
   ) { }

   ngOnInit(): void {
      this.appraisalTextId = this.activatedRoute.snapshot.params.appraisalTextId
      this.brickData = this.activatedRoute.snapshot.data['brickData'];
      this.formConditions();

      this.breadcrumbsService.Apply([
         { ...BreadcrumbDefault.TextgeneratorList },
         { ...this.brickNavigationService.getOverviewBreadcrumb(this.appraisalTextId, this.activatedRoute.parent.snapshot.url) },
         { label: 'Baurecht' }
      ]);
   }

   cancel(): void {
      this.brickNavigationService.navigateToOverview(this.appraisalTextId, this.activatedRoute.parent.snapshot.url);
   }

   save(): void {
      if (this.form.valid) {
         const clientType: string = this.brickNavigationService.getClientType(this.activatedRoute.parent.snapshot.url);
         this.brickService.updateBrickData(this.appraisalTextId, clientType, this.brickData)
            .subscribe();

         this.snackbarService.showSuccess('Speichern erfolgreich');
      }
   }

   saveAndExit(): void {
      if (this.form.valid) {
         this.save();
         this.brickNavigationService.navigateToOverview(this.appraisalTextId, this.activatedRoute.parent.snapshot.url, true); // with reload
      }
   }

   formConditions() {

      this.form.controls.bebauungsPlan.valueChanges.subscribe((value) => {
         this.formService.handleCondition(Number(value) === BebauungsPlan.GrundstueckInBPlanEnthalten, this.form.controls.titelBebauungsplan, true);
         this.formService.handleCondition(Number(value) === BebauungsPlan.GrundstueckInBPlanEnthalten, this.form.controls.datumBebauungsplan, true);
         this.formService.handleCondition(Number(value) === BebauungsPlan.GrundstueckInBPlanEnthalten, this.form.controls.festsetzungen, true);
      });

   }
}
