import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BrickService } from './brick.service';
import { map } from 'rxjs/operators';
import { BrickNavigationService } from './brick-navigation.service';

@Injectable({
   providedIn: 'root'
})
export class BrickProductionCostsObjectResolverService implements Resolve<any>  {

   constructor(
      private brickService: BrickService,
      private brickNavigationService: BrickNavigationService
   ) { }

   resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

      // assert: in object-create an appraisal text must exist
      const appraisalTextId: number = Number(route.paramMap.get("appraisalTextId"));

      // object-create is defined in last URL segment, see textgenerator-routing.module.ts
      if (this.brickNavigationService.getLastSegment(route.url) === 'object-create') {

         // assert: in object-create we have appraisal text only, i.e. not object
         const clientType: string = 'BrickProductionCosts'; // TODO eho: use ts-nameof library
         return this.brickService.loadBrickData(appraisalTextId, clientType)
            .pipe(map(res => res.data));
      }
      else { // object-update

         // assert: in object-update an object must exist
         const objectId: number = Number(route.paramMap.get("objectId"));
         return this.brickService.loadProductionCostsObject(appraisalTextId, objectId)
            .pipe(map(res => res.data));
      }
   }
}
