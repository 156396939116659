import { Component, Input } from '@angular/core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { rotate, slideInOut } from '../../../core/animations/animations';

@Component({
    selector: 'app-expansion-panel',
    templateUrl: './expansion-panel.component.html',
    styleUrls: ['./expansion-panel.component.scss'],
    animations: [rotate, slideInOut]
})
export class ExpansionPanelComponent {
    @Input() disabled = false;

    state = 'default';
    @Input() panelOpen = 'closed';

    faChevronRight = faChevronRight;

    changeOpenState(): void {
        if (!this.disabled) {
            this.state = this.state === 'default' ? 'rotated' : 'default';
            this.panelOpen = this.panelOpen === 'closed' ? 'open' : 'closed';
        }
    }
}
