<form [formGroup]="formGroup">
    <div id="container-content">
        <div id="container-nutzung">
            <div class="list-title">Nutzungsart wählen (Einfachauswahl)</div>
            <div class="divider"></div>
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Nutzungsart</mat-label>
                <input
                    type="text"
                    placeholder="Nutzungsart eingeben"
                    matInput
                    formControlName="nutzungsart"
                    [matAutocomplete]="autoUsage"
                    (click)="resetNutzung()"
                />
                <mat-autocomplete autoActiveFirstOption #autoUsage="matAutocomplete">
                    <mat-option *ngFor="let nutzung of filteredNutzungOptions | async" [value]="nutzung.name">
                        {{ nutzung.name }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div *ngIf="objektartenActive && (filteredObjektArtOptions | async).length > 0">
            <div class="list-title">Objektarten wählen (Mehrfachauswahl)</div>
            <div class="divider"></div>
            <mat-form-field appearance="fill">
                <mat-label>Objektarten</mat-label>
                <input
                    class="chip-input"
                    placeholder="Objektarten"
                    formControlName="objektart"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="add($event)"
                />
                <div id="chiplist-container">
                    <mat-chip-list #chipList aria-label="ObjectType selection">
                        <mat-chip *ngFor="let objektart of savedObjektarten" (removed)="remove(objektart)">
                            {{ objektart }}
                            <button matChipRemove>
                                <mat-icon id="btnChipRemoveIcon">cancel</mat-icon>
                            </button>
                        </mat-chip>
                    </mat-chip-list>
                </div>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                    <mat-option *ngFor="let objektart of filteredObjektArtOptions | async" [value]="objektart.name">
                        {{ objektart.name }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </div>
    <!--Button Row-->
    <div id="btns">
        <button (click)="submit()" class="primary" type="submit" [disabled]="!formGroup.valid">
            {{ data.primaryBtnTitle || 'Speichern' }}
        </button>
        <button (click)="cancel()" class="default">Abbrechen</button>
    </div>
</form>
