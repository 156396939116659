import { Pipe, PipeTransform } from '@angular/core';
import { Besonderheiten } from '../interfaces/AppraisalFilterTypes';

@Pipe({
    name: 'gibtBesonderheiten'
})
export class GibtBesonderheiten implements PipeTransform {
    transform(value: Besonderheiten[]): boolean {
        return value.some((b) => b.value);
    }
}
