import { Pipe, PipeTransform } from '@angular/core';
import { GenericSelectOption } from '../components/radio-group/SelectOption';

@Pipe({
    name: 'select',
    pure: true
})
export class SelectOptionToText implements PipeTransform {
    transform(value: number, selectOption: GenericSelectOption<any>[]): string {
        return selectOption.find((select) => select.value === value)?.label || '-';
    }
}
