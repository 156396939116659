import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, finalize, map, switchMap } from 'rxjs/operators';
import { BrickProductionCosts } from 'src/app/appraisal-textgenerator/interfaces/BrickTypes';
import { BrickProductionCostsObject } from 'src/app/appraisal-textgenerator/interfaces/BrickTypesProductionCosts';
import { BrickNavigationService } from 'src/app/appraisal-textgenerator/services/brick-navigation.service';
import { BrickService } from 'src/app/appraisal-textgenerator/services/brick.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { DeleteDialogComponent } from '../../delete-dialog/delete-dialog.component';


@Component({
   selector: 'app-brick-production-costs-detail',
   templateUrl: './brick-production-costs-detail.component.html',
   styleUrls: ['./brick-production-costs-detail.component.scss']
})
export class BrickProductionCostsDetailComponent implements OnInit {

   @Input()
   data: BrickProductionCosts;
   appraisalTextId: number;

   constructor(private router: Router,
      private activatedRoute: ActivatedRoute,
      private brickService: BrickService,
      private snackbarService: SnackbarService,
      private dialog: MatDialog,
      private loadingService: LoadingService,
      private brickNavigationService: BrickNavigationService



   ) { }

   ngOnInit(): void {
      this.appraisalTextId = this.activatedRoute.snapshot.params.appraisalTextId

   }

   navigateToBrickObjectCreate() {
      this.router.navigate(['brick-production-costs/object-create'], { relativeTo: this.activatedRoute });
   }

   navigateToBrickObjectEdit(brickObject: BrickProductionCostsObject) {

      this.router.navigate(['brick-production-costs/object-update/', brickObject.id], { relativeTo: this.activatedRoute });
   }

   loadBrickObjectData(): void {
      const clientType: string = 'BrickProductionCosts'; //ToDo : Frage: Soll so beliben? ohne die getClientType Methode
      this.loadingService.setLoading(true);
      this.brickService.loadBrickData(this.appraisalTextId, clientType)
         .pipe(
            map(res => res.data),
            finalize(() => this.loadingService.setLoading(false))
         )
         .subscribe(data => {
            this.data = data as BrickProductionCosts;
         });
   }

   deleteBrickObjectById(brickObject: BrickProductionCostsObject) {

      const dialogRef = this.dialog.open(DeleteDialogComponent, {
         data: {
            objekt: "Objekt",
            objektTitel: brickObject.objektNummer
         }, // Pass the object here
      });


      dialogRef.afterClosed()
         .pipe(
            filter((result) => !!result),
            switchMap(() => this.brickService.DeleteProductionCostsObject(brickObject.id)),
            // when successfully deleted, remove from list
            filter((deleteResult) => !!deleteResult.succeeded) // server-side delete operation successful
         )
         .subscribe(
            () => {
               this.loadBrickObjectData()
               this.snackbarService.showSuccess(`Objekt wurde gelöscht.`);
            },
            error => {
               this.snackbarService.showError(error.error.message);
            }
         );

   }

}
