import { Injectable } from '@angular/core';
import { ChartConfiguration, ChartPoint } from 'chart.js';
import _ from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Appraisal, Entwicklung } from 'src/app/appraisal-detail/models/Appraisal';
import config from '../../core/config/config';
import { getAvgOfAppraisalsByProp } from '../../core/utils/utils';
import {
    ChartConfigurationWithCount,
    ChartContentType,
    ChartContentTypes,
    ChartTypes,
    HistogrammClass,
    StrNumberMap,
    UnitType,
    UnitTypesXY
} from '../models/Chart';
import { AppraisalService } from './appraisal.service';
import { SidebarService } from './sidebar.service';

const scatterColor = 'rgba(198, 48, 33, 1)';

@Injectable({
    providedIn: 'root'
})
export class ChartService {
    constructor(private sidebarService: SidebarService, private appraisalService: AppraisalService) {
        this.loadEntwicklungen(config.entwicklungsRange);
    }

    get entwicklungen$(): Observable<Entwicklung[]> {
        return this.entwicklungen.asObservable();
    }

    private entwicklungen: BehaviorSubject<Entwicklung[]> = new BehaviorSubject([]);

    private static setupColors(count: number): string[] {
        const slice = (10 - count) / 2 + 1;
        const colors = [
            'rgba(221, 221, 221, 1)',
            'rgba(216, 209, 209, 1)',
            'rgba(222, 196, 194, 1)',
            'rgba(216, 163, 158, 1)',
            'rgba(219, 127, 118, 1)',
            'rgba(198, 81, 70, 1)',
            'rgba(198, 48, 33, 1)',
            'rgba(152, 33, 21, 1)',
            'rgba(95, 18, 10, 1)',
            'rgba(59, 10, 5, 1)'
        ];
        return colors.slice(slice, slice + count) || [];
    }

    private static setupScales(
        stepsx: number = 4,
        beginAtZero = true,
        units: UnitTypesXY,
        chartType: ChartTypes
    ): object {
        return {
            yAxes: [
                {
                    gridLines: {
                        display: true
                    },
                    ticks: {
                        min: 0,
                        steps: 4,
                        autoSkip: true,
                        autoSkipPadding: 3,
                        padding: 10,
                        maxTicksLimit: 6,
                        precision: 0,
                        beginAtZero,
                        callback: (value) => {
                            switch (chartType) {
                                case ChartTypes.SCATTER: {
                                    return value + ' ' + units.unitx;
                                }
                                case ChartTypes.HISTOGRAM: {
                                    return value + 'x';
                                }
                                default: {
                                    return value;
                                }
                            }
                        }
                    }
                }
            ],
            xAxes: [
                {
                    gridLines: {
                        display: true
                    },
                    ticks: {
                        autoSkip: true,
                        autoSkipPadding: 3,
                        padding: 10,
                        stepsx,
                        beginAtZero,
                        callback: (value) => {
                            switch (chartType) {
                                case ChartTypes.SCATTER: {
                                    return value + ' ' + units.unity;
                                }
                                case ChartTypes.HISTOGRAM: {
                                    return value.toLocaleString('de-DE') + ' ' + units.unitx;
                                }
                                default: {
                                    return value;
                                }
                            }
                        }
                    }
                }
            ],
            elements: {
                line: {
                    fill: false
                }
            }
        };
    }

    public loadEntwicklungen(years: number): void {
        this.appraisalService.getEntwicklungen(years).subscribe((data) => this.entwicklungen.next(data));
    }

    setupHistogram(
        appraisals: Appraisal[],
        chartType: ChartContentTypes,
        round: number
    ): Observable<ChartConfigurationWithCount> {
        const cat = this.setupHistogramClasses(chartType, appraisals, round).filter((x) => x != null);

        const data: StrNumberMap = cat.reduce(
            (acc, current) => ({
                ...acc,
                [current.id]: appraisals.filter(
                    (app) =>
                        app[chartType.name] != null &&
                        app[chartType.name] >= current.min &&
                        app[chartType.name] < current.max
                ).length
            }),
            {}
        );

        const countValues = cat.reduce((acc, category) => acc + data[category.id], 0);

        return of(
            this.setupHistogramConfiguration(data, countValues, appraisals.length, cat.length, chartType.unitxy)
        ).pipe(shareReplay(1));
    }

    setupHistogrammAusstattung(appraisals: Appraisal[]): Observable<ChartConfigurationWithCount> {
        let countValues = 0;
        const filteredAppraisals = appraisals.filter((a) => a.ausstattungsQualitaet?.id != null);
        return this.sidebarService.ausstattungsQualitaeten.pipe(
            map((ausstattungsQualitaeten) => {
                const data: StrNumberMap = ausstattungsQualitaeten
                    .filter((ausstattung) =>
                        filteredAppraisals.some((a) => a.ausstattungsQualitaet.id === ausstattung.id)
                    )
                    .reduce((acc, ausstattung) => {
                        const count = filteredAppraisals.filter((a) => a.ausstattungsQualitaet.id === ausstattung.id);
                        const readableKey = ausstattung.name;
                        countValues += count.length;
                        return { ...acc, [readableKey]: count.length };
                    }, {});
                return this.setupHistogramConfiguration(
                    data,
                    countValues,
                    appraisals.length,
                    4,
                    ChartContentType.AUSSTATTUNG.unitxy
                );
            })
        );
    }

    setupHistogrammBaujahr(appraisals: Appraisal[]): Observable<ChartConfigurationWithCount> {
        let countValues = 0;
        const filteredAppraisals = appraisals.filter(
            (a) =>
                a.baujahr != null &&
                a.baujahrFlaechengewichtet != null &&
                (a.baujahr.length === 1 || (a.baujahr.length > 1 && a.objektArt.id === 15))
        );

        return this.sidebarService.baujahresKlassen.pipe(
            map((baujahresKlassen) => {
                const data: StrNumberMap = baujahresKlassen
                    .filter((baujahresKlasse) =>
                        filteredAppraisals.some(
                            (a) =>
                                a.baujahrFlaechengewichtet >= baujahresKlasse.from &&
                                a.baujahrFlaechengewichtet <= baujahresKlasse.to
                        )
                    )
                    .reduce((acc, baujahresKlasse) => {
                        const count = filteredAppraisals.filter(
                            (a) =>
                                a.baujahrFlaechengewichtet >= baujahresKlasse.from &&
                                a.baujahrFlaechengewichtet <= baujahresKlasse.to
                        );
                        const readableKey = baujahresKlasse.name;
                        countValues += count.length;
                        return { ...acc, [readableKey]: count.length };
                    }, {});
                return this.setupHistogramConfiguration(
                    data,
                    countValues,
                    appraisals.length,
                    7,
                    ChartContentType.BAUJAHR.unitxy
                );
            })
        );
    }

    setupHistogrammMikrolage(appraisals: Appraisal[]): Observable<ChartConfigurationWithCount> {
        let countValues = 0;
        const filteredAppraisals = appraisals.filter((a) => a.lage?.lageQualitaet != null);
        const data: StrNumberMap = appraisals
            .map((a) => a.lage?.lageQualitaet)
            .filter((lg) => lg != null)
            .reduce((acc, lage) => {
                const matchedAppraisalsForObjektArt = filteredAppraisals.filter(
                    (a) => a.lage.lageQualitaet.id === lage.id
                );
                countValues = matchedAppraisalsForObjektArt.length;
                return { ...acc, [lage.name + ' (' + lage.id + ')']: matchedAppraisalsForObjektArt.length };
            }, {});
        return of(
            this.setupHistogramConfiguration(data, countValues, appraisals.length, 4, ChartContentType.MICROLAGE.unitxy)
        );
    }

    setupScatter(chartType: ChartContentTypes, maxValue: number): Observable<ChartConfigurationWithCount> {
        return this.entwicklungen$.pipe(
            map((entwicklungen) => {
                const dataSets = new Map<number, Set<number>>();
                const xValues: number[] = [];
                const yValues: number[] = [];

                let evaluableDataCount = 0;

                for (const e of entwicklungen) {
                    if (e[chartType.name] == null || e[chartType.name] === 0 || e[chartType.name] > maxValue) {
                        continue;
                    }

                    const x: number = new Date(e.kaufpreisDatum).getFullYear();
                    const y: number = e[chartType.name];

                    xValues.push(x);
                    yValues.push(y);

                    if (!dataSets.has(x)) {
                        dataSets.set(x, new Set());
                    }

                    dataSets.get(x).add(y);

                    evaluableDataCount++;
                }

                if (evaluableDataCount < 5) {
                    return null;
                }

                const dataUnique: ChartPoint[] = [];
                dataSets.forEach((set, key) => set.forEach((v) => dataUnique.push({ x: key, y: v })));

                return {
                    data: {
                        datasets: [
                            {
                                type: ChartTypes.SCATTER,
                                pointRadius: 3,
                                data: dataUnique,
                                pointBackgroundColor: scatterColor,
                                pointHoverBorderColor: scatterColor,
                                fill: false
                            },
                            {
                                data: this.setupTrendline(xValues, yValues),
                                type: ChartTypes.LINE,
                                borderColor: scatterColor,
                                pointBackgroundColor: scatterColor,
                                fill: false
                            }
                        ]
                    },
                    options: {
                        plugins: {
                            datalabels: {
                                display: false
                            }
                        },
                        scales: ChartService.setupScales(4, false, chartType.unitxy, ChartTypes.SCATTER),
                        tooltips: {
                            callbacks: {
                                title(item): string {
                                    return 'Jahr ' + item[0].xLabel + ':';
                                },
                                label: (tooltipItems, _) => {
                                    return tooltipItems.yLabel.toLocaleString() + ' ' + chartType.unitxy.unitx;
                                }
                            }
                        }
                    },
                    count: { all: entwicklungen.length, used: evaluableDataCount }
                };
            }),
            shareReplay(1)
        );
    }

    setupObjektart(appraisals: Appraisal[]): Observable<ChartConfiguration> {
        return this.sidebarService.objektArten.pipe(
            map((objektarten) => {
                const data: StrNumberMap = objektarten
                    .filter((o) => appraisals.some((a) => a.objektArt.id === o.id))
                    .reduce((acc, o) => {
                        const matchedAppraisalsForObjektArt = appraisals.filter((a) => a.objektArt.id === o.id);
                        return {
                            ...acc,
                            [o.name]: Math.round((matchedAppraisalsForObjektArt.length * 100) / appraisals.length)
                        };
                    }, {});
                return {
                    data: {
                        labels: Object.keys(data),
                        datasets: [
                            {
                                type: ChartTypes.DOUGHNUT,
                                data: Object.values(data),
                                backgroundColor: ChartService.setupColors(Object.keys(data).length + 1)
                            }
                        ]
                    },
                    options: {
                        tooltips: {
                            callbacks: {
                                label(tooltipItems, dataLabel) {
                                    const label = dataLabel.labels[tooltipItems.index];
                                    const value =
                                        dataLabel.datasets[tooltipItems.datasetIndex].data[tooltipItems.index];
                                    return label + ' : ' + value + UnitType.PERCENT;
                                }
                            }
                        }
                    }
                };
            })
        );
    }

    setupGnd(): Observable<ChartConfiguration> {
        const chartInfos: ChartConfiguration = {
            data: {
                labels: ['One', 'Two'],
                datasets: [
                    {
                        type: ChartTypes.DOUGHNUT,
                        data: [20, 80],
                        backgroundColor: ChartService.setupColors(2)
                    }
                ]
            },
            options: {
                rotation: Math.PI,
                circumference: Math.PI
            }
        };

        return of(chartInfos);
    }

    setupBueroImmobilien(): Observable<ChartConfiguration> {
        return of({
            data: {
                labels: ['2005', '2010', '2015', '2020'],
                datasets: [
                    {
                        type: ChartTypes.BAR,
                        data: [1, 2, 4, 5],
                        backgroundColor: ChartService.setupColors(5),
                        barPercentage: 1.3
                    }
                ]
            },
            options: {
                plugins: {
                    datalabels: {
                        display: false
                    }
                },
                tooltips: {
                    callbacks: {
                        label(tooltipItems) {
                            return tooltipItems.yLabel.toLocaleString() + 'x';
                        }
                    }
                },
                scales: ChartService.setupScales(4, true, ChartContentType.BUEROIMMOBILIEN.unitxy, ChartTypes.HISTOGRAM)
            }
        });
    }

    private setupTrendline(xValues: number[], yValues: number[]): ChartPoint[] {
        // lineare regression / Regressionsgerade

        // 1. Berechne Durchschnitt von x und y

        // avg xValues
        const xSum = xValues.reduce((xa, xb) => xa + xb, 0);
        const xAvg = xSum / xValues.length || 0;

        // avg yValues
        const ySum = yValues.reduce((ya, yb) => ya + yb, 0);
        const yAvg = ySum / yValues.length || 0;

        const xMax = Math.max(...xValues);
        const xMin = Math.min(...xValues);

        // b = (sum((xi - xavg) * (yi - yavg))) / sum((xi - xavg)hoch2)
        let b1 = 0;
        let b2 = 0;
        for (let i = 0; i < xValues.length; i++) {
            b1 += (xValues[i] - xAvg) * (yValues[i] - yAvg);
            b2 += Math.pow(xValues[i] - xAvg, 2);
        }
        const b = b1 / b2;
        // a = yavg - b * xavg
        const a = yAvg - b * xAvg;

        // Regressionsgerade y = a + b * x

        // point1 -> x = 0 -> y = a+b * 0 = a -> y = a, x = 0
        // point2 -> x = xMax -> y = a + b * xMax

        return [
            { x: xMin, y: a + b * xMin },
            { x: xMax, y: a + b * xMax }
        ];
    }

    private setupHistogramClasses(
        chartType: ChartContentTypes,
        appraisals: Appraisal[],
        round: number
    ): HistogrammClass[] {
        const filteredAppraisals = appraisals.filter((a) => chartType != null && a[chartType.name] > 0);
        let stepSize;
        let range: number[];

        if (chartType.steps?.stepSizeInPercent === false) {
            const min = Math.floor(Math.min(...filteredAppraisals.map((a) => a[chartType.name])));
            const max = Math.ceil(Math.max(...filteredAppraisals.map((a) => a[chartType.name])));
            stepSize = chartType.steps.stepSize;
            range = Array.from({ length: (max - min) / stepSize }, (_, i) => min + i * stepSize);
        } else {
            const avg = getAvgOfAppraisalsByProp(chartType.name, filteredAppraisals);
            //Calculate quarter/min value out of avg
            const min = 0.5 * avg;
            //Calculate step size (50% because avg - min is half of range) or use default (25%)
            stepSize = (avg - min) / (50 / chartType.steps?.stepSize || 25);
            range = Array.from(
                { length: 100 / chartType.steps?.stepSize || 25 },
                (_, i) => Math.round((min + i * stepSize) / round) * round
            );
            //new rounded stepsize
            stepSize = range[1] - range[0];
        }

        return range
            .filter((r) => filteredAppraisals.some((a) => _.inRange(a[chartType.name], r, r + stepSize)))
            .map((r) => this.setupHistogramClass(r, r + stepSize));
    }

    private setupHistogramClass(min: number, max: number): HistogrammClass {
        return {
            id: `${min.toLocaleString()} - ${max.toLocaleString()}`,
            min: min,
            max: max
        };
    }

    private setupHistogramConfiguration(
        data: StrNumberMap,
        countValues: number,
        appraisalsCount: number,
        scaleSteps: number,
        units: UnitTypesXY
    ): ChartConfigurationWithCount {
        if (countValues < 5) {
            return null;
        }

        return {
            data: {
                labels: Object.keys(data),
                datasets: [
                    {
                        type: ChartTypes.BAR,
                        data: Object.values(data),
                        backgroundColor: ChartService.setupColors(Object.keys(data).length + 1),
                        barPercentage: 1.3
                    }
                ]
            },
            options: {
                scales: ChartService.setupScales(scaleSteps, true, units, ChartTypes.HISTOGRAM),
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    datalabels: { display: false }
                },
                tooltips: {
                    callbacks: {
                        title(item): string {
                            return item[0].yLabel + ' Objekt(e) in:';
                        },
                        label(tooltipItems): string {
                            return tooltipItems.xLabel.toLocaleString() + ' ' + units.unitx;
                        }
                    }
                }
            },
            count: { all: appraisalsCount, used: countValues }
        };
    }
}
