import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { AppraisalFilter, AppraisalUploadSorting, IAppraisalListView, PagedData } from '../../../appraisal-detail/models/Appraisal';
import { AppraisalCompareService } from '../../../appraisal-detail/services/appraisal-compare.service';
import { AppraisalService } from '../../../appraisal-detail/services/appraisal.service';
import { Pagination } from '../../../shared/models/Pagination';
import { Sort } from "@angular/material/sort";
import { BreadcrumbDefault, BreadcrumbsService } from 'src/app/core/services/breadcrumbs.service';

@Component({
   selector: 'app-list-view',
   templateUrl: './list-view.component.html',
   styleUrls: ['./list-view.component.scss']
})
export class ListViewComponent implements OnInit, OnDestroy {
   @ViewChild('top') top: ElementRef;

   displayedColumns: string[] = [
      'distanz',
      'objektUnterArtName',
      'auftraggeberName',
      'auftragsart',
      'marktwert',
      'marktwertProQuadratmeter',
      'baujahr',
      'adresse',
      'lageQualitaetName',
      'ausstattungsQualitaetName',
      'bewertungsstichtag',
      'compare'
   ];

   exactDataSource: MatTableDataSource<IAppraisalListView> = new MatTableDataSource([]);
   perimeterDataSource: MatTableDataSource<IAppraisalListView> = new MatTableDataSource([]);
   comparableAppraisals$: Observable<number[]>;

   pagination: Pagination = Pagination.default();

   private queryParamSub: Subscription;
   private dataSourceSub: Subscription;

   constructor(private appraisalService: AppraisalService,
      private appraisalCmpService: AppraisalCompareService,
      private router: Router,
      private route: ActivatedRoute,
      private breadcrumbsService: BreadcrumbsService
   ) {
   }

   ngOnInit(): void {
      this.appraisalService.listToFilterChangesListView();

      this.dataSourceSub = this.appraisalService.appraisalsFiltered
         .pipe(withLatestFrom(this.appraisalService.currentFilter))
         .subscribe(([filteredData, filter]: [PagedData<IAppraisalListView>, AppraisalFilter]) => {
            const [exactDatasource, perimeterDatasource] = this.partitionArrayByAddressFilter(
               filteredData.data,
               filter
            );

            this.exactDataSource.data = exactDatasource;
            this.perimeterDataSource.data = perimeterDatasource;

            // Backend page index starts with 1, but mat paginator starts with 0.
            // So we need to decrease for paginator
            const { page, size, totalRecords } = filteredData;
            this.pagination.updatePageInfo(page - 1, size, totalRecords);
            this.updateQueryParams(page, size);
         });

      this.comparableAppraisals$ = this.appraisalCmpService.comparableAppraisalIds;

      // We need to update tbe list if user navs back and forth and therefore query params change
      this.queryParamSub = this.route.queryParams.subscribe((queryParams: Params) => {
         if ('page' in queryParams && 'size' in queryParams) {
            const page = parseInt(queryParams.page, 10);
            const size = parseInt(queryParams.size, 10);
            this.appraisalService.updateListPagination(page, size);
         }
      });

      this.breadcrumbsService.Apply([
         { ...BreadcrumbDefault.SearchListLabel },
      ]);
   }

   ngOnDestroy(): void {
      this.dataSourceSub?.unsubscribe();
      this.queryParamSub?.unsubscribe();
   }

   sortData(sort: Sort): void {
      this.appraisalService.updateSorting(sort as AppraisalUploadSorting);
   }

   addOrRemoveAppraisal(id: number): void {
      this.appraisalCmpService.addOrRemoveAppraisal(id);
   }

   onPaginationChange(ev: PageEvent): void {
      // Backend page index starts with 1, but mat paginator starts with 0.
      // So we need to increase for backend
      this.appraisalService.updateListPagination(ev.pageIndex + 1, ev.pageSize);

      this.top.nativeElement.scrollIntoView();
   }

   private updateQueryParams(page: number, size: number): void {
      this.router.navigate(['./'], {
         relativeTo: this.route,
         queryParams: {
            page,
            size
         },
         queryParamsHandling: 'merge'
      });
   }

   /**
    * Partitions an array of appraisals into two lists. First list contains appraisals matching the exact address of
    * the current filter and the second list are appraisals within the radius
    * @param filteredData
    * @param filter
    * @private
    */
   private partitionArrayByAddressFilter(
      filteredData: IAppraisalListView[],
      filter: AppraisalFilter
   ): IAppraisalListView[][] {
      const { ort, hausnummer, strasse, plz } = filter;

      return _.partition(filteredData, (appraisal: IAppraisalListView) => {
         if (!ort && !hausnummer && !strasse && !plz) {
            return false;
         }

         const matches: boolean[] = [];

         if (ort) {
            matches.push(appraisal.ort == ort);
         }

         if (plz) {
            // Replace ort with plz because plz > ort
            matches[0] = appraisal.pLZ == plz;
         }

         if (strasse) {
            matches.push(appraisal.strasse?.toLowerCase() == strasse?.toLowerCase());
         }

         if (hausnummer) {
            matches.push(appraisal.hausnummer?.toLowerCase() == hausnummer?.toLowerCase());
         }

         return _.every(matches);
      });
   }
}
