import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent {
    @Input() isOpen = false;
    @Output() isOpenChange: EventEmitter<boolean> = new EventEmitter(false);
    @Input() height = 35;
    @Input() showRequired = false;
    faChevronUp = faChevronUp;
    faChevronDown = faChevronDown;

    revertOpen(): void {
        this.isOpen = !this.isOpen;
        this.isOpenChange.emit(this.isOpen);
    }
}
