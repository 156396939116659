<div [ngClass]="pdfStyle ? 'framePdf' : 'frameWeb'" class="frame" *ngIf="_appraisals.length">
    <div *ngIf="!pdfStyle && _appraisals.length > 1">
        <div class="title titleWeb header"></div>
        <div class="title titleWeb"></div>
        <div
            class="contentWeb"
            *ngFor="let appraisal of _appraisals"
            id="del-btn-box"
            (click)="removeAppraisal(appraisal.id)"
        >
            <button class="default" id="btn-del-appraisal">
                <mat-icon fontIcon="close" id="icn-del-appraisal"></mat-icon>
            </button>
        </div>
        <div class="avg"></div>
    </div>

    <div [ngClass]="pdfStyle ? 'boxPdf' : 'boxWeb'" class="box-image">
        <div [ngClass]="pdfStyle ? 'titlePdf' : 'titleWeb'" class="title-image title header"></div>
        <div [ngClass]="pdfStyle ? 'titlePdf' : 'titleWeb'" class="title-image title"><p>Bild</p></div>
        <div *ngFor="let appraisal of _appraisals" class="content-image">
            <app-img [id]="appraisal.id" size="50" type="thumbnail"></app-img>
        </div>
        <div [ngClass]="pdfStyle ? 'titlePdf' : 'titleWeb'" class="avg"></div>
    </div>

    <div *ngFor="let header of columnHeader">
        <div [ngClass]="pdfStyle ? 'titlePdf' : 'titleWeb'" class="title-text title header">
            <p>{{ header.title }}</p>
        </div>

        <div class="flex">
            <div
                *ngFor="let col of header.columns"
                [class.hidden]="!col.show"
                [ngClass]="pdfStyle ? 'boxPdf' : 'boxWeb'"
                [ngSwitch]="col.key"
                [style.width.px]="col.width"
                class="flex"
            >
                <div [ngClass]="pdfStyle ? 'titlePdf' : 'titleWeb'" class="title-text title">
                    <p>{{ col.title }}</p>
                    <mat-icon
                        *ngIf="arrowMap[col.key]?.direction === 'desc' || !arrowMap[col.key]"
                        class="sort-arrow"
                        (click)="sort(col, 'asc')"
                        >arrow_downward
                    </mat-icon>

                    <mat-icon
                        *ngIf="arrowMap[col.key]?.direction === 'asc'"
                        class="sort-arrow"
                        (click)="sort(col, 'desc')"
                        >arrow_upward
                    </mat-icon>
                </div>
            </div>
        </div>

        <div class="flex">
            <div
                *ngFor="let col of header.columns"
                [class.hidden]="!col.show"
                [style.width.px]="col.width"
                [ngClass]="pdfStyle ? 'boxPdf' : 'boxWeb'"
                [ngSwitch]="col.key"
                class="flex-col"
            >
                <div
                    *ngFor="let appraisal of _appraisals"
                    [ngClass]="pdfStyle ? 'contentPdf' : 'contentWeb'"
                    class="content"
                >
                    <ng-container *ngSwitchCase="'objektArt'">
                        {{ appraisal.objektUnterArt?.name || appraisal.objektArt?.name }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'lage'">
                        {{ appraisal.lage?.lageQualitaet?.name | placeholder }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'ausstattungsQualitaet'">
                        {{ appraisal.ausstattungsQualitaet?.name | placeholder }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'datenQuelle'">
                        <span
                            (click)="showSnackbar('ID wurde kopiert!')"
                            [cdkCopyToClipboard]="appraisal | getAppraisalId"
                            >{{ appraisal | getAppraisalId }}</span
                        >
                    </ng-container>
                    <ng-container *ngSwitchCase="'id'">
                        <span
                            (click)="showSnackbar('ID wurde kopiert!')"
                            *ngIf="appraisal.id as id; else noId"
                            [cdkCopyToClipboard]="id + ''"
                            >{{ id }}</span
                        >
                        <ng-template #noId>-</ng-template>
                    </ng-container>
                    <ng-container *ngSwitchCase="'entfernung'">
                        {{ appraisal.entfernung | number: '1.2-2' | placeholder: { suffix: 'km' } }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'auftraggeber'">
                        {{ appraisal.auftraggeber?.loraAuftraggeberName | placeholder }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'auftragsart'">
                        {{ appraisal.auftragsart?.name | placeholder }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'objektzustand'">
                        {{ appraisal.objektzustand?.bezeichnung | placeholder }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'adresse'">
                        {{ appraisal.strasse }} {{ appraisal.hausnummer }}<br />{{ appraisal.plz }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'marktwert'">
                        <ng-container
                            *ngTemplateOutlet="
                                prices;
                                context: { value: appraisal[col.key], params: { suffix: col.unit } }
                            "
                        >
                        </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'kaufpreis'">
                        <ng-container
                            *ngTemplateOutlet="
                                prices;
                                context: { value: appraisal[col.key], params: { suffix: col.unit } }
                            "
                        >
                        </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'sachwertfaktor'">
                        <ng-container
                            *ngTemplateOutlet="
                                prices;
                                context: { value: appraisal[col.key], params: { suffix: col.unit } }
                            "
                        >
                        </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'kaufpreisDatum'">
                        <ng-container *ngTemplateOutlet="dates; context: { value: appraisal[col.key] }"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'bewertungsstichtag'">
                        <ng-container *ngTemplateOutlet="dates; context: { value: appraisal[col.key] }"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'besonderheiten'">
                        {{ appraisal.besonderheiten | gibtBesonderheiten | booleanToLanguage }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'fazit'">
                        <div *ngIf="pdfStyle" [innerHTML]="appraisal.fazit"></div>
                        <div *ngIf="!pdfStyle">
                            <div *ngIf="!appraisal.fazit">{{ appraisal.fazit | placeholder }}</div>
                            <button
                                (click)="openDialog(appraisal.id, appraisal.fazit)"
                                *ngIf="appraisal.fazit"
                                class="default"
                                id="fazit-button"
                            >
                                Ansehen
                            </button>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'baujahr'">
                        {{ appraisal.baujahr | gibtBaujahreRange: appraisal.baujahrFlaechengewichtet }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'fiktivesBaujahr'">
                        {{ appraisal.fiktivesBaujahr }}
                    </ng-container>

                    <ng-container *ngSwitchCase="'rohertragsvervielfaeltiger'">
                        {{ appraisal.rohertragsvervielfaeltiger | number: '1.1-1' }}
                    </ng-container>

                    <ng-container *ngSwitchDefault>
                        <app-number-display
                            [value]="appraisal[col.key]"
                            [placeHolderParams]="{ suffix: col.unit }"
                            decimalPlaces="2"
                        >
                        </app-number-display>
                    </ng-container>
                </div>

                <div *ngIf="col.calcAverage" [ngClass]="pdfStyle ? 'contentPdf' : 'contentWeb'" class="content avg">
                    <div *ngIf="col.average !== null && col.average !== undefined && col.key !== 'baujahr'">
                        Ø: {{ col.average | number: col.digitsInfo || '1.0-1' }}
                    </div>

                    <div *ngIf="col.max !== null && col.max !== undefined && col.key !== 'baujahr'">
                        Max: {{ col.max | number: col.digitsInfo || '1.0-1' }}
                    </div>
                    <div *ngIf="col.min !== null && col.min !== undefined && col.key !== 'baujahr'">
                        Min: {{ col.min | number: col.digitsInfo || '1.0-1' }}
                    </div>

                    <div *ngIf="col.average !== null && col.average !== undefined && col.key === 'baujahr'">
                        Ø: {{ col.average | date: 'yyyy' }}
                    </div>
                    <div *ngIf="col.max !== null && col.max !== undefined && col.key === 'baujahr'">
                        Max: {{ col.max | date: 'yyyy' }}
                    </div>
                    <div *ngIf="col.min !== null && col.min !== undefined && col.key === 'baujahr'">
                        Min: {{ col.min | date: 'yyyy' }}
                    </div>
                </div>

                <div *ngIf="!col.calcAverage" class="avg"></div>
            </div>
        </div>
    </div>

</div>

<ng-template #prices let-value="value" let-params="params">
    <app-number-display [value]="value" [placeHolderParams]="params"></app-number-display>
</ng-template>

<ng-template #dates let-value="value">
    {{ value | date: 'dd.MM.yyyy' | placeholder }}
</ng-template>
