<div id="table-container">
    <form [formGroup]="formGroup">
        <div class="dialog-content">
            <div class="dialog-side flex-1">
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Postleitzahl</mat-label>
                    <input
                        name="plz"
                        type="text"
                        placeholder="Postleitzahl eingeben"
                        matInput
                        formControlName="postleitzahl"
                        [matAutocomplete]="autoPostalCode"
                    />
                    <mat-autocomplete autoActiveFirstOption #autoPostalCode="matAutocomplete">
                        <mat-option *ngFor="let postalCode of filteredPlzOptions | async" [value]="postalCode.plz">
                            {{ postalCode.plz }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Gemeindekennziffer</mat-label>
                    <input
                        type="text"
                        placeholder="KGS eingeben"
                        matInput
                        formControlName="gemeindekennziffer"
                        [matAutocomplete]="autoMunicipalCode"
                    />
                    <mat-autocomplete autoActiveFirstOption #autoMunicipalCode="matAutocomplete">
                        <mat-option
                            *ngFor="let gemeindekennziffer of filteredGemeindekennzifferOptions | async"
                            [value]="gemeindekennziffer"
                        >
                            {{ gemeindekennziffer.kreisGemeindeSchluessel }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <mat-checkbox formControlName="countryWide"
                              class="checkbox">Deutschlandweit?
                </mat-checkbox>
            </div>
            <div class="dialog-side flex-2">
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Gemeinde</mat-label>
                    <input
                        type="text"
                        placeholder="Gemeinde eingeben"
                        matInput
                        formControlName="gemeinde"
                        [matAutocomplete]="autoCommunes"
                    />
                    <mat-autocomplete
                        autoActiveFirstOption
                        #autoCommunes="matAutocomplete"
                        (optionSelected)="onOptionSelected($event)"
                    >
                        <mat-option *ngFor="let commune of filteredGemeindeOptions | async" [value]="commune">
                            <span>{{ commune.name + ' - ' + commune.kreisGemeindeSchluessel }}</span>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Bundesland</mat-label>
                    <input
                        type="text"
                        placeholder="Bundesland eingeben"
                        matInput
                        formControlName="bundesland"
                        [matAutocomplete]="autoState"
                    />
                    <mat-autocomplete autoActiveFirstOption #autoState="matAutocomplete">
                        <mat-option *ngFor="let state of filteredBundeslandOptions | async" [value]="state.name">
                            {{ state.name }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Landkreis</mat-label>
                    <input
                        type="text"
                        placeholder="Landkreis eingeben"
                        matInput
                        formControlName="landkreis"
                        [matAutocomplete]="autoCounty"
                    />
                    <mat-autocomplete autoActiveFirstOption #autoCounty="matAutocomplete">
                        <mat-option
                            *ngFor="let county of filteredLandkreisOptions | async; trackBy: trackCounty"
                            [value]="county.name"
                        >
                            {{ county.name }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <div class="error-messages">
                <span
                    *ngIf="formGroup.get('postleitzahl').errors?.['invalidPostalCodeCityMatch'] && formGroup.get('postleitzahl').dirty"
                >
                    Ort/Stadt und Postleitzahl stimmen nicht überein.
                </span>
                <span
                    *ngIf="formGroup.get('postleitzahl').errors?.['invalidPostalMunicipalCodeMatch'] && formGroup.get('postleitzahl').dirty"
                >
                    Postleitzahl und Gemeindekennziffer stimmen nicht überein.
                </span>
                <span
                    *ngIf="formGroup.get('gemeinde').errors?.['invalidMunicipalCodeCityMatch'] && formGroup.get('gemeinde').dirty"
                >
                    Ort/Stadt und Gemeindekennziffer stimmen nicht überein.
                </span>
                <span
                    *ngIf="formGroup.get('gemeindekennziffer').errors?.['invalidMunicipalCode'] && formGroup.get('gemeindekennziffer').dirty"
                >
                    Gemeindekennziffer ist ungültig.
                </span>
                <span
                    *ngIf="(formGroup.get('bundesland').errors?.['invalidSelection'] && formGroup.get('bundesland').dirty)
                    || (formGroup.get('landkreis').errors?.['invalidSelection'] && formGroup.get('landkreis').dirty)
                    || (formGroup.get('gemeinde').errors?.['invalidSelection'] && formGroup.get('gemeinde').dirty)
                    || (formGroup.get('postleitzahl').errors?.['invalidSelection'] && formGroup.get('postleitzahl').dirty)"
                >
                    Bitte überprüfen Sie Ihre Eingaben.
                </span>

                <span
                    *ngIf="formGroup.errors?.['bundeslandOrCountryWide'] && (formGroup.get('bundesland').dirty || formGroup.get('countryWide').dirty)">
                Bundesland oder Deutschlandweit muss ausgewählt werden
                </span>
            </div>

            <!--Button Row-->
            <div id="btns">
                <button (click)="submit()" class="primary" type="submit" [disabled]="!formGroup.valid">
                    {{ data.primaryBtnTitle || 'Speichern' }}
                </button>
                <button (click)="cancel()" class="default">Abbrechen</button>
            </div>
        </div>
    </form>
</div>
