import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations';

export const rotate: AnimationTriggerMetadata = trigger('rotate', [
  state('default', style({transform: 'rotate(0)'})),
  state('rotated', style({transform: 'rotate(90deg)'})),
  transition('rotated => default', animate('200ms ease-out')),
  transition('default => rotated', animate('200ms ease-in')),
]);

export const slideInOut: AnimationTriggerMetadata = trigger('slideInOut', [
  state('open', style({
    overflow: 'hidden',
    height: '*',
    width: '100%',
  })),
  state('closed', style({
    opacity: '0',
    overflow: 'hidden',
    height: '0px',
    width: '0px',
  })),
  transition('open => closed', animate('200ms ease-in-out')),
  transition('closed => open', animate('200ms ease-in-out')),
]);
