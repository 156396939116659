<p class="mt-5 display-6">Gutachtentext</p>

<app-card class="mt-4">
   <div header>Übersicht</div>
   <div body class="row">
      <div class="col">
         <app-field label="Auftragsnummer">
            {{appraisalText.loraAuftragsnummer}}
         </app-field>
      </div>
      <div class="col">
         <app-field label="Auftraggeber">
            {{appraisalText.auftraggeber.loraAuftraggeberName}}
         </app-field>
      </div>
      <div class="col">
         <app-field label="Auftragsart">
            {{appraisalText.auftragsart.name}}
         </app-field>
      </div>
      <div class="col">
         <app-field label="wertbestimmendes Verfahren">
            {{WertbestimmendesVerfahrenNames[appraisalText.wertbestimmendesVerfahren || ''] }}
         </app-field>
      </div>
   </div>
</app-card>

<app-card class="mt-4">
   <div header>Bausteine</div>
   <div body>
      <app-bricks [bricks]="bricksData"></app-bricks>
   </div>
</app-card>

<!-- <mat-card class="mt-4">
   <mat-card-title class="mb-4">Hinweise</mat-card-title>
   <mat-card-content>
   </mat-card-content>
</mat-card> -->

<app-card class="my-4">
   <div header>Vorschau</div>
   <div body>
      <div class="text-end">
         <button class="btn btn-primary" (click)="downloadWord()">Export: Word</button>
      </div>
      <app-card class="mt-2">
         <div body class="htmlViewer" *ngIf="appraisalText.generierterTextHtml" [innerHTML]="appraisalText.generierterTextHtml"></div>
      </app-card>
   </div>
</app-card>