import { BauKostenSpanne } from "../enums/BauKostenSpanne"
import { PkwStellplatzArt } from "../enums/PkwStellplatzArt"
import { UmsatzSteuerArt } from "../enums/UmsatzSteuerArt"
import { VermietungsSituation } from "../enums/VermietungsSituation"

// Region
export interface Region {
   id: number,
   name: string,
   regionalFaktor: number
}

// BauPreisIndexStand
export interface ConstructionPriceIndexState {
   id: number,
   jahr: number,
   quartal: number,
   caption: string
}

// BausteinHerstellungsKostenObjekt
export class BrickProductionCostsObject {
   id: number;
   objektNummer: number;
   gutachtenTextObjektUnterArt: AppraisalTextObjectSubType;
   umsatzSteuerArt: UmsatzSteuerArt;
   bpiAktuell: number;
   bpiBeiVeroeffentlichung: number;
   bpiAnpassung: number;
   bezugsEinheit: ReferenceUnit;
   bezugsEinheitVon: number;
   bezugsEinheitBis: number;
   bezugsEinheitMittelWert: number;
   bezugsEinheitVonInklUSt: number;
   bezugsEinheitBisInklUSt: number;
   bezugsEinheitMittelWertInklUSt: number;
   bezugsEinheitVonOhneUSt: number;
   bezugsEinheitBisOhneUSt: number;
   bezugsEinheitMittelWertOhneUSt: number;
   aussenStellplatzVorhanden: boolean;
   pkwStellplatzArt: PkwStellplatzArt;
   kostenProStellplatz: number;
   vermietungsSituation: VermietungsSituation;
   einordnungBaukostenSpanneWohnen: BauKostenSpanne;
   einordnungBaukostenSpanneGewerbe: BauKostenSpanne;
}

// GutachtenTextObjektUnterArt
export class AppraisalTextObjectSubType {
   id: number;
   name: string;
   gutachtenTextObjektArt: AppraisalTextObjectType;
   gutachtenTextObjektKategorie: AppraisalTextObjectCategory;
}

// GutachtenTextObjektArt
export class AppraisalTextObjectType {
   id: number;
   name: string
}

// GutachtenTextObjektKategorie
export class AppraisalTextObjectCategory {
   id: number;
   name: string
}

// BezugsEinheit
export class ReferenceUnit {
   id: number;
   name: string;
   kuerzel: string
}

// AusschnittBauPreisIndizes
export class ExcerptConstructionPriceIndices {
   bpiAktuell: number;
   bpiBeiVeroeffentlichung: number;
   bpiAnpassung: number;
}

// AusschnittBauKosten
export class ExcerptConstructionCosts {
   bezugsEinheitVon: number;
   bezugsEinheitBis: number;
   bezugsEinheitMittelWert: number;
   bezugsEinheitVonInklUSt: number;
   bezugsEinheitBisInklUSt: number;
   bezugsEinheitMittelWertInklUSt: number;
   bezugsEinheitVonOhneUSt: number;
   bezugsEinheitBisOhneUSt: number;
   bezugsEinheitMittelWertOhneUSt: number;
}