import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AppraisalText, AppraisalTextOptions } from '../interfaces/AppraisalTextTypes';
import { ApiService } from 'src/app/core/services/api.service';
import { environment } from 'src/environments/environment';
import { RequestData } from 'src/app/appraisal-detail/models/Appraisal';
import { BrickUpdate } from '../interfaces/BrickTypes';
import { HttpParams } from '@angular/common/http';
import { Auftraggeber, Auftragsart } from 'src/app/appraisal-detail/interfaces/AppraisalFilterTypes';

@Injectable({
  providedIn: 'root'
})
export class AppraisalTextService {

  constructor(
    private apiService: ApiService) { }

  loadAll(): Observable<RequestData<AppraisalText[]>> {
    const url = `${environment.appraisalTextsUrl}`;
    return this.apiService.get<RequestData<AppraisalText[]>>(url);
  }
  
  loadOne(id: number): Observable<RequestData<AppraisalText>> {
    const url = `${environment.appraisalTextsUrl}/${id}`;
    return this.apiService.get<RequestData<AppraisalText>>(url);
  }

  loadOptions(): Observable<RequestData<AppraisalTextOptions>> {
    const url = `${environment.appraisalTextsOptionsUrl}`;
    return this.apiService.get<RequestData<AppraisalTextOptions>>(url);
  }

  delete(id: number): Observable<RequestData<any>> {
    const url = `${environment.appraisalTextDeleteUrl}/${id}`;
    return this.apiService.delete(url);
  }

  create(data: FormData): Observable<any> {
    const url = environment.appraisalTextCreateUrl;
    return this.apiService.post(url, data);
  }

  loadAllOrderers(): Observable<RequestData<Auftraggeber[]>> {
    const url = `${environment.appraisalTextsOrderersUrl}`;
    return this.apiService.get<RequestData<Auftraggeber[]>>(url);
  }

  loadAllOrderTypes(): Observable<RequestData<Auftragsart[]>> {
    const url = `${environment.appraisalTextsOrderTypesUrl}`;
    return this.apiService.get<RequestData<Auftragsart[]>>(url);
  }

}
