import { Injectable } from '@angular/core';
import { NgElement, WithProperties } from '@angular/elements';
import * as L from 'leaflet';
import {
    circle,
    Circle,
    CircleMarkerOptions,
    LatLng,
    LatLngExpression,
    MapOptions,
    Marker,
    marker,
    MarkerOptions
} from 'leaflet';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';
import { IAppraisalMapView } from '../../appraisal-detail/models/Appraisal';
import { PopupComponent } from '../../appraisal-search/components/map-view/map/popup/popup.component';
import config from '../config/config';
import { calculateDestinationPoint, DefaultMapOptions, DEFAULT_CENTER_COORDS, WORLD_BOUNDS } from '../utils/map.utils';

// higher value means more points around the circle and makes the polygon more accurate
const AMOUNT_OF_POSITIONS = 36;

// the iteration is from 0 to amount of positions, along the degree values of a circle,
// so to have the correct value index needs to be multiplied by 10
const INDEX_TO_DEGREE_FACTOR = 10;

@Injectable({
    providedIn: 'root'
})
export class MapService {
    private _centerCoords$: BehaviorSubject<LatLng> = new BehaviorSubject(null);
    private _currentZoom$: BehaviorSubject<number> = new BehaviorSubject(null);
    private _radiusSubject$: BehaviorSubject<number> = new BehaviorSubject(config.defaultPerimeter);

    private _userPos: LatLng;

    get userPos(): LatLng {
        return this._userPos;
    }

    get centerCoords(): Observable<LatLng> {
        return this._centerCoords$.asObservable().pipe(
            filter((x) => !!x),
            distinctUntilChanged(),
            shareReplay()
        );
    }

    get radius(): Observable<number> {
        return this._radiusSubject$.asObservable();
    }

    get mapOptions(): MapOptions {
        return { ...DefaultMapOptions, zoom: this._currentZoom$.getValue() || DefaultMapOptions.zoom };
    }

    get zoom(): number {
        return this._currentZoom$.getValue();
    }

    setRadius(radius: number): void {
        this._radiusSubject$.next(radius);
    }

    setZoom(zoom: number): void {
        this._currentZoom$.next(zoom);
    }

    setCenterCoords(coords: LatLng) {
        localStorage.setItem('coords', JSON.stringify(coords));
        this._centerCoords$.next(coords);
    }

    getUserPos(): Observable<LatLng> {
        return this.getCurrentLocationObs().pipe(
            map(({ coords }) => new LatLng(coords.latitude, coords.longitude)),
            catchError(() => of(DEFAULT_CENTER_COORDS))
        );
    }

    setUserPos(coords: LatLng) {
        this._userPos = coords;
    }

    createCircle(latlng: LatLng, options: CircleMarkerOptions): Circle {
        return circle(latlng, options);
    }

    createMarker(latLng: LatLng, options: MarkerOptions): Marker {
        return marker(latLng, options);
    }

    getOverlayPolygonBounds(center: LatLng, radius: number): LatLngExpression[][] {
        const radiusCircleBounds: LatLng[] = [];
        for (let i = 0; i < AMOUNT_OF_POSITIONS; i++) {
            // Calculates points around the circle which is (center + radius) to determine where the polygon needs to have a whole
            radiusCircleBounds.push(
                calculateDestinationPoint(center.lat, center.lng, i * INDEX_TO_DEGREE_FACTOR, radius)
            );
        }

        // creates a new Polygon which covers the whole map, except the calculated circle
        return [WORLD_BOUNDS, radiusCircleBounds];
    }

    createAppraisalMarker(appraisal: IAppraisalMapView): Marker {
        const coords: LatLng = new LatLng(appraisal.breitengrad, appraisal.laengengrad);
        const markericon = {
            icon: L.icon({
                iconSize: [40, 40],
                iconAnchor: [20, 0],
                iconUrl: `assets/icons/marker_icon_01.png`
            })
        };

        return this.createMarker(coords, markericon).bindPopup(() => {
            // needed to create the popup.component as ng element to add it as html element in DOM
            const popupEl: NgElement & WithProperties<PopupComponent> = document.createElement('popup-element') as any;
            // Listen to the close event
            popupEl.addEventListener('closed', () => document.body.removeChild(popupEl));
            popupEl.appraisalId = appraisal.gutachtenId;
            // Add to the DOM
            document.body.appendChild(popupEl);
            return popupEl;
        });
    }

    private getCurrentLocationObs(): Observable<GeolocationPosition> {
        return new Observable((observer) => {
            if ('geolocation' in navigator) {
                navigator.geolocation.getCurrentPosition(
                    (position: GeolocationPosition) => {
                        observer.next(position);
                    },
                    (error: GeolocationPositionError) => {
                        observer.error(error);
                    }
                );
            } else {
                observer.error('Geolocation not available');
            }
        });
    }
}
