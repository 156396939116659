<div #top></div>
<table [dataSource]="exactDataSource" mat-table matSort (matSortChange)="sortData($event)">
    <ng-container matColumnDef="distanz">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="distanz">
            <span>Entfernung</span>
        </th>
        <td *matCellDef="let element" mat-cell>
            <app-img [id]="element.gutachtenId" size="42" type="thumbnail"></app-img>
            <app-number-display
                [value]="element.entfernung"
                displayZero="true"
                [placeHolderParams]="{ suffix: 'km' }"
            ></app-number-display>
        </td>
    </ng-container>

    <ng-container matColumnDef="objektUnterArtName">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="objektUnterArtName">
            <span>Unterart</span>
        </th>
        <td matTooltip="Info about the action" *matCellDef="let element" mat-cell>
            {{ element.objektUnterArtName | placeholder }}
        </td>
    </ng-container>

    <ng-container matColumnDef="auftraggeberName">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="auftraggeberName">
            <span>Auftraggeber</span>
        </th>
        <td *matCellDef="let element" mat-cell>{{ element.auftraggeberName | placeholder }}</td>
    </ng-container>

    <ng-container matColumnDef="auftragsart">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="auftragsart">
            <span>Auftragsart</span>
        </th>
        <td *matCellDef="let element" mat-cell>
            {{ element.auftragsart?.name }}
        </td>
    </ng-container>

    <ng-container matColumnDef="marktwert">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="marktwert">
            <span>Marktwert</span>
        </th>
        <td *matCellDef="let element" mat-cell>
            <app-number-display
                [value]="element.marktwert"
                [placeHolderParams]="{ placeHolderText: ' ', suffix: '€' }"
            ></app-number-display>
        </td>
    </ng-container>

    <ng-container matColumnDef="marktwertProQuadratmeter">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="marktwertProQuadratmeter">
            <span>MWT pro m²</span>
        </th>
        <td *matCellDef="let element" mat-cell>
            <app-number-display
                [value]="element.marktwertProQuadratmeter"
                [placeHolderParams]="{ placeHolderText: ' ', suffix: '€/m²' }"
            ></app-number-display>
        </td>
    </ng-container>

    <ng-container matColumnDef="baujahr">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="baujahr">
            <span>Baujahr</span>
        </th>
        <td *matCellDef="let element" mat-cell>
            <span>{{ element.baujahr | gibtBaujahreRange: element.baujahrFlaechengewichtet:'' }}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="adresse">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="adresse">
            <span>Adresse</span>
        </th>
        <td *matCellDef="let element" mat-cell>
            {{ element.plz | placeholder: {placeHolderText: ''} }},
            {{ element.ort | placeholder: {placeHolderText: ''} }},
            {{ element.strasse | placeholder: {placeHolderText: ''} }}
            {{ element.hausnummer | placeholder: {placeHolderText: ''} }}
        </td>
    </ng-container>

    <ng-container matColumnDef="lageQualitaetName">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="lageQualitaetName">
            <span>Lage</span>
        </th>
        <td *matCellDef="let element" mat-cell>{{ element.lageQualitaetName | placeholder }}</td>
    </ng-container>

    <ng-container matColumnDef="ausstattungsQualitaetName">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="ausstattungsQualitaetName">
            <span>Ausstattung</span>
        </th>
        <td *matCellDef="let element" mat-cell>{{ element.ausstattungsQualitaetName | placeholder }}</td>
    </ng-container>

    <ng-container matColumnDef="besonderheitenVorhanden">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="besonderheitenVorhanden">
            <span>Besonderheiten</span>
        </th>
        <td *matCellDef="let element" mat-cell>{{ element.besonderheitenVorhanden | booleanToLanguage }}</td>
    </ng-container>

    <ng-container matColumnDef="bewertungsstichtag">
        <th *matHeaderCellDef mat-header-cell mat-sort-header="bewertungsstichtag">
            <span>Stichtag</span>
        </th>
        <td *matCellDef="let element" mat-cell>{{ element.bewertungsstichtag | date: 'dd.MM.yyyy' }}</td>
    </ng-container>

    <ng-container matColumnDef="compare">
        <th *matHeaderCellDef class="max-width-auto" mat-header-cell>
            <button class="default btnCmp">Vergleichen</button>
        </th>
        <td *matCellDef="let element" mat-cell>
            <ng-container *ngIf="comparableAppraisals$ | async as comparableAppraisals">
                <button
                    (click)="addOrRemoveAppraisal(element.gutachtenId); $event.stopPropagation()"
                    [ngClass]="comparableAppraisals?.includes(element.gutachtenId) ? 'primary' : 'default'"
                    class="btnCmp"
                >
                    Vergleichen
                </button>
            </ng-container>
        </td>
    </ng-container>

    <ng-container matColumnDef="subHeader">
        <th *matHeaderCellDef class="row-component space-between table-sub-header" mat-header-cell>
            <span class="text">Aus exakter Adresse</span>
        </th>
    </ng-container>
    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
    <tr *matHeaderRowDef="['subHeader']" aria-colspan="{{ displayedColumns.length }}" mat-header-row></tr>
    <tr
        *matRowDef="let row; columns: displayedColumns"
        [routerLink]="['/detail/', row.gutachtenId]"
        class="hover pointer"
        mat-row
    ></tr>
</table>

<table [dataSource]="perimeterDataSource" mat-table>
    <ng-container matColumnDef="distanz">
        <td *matCellDef="let element" mat-cell>
            <app-img [id]="element.gutachtenId" size="42" type="thumbnail"></app-img>
            <app-number-display
                [value]="element.entfernung"
                [placeHolderParams]="{ suffix: 'km' }"
                displayZero="true"
                decimalPlaces="2"
            >
            </app-number-display>
        </td>
    </ng-container>

    <ng-container matColumnDef="objektUnterArtName">
        <td *matCellDef="let element" mat-cell [matTooltip]="element.objektUnterArtName">
            {{ element.objektUnterArtName | placeholder }}
        </td>
    </ng-container>

    <ng-container matColumnDef="auftraggeberName">
        <td *matCellDef="let element" mat-cell>{{ element.auftraggeberName | placeholder }}</td>
    </ng-container>

    <ng-container matColumnDef="auftragsart">
        <th *matHeaderCellDef mat-header-cell>
            <span>Auftragsart</span>
        </th>
        <td *matCellDef="let element" mat-cell>{{ element.auftragsart?.name }}</td>
    </ng-container>

    <ng-container matColumnDef="marktwert">
        <td *matCellDef="let element" mat-cell>
            <app-number-display
                [value]="element.marktwert"
                [placeHolderParams]="{ placeHolderText: ' ', suffix: '€' }"
            ></app-number-display>
        </td>
    </ng-container>

    <ng-container matColumnDef="marktwertProQuadratmeter">
        <th *matHeaderCellDef mat-header-cell>
            <span>MWT pro m²</span>
        </th>
        <td *matCellDef="let element" mat-cell>
            <app-number-display
                [value]="element.marktwertProQuadratmeter"
                [placeHolderParams]="{ placeHolderText: ' ', suffix: '€/m²' }"
            ></app-number-display>
        </td>
    </ng-container>

    <ng-container matColumnDef="baujahr">
        <th *matHeaderCellDef mat-header-cell>
            <span>Baujahr</span>
        </th>
        <td *matCellDef="let element" mat-cell>
            <span>{{ element.baujahr | gibtBaujahreRange: element.baujahrFlaechengewichtet:'' }}</span>
        </td>
    </ng-container>

    <ng-container matColumnDef="adresse">
        <th *matHeaderCellDef mat-header-cell>
            <span>Adresse</span>
        </th>
        <td *matCellDef="let element" mat-cell>
            <span *ngIf="element.pLZ">{{ element.pLZ }} </span>
            <span *ngIf="element.ort">{{ element.ort }}, </span>
            <span *ngIf="element.strasse">{{ element.strasse }} </span>
            <span *ngIf="element.hausnummer">{{ element.hausnummer }} </span>
        </td>
    </ng-container>

    <ng-container matColumnDef="lageQualitaetName">
        <td *matCellDef="let element" mat-cell>{{ element.lageQualitaetName | placeholder }}</td>
    </ng-container>

    <ng-container matColumnDef="ausstattungsQualitaetName">
        <td *matCellDef="let element" mat-cell>{{ element.ausstattungsQualitaetName | placeholder }}</td>
    </ng-container>

    <ng-container matColumnDef="besonderheitenVorhanden">
        <td *matCellDef="let element" mat-cell>{{ element.besonderheitenVorhanden | booleanToLanguage }}</td>
    </ng-container>

    <ng-container matColumnDef="bewertungsstichtag">
        <td *matCellDef="let element" mat-cell>{{ element.bewertungsstichtag | date: 'dd.MM.yyyy' }}</td>
    </ng-container>

    <ng-container matColumnDef="compare">
        <th *matHeaderCellDef class="max-width-auto" mat-header-cell>
            <button class="default btnCmp">Vergleichen</button>
        </th>
        <td *matCellDef="let element" mat-cell>
            <ng-container *ngIf="comparableAppraisals$ | async as comparableAppraisals">
                <button
                    (click)="addOrRemoveAppraisal(element.gutachtenId); $event.stopPropagation()"
                    [ngClass]="comparableAppraisals?.includes(element.gutachtenId) ? 'primary' : 'default'"
                    class="btnCmp"
                >
                    Vergleichen
                </button>
            </ng-container>
        </td>
    </ng-container>

    <ng-container matColumnDef="subHeader">
        <th *matHeaderCellDef class="table-sub-header text" mat-header-cell>
            <span class="text">Aus Umkreis</span>
        </th>
    </ng-container>

    <tr *matHeaderRowDef="['subHeader']" aria-colspan="{{ displayedColumns.length }}" mat-header-row></tr>
    <tr
        *matRowDef="let row; columns: displayedColumns"
        [routerLink]="['/detail/', row.gutachtenId]"
        class="hover pointer"
        mat-row
    ></tr>
</table>
<mat-paginator
    (page)="onPaginationChange($event)"
    [length]="pagination.total"
    [pageIndex]="pagination.page"
    [pageSize]="pagination.size"
    hidePageSize
>
</mat-paginator>
