<div id="container">
    <form>
        <input
            (input)="setInput($event.target.value)"
            placeholder="{{ data.placeholder }}"
            class="create mat-input"
            matInput
            [max]=""
            [min]=""
            [formControl]="formGroup"
            autocomplete="off"
        />
        <button
            *ngIf="inputAvailable"
            id="btnClear"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="inputClear()"
        >
            <mat-icon id="icnClose">close</mat-icon>
        </button>
        <label id="requiredField" *ngIf="data.required && !inputAvailable">*</label>
        <label *ngIf="inputAvailable" id="placeholder">{{ data.placeholder }}</label>
    </form>
</div>
