import { Pipe, PipeTransform } from '@angular/core';
import { Restnutzungsdauer } from '../interfaces/AppraisalFilterTypes';

@Pipe({
    name: 'gibtRndRange'
})
export class GibtRndRangePipe implements PipeTransform {
    transform(value: Restnutzungsdauer[], avg: number): string {
        if (value == null || value.length === 0) {
            return '-';
        }

        const vals = new Set([...value.map((b) => b.restnutzungsdauerValue)]);
        return vals.size > 1
            ? `${Math.min(...vals)} - ${Math.max(...vals)} ${avg > 0 ? `(Ø${avg.toFixed(0)})` : ''} Jahre`
            : `${[...vals][0]} Jahre`;
    }
}
