import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-dialog-text',
    templateUrl: './dialog-text.component.html',
    styleUrls: ['./dialog-text.' + 'component.scss']
})
export class DialogTextComponent {
    @Input() text: string;
}
