import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Pagination } from '../../../shared/models/Pagination';
import {
   CollectionNutzungsart,
   ICollectionListView,
   CollectionUploadSorting,
   FilteredCollection
} from '../../models/Collection';
import { CollectionService } from '../../services/collection.service';
import {
   UploadDropdownKategorie,
   UploadDropdownKostenpflichtig,
   UploadDropdownPublikaionsturnus
} from '../../UploadDropdownData';
import { Sort } from "@angular/material/sort";
import { BreadcrumbDefault, BreadcrumbsService } from 'src/app/core/services/breadcrumbs.service';

@Component({
   selector: 'app-upload-list',
   templateUrl: './upload-list.component.html',
   styleUrls: ['./upload-list.component.scss']
})
export class UploadListComponent implements OnInit {
   @ViewChild('top') top: ElementRef;

   Kategorie = UploadDropdownKategorie;
   Publikationsturnus = UploadDropdownPublikaionsturnus;
   Kostenpflichtig = UploadDropdownKostenpflichtig;

   dataSource: MatTableDataSource<ICollectionListView> = new MatTableDataSource([]);
   resultHint: string = '';

   currentNutzungsarten$: Observable<number[]>;
   allNutzungsarten: CollectionNutzungsart[];

   pagination: Pagination = Pagination.default();

   displayedColumns: string[] = [
      'titel',
      'herausgeberName',
      'publikationsturnus',
      'publikationsdatum',
      'nutzungen',
      'lagen',
      'kategorie',
      'bewertungAverage'
   ];

   constructor(
      public collectionService: CollectionService,
      private router: Router,
      private route: ActivatedRoute,
      private breadcrumbsService: BreadcrumbsService
   ) { }

   public loading = false;

   ngOnInit(): void {
      this.collectionService.loadData$
         .pipe(
            tap(() => this.loading = true),
            switchMap(() => this.collectionService.getCollectionsFiltered())
         )
         .subscribe((filteredData: FilteredCollection) => {
            if (filteredData.countryWide?.data?.length) {
               this.dataSource.data = filteredData.countryWide.data;
               this.resultHint = `keine Ergebnisse für den aktuellen Lage-Filter, Ergebnisse für ganz Deutschland`;
            }

            if (filteredData.byBundesland?.data?.length) {
               this.dataSource.data = filteredData.byBundesland.data;
               this.resultHint = `keine Ergebnisse für den aktuellen Lage-Filter, Ergebnisse für das Bundesland ${filteredData.bundesland.name}`;
            }

            if (filteredData.byLandkreis?.data?.length) {
               this.dataSource.data = filteredData.byLandkreis.data;
               this.resultHint = `keine Ergebnisse für den aktuellen Lage-Filter, Ergebnisse für den Landkreis ${filteredData.landkreis.name}`;
            }

            if (filteredData.byFilter?.data?.length) {
               this.dataSource.data = filteredData.byFilter.data;
               this.resultHint = 'Ergebnisse für den aktuellen Filter';
            }

            // Backend page index starts with 1, but mat paginator starts with 0.
            // So we need to decrease for paginator
            const page = {
               pageNumber: filteredData.byFilter.pageNumber,
               pageSize: filteredData.byFilter.pageSize,
               totalRecords: filteredData.byFilter.totalRecords
            };
            this.pagination.updatePageInfo(page.pageNumber - 1, page.pageSize, page.totalRecords);
            this.loading = false;
         });

      // We need to update tbe list if user navs back and forth and therefore query params change
      this.route.queryParams.subscribe((queryParams: Params) => {
         if ('page' in queryParams && 'size' in queryParams) {
            const page = parseInt(queryParams.page, 10);
            const size = parseInt(queryParams.size, 10);
            this.collectionService.updateListPagination(page, size);
         }
      });
      this.currentNutzungsarten$ = this.collectionService.getCurrentNutzungsarten();
      this.collectionService
         .getNutzungen()
         .subscribe((nutzungen) => (this.allNutzungsarten = nutzungen.nutzungsarten));

      this.breadcrumbsService.Apply([
         { ...BreadcrumbDefault.UploadListLabel },
      ]);
   }

   sortData(sort: Sort): void {
      this.collectionService.setSorting(sort as CollectionUploadSorting);
   }

   onPaginationChange(ev: PageEvent): void {
      this.updateQueryParams(ev.pageIndex + 1, ev.pageSize);
      // Backend page index starts with 1, but mat paginator starts with 0.
      // So we need to increase for backend
      // this.collectionService.updateListPagination(ev.pageIndex + 1, ev.pageSize);
      this.top.nativeElement.scrollIntoView();
   }

   private updateQueryParams(page: number, size: number): void {
      this.router.navigate(['./'], {
         relativeTo: this.route,
         queryParams: {
            page,
            size
         },
         queryParamsHandling: 'merge'
      });
   }
}
