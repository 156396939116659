import { Pipe, PipeTransform } from '@angular/core';

// the simplest way to unescape all special character
const htmlDecode = (input: string) => {
  const e = document.createElement('textarea');
  e.innerHTML = input;
  // handle case of empty input
  return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
};

@Pipe({
  name: 'htmlToText'
})
export class HtmlToTextPipe implements PipeTransform {

  transform(text: string): string {
    if (text) {
      text = text.replace(/<\/?[^>]+(>|$)/g, '');
      return htmlDecode(text);
    }
    return null;
  }

}
