import { ChartConfiguration, ChartOptions } from 'chart.js';

export interface StrNumberMap {
    [key: string]: number;
}

export enum UnitType {
    EURO = '€',
    QM = 'm²',
    PERCENT = '%',
    EURO_PRO_QM = '€/m²',
    YEAR = 'Jahre',
    X = 'x',
    NONE = ''
}

export interface ChartConfigurationWithCount extends ChartConfiguration {
    count?: {
        all: number;
        used: number;
    };
}

export interface HistogrammClass {
    id: string;
    min: number;
    max: number;
}

export enum ChartTypes {
    DOUGHNUT = 'doughnut',
    BAR = 'bar',
    LINE = 'line',
    HISTOGRAM = 'histogram',
    SCATTER = 'scatter'
}

export class UnitTypesXY {
    unitx: UnitType;
    unity: UnitType;

    constructor(unitx: UnitType, unity: UnitType) {
        this.unitx = unitx;
        this.unity = unity;
    }
}

export class HistogramClassRange {
    stepSize: number;
    stepSizeInPercent: boolean;

    constructor(stepSize: number, stepSizeInPercent: boolean) {
        this.stepSize = stepSize;
        this.stepSizeInPercent = stepSizeInPercent;
    }
}

export class ChartContent {
    name: string;
    unitxy: UnitTypesXY;
    steps?: HistogramClassRange;

    constructor(name: string, unitxy: UnitTypesXY, steps?: HistogramClassRange) {
        this.name = name;
        this.unitxy = unitxy;
        this.steps = steps;
    }
}

export const ChartContentType = {
    GND: new ChartContent('gnd', new UnitTypesXY(UnitType.YEAR, UnitType.NONE)),
    RND: new ChartContent('rnd', new UnitTypesXY(UnitType.YEAR, UnitType.NONE)),
    AUSSTATTUNG: new ChartContent('ausstattungsQualitaet', new UnitTypesXY(UnitType.NONE, UnitType.NONE)),
    OBJEKTART: new ChartContent('objektart', new UnitTypesXY(UnitType.NONE, UnitType.NONE)),
    LAGE: new ChartContent('lageQualitaet', new UnitTypesXY(UnitType.NONE, UnitType.NONE)),
    BAUJAHR: new ChartContent('baujahr', new UnitTypesXY(UnitType.NONE, UnitType.NONE)),
    MIETE_IST_WOHNEN: new ChartContent(
        'mieteIstWohnenDurchschnitt',
        new UnitTypesXY(UnitType.EURO_PRO_QM, UnitType.NONE)
    ),
    MIETE_IST_GEWERBE: new ChartContent(
        'mieteIstGewerbeDurchschnitt',
        new UnitTypesXY(UnitType.EURO_PRO_QM, UnitType.NONE)
    ),
    MIETE_ANSATZ_WOHNEN: new ChartContent(
        'mieteAnsatzWohnenDurchschnitt',
        new UnitTypesXY(UnitType.EURO_PRO_QM, UnitType.NONE)
    ),
    MIETE_ANSATZ_GEWERBE: new ChartContent(
        'mieteAnsatzGewerbeDurchschnitt',
        new UnitTypesXY(UnitType.EURO_PRO_QM, UnitType.NONE)
    ),
    ANSATZMIETE: new ChartContent('ansatzmiete', new UnitTypesXY(UnitType.EURO_PRO_QM, UnitType.NONE)),
    HANDELSIMMOBILIEN: new ChartContent('handelsimmobilien', new UnitTypesXY(UnitType.EURO_PRO_QM, UnitType.NONE)),
    BUEROIMMOBILIEN: new ChartContent('bueroimmobilien', new UnitTypesXY(UnitType.EURO_PRO_QM, UnitType.NONE)),
    MIETEN: new ChartContent('mieten', new UnitTypesXY(UnitType.EURO_PRO_QM, UnitType.NONE)),
    LIEGENSCHAFTSZINS: new ChartContent(
        'liegenschaftszinsDurchschnitt',
        new UnitTypesXY(UnitType.PERCENT, UnitType.NONE),
        new HistogramClassRange(0.25, false)
    ),
    ANZAHL_OBJEKTE: new ChartContent('anzahlObjekte', new UnitTypesXY(UnitType.NONE, UnitType.NONE)),
    KAUFPREIS_PRO_QM: new ChartContent('kaufpreisProQm', new UnitTypesXY(UnitType.EURO_PRO_QM, UnitType.NONE)),
    KAUFPREIS: new ChartContent('kaufpreis', new UnitTypesXY(UnitType.EURO, UnitType.NONE)),
    MARKTWERT: new ChartContent(
        'marktwert',
        new UnitTypesXY(UnitType.EURO, UnitType.NONE),
        new HistogramClassRange(10, true)
    ),
    MARKTWERT_PRO_QM: new ChartContent(
        'marktwertProQuadratmeter',
        new UnitTypesXY(UnitType.EURO_PRO_QM, UnitType.NONE),
        new HistogramClassRange(10, true)
    ),
    KAUFPREIS_X_FACH: new ChartContent('kaufpreisXfaches', new UnitTypesXY(UnitType.NONE, UnitType.NONE)),
    WNF: new ChartContent('wohnNutzflaeche', new UnitTypesXY(UnitType.QM, UnitType.NONE)),
    RENDITE_ANSATZ: new ChartContent('renditeAnsatz', new UnitTypesXY(UnitType.EURO, UnitType.NONE)),
    RENDITE_IST: new ChartContent('renditeIst', new UnitTypesXY(UnitType.EURO, UnitType.NONE)),
    MICROLAGE: new ChartContent('lage', new UnitTypesXY(UnitType.NONE, UnitType.NONE))
} as const;
export type ChartContentTypes = typeof ChartContentType[keyof typeof ChartContentType];

export const DefaultChartSettings: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: { enabled: true },
    hover: { mode: null },
    legend: {
        display: false,
        labels: {
            fontColor: 'rgb(255, 99, 132)'
        }
    },
    plugins: {
        datalabels: {
            color: 'white',
            formatter: (value) => `${value}%`
        },
        labels: {
            title: {
                font: {
                    weight: 'bold',
                    size: 13
                }
            }
        }
    }
};
