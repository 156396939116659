<div id="box">
    <!--Title-->
    <h3>
        {{ dialogData.title || dialogData.contentType + ' ' + action }}
    </h3>

    <!--Content-->
    <app-dialog-text
        *ngIf="dialogData.type === DialogType.Text"
        [text]="dialogData.text"
        class="text"
    ></app-dialog-text>
    <app-dialog-html
        *ngIf="dialogData.type === DialogType.Html"
        [text]="dialogData.text"
        class="text"
    ></app-dialog-html>
    <app-dialog-form
        *ngIf="dialogData.type === DialogType.Form"
        [data]="dialogData"
        (closeDialog)="close($event)"
        class="text"
    ></app-dialog-form>
    <app-dialog-confirm
        *ngIf="dialogData.type === DialogType.Confirm"
        [data]="dialogData"
        (closed)="close($event)"
        class="text"
    >
    </app-dialog-confirm>
</div>
