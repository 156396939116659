<div class="modal-header">
    <h3 class="modal-title">Löschen </h3>
    </div>
    <div class="modal-body">
      {{objekt}} {{titel}} wirklich löschen?
    </div>
    <div class="modal-footer hstack gap-2 justify-content-end mt-4">
      <button type="button" class="btn btn btn-light ms-auto" (click)="close()">Abbrechen</button>
      <button type="button" class="btn btn-danger" (click)="delete()">Löschen</button>
    </div>
