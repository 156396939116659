import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Appraisal, AppraisalCompareViewOptions } from '../../../models/Appraisal';
import { AppraisalCompareService } from '../../../services/appraisal-compare.service';
import { BreadcrumbDefault, BreadcrumbsService } from 'src/app/core/services/breadcrumbs.service';

@Component({
   selector: 'app-appraisal-compare',
   templateUrl: './appraisal-cmp-view.component.html',
   styleUrls: ['./appraisal-cmp-view.component.scss']
})
export class AppraisalCmpViewComponent implements OnInit, OnDestroy {
   columns: number[] = [0, 1, 2];
   currentSelection: Appraisal[];
   comparableAppraisals: Appraisal[];

   viewOptions$: Observable<AppraisalCompareViewOptions>;

   private selectionSub: Subscription;
   private cmpAppraisalSub: Subscription;

   constructor(
      private appraisalCmpService: AppraisalCompareService,
      private route: ActivatedRoute,
      private breadcrumbsService: BreadcrumbsService
   ) { }

   ngOnInit(): void {
      this.viewOptions$ = this.appraisalCmpService.currentSelection.pipe(
         map((appraisals: Appraisal[]) => ({
            fazit: appraisals.map((a) => a.fazit).some((fazit) => !!fazit),
            makrolage: appraisals.map((a) => a.lage?.makrolage).some((lage) => !!lage),
            mikrolage: appraisals.map((a) => a.lage?.mikrolage).some((lage) => !!lage),
            lageAllgemein: appraisals.map((a) => a.lage?.lageAllgemein).some((lage) => !!lage),
            immobilienmarkt: appraisals.map((a) => a.lage?.immobilienmarkt).some((lage) => !!lage),
            investmentmarkt: appraisals.map((a) => a.lage?.investmentmarkt).some((lage) => !!lage),
            vermietungsmarkt: appraisals.map((a) => a.lage?.vermietungsmarkt).some((lage) => !!lage),
            besonderheiten: appraisals.map((a) => a.besonderheiten).some((lage) => !!lage),
            nutzungsart: appraisals.some((a: Appraisal) => this.hasNutzungsartValues(a))
         }))
      );

      this.cmpAppraisalSub = this.appraisalCmpService.currentSelection.subscribe(
         (appraisals: Appraisal[]) => (this.currentSelection = appraisals)
      );

      this.cmpAppraisalSub = this.appraisalCmpService.comparableAppraisals.subscribe(
         (appraisals: Appraisal[]) => (this.comparableAppraisals = appraisals)
      );

      const appraisals = this.route.snapshot.data.appraisals || [];
      this.appraisalCmpService.setComparableAppraisals(appraisals);
      this.appraisalCmpService.initCurrentSelection(this.columns.length);

      this.breadcrumbsService.Apply([
         { ...BreadcrumbDefault.SearchList },
         { label: 'Vergleich Detail' }
      ]);
   }

   hasNutzungsartValues(a: Appraisal): boolean {
      return (
         !!a.wohnflaeche ||
         !!a.mieteIstWohnenDurchschnitt ||
         !!a.liegenschaftzinsWohnenDurchschnitt ||
         !!a.nutzflaecheBuero ||
         !!a.mieteIstBueroDurchschnitt ||
         !!a.liegenschaftzinsBueroDurchschnitt ||
         !!a.nutzflaecheHandel ||
         !!a.mieteIstHandelDurchschnitt ||
         !!a.liegenschaftzinsHandelDurchschnitt ||
         !!a.nutzflaecheSonstiges ||
         !!a.mieteIstSonstigesDurchschnitt ||
         !!a.liegenschaftzinsSonstigesDurchschnitt
      );
   }

   ngOnDestroy() {
      this.selectionSub?.unsubscribe();
      this.cmpAppraisalSub?.unsubscribe();
   }

   onIdChange(id: number, index: number) {
      this.appraisalCmpService.replaceCurrentSelectedByIndex(id, index);
   }
}
