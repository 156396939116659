import { Pipe, PipeTransform } from '@angular/core';
import { IOrtData } from '../interfaces/LocationData';
import { ILocation } from 'src/app/appraisal-textgenerator/interfaces/BrickTypesMacroLocation';

@Pipe({
   name: 'locationItem'
})

// location (german: Ort)
export class LocationPipe implements PipeTransform {

   transform(location: IOrtData | ILocation, ...args: unknown[]): string {
      return location?.ortsName
         ? location?.ortsteilName ? `${location?.ortsName} (${location?.ortsteilName})` : location?.ortsName
         : null;
   }

}
