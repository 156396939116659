export enum BauKostenSpanne {
      UnterenBereich = 1,
      MittlerenBereich = 2,
      OberenBereich = 3
};

export type BauKostenSpanneName = {
   [key in BauKostenSpanne]: string;
};

export const BauKostenSpanneNames: BauKostenSpanneName = {
   [BauKostenSpanne.UnterenBereich]: 'im unteren Bereich',
   [BauKostenSpanne.MittlerenBereich]: 'im mittleren Bereich',
   [BauKostenSpanne.OberenBereich]: 'im oberen Bereich'
};