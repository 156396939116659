<div class="row">
    <div class="col-md-4">
        <app-field label="Bebauungsplan">
            {{BebauungsPlanNames[data.bebauungsPlan]}}
        </app-field>
    </div>
</div>
<div class="row mt-4">
    <div class="col-md">
        <app-field label="Titel Bebauungsplan">
            {{data.bebauungsplanTitel}}
        </app-field>
    </div>
    <div class="col-md">
        <app-field label="Datum Bebauungsplan">
            {{ data.bebauungsplanDatum | date: 'dd.MM.yyyy'}}
        </app-field>
    </div>
    <div class="col-md">
        <app-field label="Festsetzungen">
            <p [innerHTML]="data.festsetzungen | newlineToHtml">
            </p>
        </app-field>
    </div>
</div>
<div class="row mt-4">
    <div class="col-md-4">
        <app-field label="Baugenehmigung">
            {{BaugenehmigungNames[data.baugenehmigung || '']}}
        </app-field>
    </div>
    <div class="col-md-4">
        <app-field label="Gebrauchsabnahmenachweis">
            {{GebrauchsAbnahmeNachweisNames[data.gebrauchsAbnahmeNachweis || ''] }}
        </app-field>
    </div>
</div>