import { Component, Input, OnInit } from '@angular/core';
import { Source } from 'src/app/appraisal-textgenerator/interfaces/BrickTypesMacroLocation';

@Component({
   selector: 'app-source-display-icon',
   templateUrl: './source-display-icon.component.html',
   styleUrls: ['./source-display-icon.component.scss']
})
export class SourceDisplayIconComponent implements OnInit {

   @Input()
   data: Source;

   @Input()
   additionalTooltipText: string;
   
   iconClass: string;
   readonly iconClassAvailable: string = 'fa-light fa-circle-info text-secondary';
   readonly iconClassNotAvailable: string = 'fa-solid fa-triangle-exclamation text-warning';

   constructor() { }

   ngOnInit(): void {
      this.iconClass = this.data.quelleVerfuegbarFlag ? this.iconClassAvailable : this.iconClassNotAvailable;
   }

}
