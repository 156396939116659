import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CollectionNutzungsart, CollectionObjektart } from '../../../../../collection-upload/models/Collection';
import { SnackbarService } from '../../../../../core/services/snackbar.service';
import { SelectOptionNumber } from '../../../radio-group/SelectOption';

@Component({
    selector: 'app-dialog-upload-item-nutzung',
    templateUrl: './dialog-upload-item-nutzung.component.html',
    styleUrls: ['./dialog-upload-item-nutzung.component.scss']
})
export class DialogUploadItemNutzungComponent implements OnInit {
    @Output() closed = new EventEmitter<any>();
    @Input() data;

    newItemsNutzung: CollectionNutzungsart[] = [];
    newItemsObjektart: CollectionObjektart[] = [];
    nutzungenSelectOption: SelectOptionNumber[] = [];
    objektartenSelectOption: SelectOptionNumber[] = [];
    nutzungsarten: CollectionNutzungsart[];
    objektarten: CollectionObjektart[];

    formGroup: FormGroup;

    constructor(private snackbarService: SnackbarService) {}

    submit(): void {
        this.closed.emit({
            nutzungsart: this.newItemsNutzung.map((nutzungsart) => nutzungsart.name),
            objektart: this.newItemsObjektart.map((objektart) => ({
                name: objektart.name,
                nutzungsart: { name: objektart.collectionNutzungsart.name, id: objektart.collectionNutzungsart.id }
            }))
        });
    }

    cancel(): void {
        this.closed.emit(null);
    }

    ngOnInit(): void {
        this.formGroup = new FormGroup({
            nutzungen: new FormControl(null, Validators.required),
            objektartenNutzung: new FormControl(null, Validators.required),
            objektartenObjektart: new FormControl(null, Validators.required)
        });
        //Setup Data
        this.nutzungsarten = this.data.content.nutzungObjektartContainer.nutzungsarten;
        this.objektarten = this.data.content.nutzungObjektartContainer.objektarten.map((o) => ({
            name: o.name,
            id: o.id,
            collectionNutzungsart: o.collectionNutzungsart
        }));
        //Setup SelectOptions
        this.nutzungenSelectOption = this.nutzungsarten.map((o) => ({
            value: o.id,
            label: o.name
        }));
        this.objektartenSelectOption = this.objektarten.map((o) => ({
            value: o.id,
            label: o.name
        }));
    }

    setSelect(event: number, name: string): void {
        this.formGroup.get(name).setValue({ id: event, label: name });
    }

    addNutzung(): void {
        const value = this.formGroup.get('nutzungen').value;
        const existsInList =
            this.newItemsNutzung.find((n) => n.name === value) || this.nutzungsarten.find((n) => n.name === value);
        if (value && !existsInList) {
            const tempId =
                Math.max(...this.nutzungenSelectOption.map((n) => n.value)) + 1 + this.newItemsNutzung.length;
            this.newItemsNutzung.push({
                name: value,
                id: tempId
            });
            this.nutzungenSelectOption.push({
                value: tempId,
                label: value
            });
            this.formGroup.get('nutzungen').setValue(null);
        } else {
            const message = existsInList ? 'Nutzungsart bereits vorhanden' : 'Bitte alle Felder ausfüllen';
            this.snackbarService.showError(message);
        }
    }

    addObjektart(): void {
        const valueNutzung = this.formGroup.get('objektartenNutzung').value;
        const valueObjektart = this.formGroup.get('objektartenObjektart').value;
        const existsInList =
            this.newItemsObjektart.find((n) => n.name === valueObjektart) ||
            this.objektarten.find((n) => n.name === valueObjektart);
        const nutzungName = valueNutzung
            ? this.nutzungsarten.find((n) => n.id === valueNutzung.id) ||
              this.newItemsNutzung.find((n) => n.id === valueNutzung.id)
            : null;

        if (nutzungName && valueObjektart && existsInList === undefined) {
            this.newItemsObjektart.push({
                id: valueObjektart.id,
                name: valueObjektart,
                collectionNutzungsart: { id: null, name: nutzungName.name }
            });
            this.formGroup.get('objektartenObjektart').setValue(null);
        } else {
            const message = existsInList ? 'Objektart bereits vorhanden' : 'Bitte alle Felder ausfüllen';
            this.snackbarService.showError(message);
        }
    }

    removeItem(index: number, type: string): void {
        if (type === 'nutzung') {
            const nutzungToRemove = this.newItemsNutzung[index];
            //Remove nutzungen item
            this.nutzungenSelectOption = this.nutzungenSelectOption.filter((n) => n.label != nutzungToRemove.name);
            this.newItemsNutzung.splice(index, 1);
            //Remove objektart items if connected to removed nutzung
            this.newItemsObjektart = this.newItemsObjektart.filter(
                (o) => o.collectionNutzungsart.name != nutzungToRemove.name
            );
        } else if (type === 'objektart') {
            this.newItemsObjektart.splice(index, 1);
        }
    }
}
