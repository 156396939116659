export enum WertbestimmendesVerfahren {
   KeineAngabe = 0,
   Bodenwert = 1,
   Sachwert = 2,
   Ertragswert = 3,
   Vergleichswert = 4,
   Standard = 5,
   Zustandswert = 6
};

export type WertbestimmendesVerfahrenName = {
   [key in WertbestimmendesVerfahren]: string;
};

export const WertbestimmendesVerfahrenNames: WertbestimmendesVerfahrenName = {
   [WertbestimmendesVerfahren.KeineAngabe]: 'Keine Angabe',
   [WertbestimmendesVerfahren.Bodenwert]: 'Bodenwert',
   [WertbestimmendesVerfahren.Sachwert]: 'Sachwert',
   [WertbestimmendesVerfahren.Ertragswert]: 'Ertragswert',
   [WertbestimmendesVerfahren.Vergleichswert]: 'Vergleichswert',
   [WertbestimmendesVerfahren.Standard]: 'Standard',
   [WertbestimmendesVerfahren.Zustandswert]: 'Zustandswert'
};