<div id="table-container">
    <div class="tab-row">
        <p *ngFor="let tab of tabs" (click)="selectTab(tab)">
            <button [class]="currentActiveTab === tab ? 'btn-tab-active' : 'btn-tab-inactive'">
                {{ tab.title }}
            </button>
        </p>
    </div>
    <ng-content></ng-content>
</div>
