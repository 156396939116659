import { DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { BauKostenSpanne, BauKostenSpanneNames } from 'src/app/appraisal-textgenerator/enums/BauKostenSpanne';
import { PkwStellplatzArt, PkwStellplatzArtNames } from 'src/app/appraisal-textgenerator/enums/PkwStellplatzArt';
import { UmsatzSteuerArt, UmsatzSteuerArtNames } from 'src/app/appraisal-textgenerator/enums/UmsatzSteuerArt';
import { VermietungsSituation, VermietungsSituationNames } from 'src/app/appraisal-textgenerator/enums/VermietungsSituation';
import { AppraisalTextObjectCategory, AppraisalTextObjectSubType, AppraisalTextObjectType, BrickProductionCostsObject, ExcerptConstructionCosts, ExcerptConstructionPriceIndices, ReferenceUnit } from 'src/app/appraisal-textgenerator/interfaces/BrickTypesProductionCosts';
import { BrickNavigationService } from 'src/app/appraisal-textgenerator/services/brick-navigation.service';
import { BrickService } from 'src/app/appraisal-textgenerator/services/brick.service';
import { FormService } from 'src/app/appraisal-textgenerator/services/form.service';
import { BreadcrumbDefault, BreadcrumbsService } from 'src/app/core/services/breadcrumbs.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';

@Component({
   selector: 'app-brick-production-costs-object-edit',
   templateUrl: './brick-production-costs-object-edit.component.html',
   styleUrls: ['./brick-production-costs-object-edit.component.scss']
})
export class BrickProductionCostsObjectEditComponent implements OnInit {
   public umsatzSteuerArtNames = UmsatzSteuerArtNames;
   umsatzSteuerArt = UmsatzSteuerArt;
   public umsatzSteuerArtArray = Object.values(UmsatzSteuerArt).filter((v) => !isNaN(Number(v)));

   public pkwStellplatzArtNames = PkwStellplatzArtNames;
   public pkwStellplatzArtArray = Object.values(PkwStellplatzArt).filter((v) => !isNaN(Number(v)));

   public vermietungsSituationNames = VermietungsSituationNames;
   public vermietungsSituationArray = Object.values(VermietungsSituation).filter((v) => !isNaN(Number(v)));

   public bauKostenSpanneNames = BauKostenSpanneNames;
   public bauKostenSpanneArray = Object.values(BauKostenSpanne).filter((v) => !isNaN(Number(v)));

   excerptConstructionPriceIndices: ExcerptConstructionPriceIndices;
   excerptConstructionCosts: ExcerptConstructionCosts;
   gutachtenTextObjektKategories: AppraisalTextObjectCategory[];
   objectSubTypesFiltered: AppraisalTextObjectSubType[];
   appraisalTextObjectSubTypes: AppraisalTextObjectSubType[];
   gutachtenTextObjectTypes: AppraisalTextObjectType[];
   data = new BrickProductionCostsObject();
   referenceUnits: ReferenceUnit[];
   appraisalTextId: number;
   UmsatzSteuerArt = UmsatzSteuerArt;

   form = this.fb.group({
      gutachtenTextObjektArt: [{ value: null, disabled: false }, Validators.required],
      gutachtenTextObjektUnterArt: [{ value: null, disabled: false }, Validators.required],
      gutachtenTextObjektKategorie: [{ value: null, disabled: true }, Validators.required],
      bpiBeiVeroeffentlichung: [{ value: null, disabled: true }, Validators.required],
      umsatzSteuerArt: [{ value: null, disabled: false }, Validators.required],
      bezugsEinheit: [{ value: this.data.bezugsEinheit, disabled: false }, Validators.required],
      aussenStellplatzVorhanden: [{ value: null, disabled: false }, Validators.required],
      pkwStellplatzArt: [{ value: null, disabled: false }, Validators.required],
      kostenProStellplatz: [{ value: null, disabled: false }, Validators.required],
      vermietungsSituation: [{ value: null, disabled: true }, Validators.required],
      einordnungBaukostenSpanneWohnen: [{ value: null, disabled: true }, Validators.required],
      einordnungBaukostenSpanneGewerbe: [{ value: null, disabled: true }, Validators.required]
   });

   constructor(
      private activatedRoute: ActivatedRoute,
      private fb: FormBuilder,
      private formService: FormService,
      private brickService: BrickService,
      private brickNavigationService: BrickNavigationService,
      private decimalPipe: DecimalPipe,
      private snackbarService: SnackbarService,
      private breadcrumbsService: BreadcrumbsService
   ) { }

   ngOnInit(): void {
      this.initFormData();
      this.loadAllObjectTypes();
      this.loadAllSubTypesArts();
      this.loadAllReferenceUnits();
      this.formConditions();
      this.form.controls.umsatzSteuerArt.setValue(this.data.umsatzSteuerArt)

      this.breadcrumbsService.Apply([
         { ...BreadcrumbDefault.TextgeneratorList },
         { ...this.brickNavigationService.getOverviewBreadcrumb(this.appraisalTextId, this.getUrlForNavigation()) },
         { label: 'Herstellungskosten: Objekt bearbeiten' }
      ]);
   }

   formConditions() {
      this.form.controls.gutachtenTextObjektArt.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
         if (this.appraisalTextObjectSubTypes) {
            this.formService.handleCondition(true, this.form.controls.gutachtenTextObjektUnterArt, true);
            this.objectSubTypesFiltered = this.appraisalTextObjectSubTypes.filter(
               (objectSubType) => objectSubType.gutachtenTextObjektArt.id == value.id
            );
            this.form.controls.gutachtenTextObjektUnterArt.setValue(null); // reset
         }

      });

      this.form.controls.gutachtenTextObjektUnterArt.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
         const category: AppraisalTextObjectCategory = value?.gutachtenTextObjektKategorie;
         const categoryName: string = category?.name;
         this.form.controls.gutachtenTextObjektKategorie.setValue(categoryName);

         const objectSubTypeId: number = value?.id;
         this.loadExcerptConstructionPriceIndices(category?.id);

         const categoryId: number = category?.id;
         const referenceUnitId: number = this.form.controls.bezugsEinheit.value?.id;
         this.loadExcerptConstructionCosts(objectSubTypeId, categoryId, referenceUnitId);
      });

      this.form.controls.umsatzSteuerArt.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
         this.formService.handleCondition(
            value === UmsatzSteuerArt.UStBefreit,
            this.form.controls.vermietungsSituation,
            true
         );
         this.formService.handleCondition(
            value !== UmsatzSteuerArt.ZzglUSt,
            this.form.controls.einordnungBaukostenSpanneWohnen,
            true
         );

      });

      this.form.controls.vermietungsSituation.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
         this.formService.handleCondition(
            value !== VermietungsSituation.IstEigengenutzt,
            this.form.controls.einordnungBaukostenSpanneGewerbe,
            true
         );
      });
      this.form.controls.bezugsEinheit.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
         const objectSubType: AppraisalTextObjectSubType = this.form.controls.gutachtenTextObjektUnterArt.value;
         const objectSubTypeId: number = objectSubType?.id;
         const categoryId: number = objectSubType?.gutachtenTextObjektKategorie?.id;
         const referenceUnitId: number = value?.id;
         this.loadExcerptConstructionCosts(objectSubTypeId, categoryId, referenceUnitId);
         if (value.name == "Nutzeinheit") {
            this.form.controls.bezugsEinheit.setErrors({ 'failed data': true }) // ToDo: text to be discussed
         }
      });

      this.form.controls.aussenStellplatzVorhanden.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
         this.formService.handleCondition(value, this.form.controls.pkwStellplatzArt, true);
         this.formService.handleCondition(value, this.form.controls.kostenProStellplatz, true);
      });
   }

   loadExcerptConstructionPriceIndices(objectCategoryId: number = null): void {
      this.brickService
         .loadExcerptConstructionPriceIndices(this.appraisalTextId, objectCategoryId)
         .pipe(map((res) => res.data))
         .subscribe((data) => {
            this.excerptConstructionPriceIndices = data as ExcerptConstructionPriceIndices;
         });
   }

   loadExcerptConstructionCosts(
      objectSubTypeId: number = null,
      objectCategoryId: number = null,
      referenceUnitId: number = null
   ): void {
      this.brickService
         .loadExcerptConstructionCosts(this.appraisalTextId, objectSubTypeId, objectCategoryId, referenceUnitId)
         .pipe(map((res) => res.data))
         .subscribe((data) => {
            this.excerptConstructionCosts = data as ExcerptConstructionCosts;
         });
   }

   initFormData() {
      this.appraisalTextId = this.activatedRoute.snapshot.params['appraisalTextId'];
      this.data = this.activatedRoute.snapshot.data['objectData']; // in object-edit we get brick object data from the resolver

      this.excerptConstructionPriceIndices = { ...this.excerptConstructionPriceIndices, ...this.data };
      this.excerptConstructionCosts = { ...this.excerptConstructionCosts, ...this.data };

      this.form.controls.umsatzSteuerArt.setValue(this.data.umsatzSteuerArt)
   }

   initFormControl_bezugsEinheit() {
      this.form.controls.bezugsEinheit.setValue(
         this.referenceUnits.find((value) => value.id === this.data.bezugsEinheit.id)
      );
   }

   initFormControl_gutachtenTextObjektArt() {
      this.form.controls.gutachtenTextObjektArt.setValue(
         this.gutachtenTextObjectTypes.find(
            (value) => value.id === this.data.gutachtenTextObjektUnterArt.gutachtenTextObjektArt?.id
         )
      );
   }

   initFormControl_gutachtenTextObjektUnterArt() {

      this.form.controls.gutachtenTextObjektUnterArt.setValue(
         this.objectSubTypesFiltered.find((value) => value.id === this.data.gutachtenTextObjektUnterArt.id)
      );
      this.objectSubTypesFiltered = this.appraisalTextObjectSubTypes.filter(
         (objectSubType) =>
            objectSubType.gutachtenTextObjektArt.id ==
            this.data.gutachtenTextObjektUnterArt.gutachtenTextObjektArt.id
      );
   }

   displayNumber(num: number, nullText: string = null): string {
      if (num) {
         return this.decimalPipe.transform(num, null, 'de-DE');
      }

      return nullText;
   }

   loadAllObjectTypes(): void {
      this.brickService
         .loadAllObjectTypes()
         .pipe(map((res) => res.data))
         .subscribe((data) => {
            this.gutachtenTextObjectTypes = data as AppraisalTextObjectType[];
            this.initFormControl_gutachtenTextObjektArt();
         });
   }

   loadAllSubTypesArts(): void {
      this.brickService
         .loadAllObjectSubTypes()
         .pipe(map((res) => res.data))
         .subscribe((data) => {
            this.appraisalTextObjectSubTypes = data as AppraisalTextObjectSubType[];
            this.objectSubTypesFiltered = this.appraisalTextObjectSubTypes;
            this.initFormControl_gutachtenTextObjektUnterArt();
         });
   }

   loadAllReferenceUnits(): void {
      this.brickService
         .loadAllReferenceUnits()
         .pipe(map((res) => res.data))
         .subscribe((data) => {
            this.referenceUnits = data as ReferenceUnit[];
            this.initFormControl_bezugsEinheit();
         });
   }

   getUrlForNavigation() {
      // example URL:
      // http://localhost:4200/textgenerator/list/2/brick-production-costs/object-update/1 -> segments to remove: 2

      // remove segments from URL to make BrickNavigationService have the brick relevant URL segement as last
      const url = this.activatedRoute.parent.snapshot.url.slice(0, -2);
      return url;
   }

   cancel(): void {
      this.brickNavigationService.navigateToOverview(this.appraisalTextId, this.getUrlForNavigation());
   }

   save(): void {
      if (this.form.valid) {
         let instance: BrickProductionCostsObject = new BrickProductionCostsObject();
         // save the new data from the form
         instance = { ...this.data, ...this.form.value };

         this.brickService.updateProductionCostsObject(this.appraisalTextId, instance).subscribe({
            next: () => this.snackbarService.showSuccess('Speichern erfolgreich')
            // error: (err) => (this.errorMessage = err)
         });
      }
   }

   saveAndExit(): void {
      if (this.form.valid) {
         this.save();
         this.brickNavigationService.navigateToOverview(this.appraisalTextId, this.getUrlForNavigation(), true); // with reload
      }
   }
}
