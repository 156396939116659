<div id="table-container">
    <form [formGroup]="formGroup" id="form">
        <input
            class="form-control create"
            type="text"
            placeholder="{{ placeholder }}"
            formControlName="formControl"
            autocomplete="off"
            [matAutocomplete]="auto"
        />
        <button
            *ngIf="formControl.value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="inputChange('')"
            class="btnClearSelected"
        >
            <mat-icon class="icnClose">close</mat-icon>
        </button>
        <mat-autocomplete
            #auto="matAutocomplete"
            appearance="outline"
            (optionSelected)="inputChange($event.option.value)"
        >
            <div class="container-autocomplete">
                <mat-option *ngFor="let dropvalue of filteredDropdown | async" [value]="dropvalue">
                    {{ dropvalue }}
                </mat-option>
            </div>
        </mat-autocomplete>
    </form>
</div>
