import { Component, Input } from '@angular/core';
import { Besonderheiten } from '../../../../interfaces/AppraisalFilterTypes';

@Component({
    selector: 'app-appraisal-besonderheiten',
    templateUrl: './appraisal-besonderheiten.component.html',
    styleUrls: ['./appraisal-besonderheiten.component.scss', '../appraisal-standards.scss']
})
export class AppraisalBesonderheitenComponent {
    @Input()
    set besonderheiten(besonderheiten: Besonderheiten[]) {
        this.besonderheitenErbbaurecht = (besonderheiten || []).filter(
            (b) => b.bezeichnung === 'Erbbaurecht vorhanden'
        );
    }

    besonderheitenErbbaurecht: Besonderheiten[];
}
