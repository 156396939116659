export enum PkwStellplatzArt {
   Stellplatz = 1,
   Garage = 2,
   TiefgaragenStellplatz = 3
};

export type PkwStellplatzArtName = {
   [key in PkwStellplatzArt]: string;
};

export const PkwStellplatzArtNames: PkwStellplatzArtName = {
   [PkwStellplatzArt.Stellplatz]: 'Pkw-Außenstellplätze',
   [PkwStellplatzArt.Garage]: 'Garagen',
   [PkwStellplatzArt.TiefgaragenStellplatz]: 'Tiefgaragenstellplätze'
};