<div class="div-content">
    <div *ngIf="besonderheitenErbbaurecht" class="div-content-bs">
        <span *ngIf="!besonderheitenErbbaurecht?.length" id="no-data">Keine Daten vorhanden</span>

        <!-- temporary: only show Erbbaurecht --->
        <ng-container *ngFor="let besonderheit of besonderheitenErbbaurecht">
            <b>{{ besonderheit.bezeichnung }}</b>
            <span>{{ besonderheit.value | booleanToLanguage | placeholder: { placeHolderText: 'Unbekannt' } }}</span>
        </ng-container>
        <!-- temp end --->

        <!-- under construction
        <ng-container *ngFor="let besonderheit of appraisal-detail-boxes.besonderheiten">
          <b>{{besonderheit.bezeichnung}}</b>
          <span>{{ besonderheit.value | booleanToLanguage | placeholder: {placeHolderText: 'Unbekannt'} }}</span>
        </ng-container>
        -->
    </div>
</div>
