import { Component, Input, OnInit } from '@angular/core';

@Component({
   selector: 'app-brick-notes',
   templateUrl: './brick-notes.component.html',
   styleUrls: ['./brick-notes.component.scss']
})
export class BrickNotesComponent implements OnInit {

   @Input()
   notes: string[]

   constructor() { }

   ngOnInit(): void {
   }

}
