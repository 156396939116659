import { Component, Input } from '@angular/core';

@Component({
   selector: 'app-info-display-icon',
   templateUrl: './info-display-icon.component.html',
   styleUrls: ['./info-display-icon.component.scss']
})
export class InfoDisplayIconComponent {
   @Input()
   iconClass: string = 'fa-light fa-circle-info text-secondary';

   @Input()
   tooltip: string;
}