import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    private isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);

    get isLoading$(): Observable<boolean> {
        return this.isLoading.asObservable();
    }

    setLoading(val: boolean): void {
        if (this.isLoading.value === val) {
            return;
        }

        this.isLoading.next(val);
    }
}
