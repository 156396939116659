<div class="hstack gap-2 justify-content-end mb-2">
   <button class="btn btn-light" (click)="accordion.closeAll()" matTooltip="alles zuklappen"><i class="fa-solid fa-down-left-and-up-right-to-center"></i></button>
   <button class="btn btn-light" (click)="accordion.openAll()" matTooltip="alles aufklappen"><i class="fa-light fa-arrows-maximize"></i></button>
</div>

<mat-accordion multi>

   <!-- Entfernung zu Städten -->
   <mat-expansion-panel [expanded]="true" class="shadow-sm">
      <mat-expansion-panel-header class="bg-light">
         <mat-panel-title>Entfernung zu Städten</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="table-responsive mt-2">
         <table class="table table-sm table-hover text-center">
            <thead>
               <tr>
                  <th></th>
                  <th>nächstgelegene RIWIS-Stadt</th>
                  <th>nächstgelegenes Oberzentrum</th>
                  <th>nächstgelegenes Mittelzentrum</th>
               </tr>
            </thead>
            <tbody>
               <tr>
                  <td class="text-start">Name</td>
                  <td>{{data.kennzahlen.entfernungRiwisStadtName.wert | nullText:'n/a'}}</td>
                  <td>{{data.kennzahlen.entfernungOberzentrumName.wert | nullText:'n/a'}}</td>
                  <td>{{data.kennzahlen.entfernungMittelzentrumName.wert | nullText:'n/a'}}</td>
               </tr>
               <tr>
                  <td class="text-start">Entfernung (Luftlinie)</td>
                  <td>{{data.kennzahlen.entfernungRiwisStadtLuftlinie.wert | number:'':'de-DE' | nullText:'n/a'}}</td>
                  <td>{{data.kennzahlen.entfernungOberzentrumLuftlinie.wert | number:'':'de-DE' | nullText:'n/a'}}</td>
                  <td>{{data.kennzahlen.entfernungMittelzentrumLuftlinie.wert | number:'':'de-DE' | nullText:'n/a'}}</td>
               </tr>
            </tbody>
         </table>
      </div>
      <!-- <div class="row mt-2">
         <app-field label="nächstgelegene RIWIS-Stadt" class="col-md-2">
            Suhl, Stadt            
         </app-field>
         <app-field label="Entfernung (Luftlinie)" class="col-md-2">
            11           
         </app-field>
      </div> -->
   </mat-expansion-panel>

   <!-- Demografie -->
   <mat-expansion-panel [expanded]="true" class="shadow-sm">
      <mat-expansion-panel-header class="bg-light">
         <mat-panel-title>Demografie</mat-panel-title>
      </mat-expansion-panel-header>

      <div class="table-responsive mt-2">
         <table class="table table-sm table-hover">
            <thead>
               <tr>
                  <th></th>
                  <th></th>
                  <th colspan="3" class="text-center">Gemeinde</th>
                  <th colspan="3" class="text-center">Gemeindeverband</th>
                  <th colspan="3" class="text-center">Kreis</th>
                  <th colspan="3" class="text-center">Deutschland</th>
               </tr>
            </thead>
            <tbody class="text-center">
               <tr>
                  <td class="text-start">Bevölkerungszahl ({{data.kennzahlen.bevoelkerungGemeinde.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.bevoelkerungGemeinde.wert | number:'':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.bevoelkerungGemeinde.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.bevoelkerungGemeinde.quelle"></app-source-display-icon></td>
                  <td></td>
                  <td></td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.bevoelkerungKreis.wert | number:'':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.bevoelkerungKreis.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.bevoelkerungKreis.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.bevoelkerungDeutschland.wert | number:'':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.bevoelkerungDeutschland.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td><app-source-display-icon [data]="data.kennzahlen.bevoelkerungDeutschland.quelle"></app-source-display-icon></td>
               </tr>
               <tr>
                  <td class="text-start">Bevölkerungsentwicklung ({{data.kennzahlen.bevoelkerungsEntwicklungJahrVon}}-{{data.kennzahlen.bevoelkerungsEntwicklungJahrAktuell}})</td>
                  <td class="border-end">%</td>
                  <td>{{data.kennzahlen.bevoelkerungsEntwicklungGemeinde.wert | number:'1.1-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.bevoelkerungsEntwicklungJahrVon}}-{{data.kennzahlen.bevoelkerungsEntwicklungJahrAktuell}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.bevoelkerungGemeinde.quelle"></app-source-display-icon></td>
                  <td></td>
                  <td></td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.bevoelkerungsEntwicklungKreis.wert | number:'1.1-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.bevoelkerungsEntwicklungJahrVon}}-{{data.kennzahlen.bevoelkerungsEntwicklungJahrAktuell}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.bevoelkerungKreis.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.bevoelkerungsEntwicklungDeutschland.wert | number:'1.1-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.bevoelkerungsEntwicklungJahrVon}}-{{data.kennzahlen.bevoelkerungsEntwicklungJahrAktuell}})</td>
                  <td><app-source-display-icon [data]="data.kennzahlen.bevoelkerungDeutschland.quelle" additionalTooltipText="Orientierung Bevölkerungsentwicklung am Bundesdurchschnitt"></app-source-display-icon></td>
               </tr>
               <tr>
                  <td class="text-start">Bevölkerungsprognose (2017-2040)</td>
                  <td class="border-end">%</td>
                  <td></td>
                  <td></td>
                  <td class="border-end"></td>
                  <td></td>
                  <td></td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.bevoelkerungsPrognoseKreis.wert | number:'1.0-0':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.bevoelkerungsPrognoseKreis.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.bevoelkerungsPrognoseKreis.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.bevoelkerungsPrognoseDeutschland.wert | number:'1.0-0':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.bevoelkerungsPrognoseDeutschland.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td><app-source-display-icon [data]="data.kennzahlen.bevoelkerungsPrognoseDeutschland.quelle"></app-source-display-icon></td>
               </tr>
               <tr>
                  <td class="text-start">Natürlicher Saldo</td>
                  <td class="border-end">je 1.000 EW</td>
                  <td>{{data.kennzahlen.natuerlicherSaldoGemeinde.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.natuerlicherSaldoGemeinde.quelle.quelleDatenStand| date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.natuerlicherSaldoGemeinde.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.natuerlicherSaldoGemeindeVerband.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.natuerlicherSaldoGemeindeVerband.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.natuerlicherSaldoGemeindeVerband.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.natuerlicherSaldoKreis.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.natuerlicherSaldoKreis.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.natuerlicherSaldoKreis.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.natuerlicherSaldoDeutschland.wert | number:'1.0-0':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.natuerlicherSaldoDeutschland.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td><app-source-display-icon [data]="data.kennzahlen.natuerlicherSaldoDeutschland.quelle"></app-source-display-icon></td>
               </tr>
               <tr>
                  <td class="text-start">Gesamtwanderungssaldo</td>
                  <td class="border-end">je 1.000 EW</td>
                  <td>{{data.kennzahlen.gesamtwanderungssaldoGemeinde.wert | number:'1.1-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.gesamtwanderungssaldoGemeinde.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.gesamtwanderungssaldoGemeinde.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.gesamtwanderungssaldoGemeindeVerband.wert | number:'1.1-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.gesamtwanderungssaldoGemeindeVerband.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.gesamtwanderungssaldoGemeindeVerband.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.gesamtwanderungssaldoKreis.wert | number:'1.1-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.gesamtwanderungssaldoKreis.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.gesamtwanderungssaldoKreis.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.gesamtwanderungssaldoDeutschland.wert | number:'1.1-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.gesamtwanderungssaldoDeutschland.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td><app-source-display-icon [data]="data.kennzahlen.gesamtwanderungssaldoDeutschland.quelle"></app-source-display-icon></td>
               </tr>
               <tr>
                  <td class="text-start">Durchschnittsalter der Bevölkerung</td>
                  <td class="border-end">Jahre</td>
                  <td>{{data.kennzahlen.durchschnittsalterderBevoelkerungGemeinde.wert | number:'1.1-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.durchschnittsalterderBevoelkerungGemeinde.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.durchschnittsalterderBevoelkerungGemeinde.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.durchschnittsalterderBevoelkerungGemeindeVerband.wert | number:'1.1-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.durchschnittsalterderBevoelkerungGemeindeVerband.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.durchschnittsalterderBevoelkerungGemeindeVerband.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.durchschnittsalterderBevoelkerungKreis.wert | number:'1.1-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.durchschnittsalterderBevoelkerungKreis.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.durchschnittsalterderBevoelkerungKreis.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.durchschnittsalterderBevoelkerungDeutschland.wert | number:'1.1-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.durchschnittsalterderBevoelkerungDeutschland.quelle.quelleDatenStand| date:'yyyy'}})</td>
                  <td><app-source-display-icon [data]="data.kennzahlen.durchschnittsalterderBevoelkerungDeutschland.quelle"></app-source-display-icon></td>
               </tr>
               <tr>
                  <td class="text-start">Grad der Verstädterung</td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.gradDerVerstaedterungGemeinde.wert | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.gradDerVerstaedterungGemeinde.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.gradDerVerstaedterungGemeinde.quelle"></app-source-display-icon></td>
                  <td></td>
                  <td></td>
                  <td class="border-end"></td>
                  <td></td>
                  <td></td>
                  <td class="border-end"></td>
                  <td></td>
                  <td></td>
                  <td></td>
               </tr>
               <tr>
                  <td class="text-start">Siedlungsdichte</td>
                  <td class="border-end">EW/km²</td>
                  <td>{{data.kennzahlen.siedlungsdichteGemeinde.wert | number:'1.1-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.siedlungsdichteGemeinde.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.siedlungsdichteGemeinde.quelle"></app-source-display-icon></td>
                  <td></td>
                  <td></td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.siedlungsdichteKreis.wert | number:'1.1-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.siedlungsdichteKreis.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.siedlungsdichteKreis.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.siedlungsdichteDeutschland.wert | number:'1.1-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.siedlungsdichteDeutschland.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td><app-source-display-icon [data]="data.kennzahlen.siedlungsdichteDeutschland.quelle"></app-source-display-icon></td>
               </tr>
            </tbody>
         </table>
      </div>

   </mat-expansion-panel>

   <!-- Landesplanungsrechtliche Bedeutung -->
   <mat-expansion-panel [expanded]="true" class="shadow-sm">
      <mat-expansion-panel-header class="bg-light">
         <mat-panel-title>Landesplanungsrechtliche Bedeutung</mat-panel-title>
      </mat-expansion-panel-header>

      <div class="table-responsive mt-2">
         <table class="table table-sm table-hover">
            <thead>
               <tr>
                  <th></th>
                  <th></th>
                  <th colspan="12" class="text-center">Gemeinde</th>
               </tr>
            </thead>
            <tbody class="text-center">
               <tr>
                  <td class="text-start">Zentralörtlicher Status (differenziert)</td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.zentraloertlicherStatusDifferenziertGemeinde.wert | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.zentraloertlicherStatusDifferenziertGemeinde.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td><app-source-display-icon [data]="data.kennzahlen.zentraloertlicherStatusDifferenziertGemeinde.quelle"></app-source-display-icon></td>
               </tr>
               <tr>
                  <td class="text-start">RegioStaR 17 - Regionalstatistischer Raumtyp</td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.regioStaR17RegionalstatistischerRaumtypGemeinde.wert | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.regioStaR17RegionalstatistischerRaumtypGemeinde.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td><app-source-display-icon [data]="data.kennzahlen.regioStaR17RegionalstatistischerRaumtypGemeinde.quelle"></app-source-display-icon></td>
            </tbody>
         </table>
      </div>

   </mat-expansion-panel>

   <!-- Prognos-Ranking -->
   <mat-expansion-panel [expanded]="true" class="shadow-sm">
      <mat-expansion-panel-header class="bg-light">
         <mat-panel-title>Prognos-Ranking</mat-panel-title>
      </mat-expansion-panel-header>

      <div class="table-responsive mt-2">
         <table class="table table-sm table-hover">
            <thead>
               <tr>
                  <th></th>
                  <th></th>
                  <th colspan="3" class="text-center">Gemeinde</th>
                  <th colspan="3" class="text-center">Gemeindeverband</th>
                  <th colspan="3" class="text-center">Kreis</th>
                  <th colspan="3" class="text-center">Deutschland</th>
               </tr>
            </thead>
            <tbody class="text-center">
               <tr>
                  <td class="text-start">Prognos - Zukunftschancen - Klasse</td>
                  <td class="border-end">
                     Klasse
                     <i class="fa-light fa-circle-info text-secondary" matTooltip="Für die Berechnung des Zukunftsatlas-Rankings werden insgesamt 29 makro- und sozioökonomische Indikatoren herangezogen, die die Zukunftsaussichten der Regionen maßgeblich beeinflussen. Dazu gehören zum Beispiel die Bevölkerungsentwicklung, die Schulabbrecherquote und die Kaufkraft. Zu jedem Indikator liegen Daten für alle 400 Kreise und kreisfreien Städte in Deutschland vor. Diese Daten fließen in die Berechnung eines Indexwertes ein: des Zukunftsindex. Dieser macht die Zukunftsaussichten aller Regionen vergleichbar.
                     Für eine bessere Übersicht auf der Deutschlandkarte werden die Ränge in acht Zukunftsklassen eingeteilt: Klasse 1 mit „besten Chancen“, Klasse 2 mit „sehr hohen Chancen“, Klasse 3 mit
                     „hohen Chancen“ und Klasse 4 mit „leichten Chancen“. In den Klassen 1 bis 4 dominieren die Zukunftschancen. Klasse 5 zeichnet sich durch einen ausgeglichenen Mix an Zukunftschancen und -risiken aus („ausgeglichene Chancen/Risiken“). In den Klassen 6 bis 8 überwiegen die Zukunftsrisiken (Klasse 6 mit „leichten Risiken“, Klasse 7 mit „hohen Risiken“, Klasse 8 mit „sehr hohen Risiken“). Die Klassen sind jeweils farbig gekennzeichnet – von Rot für Klasse 1 bis Dunkelblau für Klasse 8."></i>
                  </td>
                  <td colspan="3" class="border-end"></td>
                  <td colspan="3" class="border-end"></td>
                  <td>{{data.kennzahlen.zukunftschancenKlasseKreis.wert | number:'1.0-0':'de-DE' | nullText: 'n/a'}} / {{data.kennzahlen.zukunftschancenKlasseKreisMax | number:'1.0-0':'de-DE' | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.zukunftschancenKlasseKreis.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.zukunftschancenKlasseKreis.quelle"></app-source-display-icon></td>
                  <td colspan="3"></td>
               </tr>
               <tr>
                  <td class="text-start">Prognos - Zukunftschancen - Rang</td>
                  <td class="border-end">Rang</td>
                  <td colspan="3" class="border-end"></td>
                  <td colspan="3" class="border-end"></td>
                  <td>{{data.kennzahlen.zukunftschancenRangKreis.wert | number:'1.0-0':'de-DE' | nullText: 'n/a'}} / {{data.kennzahlen.kreisAnzahl | number:'1.0-0':'de-DE' | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.zukunftschancenRangKreis.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.zukunftschancenRangKreis.quelle"></app-source-display-icon></td>
                  <td colspan="3"></td>
               </tr>
               <tr>
                  <td class="text-start">Prognos - Stärkerang</td>
                  <td class="border-end">
                     Rang
                     <i class="fa-light fa-circle-info text-secondary" matTooltip="Die Stärke-Indikatoren geben Auskunft über den Ist-Zustand (Standortstärke) einer Region. Zum Beispiel: Wie hoch ist die Arbeitslosigkeit? Wie viele Patente werden in der Region angemeldet?"></i>
                  </td>
                  <td colspan="3" class="border-end"></td>
                  <td colspan="3" class="border-end"></td>
                  <td>{{data.kennzahlen.staerkerangKreis.wert | number:'1.0-0':'de-DE' | nullText: 'n/a'}} / {{data.kennzahlen.kreisAnzahl | number:'1.0-0':'de-DE' | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.staerkerangKreis.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.staerkerangKreis.quelle"></app-source-display-icon></td>
                  <td colspan="3"></td>
               </tr>
               <tr>
                  <td class="text-start">Prognos - Dynamikrang</td>
                  <td class="border-end">
                     Rang
                     <i class="fa-light fa-circle-info text-secondary" matTooltip="Die Dynamik-Indikatoren bilden ab, wie sich die Region im Zeitverlauf entwickelt hat. Beispielsweise: Wie hat sich die Bevölkerungszahl in der Region entwickelt? Konnte die Region in den letzten Jahren Beschäftigung aufbauen?"></i>
                  </td>
                  <td colspan="3" class="border-end"></td>
                  <td colspan="3" class="border-end"></td>
                  <td>{{data.kennzahlen.dynamikrangKreis.wert | number:'1.0-0':'de-DE' | nullText: 'n/a'}} / {{data.kennzahlen.kreisAnzahl | number:'1.0-0':'de-DE' | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.dynamikrangKreis.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.dynamikrangKreis.quelle"></app-source-display-icon></td>
                  <td colspan="3"></td>
               </tr>
               <tr>
                  <td class="text-start">Bertelsmann - Demografietyp</td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.demografietypGemeinde.wert | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.demografietypGemeinde.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.demografietypGemeinde.quelle"></app-source-display-icon></td>
                  <td colspan="3" class="border-end"></td>
                  <td colspan="3" class="border-end"></td>
                  <td colspan="3"></td>
               </tr>
            </tbody>
         </table>
      </div>

   </mat-expansion-panel>

   <!-- Infrastruktur -->
   <mat-expansion-panel [expanded]="true" class="shadow-sm">
      <mat-expansion-panel-header class="bg-light">
         <mat-panel-title>Infrastruktur</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="table-responsive mt-2">
         <table class="table table-sm table-hover">
            <thead>
               <tr>
                  <th></th>
                  <th></th>
                  <th colspan="3" class="text-center">Gemeinde</th>
                  <th colspan="3" class="text-center">Gemeindeverband</th>
                  <th colspan="3" class="text-center">Kreis</th>
                  <th colspan="3" class="text-center">Deutschland</th>
               </tr>
            </thead>
            <tbody class="text-center">
               <tr>
                  <td class="text-start">Nahversorgung Supermärkte Durchschnittsdistanz</td>
                  <td class="border-end">in m</td>
                  <td>{{data.kennzahlen.nahversorgungSupermaerkteDurchschnittsdistanzGemeinde.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.nahversorgungSupermaerkteDurchschnittsdistanzGemeinde.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.nahversorgungSupermaerkteDurchschnittsdistanzGemeinde.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.nahversorgungSupermaerkteDurchschnittsdistanzGemeindeVerband.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.nahversorgungSupermaerkteDurchschnittsdistanzGemeindeVerband.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.nahversorgungSupermaerkteDurchschnittsdistanzGemeindeVerband.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.nahversorgungSupermaerkteDurchschnittsdistanzKreis.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.nahversorgungSupermaerkteDurchschnittsdistanzKreis.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.nahversorgungSupermaerkteDurchschnittsdistanzKreis.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.nahversorgungSupermaerkteDurchschnittsdistanzDeutschland.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.nahversorgungSupermaerkteDurchschnittsdistanzDeutschland.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td><app-source-display-icon [data]="data.kennzahlen.nahversorgungSupermaerkteDurchschnittsdistanzDeutschland.quelle"></app-source-display-icon></td>
               </tr>
               <tr>
                  <td class="text-start">Nahversorgung Apotheken Durchschnittsdistanz</td>
                  <td class="border-end">in m</td>
                  <td>{{data.kennzahlen.nahversorgungApothekenDurchschnittsdistanzGemeinde.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.nahversorgungApothekenDurchschnittsdistanzGemeinde.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.nahversorgungApothekenDurchschnittsdistanzGemeinde.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.nahversorgungApothekenDurchschnittsdistanzGemeindeVerband.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.nahversorgungApothekenDurchschnittsdistanzGemeindeVerband.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.nahversorgungApothekenDurchschnittsdistanzGemeindeVerband.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.nahversorgungApothekenDurchschnittsdistanzKreis.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.nahversorgungApothekenDurchschnittsdistanzKreis.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.nahversorgungApothekenDurchschnittsdistanzKreis.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.nahversorgungApothekenDurchschnittsdistanzDeutschland.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.nahversorgungApothekenDurchschnittsdistanzDeutschland.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td><app-source-display-icon [data]="data.kennzahlen.nahversorgungApothekenDurchschnittsdistanzDeutschland.quelle"></app-source-display-icon></td>
               </tr>
               <tr>
                  <td class="text-start">Nahversorgung Grundschulen Durchschnittsdistanz</td>
                  <td class="border-end">in m</td>
                  <td>{{data.kennzahlen.nahversorgungGrundschulenDurchschnittsdistanzGemeinde.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.nahversorgungGrundschulenDurchschnittsdistanzGemeinde.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.nahversorgungGrundschulenDurchschnittsdistanzGemeinde.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.nahversorgungGrundschulenDurchschnittsdistanzGemeindeVerband.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.nahversorgungGrundschulenDurchschnittsdistanzGemeindeVerband.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.nahversorgungGrundschulenDurchschnittsdistanzGemeindeVerband.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.nahversorgungGrundschulenDurchschnittsdistanzKreis.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.nahversorgungGrundschulenDurchschnittsdistanzKreis.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.nahversorgungGrundschulenDurchschnittsdistanzKreis.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.nahversorgungGrundschulenDurchschnittsdistanzDeutschland.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.nahversorgungGrundschulenDurchschnittsdistanzDeutschland.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td><app-source-display-icon [data]="data.kennzahlen.nahversorgungGrundschulenDurchschnittsdistanzDeutschland.quelle"></app-source-display-icon></td>
               </tr>
               <tr>
                  <td class="text-start">Ärzte</td>
                  <td class="border-end">je {{10000 | number:'1.0-0':'de-DE'}} EW</td>
                  <td>{{data.kennzahlen.aerzteGemeinde.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.aerzteGemeinde.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.aerzteGemeinde.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.aerzteGemeindeVerband.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.aerzteGemeindeVerband.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.aerzteGemeindeVerband.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.aerzteKreis.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.aerzteKreis.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.aerzteKreis.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.aerzteDeutschland.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.aerzteDeutschland.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td><app-source-display-icon [data]="data.kennzahlen.aerzteDeutschland.quelle"></app-source-display-icon></td>
               </tr>
               <tr>
                  <td class="text-start">Bandbreitenverfügbarkeit mindestens 50 Mbit/s</td>
                  <td class="border-end">
                     %
                     <i class="fa-light fa-circle-info text-secondary" matTooltip="Anzahl der Haushalte mit einem Breitbandanschluss von mindestens 50 MBit/s <Zeitpunkt> / Anzahl der Haushalte <Zeitpunkt> x 100"></i>
                  </td>
                  <td>{{data.kennzahlen.bandbreitenverfuegbarkeitmindestens50MbitsGemeinde.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.bandbreitenverfuegbarkeitmindestens50MbitsGemeinde.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.bandbreitenverfuegbarkeitmindestens50MbitsGemeinde.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.bandbreitenverfuegbarkeitmindestens50MbitsGemeindeVerband.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.bandbreitenverfuegbarkeitmindestens50MbitsGemeindeVerband.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.bandbreitenverfuegbarkeitmindestens50MbitsGemeindeVerband.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.bandbreitenverfuegbarkeitmindestens50MbitsKreis.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.bandbreitenverfuegbarkeitmindestens50MbitsKreis.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.bandbreitenverfuegbarkeitmindestens50MbitsKreis.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.bandbreitenverfuegbarkeitmindestens50MbitsDeutschland.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.bandbreitenverfuegbarkeitmindestens50MbitsDeutschland.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td><app-source-display-icon [data]="data.kennzahlen.bandbreitenverfuegbarkeitmindestens50MbitsDeutschland.quelle"></app-source-display-icon></td>
               </tr>
               <tr>
                  <td class="text-start">Bandbreitenverfügbarkeit mindestens 100 Mbit/s</td>
                  <td class="border-end">
                     %
                     <i class="fa-light fa-circle-info text-secondary" matTooltip="Anzahl der Haushalte mit einem Breitbandanschluss von mindestens 100 MBit/s <Zeitpunkt> / Anzahl der Haushalte <Zeitpunkt> x 100"></i>
                  </td>
                  <td>{{data.kennzahlen.bandbreitenverfuegbarkeitmindestens100MbitsGemeinde.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.bandbreitenverfuegbarkeitmindestens100MbitsGemeinde.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.bandbreitenverfuegbarkeitmindestens100MbitsGemeinde.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.bandbreitenverfuegbarkeitmindestens100MbitsGemeindeVerband.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.bandbreitenverfuegbarkeitmindestens100MbitsGemeindeVerband.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.bandbreitenverfuegbarkeitmindestens100MbitsGemeindeVerband.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.bandbreitenverfuegbarkeitmindestens100MbitsKreis.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.bandbreitenverfuegbarkeitmindestens100MbitsKreis.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.bandbreitenverfuegbarkeitmindestens100MbitsKreis.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.bandbreitenverfuegbarkeitmindestens100MbitsDeutschland.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.bandbreitenverfuegbarkeitmindestens100MbitsDeutschland.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td><app-source-display-icon [data]="data.kennzahlen.bandbreitenverfuegbarkeitmindestens100MbitsDeutschland.quelle"></app-source-display-icon></td>
               </tr>
            </tbody>
         </table>
      </div>
   </mat-expansion-panel>

   <!-- Anbindung -->
   <mat-expansion-panel [expanded]="true" class="shadow-sm">
      <mat-expansion-panel-header class="bg-light">
         <mat-panel-title>Anbindung</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="table-responsive mt-2">
         <table class="table table-sm table-hover">
            <thead>
               <tr>
                  <th></th>
                  <th></th>
                  <th colspan="3" class="text-center">Gemeinde</th>
                  <th colspan="3" class="text-center">Gemeindeverband</th>
                  <th colspan="3" class="text-center">Kreis</th>
                  <th colspan="3" class="text-center">Deutschland</th>
               </tr>
            </thead>
            <tbody class="text-center">
               <tr>
                  <td class="text-start">Erreichbarkeit von Autobahnen</td>
                  <td class="border-end">Fahrminuten</td>
                  <td>{{data.kennzahlen.erreichbarkeitVonAutobahnenGemeinde.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.erreichbarkeitVonAutobahnenGemeinde.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.erreichbarkeitVonAutobahnenGemeinde.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.erreichbarkeitVonAutobahnenGemeindeVerband.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.erreichbarkeitVonAutobahnenGemeindeVerband.quelle.quelleDatenStand| date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.erreichbarkeitVonAutobahnenGemeindeVerband.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.erreichbarkeitVonAutobahnenKreis.wert | number:'1.0-1':'de-DE'  | nullText:'n/a'}}</td>
                  <td >({{data.kennzahlen.erreichbarkeitVonAutobahnenKreis.quelle.quelleDatenStand| date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.erreichbarkeitVonAutobahnenKreis.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.erreichbarkeitVonAutobahnenDeutschland.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.erreichbarkeitVonAutobahnenDeutschland.quelle.quelleDatenStand| date:'yyyy'}})</td>
                  <td><app-source-display-icon [data]="data.kennzahlen.erreichbarkeitVonAutobahnenDeutschland.quelle"></app-source-display-icon></td>
               </tr>
               <tr>
                  <td class="text-start">Erreichbarkeit von Flughäfen</td>
                  <td class="border-end">Fahrminuten</td>
                  <td>{{data.kennzahlen.erreichbarkeitVonFlughaefenGemeinde.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.erreichbarkeitVonFlughaefenGemeinde.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.erreichbarkeitVonFlughaefenGemeinde.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.erreichbarkeitVonFlughaefenGemeindeVerband.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.erreichbarkeitVonFlughaefenGemeindeVerband.quelle.quelleDatenStand| date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.erreichbarkeitVonFlughaefenGemeindeVerband.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.erreichbarkeitVonFlughaefenKreis.wert | number:'1.0-1':'de-DE'  | nullText:'n/a'}}</td>
                  <td >({{data.kennzahlen.erreichbarkeitVonFlughaefenKreis.quelle.quelleDatenStand| date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.erreichbarkeitVonFlughaefenKreis.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.erreichbarkeitVonFlughaefenDeutschland.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.erreichbarkeitVonFlughaefenDeutschland.quelle.quelleDatenStand| date:'yyyy'}})</td>
                  <td><app-source-display-icon [data]="data.kennzahlen.erreichbarkeitVonFlughaefenDeutschland.quelle"></app-source-display-icon></td>
               </tr>
			      <tr>
                  <td class="text-start">Erreichbarkeit von IC/EC/ICE-Bahnhöfen</td>
                  <td class="border-end">Fahrminuten</td>
                  <td>{{data.kennzahlen.erreichbarkeitVonICECICEBahnhoefenGemeinde.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.erreichbarkeitVonICECICEBahnhoefenGemeinde.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.erreichbarkeitVonICECICEBahnhoefenGemeinde.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.erreichbarkeitVonICECICEBahnhoefenGemeindeVerband.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.erreichbarkeitVonICECICEBahnhoefenGemeindeVerband.quelle.quelleDatenStand| date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.erreichbarkeitVonICECICEBahnhoefenGemeindeVerband.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.erreichbarkeitVonICECICEBahnhoefenKreis.wert | number:'1.0-1':'de-DE'  | nullText:'n/a'}}</td>
                  <td >({{data.kennzahlen.erreichbarkeitVonICECICEBahnhoefenKreis.quelle.quelleDatenStand| date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.erreichbarkeitVonICECICEBahnhoefenKreis.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.erreichbarkeitVonICECICEBahnhoefenDeutschland.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.erreichbarkeitVonICECICEBahnhoefenDeutschland.quelle.quelleDatenStand| date:'yyyy'}})</td>
                  <td><app-source-display-icon [data]="data.kennzahlen.erreichbarkeitVonICECICEBahnhoefenDeutschland.quelle"></app-source-display-icon></td>
               </tr>
               <tr>
                  <td class="text-start">Erreichbarkeit von Oberzentren</td>
                  <td class="border-end">
                     Fahrminuten
                     <i class="fa-light fa-circle-info text-secondary" matTooltip="Bund: rd. 97 Prozent der Bevölkerung erreichen das nächste Mittel- oder Oberzentrum 
                     innerhalb von 20 Minuten Fahrzeit mit Pkw bzw. rd. 82 Prozent in 30 Minuten mit ÖPNV"></i>
                     
                  </td>
                  <td>{{data.kennzahlen.erreichbarkeitVonOberzentrenGemeinde.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.erreichbarkeitVonOberzentrenGemeinde.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.erreichbarkeitVonOberzentrenGemeinde.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.erreichbarkeitVonOberzentrenGemeindeVerband.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.erreichbarkeitVonOberzentrenGemeindeVerband.quelle.quelleDatenStand| date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.erreichbarkeitVonOberzentrenGemeindeVerband.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.erreichbarkeitVonOberzentrenKreis.wert | number:'1.0-1':'de-DE'  | nullText:'n/a'}}</td>
                  <td >({{data.kennzahlen.erreichbarkeitVonOberzentrenKreis.quelle.quelleDatenStand| date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.erreichbarkeitVonOberzentrenKreis.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.erreichbarkeitVonOberzentrenDeutschland.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.erreichbarkeitVonOberzentrenDeutschland.quelle.quelleDatenStand| date:'yyyy'}})</td>
                  <td><app-source-display-icon [data]="data.kennzahlen.erreichbarkeitVonOberzentrenDeutschland.quelle"></app-source-display-icon></td>
               </tr>
               <tr>
                  <td class="text-start">Erreichbarkeit von Mittelzentren</td>
                  <td class="border-end">
                     Fahrminuten
                     <i class="fa-light fa-circle-info text-secondary" matTooltip="Bund: rd. 97 Prozent der Bevölkerung erreichen das nächste Mittel- oder Oberzentrum 
                     innerhalb von 20 Minuten Fahrzeit mit Pkw bzw. rd. 82 Prozent in 30 Minuten mit ÖPNV"></i>
                     
                  </td>
                  <td>{{data.kennzahlen.erreichbarkeitVonMittelzentrenGemeinde.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.erreichbarkeitVonMittelzentrenGemeinde.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.erreichbarkeitVonMittelzentrenGemeinde.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.erreichbarkeitVonMittelzentrenGemeindeVerband.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.erreichbarkeitVonMittelzentrenGemeindeVerband.quelle.quelleDatenStand| date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.erreichbarkeitVonMittelzentrenGemeindeVerband.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.erreichbarkeitVonMittelzentrenKreis.wert | number:'1.0-1':'de-DE'  | nullText:'n/a'}}</td>
                  <td >({{data.kennzahlen.erreichbarkeitVonMittelzentrenKreis.quelle.quelleDatenStand| date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.erreichbarkeitVonMittelzentrenKreis.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.erreichbarkeitVonMittelzentrenDeutschland.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.erreichbarkeitVonMittelzentrenDeutschland.quelle.quelleDatenStand| date:'yyyy'}})</td>
                  <td><app-source-display-icon [data]="data.kennzahlen.erreichbarkeitVonMittelzentrenDeutschland.quelle"></app-source-display-icon></td>
               </tr>
               <tr>
                  <td class="text-start">Nahversorgung Haltestellen des ÖV Durchschnittsdistanz</td>
                  <td class="border-end">Luftlinie in m</td>
                  <td>{{data.kennzahlen.nahversorgungHaltestellendesOeVDurchschnittsdistanzGemeinde.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.nahversorgungHaltestellendesOeVDurchschnittsdistanzGemeinde.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.nahversorgungHaltestellendesOeVDurchschnittsdistanzGemeinde.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.nahversorgungHaltestellendesOeVDurchschnittsdistanzGemeindeVerband.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.nahversorgungHaltestellendesOeVDurchschnittsdistanzGemeindeVerband.quelle.quelleDatenStand| date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.nahversorgungHaltestellendesOeVDurchschnittsdistanzGemeindeVerband.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.nahversorgungHaltestellendesOeVDurchschnittsdistanzKreis.wert | number:'1.0-1':'de-DE'  | nullText:'n/a'}}</td>
                  <td >({{data.kennzahlen.nahversorgungHaltestellendesOeVDurchschnittsdistanzKreis.quelle.quelleDatenStand| date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.nahversorgungHaltestellendesOeVDurchschnittsdistanzKreis.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.nahversorgungHaltestellendesOeVDurchschnittsdistanzDeutschland.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.nahversorgungHaltestellendesOeVDurchschnittsdistanzDeutschland.quelle.quelleDatenStand| date:'yyyy'}})</td>
                  <td><app-source-display-icon [data]="data.kennzahlen.nahversorgungHaltestellendesOeVDurchschnittsdistanzDeutschland.quelle"></app-source-display-icon></td>
               </tr>
				   <tr>
                  <td class="text-start">ÖV-Abfahrten</td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.oeVAbfahrtenGemeinde.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.oeVAbfahrtenGemeinde.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.oeVAbfahrtenGemeinde.quelle"></app-source-display-icon></td>
                  <td></td>
                  <td></td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.oeVAbfahrtenKreis.wert | number:'1.0-1':'de-DE'  | nullText:'n/a'}}</td>
                  <td >({{data.kennzahlen.oeVAbfahrtenKreis.quelle.quelleDatenStand| date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.oeVAbfahrtenKreis.quelle"></app-source-display-icon></td>
                  <td></td>
                  <td></td>
                  <td></td>
               </tr>
               <tr>
                  <td class="text-start">Bahn-Abfahrten</td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.bahnAbfahrtenGemeinde.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.bahnAbfahrtenGemeinde.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.bahnAbfahrtenGemeinde.quelle"></app-source-display-icon></td>
                  <td></td>
                  <td></td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.bahnAbfahrtenKreis.wert | number:'1.0-1':'de-DE'  | nullText:'n/a'}}</td>
                  <td >({{data.kennzahlen.bahnAbfahrtenKreis.quelle.quelleDatenStand| date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.bahnAbfahrtenKreis.quelle"></app-source-display-icon></td>
                  <td></td>
                  <td></td>
                  <td></td>
               </tr>
               <tr>
                  <td class="text-start">Bus-Abfahrten</td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.busAbfahrtenGemeinde.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.busAbfahrtenGemeinde.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.busAbfahrtenGemeinde.quelle"></app-source-display-icon></td>
                  <td></td>
                  <td></td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.busAbfahrtenKreis.wert | number:'1.0-1':'de-DE'  | nullText:'n/a'}}</td>
                  <td >({{data.kennzahlen.busAbfahrtenKreis.quelle.quelleDatenStand| date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.busAbfahrtenKreis.quelle"></app-source-display-icon></td>
                  <td></td>
                  <td></td>
                  <td></td>
               </tr>
               <tr>
                  <td class="text-start">Erreichbarkeit KV-Terminal</td>
                  <td class="border-end">Fahrminuten</td>
                  <td>{{data.kennzahlen.erreichbarkeitKVTerminalGemeinde.wert | number:'1.0-1':'de-DE' | nullText:'n/a'}}</td>
                  <td>({{data.kennzahlen.erreichbarkeitKVTerminalGemeinde.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.erreichbarkeitKVTerminalGemeinde.quelle"></app-source-display-icon></td>
                  <td></td>
                  <td></td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.erreichbarkeitKVTerminalKreis.wert | number:'1.0-1':'de-DE'  | nullText:'n/a'}}</td>
                  <td >({{data.kennzahlen.erreichbarkeitKVTerminalKreis.quelle.quelleDatenStand| date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.erreichbarkeitKVTerminalKreis.quelle"></app-source-display-icon></td>
                  <td></td>
                  <td></td>
                  <td></td>
               </tr>
            </tbody>
         </table>
      </div>
   </mat-expansion-panel>

   <!-- Wirtschaft -->
   <mat-expansion-panel [expanded]="true" class="shadow-sm">
      <mat-expansion-panel-header class="bg-light">
         <mat-panel-title>Wirtschaft</mat-panel-title>
      </mat-expansion-panel-header>
      <div class="table-responsive mt-2">
         <table class="table table-sm table-hover">
            <thead>
               <tr>
                  <th></th>
                  <th></th>
                  <th colspan="3" class="text-center">Gemeinde</th>
                  <th colspan="3" class="text-center">Gemeindeverband</th>
                  <th colspan="3" class="text-center">Kreis</th>
                  <th colspan="3" class="text-center">Deutschland</th>
               </tr>
            </thead>
            <tbody class="text-center">
               <tr>
                  <td class="text-start">Bruttoinlandsprodukt je Kopf</td>
                  <td class="border-end"></td>
                  <td></td>
                  <td></td>
                  <td class="border-end"></td>
                  <td></td>
                  <td></td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.bruttoinlandsproduktKreis.wert | number:'1.0-0':'de-DE' | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.bruttoinlandsproduktKreis.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.bruttoinlandsproduktKreis.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.bruttoinlandsproduktDeutschland.wert | number:'1.0-0':'de-DE' | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.bruttoinlandsproduktDeutschland.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td><app-source-display-icon [data]="data.kennzahlen.bruttoinlandsproduktDeutschland.quelle"></app-source-display-icon></td>
               </tr>
               <tr>
                  <td class="text-start">Arbeitslosenquote</td>
                  <td class="border-end">%</td>
                  <td>{{data.kennzahlen.arbeitslosenQuoteGemeinde.wert | number:'1.0-1':'de-DE' | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.arbeitslosenQuoteGemeinde.quelle.quelleDatenStand | date:'MMMM yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.arbeitslosenQuoteGemeinde.quelle"></app-source-display-icon></td>
                  <td></td>
                  <td></td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.arbeitslosenQuoteKreis.wert | number:'1.0-1':'de-DE' | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.arbeitslosenQuoteKreis.quelle.quelleDatenStand | date:'MMMM yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.arbeitslosenQuoteKreis.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.arbeitslosenQuoteDeutschland.wert | number:'1.0-1':'de-DE' | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.arbeitslosenQuoteDeutschland.quelle.quelleDatenStand | date:'MMMM yyyy'}})</td>
                  <td><app-source-display-icon [data]="data.kennzahlen.arbeitslosenQuoteDeutschland.quelle"></app-source-display-icon></td>
               </tr>
               <tr>
                  <td class="text-start">Arbeitsplatzzentralität</td>
                  <td class="border-end">
                     <i class="fa-light fa-circle-info text-secondary" matTooltip="Verhältnis der SV-Beschäftigten am Arbeitsort zu den SV-Beschäftigten am Wohnort"></i>
                  </td>
                  <td>{{data.kennzahlen.arbeitsplatzZentralitaetGemeinde.wert | number:'1.0-1':'de-DE' | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.arbeitsplatzZentralitaetGemeinde.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.arbeitsplatzZentralitaetGemeinde.quelle"></app-source-display-icon></td>
                  <td></td>
                  <td></td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.arbeitsplatzZentralitaetKreis.wert | number:'1.0-1':'de-DE' | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.arbeitsplatzZentralitaetKreis.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.arbeitsplatzZentralitaetKreis.quelle"></app-source-display-icon></td>
                  <td></td>
                  <td></td>
                  <td></td>
               </tr>
               <tr>
                  <td class="text-start">Einwohner-Arbeitsplatz-Dichte</td>
                  <td class="border-end">EW u. Beschäft./km²</td>
                  <td>{{data.kennzahlen.einwohnerArbeitsplatzDichteGemeinde.wert | number:'1.0-1':'de-DE' | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.einwohnerArbeitsplatzDichteGemeinde.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.einwohnerArbeitsplatzDichteGemeinde.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.einwohnerArbeitsplatzDichteGemeindeVerband.wert | number:'1.0-1':'de-DE' | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.einwohnerArbeitsplatzDichteGemeindeVerband.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.einwohnerArbeitsplatzDichteGemeindeVerband.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.einwohnerArbeitsplatzDichteKreis.wert | number:'1.0-1':'de-DE' | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.einwohnerArbeitsplatzDichteKreis.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.einwohnerArbeitsplatzDichteKreis.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.einwohnerArbeitsplatzDichteDeutschland.wert | number:'1.0-1':'de-DE' | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.einwohnerArbeitsplatzDichteDeutschland.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td><app-source-display-icon [data]="data.kennzahlen.einwohnerArbeitsplatzDichteDeutschland.quelle"></app-source-display-icon></td>
               </tr>
               <tr>
                  <td class="text-start">Pendlersaldo</td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.pendlerSaldoGemeinde.wert | number:'1.0-1':'de-DE' | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.pendlerSaldoGemeinde.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.pendlerSaldoGemeinde.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.pendlerSaldoGemeindeVerband.wert | number:'1.0-1':'de-DE' | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.pendlerSaldoGemeindeVerband.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.pendlerSaldoGemeindeVerband.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.pendlerSaldoKreis.wert | number:'1.0-1':'de-DE' | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.pendlerSaldoKreis.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.pendlerSaldoKreis.quelle"></app-source-display-icon></td>
                  <td>{{data.kennzahlen.pendlerSaldoDeutschland.wert | number:'1.0-1':'de-DE' | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.pendlerSaldoDeutschland.quelle.quelleDatenStand | date:'yyyy'}})</td>
                  <td><app-source-display-icon [data]="data.kennzahlen.pendlerSaldoDeutschland.quelle"></app-source-display-icon></td>
               </tr>
               <tr>
                  <td class="text-start">Regionales Bevölkerungspotenzial</td>
                  <td class="border-end">
                     <i class="fa-light fa-circle-info text-secondary" matTooltip="Regionales Bevölkerungspotenzial der Gemeinden im Umkreis von 100 km in 1.000"></i>
                  </td>
                  <td></td>
                  <td></td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.regionalesBevoelkerungspotenzialGemeindeVerband.wert | number:'1.0-1':'de-DE' | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.regionalesBevoelkerungspotenzialGemeindeVerband.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.regionalesBevoelkerungspotenzialGemeindeVerband.quelle"></app-source-display-icon></td>
                  <td></td>
                  <td></td>
                  <td class="border-end"></td>
                  <td></td>
                  <td></td>
                  <td></td>
               </tr>
               <!-- Leerzeile -->
               <tr><td colspan="14"></td></tr>
               <tr>
                  <td class="text-start">Kaufkraftkennziffer</td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.kaufkraftkennzifferGemeinde.wert | number:'1.0-1':'de-DE' | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.kaufkraftkennzifferGemeinde.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.kaufkraftkennzifferGemeinde.quelle"></app-source-display-icon></td>
                  <td></td>
                  <td></td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.kaufkraftkennzifferKreis.wert | number:'1.0-1':'de-DE' | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.kaufkraftkennzifferKreis.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.kaufkraftkennzifferKreis.quelle"></app-source-display-icon></td>
                  <td></td>
                  <td></td>
                  <td></td>
               </tr>
               <tr>
                  <td class="text-start">Kaufkraft 5-Jahres Trend</td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.kaufkraft5JahresTrendGemeinde.wert | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.kaufkraft5JahresTrendGemeinde.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.kaufkraft5JahresTrendGemeinde.quelle"></app-source-display-icon></td>
                  <td></td>
                  <td></td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.kaufkraft5JahresTrendKreis.wert | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.kaufkraft5JahresTrendKreis.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.kaufkraft5JahresTrendKreis.quelle"></app-source-display-icon></td>
                  <td></td>
                  <td></td>
                  <td></td>
               </tr>
               <tr>
                  <td class="text-start">Zentralitätskennziffer</td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.zentralitaetskennzifferGemeinde.wert | number:'1.0-1':'de-DE' | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.zentralitaetskennzifferGemeinde.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.zentralitaetskennzifferGemeinde.quelle"></app-source-display-icon></td>
                  <td></td>
                  <td></td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.zentralitaetskennzifferKreis.wert | number:'1.0-1':'de-DE' | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.zentralitaetskennzifferKreis.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.zentralitaetskennzifferKreis.quelle"></app-source-display-icon></td>
                  <td></td>
                  <td></td>
                  <td></td>
               </tr>
               <tr>
                  <td class="text-start">Zentralität 5-Jahres Trend</td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.zentralitaet5JahresTrendGemeinde.wert | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.zentralitaet5JahresTrendGemeinde.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.zentralitaet5JahresTrendGemeinde.quelle"></app-source-display-icon></td>
                  <td></td>
                  <td></td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.zentralitaet5JahresTrendKreis.wert | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.zentralitaet5JahresTrendKreis.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.zentralitaet5JahresTrendKreis.quelle"></app-source-display-icon></td>
                  <td></td>
                  <td></td>
                  <td></td>
               </tr>
               <tr>
                  <td class="text-start">Umsatzkennziffer</td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.umsatzkennzifferGemeinde.wert | number:'1.0-1':'de-DE' | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.umsatzkennzifferGemeinde.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.umsatzkennzifferGemeinde.quelle"></app-source-display-icon></td>
                  <td></td>
                  <td></td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.umsatzkennzifferKreis.wert | number:'1.0-1':'de-DE' | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.umsatzkennzifferKreis.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.umsatzkennzifferKreis.quelle"></app-source-display-icon></td>
                  <td></td>
                  <td></td>
                  <td></td>
               </tr>
               <tr>
                  <td class="text-start">Umsatz 5-Jahres Trend</td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.umsatz5JahresTrendGemeinde.wert | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.umsatz5JahresTrendGemeinde.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.umsatz5JahresTrendGemeinde.quelle"></app-source-display-icon></td>
                  <td></td>
                  <td></td>
                  <td class="border-end"></td>
                  <td>{{data.kennzahlen.umsatz5JahresTrendKreis.wert | nullText: 'n/a'}}</td>
                  <td>({{data.kennzahlen.umsatz5JahresTrendKreis.quelle.quelleDatenStand | date: 'yyyy'}})</td>
                  <td class="border-end"><app-source-display-icon [data]="data.kennzahlen.umsatz5JahresTrendKreis.quelle"></app-source-display-icon></td>
                  <td></td>
                  <td></td>
                  <td></td>
               </tr>
            </tbody>
         </table>
      </div>
   </mat-expansion-panel>
</mat-accordion>