<div
    (leafletMapReady)="mapReady($event)"
    (leafletZoomChange)="setZoom($event)"
    [leafletCenter]="centerCoords"
    [leafletOptions]="mapOptions"
    [leafletZoom]="zoom$"
    (leafletMarkerClusterReady)="markerClusterReady($event)"
    [leafletMarkerCluster]="marker$ | async"
    [leafletMarkerClusterOptions]="markerClusterOptions"
    id="map"
    leaflet
></div>
<app-zoom-control [map]="map"></app-zoom-control>
<!-- temporary not shown
<app-layer-control [map]="map"></app-layer-control>
-->
<div id="custom-count">{{ totalRecordsFound$ | async }} Gutachten gefunden</div>
