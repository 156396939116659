<app-navbar></app-navbar>
<mat-drawer-container hasBackdrop="false">
   <mat-drawer mode="side">
      <app-sidebar></app-sidebar>
   </mat-drawer>
   <app-loading></app-loading>
   <div class="content-component">
      <router-outlet></router-outlet>
   </div>
</mat-drawer-container>
<app-snackbar></app-snackbar>