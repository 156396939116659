import { Component } from "@angular/core";
import { AuthService } from "../../guards/auth.service";

@Component({
   selector: "app-sidebar",
   templateUrl: "./sidebar.component.html",
   styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent {

   constructor(private auth: AuthService) { }
   
   isUploader(): boolean {
      return this.auth.isUploader();
   }
}
