import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DetailResolverService } from './services/detail-resolver.service';
import { TextListComponent } from './components/text-list/text-list.component';
import { TextDetailComponent } from './components/text-detail/text-detail.component';
import { BrickResolverService } from './services/brick-resolver.service';
import { TextgeneratorComponent } from './textgenerator.component';
import { BrickConstructionLoadsEditComponent } from './components/bricks/brick-construction-loads-edit/brick-construction-loads-edit.component';
import { BrickConstructionLawEditComponent } from './components/bricks/brick-construction-law-edit/brick-construction-law-edit.component';
import { BrickProductionCostsEditComponent } from './components/bricks/brick-production-costs-edit/brick-production-costs-edit.component';
import { BrickProductionCostsObjectResolverService } from './services/brick-production-costs-object-resolver.service';
import { BrickProductionCostsObjectEditComponent } from './components/bricks/brick-production-costs-object-edit/brick-production-costs-object-edit.component';
import { BrickProductionCostsObjectCreateComponent } from './components/bricks/brick-production-costs-object-create/brick-production-costs-object-create.component';
import { BrickMacroLocationEditComponent } from './components/bricks/brick-macro-location-edit/brick-macro-location-edit.component';

const routes: Routes = [
  {
    path: '',
    title: 'Textgenerator',
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
      },
      {
        path: 'list',
        component: TextgeneratorComponent,
        title: 'Textgenerator Liste',
        children: [ { path: '', component: TextListComponent } ]
      },
      {
        path: 'list/:appraisalTextId',
        component: TextgeneratorComponent,
        resolve: { appraisalText: DetailResolverService },
        title: 'Textgenerator Detail',
        children: [ { path: '', component: TextDetailComponent } ]
      },
      {
        path: 'list/:appraisalTextId/brick-construction-loads',
        component: TextgeneratorComponent,
        resolve: { brickData: BrickResolverService },
        title: 'Baulasten',
        children: [ { path: '', component: BrickConstructionLoadsEditComponent } ]
      },
      {
        path: 'list/:appraisalTextId/brick-construction-law',
        component: TextgeneratorComponent,
        resolve: { brickData: BrickResolverService },
        title: 'Baurecht',
        children: [ { path: '', component: BrickConstructionLawEditComponent } ]
      },
      {
        path: 'list/:appraisalTextId/brick-production-costs',
        component: TextgeneratorComponent,
        resolve: { brickData: BrickResolverService },
        title: 'Herstellungskosten',
        children: [ { path: '', component: BrickProductionCostsEditComponent } ]
      },
      {
        path: 'list/:appraisalTextId/brick-production-costs/object-create',
        component: TextgeneratorComponent,
        resolve: { brickData: BrickProductionCostsObjectResolverService },
        title: 'Objekt erstellen',
        children: [ { path: '', component: BrickProductionCostsObjectCreateComponent } ]
      },
      {
        path: 'list/:appraisalTextId/brick-production-costs/object-update/:objectId',
        component: TextgeneratorComponent,
        resolve: { objectData: BrickProductionCostsObjectResolverService },
        title: 'Objekt bearbeiten',
        children: [ { path: '', component: BrickProductionCostsObjectEditComponent } ]
      },
      {
         path: 'list/:appraisalTextId/brick-macro-location',
         component: TextgeneratorComponent,
         resolve: { brickData: BrickResolverService },
         title: 'Makrolage',
         children: [ { path: '', component: BrickMacroLocationEditComponent } ]
       },
    ],
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    DetailResolverService
  ]
})
export class TextgeneratorRoutingModule { }
