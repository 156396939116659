import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../shared/shared.module';
import { AppraisalRoutingModule } from './appraisal-routing.module';
import { AppraisalChartViewComponent } from './components/chart-table/appraisal-chart/appraisal-chart-view.component';
import { AppraisalTableCmpComponent } from './components/chart-table/appraisal-table/appraisal-table-cmp/appraisal-table-cmp.component';
import { AppraisalTableDetailComponent } from './components/chart-table/appraisal-table/appraisal-table-detail/appraisal-table-detail.component';
import { AppraisalTableViewComponent } from './components/chart-table/appraisal-table/appraisal-table-view.component';
import { AppraisalAusstattungLageComponent } from './components/overview/appraisal-boxes/appraisal-ausstattung-lage/appraisal-ausstattung-lage.component';
import { AppraisalBesonderheitenComponent } from './components/overview/appraisal-boxes/appraisal-besonderheiten/appraisal-besonderheiten.component';
import { AppraisalBoxComponent } from './components/overview/appraisal-boxes/appraisal-box.component';
import { AppraisalNutzungsartComponent } from './components/overview/appraisal-boxes/appraisal-nutzungsart/appraisal-nutzungsart.component';
import { AppraisalObjektbeschreibungComponent } from './components/overview/appraisal-boxes/appraisal-objektbeschreibung/appraisal-objektbeschreibung.component';
import { AppraisalCmpViewComponent } from './components/overview/appraisal-compare/appraisal-cmp-view.component';
import { AppraisalInfoColumnComponent } from './components/overview/appraisal-compare/appraisal-info-column/appraisal-info-column.component';
import { AppraisalInfoViewComponent } from './components/overview/appraisal-detail/appraisal-info-view.component';
import { SegmentCompareComponent } from './components/segment-compare/segment-compare.component';
import { SegmentDetailComponent } from './components/segment-detail/segment-detail.component';
import {CollectionSidebarComponent} from "../appraisal-search/collection-sidebar/collection-sidebar.component";
import {
    CollectionSidebarEntryComponent
} from "../appraisal-search/collection-sidebar-entry/collection-sidebar-entry.component";

@NgModule({
    declarations: [
        AppraisalCmpViewComponent,
        AppraisalInfoColumnComponent,
        AppraisalTableViewComponent,
        SegmentCompareComponent,
        SegmentDetailComponent,
        AppraisalTableCmpComponent,
        AppraisalTableDetailComponent,
        AppraisalChartViewComponent,
        AppraisalInfoViewComponent,
        AppraisalBoxComponent,
        AppraisalObjektbeschreibungComponent,
        AppraisalNutzungsartComponent,
        AppraisalAusstattungLageComponent,
        AppraisalBesonderheitenComponent,
        CollectionSidebarComponent,
        CollectionSidebarEntryComponent
    ],
    imports: [CommonModule, SharedModule, ClipboardModule, AppraisalRoutingModule, FormsModule, MatIconModule],
    exports: [
        AppraisalCmpViewComponent,
        AppraisalInfoColumnComponent,
        AppraisalTableViewComponent,
        SegmentCompareComponent,
        SegmentDetailComponent,
        AppraisalTableCmpComponent,
        AppraisalTableDetailComponent,
        AppraisalChartViewComponent,
        AppraisalInfoViewComponent,
        CollectionSidebarComponent
    ]
})
export class AppraisalModule {}
