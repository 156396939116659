import { ClipboardModule } from '@angular/cdk/clipboard';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ChartsModule } from 'ng2-charts';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ChartBoxComponent } from '../appraisal-detail/components/chart-table/appraisal-chart/chart/chart-box/chart-box.component';
import { ChartComponent } from '../appraisal-detail/components/chart-table/appraisal-chart/chart/chart/chart.component';
import { GetAppraisalIdPipe } from '../appraisal-detail/pipes/get-appraisal-id.pipe';
import { GibtBaujahreRangePipe } from '../appraisal-detail/pipes/gibt-baujahreRange.pipe';
import { GibtBesonderheiten } from '../appraisal-detail/pipes/gibt-besonderheiten';
import { GibtRndRangePipe } from '../appraisal-detail/pipes/gibt-rnd-range.pipe';
import { GibtUploadListTextPipe } from '../appraisal-detail/pipes/gibt-uploadListText';
import { UploadCollectionDetailComponent } from '../collection-upload/components/upload-collection/upload-collection-detail/upload-collection-detail.component';
import { LageFilterComponent } from '../collection-upload/components/upload-list/upload-lage-filter/lage-filter.component';
import { UploadListSidebarComponent } from '../collection-upload/components/upload-list/upload-list-sidebar/upload-list-sidebar.component';
import { UploadListComponent } from '../collection-upload/components/upload-list/upload-list.component';
import { GetNutzungsartByIdPipe } from '../collection-upload/pipes/get-nutzungsart-by-id.pipe';
import { AutocompleteFormsComponent } from './components/autocomplete-forms/autocomplete-forms.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { DialogConfirmComponent } from './components/dialog/dialog-confirm/dialog-confirm.component';
import { DialogFormComponent } from './components/dialog/dialog-form/dialog-form.component';
import { DialogUploadFileComponent } from './components/dialog/dialog-form/dialog-upload-file/dialog-upload-file.component';
import { DialogUploadItemHerausgeberComponent } from './components/dialog/dialog-form/dialog-upload-item-herausgeber/dialog-upload-item-herausgeber.component';
import { DialogUploadItemNutzungComponent } from './components/dialog/dialog-form/dialog-upload-item-nutzung/dialog-upload-item-nutzung.component';
import { DialogUploadLageComponent } from './components/dialog/dialog-form/dialog-upload-lage/dialog-upload-lage.component';
import { DialogUploadNutzungComponent } from './components/dialog/dialog-form/dialog-upload-nutzung/dialog-upload-nutzung.component';
import { DialogUploadRatingComponent } from './components/dialog/dialog-form/dialog-upload-rating/dialog-upload-rating.component';
import { DialogUploadTextfieldComponent } from './components/dialog/dialog-form/dialog-upload-textfield/dialog-upload-textfield.component';
import { DialogHtmlComponent } from './components/dialog/dialog-html/dialog-html.component';
import { DialogTextComponent } from './components/dialog/dialog-text/dialog-text.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { ExpansionPanelComponent } from './components/expansion-panel/expansion-panel.component';
import { ImgComponent } from './components/img/img.component';
import { InputInfoDeleteComponent } from './components/input-info-delete/input-info-delete.component';
import { ListItemComponent } from './components/list-item/list-item.component';
import { LocationListItemComponent } from './components/list-item/location-list-item/location-list-item.component';
import { ListSpacerComponent } from './components/list-spacer/list-spacer.component';
import { ListComponent } from './components/list/list.component';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { NotificationbarComponent } from './components/notificationbar/notificationbar.component';
import { NumberDisplayComponent } from './components/number-display/number-display.component';
import { RadioButtonComponent } from './components/radio-group/radio-button/radio-button.component';
import { RadioGroupComponent } from './components/radio-group/radio-group.component';
import { RatingStarsComponent } from './components/rating-stars/rating-stars.component';
import { SegmentedControlComponent } from './components/segmented-control/segmented-control.component';
import { SelectComponent } from './components/select/select.component';
import { SliderComponent } from './components/slider/slider.component';
import { TabComponent } from './components/tab/tab.component';
import { TabsComponent } from './components/tab/tabs.component';
import { OutsideClickDirective } from './directives/outside-click.directive';
import { BooleanToLanguage } from './pipes/boolean-to-language';
import { HtmlToTextPipe } from './pipes/html-to-text.pipe';
import { Placeholder } from './pipes/placeholder';
import { SelectOptionToText } from './pipes/select-option-to-text';
import { RouteTransform } from './routetransform/RouteTransform';
import { MatSortModule } from "@angular/material/sort";
import { DialogFeedbackComponent } from './components/dialog/dialog-feedback/dialog-feedback.component';
import { MatCheckboxModule } from "@angular/material/checkbox";
import { NewlineToHtmlPipe } from './pipes/newline-to-html.pipe';
import { LocationPipe } from './pipes/location.pipe';
import { SourceDisplayPipe } from './pipes/source-display.pipe';
import { SourceDisplayIconComponent } from './components/source-display-icon/source-display-icon.component';
import { NullTextPipe } from './pipes/null-text.pipe';
import { InfoDisplayIconComponent } from './components/info-display-icon/info-display-icon.component';

@NgModule({
   declarations: [
      ListComponent,
      ListItemComponent,
      ListSpacerComponent,
      SliderComponent,
      LocationListItemComponent,
      AutocompleteComponent,
      RadioGroupComponent,
      SegmentedControlComponent,
      DropdownComponent,
      Placeholder,
      SelectOptionToText,
      GibtUploadListTextPipe,
      ImgComponent,
      RouteTransform,
      RadioButtonComponent,
      MultiSelectComponent,
      SelectComponent,
      OutsideClickDirective,
      ChartComponent,
      ChartBoxComponent,
      GibtBesonderheiten,
      BooleanToLanguage,
      DialogHtmlComponent,
      NotificationbarComponent,
      GibtBaujahreRangePipe,
      GibtRndRangePipe,
      GetAppraisalIdPipe,
      UploadCollectionDetailComponent,
      DialogTextComponent,
      NumberDisplayComponent,
      InputInfoDeleteComponent,
      DialogComponent,
      DialogFormComponent,
      UploadListComponent,
      UploadListSidebarComponent,
      LageFilterComponent,
      DialogUploadLageComponent,
      DialogUploadNutzungComponent,
      DialogUploadTextfieldComponent,
      DialogUploadFileComponent,
      DialogUploadRatingComponent,
      RatingStarsComponent,
      AutocompleteFormsComponent,
      DialogConfirmComponent,
      DatepickerComponent,
      DialogUploadItemNutzungComponent,
      DialogUploadItemHerausgeberComponent,
      TabsComponent,
      TabComponent,
      ExpansionPanelComponent,
      GetNutzungsartByIdPipe,
      HtmlToTextPipe,
      DialogFeedbackComponent,
      NewlineToHtmlPipe,
      LocationPipe,
      SourceDisplayPipe,
      SourceDisplayIconComponent,
      NullTextPipe,
      InfoDisplayIconComponent
   ],
   imports: [
      CommonModule,
      FontAwesomeModule,
      ReactiveFormsModule,
      OverlayModule,
      FormsModule,
      RouterModule,
      ChartsModule,
      MatDialogModule,
      MatButtonModule,
      NgxSkeletonLoaderModule,
      MatTableModule,
      MatPaginatorModule,
      MatIconModule,
      MatFormFieldModule,
      MatSelectModule,
      MatAutocompleteModule,
      MatInputModule,
      MatDatepickerModule,
      MatProgressSpinnerModule,
      MatProgressSpinnerModule,
      MatChipsModule,
      ClipboardModule,
      MatSortModule,
      MatCheckboxModule,
      MatTooltipModule
   ],
   exports: [
      FontAwesomeModule,
      ChartsModule,
      ListComponent,
      ListItemComponent,
      ListSpacerComponent,
      SliderComponent,
      LocationListItemComponent,
      AutocompleteComponent,
      RadioGroupComponent,
      SegmentedControlComponent,
      DropdownComponent,
      Placeholder,
      SelectOptionToText,
      GibtUploadListTextPipe,
      ImgComponent,
      RouteTransform,
      RadioButtonComponent,
      MultiSelectComponent,
      SelectComponent,
      OutsideClickDirective,
      ChartComponent,
      ChartBoxComponent,
      GibtBesonderheiten,
      BooleanToLanguage,
      NotificationbarComponent,
      NgxSkeletonLoaderModule,
      GibtBaujahreRangePipe,
      GibtRndRangePipe,
      GetAppraisalIdPipe,
      UploadCollectionDetailComponent,
      FontAwesomeModule,
      ChartsModule,
      ListComponent,
      ListItemComponent,
      ListSpacerComponent,
      SliderComponent,
      LocationListItemComponent,
      AutocompleteComponent,
      RadioGroupComponent,
      SegmentedControlComponent,
      DropdownComponent,
      Placeholder,
      SelectOptionToText,
      ImgComponent,
      RouteTransform,
      RadioButtonComponent,
      MultiSelectComponent,
      SelectComponent,
      OutsideClickDirective,
      ChartComponent,
      ChartBoxComponent,
      GibtBesonderheiten,
      BooleanToLanguage,
      NotificationbarComponent,
      NgxSkeletonLoaderModule,
      GibtBaujahreRangePipe,
      GibtRndRangePipe,
      GetAppraisalIdPipe,
      NumberDisplayComponent,
      DialogTextComponent,
      InputInfoDeleteComponent,
      MatDatepickerModule,
      MatFormFieldModule,
      MatDatepickerModule,
      MatFormFieldModule,
      MatSelectModule,
      MatInputModule,
      ReactiveFormsModule,
      MatButtonModule,
      MatDatepickerModule,
      MatFormFieldModule,
      MatButtonModule,
      MatNativeDateModule,
      DatepickerComponent,
      TabsComponent,
      TabComponent,
      MatChipsModule,
      ExpansionPanelComponent,
      HtmlToTextPipe,
      NewlineToHtmlPipe,
      LocationPipe,
      SourceDisplayPipe,
      GetNutzungsartByIdPipe,
      SourceDisplayIconComponent,
      NullTextPipe,
      InfoDisplayIconComponent
   ]
})
export class SharedModule { }
