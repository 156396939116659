<div id="container">
    <form [formGroup]="formGroup">
        <div class="row-component" id="row-input">
            <app-input-info-delete
                [data]="{ placeholder: 'Herausg. Name', controlName: 'Name', required: true }"
                formControlName="name"
            >
            </app-input-info-delete>
            <app-input-info-delete
                [data]="{ placeholder: 'Website', controlName: 'Url', required: false }"
                formControlName="url"
            >
            </app-input-info-delete>
            <button class="default" id="btn-link" (click)="setCurrentUrl($event)">
                <mat-icon class="icnUrl"><span class="material-symbols-outlined"> content_paste </span></mat-icon>
            </button>
            <button class="primary" type="submit" (click)="addItem()" [disabled]="!formGroup.controls.name.valid">
                Hinzufügen
            </button>
        </div>
    </form>

    <div id="container-content">
        <div id="list-title">Alle Herausgeber</div>

        <!--New added items-->
        <div *ngFor="let item of newHerausgeberItems; index as i">
            <div class="divider"></div>
            <div class="item">
                <div class="item-title flex-3">{{ item.name }}</div>

                <div class="item-url flex-5" *ngIf="item.url">
                    {{ item.url }}
                </div>
                <div *ngIf="!item.url" class="flex-5"></div>

                <button class="onlytext btn-noback" (click)="removeItem(i)">
                    <mat-icon class="icnClose">close</mat-icon>
                </button>
            </div>
        </div>

        <!--Existing Item List-->
        <div *ngFor="let item of data.content | async; index as i">
            <div class="divider"></div>


            <div class="item" *ngIf="!item.editMode">

                <div class="item-title flex-3">{{ item.name }}</div>
                <div class="item-url flex-5" *ngIf="item.url">
                    {{ item.url }}
                </div>
                <div *ngIf="!item.url" class="flex-5"></div>

                <button mat-icon-button>
                    <mat-icon class="icnEdit" (click)="editItem(item, i)">edit</mat-icon>
                </button>
            </div>

            <div class="item" *ngIf="item.editMode && !!editedItems[item.id]">
                <div class="flex-3">
                    <mat-form-field class="width100" appearance="fill">
                        <input
                            (ngModelChange)="validateEditedItems()"
                            type="text"
                            name="name"
                            [(ngModel)]="editedItems[item.id].name"
                            matInput
                        />
                    </mat-form-field>
                </div>
                <div style="width:15px"></div>
                <div class="item-url flex-5 toLeft">
                    <mat-form-field class="width100" appearance="fill">
                        <input
                            class="width100"
                            name="url"
                            type="text"
                            [(ngModel)]="editedItems[item.id].url"
                            (ngModelChange)="validateEditedItems()"
                            matInput
                        />
                    </mat-form-field>
                </div>

                <button class="onlytext btn-noback">
                    <mat-icon class="icnClose" (click)="revertEditItem(item, i)">close</mat-icon>
                </button>
            </div>
        </div>
    </div>

    <!--Button Row-->
    <div class="row-component" id="btns">
        <button
            (click)="submit()"
            class="primary btns"
            id="btn-submit"
            [disabled]="newHerausgeberItems.length === 0 && !areEditedItemsValid"
        >
            {{ data.primaryBtnTitle || 'Speichern' }}
        </button>
        <button (click)="cancel()" class="default btns">Abbrechen</button>
    </div>
</div>
