import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
})
export class RadioButtonComponent {
  @Input() checked = false;
  @Output() currentSelectionChange: EventEmitter<void> = new EventEmitter();
  faCheck = faCheck;
}
