<mat-toolbar class="shadow-sm bg-success-subtle">
   <div class="hstack gap-2 me-auto">
      <button mat-icon-button aria-label="menu icon" (click)="sidebarService.toggle()">
         <mat-icon>menu</mat-icon>
      </button>
      <img src="../../../../favicon.ico">
      <div class="me-5">DataSuite</div>
      <app-breadcrumbs></app-breadcrumbs>
   </div>
   <button class="btn btn-light btn-sm text-secondary" (click)="onLogout()">Ausloggen</button>
</mat-toolbar>