import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as html2pdf from 'html2pdf.js';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Appraisal } from 'src/app/appraisal-detail/models/Appraisal';
import { BarNotification } from '../../../../../core/enums/BarNotificaton';
import { DatenQuelle } from '../../../../../core/enums/DatenQuelle';
import { FileService } from '../../../../../core/services/file.service';
import { NotificationbarService } from '../../../../../core/services/notificationbar.service';
import { SnackbarService } from '../../../../../core/services/snackbar.service';
import { BreadcrumbDefault, BreadcrumbsService } from 'src/app/core/services/breadcrumbs.service';

@Component({
   selector: 'app-appraisal-table-detail',
   templateUrl: './appraisal-table-detail.component.html',
   styleUrls: ['./appraisal-table-detail.component.scss', '../appraisal-table-view-shared.scss']
})
export class AppraisalTableDetailComponent implements OnInit {
   appraisal$: Observable<Appraisal>;
   switchAnsatz = true;
   showSwitchAnsatz = false;
   showHistograms = true;
   showScatters = false;

   constructor(
      private route: ActivatedRoute,
      private fileService: FileService,
      private snackbarService: SnackbarService,
      private notificationbarService: NotificationbarService,
      private breadcrumbsService: BreadcrumbsService
   ) {
      this.appraisal$ = this.route.parent.data.pipe(
         map(({ appraisal }) => appraisal),
         tap((appraisal) => {
            if (appraisal.datenQuelle === DatenQuelle.GIKS) {
               this.showNotificationbar(
                  'Auswahl enthält Giks-Gutachten mit BGF-Werten, welche die Charts und Tabelle verfälschen können!',
                  BarNotification.DEFAULT
               );
            }
         })
      );
   }

   ngOnInit(): void {
      this.breadcrumbsService.Apply([
         { ...BreadcrumbDefault.SearchMap },
         { label: 'Gutachten Tabelle' }
      ]);
   }

   showSnackbar(message: string) {
      this.snackbarService.showInfo(message);
   }

   showNotificationbar(message: string, type: BarNotification.DEFAULT) {
      this.notificationbarService.show(message, type);
   }

   setAnsatzButtonShowing(showBtn: boolean) {
      this.showSwitchAnsatz = showBtn;
   }

   onExcelDownloadClicked(id: number) {
      this.snackbarService.showInfo('Excel wird heruntergeladen...');
      this.fileService
         .fetchExcelFile([id])
         .subscribe((x) => this.fileService.downloadExcelFile(x, `DataSuite Nr. ${id}`));
   }

   download(id: number) {
      this.snackbarService.showInfo('PDF wird heruntergeladen...');
      const detail = document.getElementById('pdf-detail');
      detail.style.height = 'auto';
      const spacer = document.getElementById('pdf-spacer-detail');
      spacer.style.height = '3000px';
      const frame = document.getElementById('frame-detail');
      frame.style.overflow = 'hidden';
      const opt = {
         margin: 1,
         filename: `DataSuite Nr. ${id}.pdf`,
         image: { type: 'jpeg', quality: 1 },
         html2canvas: {
            dpi: 192,
            scale: 4,
            letterRendering: true,
            useCORS: true
         },
         jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait' }
      };
      html2pdf().from(detail).set(opt).save();

      setTimeout(() => {
         detail.style.height = '0px';
         spacer.style.height = '0px';
         frame.style.overflow = 'auto';
      }, 1000);
   }
}
