import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function checkValidSelection(getValidValues: (group: FormGroup | null) => { name: string }[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        // Field is valid when empty and not required
        if (control.value == null) {
            return null;
        }
        if (control.value == '') {
            return null;
        }
        const value = control.value;
        const validValues = getValidValues(control.parent as FormGroup).map((o) => o.name);
        return validValues.includes(value) ? null : { invalidSelection: true };
    };
}
