import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Collection } from '../../../collection-upload/models/Collection';

@Component({
    selector: 'app-rating-stars',
    templateUrl: './rating-stars.component.html',
    styleUrls: ['./rating-stars.component.scss']
})
export class RatingStarsComponent implements OnInit, OnDestroy {
    @Input() rating: Observable<Collection>;
    @Output() close = new EventEmitter<number>();
    formGroup: UntypedFormGroup;
    ratingSub: Subscription;

    stars = Array(5)
        .fill(null)
        .map((_, i) => ({ id: i + 1, icon: 'star', class: 'star-gray star-hover star' }));

    ngOnInit(): void {
        this.formGroup = new UntypedFormGroup({
            rating: new UntypedFormControl(null)
        });
        this.ratingSub = this.rating.subscribe((r) => this.selectStar(r.bewertungCurrentUser));
    }

    selectStar(value: number): void {
        this.stars.filter((star) => {
            if (star.id <= value) {
                star.class = 'star-gold star';
            } else {
                star.class = 'star-gray star';
            }
            return star;
        });
        this.setStar(value);
        this.formGroup.get('rating').setValue(value);
    }

    setStar(value: number) {
        this.close.emit(value);
    }

    ngOnDestroy(): void {
        this.ratingSub.unsubscribe();
    }
}
