import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { filter, finalize, map, switchMap } from 'rxjs/operators';
import { AppraisalText, AppraisalTextOptions } from '../../interfaces/AppraisalTextTypes';
import { WertbestimmendesVerfahrenNames } from '../../enums/WertbestimmendesVerfahren';
import { AppraisalTextService } from '../../services/appraisal-text.service';
import { CreateDialogComponent } from '../create-dialog/create-dialog.component';
import { LoadingService } from 'src/app/core/services/loading.service';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { combineLatest } from 'rxjs';
import { BreadcrumbDefault, BreadcrumbsService } from 'src/app/core/services/breadcrumbs.service';
import { SnackbarService } from '../../../core/services/snackbar.service';

@Component({
   selector: 'app-text-list',
   templateUrl: './text-list.component.html',
   styleUrls: ['./text-list.component.scss']
})
export class TextListComponent implements OnInit {

   appraisalTexts: AppraisalText[];
   options: AppraisalTextOptions;
   createAllowed: boolean = false;

   displayedColumns: string[] = [
      'auftragsnummer',
      'auftraggeber',
      'auftragsart',
      'wertbestimmendesVerfahren',
      'createdOn',
      'createdBy',
      'actions'
   ];

   public WertbestimmendesVerfahrenNames = WertbestimmendesVerfahrenNames;

   constructor(
      private route: ActivatedRoute,
      private router: Router,
      private appraisalTextService: AppraisalTextService,
      private loadingService: LoadingService,
      private dialog: MatDialog,
      private snackbarService: SnackbarService,
      private breadcrumbsService: BreadcrumbsService) {
   }

   ngOnInit(): void {
      this.loadData();

      this.breadcrumbsService.Apply([
         { ...BreadcrumbDefault.TextgeneratorListLabel }
      ]);
   }

   loadData(): void {
      this.loadingService.setLoading(true);

      const options$ = this.appraisalTextService.loadOptions();
      const appraisalTexts$ = this.appraisalTextService.loadAll();

      // we need both observables to complete for calculating createAllowed
      combineLatest([options$, appraisalTexts$])
         .pipe(
            finalize(() => this.loadingService.setLoading(false))
         )
         .subscribe(
            ([options, appraisalTexts]: any) => {
               this.options = options.data as AppraisalTextOptions;
               this.appraisalTexts = appraisalTexts.data as AppraisalText[];
               this.createAllowed = this.appraisalTexts.length < this.options.createCountUserLimit;
            }
         );
   }

   onCreate() {
      const config = new MatDialogConfig();
      config.disableClose = true;
      const dialogRef = this.dialog.open(CreateDialogComponent, config);

      dialogRef.afterClosed()
         .pipe(
            filter(val => !!val)
         )
         .subscribe(form => {
            let formData = new FormData();
            formData.append('auftragsnummer', form.auftragsnummer);
            formData.append('auftraggeberId', form.auftraggeber);
            formData.append('auftragsartId', form.auftragsart);
            formData.append('wertbestimmendesVerfahren', form.wertbestimmendesVerfahren);

            this.appraisalTextService.create(formData)
               .subscribe(
                  response => {
                     if (response.succeeded) {
                        this.router.navigate([response.data], { relativeTo: this.route });
                     }
                     // 2023-08-31 EHO: else Block wird bei einem gewollten Fehler der API (z.B. 400 Bad Request) nie erreicht. Deshalb besser "error" Handler nutzen.
                     // Siehe weitere HTTP Posts von Digatus, wo ein 400 Bad Request ebenfalls nicht funktionieren sollte.
                     // else {
                     //   this.snackbarService.show('Gutachtentext konnte nicht erstellt werden!', SnackbarType.ERROR);
                     // }
                  },
                  error => {
                     this.snackbarService.showError(error.error.message);
                  }
               );
         });
   }

   onDelete(appraisalText: AppraisalText): void {

      const dialogRef = this.dialog.open(DeleteDialogComponent, {
         data: {
            objekt: "Gutachtentext",
            objektTitel: appraisalText.loraAuftragsnummer
         }, // Pass the object here
      });

      dialogRef.afterClosed()
         .pipe(
            filter((result) => !!result),
            switchMap(() => this.appraisalTextService.delete(appraisalText.id)),
            // when successfully deleted, remove from list
            filter((deleteResult) => !!deleteResult.succeeded) // server-side delete operation successful
         )
         .subscribe(
            () => {
               this.loadData(); // reload data
               this.snackbarService.showSuccess(`Gutachtentext "${appraisalText.loraAuftragsnummer}" wurde gelöscht.`);
            },
            error => {
               this.snackbarService.showError(error.error.message);
            }
         );
   }
}
