import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LatLng } from 'leaflet';
import { MapService } from '../../../core/services/map.service';
import { IOSMNode } from '../../models/IOSMNode';

@Component({
    selector: 'app-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent {
    isOpen = false;
    isFocused = false;

    @Input() placeholder: string;
    @Input() value: string;
    @Input() availableNodes: IOSMNode[];
    @Output() valueChanged: EventEmitter<string> = new EventEmitter();

    @Output() onSelect: EventEmitter<IOSMNode> = new EventEmitter();

    constructor(private mapService: MapService) {}

    onClick(node: IOSMNode): void {
        this.onSelect.emit(node);
        this.isOpen = false;
        this.mapService.setCenterCoords(new LatLng(node.lat, node.lon));
        this.mapService.setZoom(20);
        this.isFocused = false;
    }

    onFocus() {
        this.isFocused = true;
        this.isOpen = true;
    }

    onBlur() {
        this.isFocused = false;
        this.isOpen = false;
    }
}
