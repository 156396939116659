<table class="table table-hover table-borderless">
   <thead>
      <tr>
         <th class="fs-5 fw-normal">Hinweise</th>
      </tr>
   </thead>
   <tbody>
      <tr *ngFor="let note of notes">
         <td class="fs-6 text-danger">{{note}}</td>
      </tr>
   </tbody>
</table>