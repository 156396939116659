<div class="innerframe">
    <ng-container *ngIf="appraisal; else loading">
        <div class="header">
            <select (ngModelChange)="onSelect($event)" [ngModel]="appraisal.id" id="select">
                <option *ngFor="let app of comparableAppraisals" [ngValue]="app.id">
                    {{ app | getAppraisalId }}
                </option>
            </select>
            <button
                (click)="showSnackbar('ID wurde kopiert!')"
                [cdkCopyToClipboard]="appraisal | getAppraisalId"
                class="default btn-copy"
            >
                ID kopieren
            </button>
        </div>
        <div class="content-component">
            <div class="box">
                <app-img [id]="appraisal.id" type="big"></app-img>
            </div>

            <div class="box">
                <app-appraisal-box [title]="'Objektbeschreibung'">
                    <app-appraisal-objektbeschreibung [appraisal]="appraisal"></app-appraisal-objektbeschreibung>
                </app-appraisal-box>
            </div>

<!--            <div class="box" *ngIf="viewOptions.nutzungsart">-->
<!--                <app-appraisal-box [title]="'Nach Nutzungsart'">-->
<!--                    <app-appraisal-nutzungsart [appraisal]="appraisal"></app-appraisal-nutzungsart>-->
<!--                </app-appraisal-box>-->
<!--            </div>-->

            <div class="box">
                <app-appraisal-box [title]="'Ausstattung/Lage'">
                    <app-appraisal-ausstattung-lage [appraisal]="appraisal"></app-appraisal-ausstattung-lage>
                </app-appraisal-box>
            </div>

            <div class="box" *ngIf="viewOptions.fazit">
                <app-appraisal-box [title]="'Fazit'"
                                   [copytext]="appraisal.fazit">
                    <span [innerHTML]="appraisal.fazit"></span>
                </app-appraisal-box>
            </div>
            <div class="box" *ngIf="viewOptions.mikrolage">
                <app-appraisal-box [title]="'Mikrolage'"
                                   [copytext]="appraisal.lage?.mikrolage">
                    <span [innerHTML]="appraisal.lage?.mikrolage"></span>
                </app-appraisal-box>
            </div>
            <div class="box" *ngIf="viewOptions.makrolage">
                <app-appraisal-box [title]="'Makrolage'"
                                   [copytext]="appraisal.lage?.makrolage">
                    <span [innerHTML]="appraisal.lage?.makrolage"></span>
                </app-appraisal-box>
            </div>
            <div class="box" *ngIf="viewOptions.lageAllgemein">
                <app-appraisal-box [title]="'Lage Allgemein'"
                                   [copytext]="appraisal.lage?.lageAllgemein">
                    <span [innerHTML]="appraisal.lage?.lageAllgemein"></span>
                </app-appraisal-box>
            </div>
            <div class="box" *ngIf="viewOptions.vermietungsmarkt">
                <app-appraisal-box [title]="'Vermietungsmarkt'"
                                   [copytext]="appraisal.lage?.vermietungsmarkt">
                    <span [innerHTML]="appraisal.lage?.vermietungsmarkt"></span>
                </app-appraisal-box>
            </div>
            <div class="box" *ngIf="viewOptions.investmentmarkt">
                <app-appraisal-box [title]="'Investmentmarkt'"
                                   [copytext]="appraisal.lage?.investmentmarkt">
                    <span [innerHTML]="appraisal.lage?.investmentmarkt"></span>
                </app-appraisal-box>
            </div>
            <div class="box" *ngIf="viewOptions.immobilienmarkt">
                <app-appraisal-box [title]="'Immobilienmarkt'"
                                   [copytext]="appraisal.lage?.immobilienmarkt">
                    <span [innerHTML]="appraisal.lage?.immobilienmarkt"></span>
                </app-appraisal-box>
            </div>

            <div class="box" *ngIf="viewOptions.besonderheiten">
                <app-appraisal-box [title]="'Besonderheiten'">
                    <app-appraisal-besonderheiten
                        [besonderheiten]="appraisal.besonderheiten"
                    ></app-appraisal-besonderheiten>
                </app-appraisal-box>
            </div>
        </div>
    </ng-container>

    <ng-template #loading>
        <ngx-skeleton-loader
            [theme]="{
                margin: '1rem 0',
                height: '40px'
            }"
            count="1"
        >
        </ngx-skeleton-loader>

        <ngx-skeleton-loader [theme]="boxTheme" count="5"></ngx-skeleton-loader>
    </ng-template>
</div>
