<input #trigger="cdkOverlayOrigin"
       (clickedOutside)="onBlur()"
       (focus)="onFocus()"
       (ngModelChange)="valueChanged.emit($event)"
       (reset)="valueChanged.emit('')"
       [ngModel]="value"
       [placeholder]="placeholder"
       appOutsideClick
       autocomplete="off"
       cdkOverlayOrigin
       class="default"
       id="address"
       type="search"
/>
<ng-template [cdkConnectedOverlayOpen]="isOpen && availableNodes?.length > 0"
             [cdkConnectedOverlayOrigin]="trigger"
             cdkConnectedOverlay>
  <app-list class="overlay-element overflow-auto">
    <app-list-item *ngFor="let node of availableNodes">
      <app-location-list-item (click)="onClick(node)" [node]="node"></app-location-list-item>
    </app-list-item>
  </app-list>
</ng-template>
