import { Component, Input } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: DatepickerComponent,
            multi: true
        }
    ]
})
export class DatepickerComponent implements ControlValueAccessor {
    @Input() data: { placeholder: string; controlName: string; required: boolean };

    get formGroup(): FormGroup {
        return this.controlContainer.control as FormGroup;
    }

    inputAvailable = false;
    onChange = (value: string) => {};

    constructor(private controlContainer: ControlContainer) {}

    setInput(value: string): void {
        this.formGroup.get(this.data.controlName).setValue(value);
        this.onChange(value);
        value != '' && value ? (this.inputAvailable = true) : (this.inputAvailable = false);
    }

    writeValue(value: string): void {
        this.setInput(value);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {}

    setDisabledState(isDisabled: boolean): void {}
}
