import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { WertbestimmendesVerfahren, WertbestimmendesVerfahrenNames } from '../../enums/WertbestimmendesVerfahren';
import { MatDialogRef } from '@angular/material/dialog';
import { AppraisalTextService } from '../../services/appraisal-text.service';
import { map } from 'rxjs/operators';
import { Auftraggeber, Auftragsart } from 'src/app/appraisal-detail/interfaces/AppraisalFilterTypes';

@Component({
  selector: 'app-create-dialog',
  templateUrl: './create-dialog.component.html',
  styleUrls: ['./create-dialog.component.scss']
})

export class CreateDialogComponent implements OnInit {

  orderers: Auftraggeber[];
  orderTypes: Auftragsart[];

  form = this.fb.group({
    auftragsnummer: ['', Validators.required],
    auftraggeber: [null, Validators.required],
    auftragsart: [null, Validators.required],
    wertbestimmendesVerfahren: ["", Validators.required]
  });

  public WertbestimmendesVerfahrenNames = WertbestimmendesVerfahrenNames;

  public verfahrenOptions = Object.values(WertbestimmendesVerfahren).filter(value =>
    value === WertbestimmendesVerfahren.Sachwert
    || value == WertbestimmendesVerfahren.Ertragswert
    || value == WertbestimmendesVerfahren.Vergleichswert
  )

  constructor(
    private fb: FormBuilder,
    private appraisalTextService: AppraisalTextService,
    private dialogRef: MatDialogRef<CreateDialogComponent>) {

  }

  ngOnInit(): void {
    this.loadOrderers();
    this.loadOrderTypes();
    this.disableWertbestimmendesVerfahren();
  }

  disableWertbestimmendesVerfahren(): void {
    const wertbestimmendesVerfahren = this.form.controls["wertbestimmendesVerfahren"];

    this.form.get('auftragsart').valueChanges
    .subscribe(val => {
      if(val === 11) {
        wertbestimmendesVerfahren.disable();
      }
      else{
        wertbestimmendesVerfahren.enable();
      }
    });
  }

  loadOrderers(): void {
    this.appraisalTextService.loadAllOrderers()
      .pipe(map(res => res.data))
      .subscribe(data => {
        this.orderers = data as Auftraggeber[];
      });
  }

  loadOrderTypes(): void {
    this.appraisalTextService.loadAllOrderTypes()
      .pipe(map(res => res.data))
      .subscribe(data => {
        this.orderTypes = data as Auftragsart[];
      });
  }

  close() {
    this.dialogRef.close();
  }

  create(auftragsnummer: string, auftraggeber: number, auftragsart: number, wertbestimmendesVerfahren: WertbestimmendesVerfahren) {
    if(this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }

}
