<div class="grid">
  <div class="info-box-left-centered row-span-2">
    <fa-icon [icon]="faMapMarker"></fa-icon>
    <span>
      {{node.distance}}km
    </span>
  </div>
  <ng-container *ngIf="node.address.road && ((node.address.city || node.address.town || node.address.village) && node.address.postcode); else noRoad">
    <div class="main-text">{{node.address.streetWithNumber}}</div>
    <div class="sub-text">{{node.address.cityWithZip}}</div>
  </ng-container>
  <ng-template #noRoad>
    <div class="main-text row-span-2">{{node.displayName}}</div>
  </ng-template>
</div>
