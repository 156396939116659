<div id="container">
    <div class="row-component space-between" id="header-box">
        <h4 class="header">{{ title }}</h4>
        <button
            (click)="showSnackbar('Text wurde kopiert!')"
            *ngIf="copytext"
            [cdkCopyToClipboard]="copytext | htmlToText"
            class="default"
        >
            Text kopieren
        </button>
    </div>
    <div id="content-box">
        <ng-content></ng-content>
    </div>
</div>
