<div [ngClass]="isOpen ? 'open' : 'closed'" class="left-sidebar column-component">
    <ng-container *ngIf="isOpen">
        <h3>Marktberichte</h3>

        <div class="left-sidebar column-component expansion-wrapper" *ngIf="collections$ | async as mapCollection">

            <ng-container *ngIf="mapCollection.hasData">


                <app-collection-sidebar-entry
                    *ngIf="mapCollection.byGemeinde.length"
                    [data]="mapCollection.byGemeinde"
                    [title]="'Gemeinde ' + mapCollection.gemeinde.name"
                    [isOpen]="isOpen"
                >
                </app-collection-sidebar-entry>

                <app-collection-sidebar-entry
                    *ngIf="mapCollection.byLandkreis.length"
                    [data]="mapCollection.byLandkreis"
                    [title]="'Landkreis ' + mapCollection.landkreis.name"
                    [isOpen]="isOpen"
                >
                </app-collection-sidebar-entry>


                <app-collection-sidebar-entry
                    *ngIf="mapCollection.byBundesland.length"
                    [data]="mapCollection.byBundesland"
                    [title]="mapCollection.bundesland.name"
                    [isOpen]="isOpen"
                >
                </app-collection-sidebar-entry>


                <app-collection-sidebar-entry
                    *ngIf="mapCollection.countryWide.length"
                    [data]="mapCollection.countryWide"
                    [title]="'Deutschland'"
                    [isOpen]="isOpen"
                >
                </app-collection-sidebar-entry>

            </ng-container>

            <ng-container *ngIf="!mapCollection.hasData">
                <span style="margin-right: 10px">Für den ausgewählten Standort gibt es keine weiteren Infos</span>
            </ng-container>
        </div>
    </ng-container>
</div>
