import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Breadcrumb } from '../types/Breadcrumbs';

@Injectable({
   providedIn: 'root'
})
export class BreadcrumbsService {
   private crumbsSubject: Subject<Breadcrumb[]> = new Subject<Breadcrumb[]>();
   public breadcrumbs$ = this.crumbsSubject.asObservable();

   constructor() {
   }

   Apply(crumbs: Breadcrumb[]): void {
      
      // example:
      // const crumbs: Breadcrumb[] = [
      //    { label: 'Textgenerator', url: '/textgenerator' },
      //    { label: 'Textansicht' },
      // ];
      this.crumbsSubject.next(crumbs);
   }
}

export class BreadcrumbDefault {
   static TextgeneratorListLabel: Breadcrumb = { label: 'Gutachtentext Liste' };
   static TextgeneratorList: Breadcrumb = { ...BreadcrumbDefault.TextgeneratorListLabel, url: '/textgenerator/list' };
   static TextgeneratorDetailLabel: Breadcrumb = { label: 'Textansicht' };
   static SearchMapLabel: Breadcrumb = { label: 'Filtermenü Karte' };
   static SearchMap: Breadcrumb = { ...BreadcrumbDefault.SearchMapLabel, url: '/search/map' };
   static SearchListLabel: Breadcrumb = { label: 'Filtermenü Liste' };
   static SearchList: Breadcrumb = { ...BreadcrumbDefault.SearchListLabel, url: '/search/list' };
   static UploadListLabel: Breadcrumb = { label: 'Research' };
   static UploadList: Breadcrumb = { ...BreadcrumbDefault.UploadListLabel, url: '/upload/list' };
   static CollectionLabel: Breadcrumb = { label: 'Sammlungsansicht' };
}
