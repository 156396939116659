import { Component, Input, OnDestroy } from '@angular/core';
import { Control, Map } from 'leaflet';
import { CustomZoomControl } from '../CustomControls';

@Component({
    selector: 'app-zoom-control',
    templateUrl: './zoom-control.component.html',
    styleUrls: ['./zoom-control.component.scss']
})
export class ZoomControlComponent implements OnDestroy {
    custom: Control;
    private _map: Map;

    get map(): Map {
        return this._map;
    }

    @Input() set map(map: Map) {
        if (map) {
            this._map = map;
            this.custom = new CustomZoomControl({ position: 'bottomright' }).addTo(map);
        }
    }

    ngOnDestroy() {
        this._map.removeControl(this.custom);
    }

    onZoomChange(delta: number): void {
        this._map.zoomIn(delta);
    }
}
