import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Segment } from '../components/segmented-control/Segment';

@Component({
    template: ``,
    selector: 'app-segment'
})
export class RouteTransform implements OnDestroy {
    segments: Segment[];
    private routeSub: Subscription;

    constructor(private route: ActivatedRoute, private router: Router) {
        this.routeSub = this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.route.snapshot.routeConfig.children),
                map((children) =>
                    children
                        .filter((r) => r.path.length > 0)
                        .map(({ path, data }) => ({ text: data?.label, route: path }))
                )
            )
            .subscribe((segments: Segment[]) => (this.segments = segments));
    }

    ngOnDestroy(): void {
        this.routeSub?.unsubscribe();
    }
}
