<div id="table-container">
    <form [formGroup]="formGroup" class="dialog-content">
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Postleitzahl</mat-label>
            <input
                name="plz"
                type="text"
                placeholder="Postleitzahl eingeben"
                matInput
                formControlName="postleitzahl"
                [matAutocomplete]="autoPostalCode"
            />
            <mat-autocomplete autoActiveFirstOption #autoPostalCode="matAutocomplete">
                <mat-option *ngFor="let postalCode of filteredPlzOptions | async" [value]="postalCode.plz">
                    {{ postalCode.plz }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Gemeinde</mat-label>
            <input
                type="text"
                placeholder="Gemeinde eingeben"
                matInput
                formControlName="gemeinde"
                [matAutocomplete]="autoCommunes"
            />
            <mat-autocomplete
                autoActiveFirstOption
                #autoCommunes="matAutocomplete"
                (optionSelected)="onOptionSelected($event)"
            >
                <mat-option *ngFor="let commune of filteredGemeindeOptions | async" [value]="commune">
                    <span>{{ commune.name + ' - ' + commune.kreisGemeindeSchluessel }}</span>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Gemeindekennziffer</mat-label>
            <input
                type="text"
                placeholder="KGS eingeben"
                matInput
                formControlName="gemeindekennziffer"
                [matAutocomplete]="autoMunicipalCode"
            />
            <mat-autocomplete autoActiveFirstOption #autoMunicipalCode="matAutocomplete">
                <mat-option
                    *ngFor="let gemeindekennziffer of filteredGemeindekennzifferOptions | async"
                    [value]="gemeindekennziffer"
                >
                    {{ gemeindekennziffer.kreisGemeindeSchluessel }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Bundesland</mat-label>
            <input
                type="text"
                placeholder="Bundesland eingeben"
                matInput
                formControlName="bundesland"
                [matAutocomplete]="autoState"
            />
            <mat-autocomplete autoActiveFirstOption #autoState="matAutocomplete">
                <mat-option *ngFor="let state of filteredBundeslandOptions | async" [value]="state.name">
                    {{ state.name }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Landkreis</mat-label>
            <input
                type="text"
                placeholder="Landkreis eingeben"
                matInput
                formControlName="landkreis"
                [matAutocomplete]="autoCounty"
            />
            <mat-autocomplete autoActiveFirstOption #autoCounty="matAutocomplete">
                <mat-option
                    *ngFor="let county of filteredLandkreisOptions | async; trackBy: trackCounty"
                    [value]="county.name"
                >
                    {{ county.name }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-checkbox class="checkbox" formControlName="countryWide">Deutschlandweit?
        </mat-checkbox>
    </form>
</div>
