<div *ngIf="showScatters">
    <p class="chart-category-title title">Zeitreihen</p>
    <div [ngClass]="pdfStyle ? 'chartframePdf' : 'chartframeWeb'" class="chartframe">
        <app-chart-box *ngIf="kaufpreisEuroQmScatter$ | async as kaufpreisChartData" [pdfStyle]="pdfStyle">
            <ng-container header>Kaufpreis in € pro m²</ng-container>
            <ng-container content>
                <div class="scatter-chart-content">
                    <app-chart [chartData]="kaufpreisChartData" type="scatter"></app-chart>
                </div>
            </ng-container>
            <ng-container footer>
                <div class="scatter-chart-footer">
                    <p>€ pro m² / Jahr</p>
                    <p class="count">{{ kaufpreisChartData.count.used }} von {{ kaufpreisChartData.count.all }}</p>
                </div>
            </ng-container>
        </app-chart-box>

        <app-chart-box *ngIf="kaufpreisAbsolutScatter$ | async as kaufpreisChartData" [pdfStyle]="pdfStyle">
            <ng-container header>Kaufpreis in €</ng-container>
            <ng-container content>
                <div class="scatter-chart-content">
                    <app-chart [chartData]="kaufpreisChartData" type="scatter"></app-chart>
                </div>
            </ng-container>
            <ng-container footer>
                <div class="scatter-chart-footer">
                    <p>€ / Jahr</p>
                    <p class="count">{{ kaufpreisChartData.count.used }} von {{ kaufpreisChartData.count.all }}</p>
                </div>
            </ng-container>
        </app-chart-box>

        <app-chart-box *ngIf="marktwertEuroQmScatter$ | async as marktwertChartData" [pdfStyle]="pdfStyle">
            <ng-container header>Marktwert in € pro m²</ng-container>
            <ng-container content>
                <div class="scatter-chart-content">
                    <app-chart [chartData]="marktwertChartData" type="scatter"></app-chart>
                </div>
            </ng-container>
            <ng-container footer>
                <div class="scatter-chart-footer">
                    <p>€ pro m² / Jahr</p>
                    <p class="count">{{ marktwertChartData.count.used }} von {{ marktwertChartData.count.all }}</p>
                </div>
            </ng-container>
        </app-chart-box>

        <app-chart-box *ngIf="marktwertAbsolutScatter$ | async as marktwertChartData" [pdfStyle]="pdfStyle">
            <ng-container header>Marktwert in €</ng-container>
            <ng-container content>
                <div class="scatter-chart-content">
                    <app-chart [chartData]="marktwertChartData" type="scatter"></app-chart>
                </div>
            </ng-container>
            <ng-container footer>
                <div class="scatter-chart-footer">
                    <p>Marktwert absolut / Jahr</p>
                    <p class="count">{{ marktwertChartData.count.used }} von {{ marktwertChartData.count.all }}</p>
                </div>
            </ng-container>
        </app-chart-box>

        <app-chart-box *ngIf="xfachesScatter$ | async as xfachesChartData" [pdfStyle]="pdfStyle">
            <ng-container header>X-Faches</ng-container>
            <ng-container content>
                <div class="scatter-chart-content">
                    <app-chart [chartData]="xfachesChartData" type="scatter"></app-chart>
                </div>
            </ng-container>
            <ng-container footer>
                <div class="scatter-chart-footer">
                    <p>X-Faches / Jahr</p>
                    <p class="count">{{ xfachesChartData.count.used }} von {{ xfachesChartData.count.all }}</p>
                </div>
            </ng-container>
        </app-chart-box>

        <app-chart-box
            *ngIf="switchAnsatzActive && (renditeAnsatzScatter$ | async) as xfachesChartData"
            [pdfStyle]="pdfStyle"
        >
            <ng-container header>Rendite Ansatz</ng-container>
            <ng-container content>
                <div class="scatter-chart-content">
                    <app-chart [chartData]="xfachesChartData" type="scatter"></app-chart>
                </div>
            </ng-container>
            <ng-container footer>
                <div class="scatter-chart-footer">
                    <p>Rendite / Jahr</p>
                    <p class="count">{{ xfachesChartData.count.used }} von {{ xfachesChartData.count.all }}</p>
                </div>
            </ng-container>
        </app-chart-box>

        <app-chart-box
            *ngIf="!switchAnsatzActive && (renditeIstScatter$ | async) as xfachesChartData"
            [pdfStyle]="pdfStyle"
        >
            <ng-container header>Rendite Ist</ng-container>
            <ng-container content>
                <div class="scatter-chart-content">
                    <app-chart [chartData]="xfachesChartData" type="scatter"></app-chart>
                </div>
            </ng-container>
            <ng-container footer>
                <div class="scatter-chart-footer">
                    <p>Rendite / Jahr</p>
                    <p class="count">{{ xfachesChartData.count.used }} von {{ xfachesChartData.count.all }}</p>
                </div>
            </ng-container>
        </app-chart-box>
    </div>
</div>

<div *ngIf="showHistograms">
    <p class="chart-category-title title-sub">Daten aus aktueller Auswahl</p>
    <div [ngClass]="pdfStyle ? 'chartframePdf' : 'chartframeWeb'" class="chartframe">
        <app-chart-box *ngIf="objektArtChartData$ | async as objektChartData" [pdfStyle]="pdfStyle">
            <ng-container header>Objektart</ng-container>
            <ng-container content>
                <div class="pie-chart-content">
                    <app-chart [chartData]="objektChartData" type="doughnut"></app-chart>
                </div>
            </ng-container>
        </app-chart-box>

        <app-chart-box *ngIf="anzahlObjekte" [pdfStyle]="pdfStyle">
            <ng-container header>Anzahl der Objekte</ng-container>
            <ng-container content>
                <div class="number-chart-content">
                    <h2>{{ anzahlObjekte }}</h2>
                </div>
            </ng-container>
        </app-chart-box>

        <app-chart-box *ngIf="kaufpreisEuroQmHistogramm$ | async as kaufpreisChartData" [pdfStyle]="pdfStyle">
            <ng-container header>Kaufpreis in € pro m²</ng-container>
            <ng-container content>
                <div class="bar-chart-content">
                    <app-chart [chartData]="kaufpreisChartData" type="bar"></app-chart>
                </div>
            </ng-container>
            <ng-container footer>
                <div class="bar-chart-footer">
                    <p>Anzahl / € pro m²</p>
                    <p class="count">{{ kaufpreisChartData.count.used }} von {{ kaufpreisChartData.count.all }}</p>
                </div>
            </ng-container>
        </app-chart-box>

        <app-chart-box *ngIf="kaufpreisAbsolutHistogramm$ | async as kaufpreisChartData" [pdfStyle]="pdfStyle">
            <ng-container header>Kaufpreis in €</ng-container>
            <ng-container content>
                <div class="bar-chart-content">
                    <app-chart [chartData]="kaufpreisChartData" type="bar"></app-chart>
                </div>
            </ng-container>
            <ng-container footer>
                <div class="bar-chart-footer">
                    <p>Anzahl / €</p>
                    <p class="count">{{ kaufpreisChartData.count.used }} von {{ kaufpreisChartData.count.all }}</p>
                </div>
            </ng-container>
        </app-chart-box>

        <app-chart-box *ngIf="marktwertEuroQmHistogramm$ | async as marktwertChartData" [pdfStyle]="pdfStyle">
            <ng-container header>Marktwert in € pro m²</ng-container>
            <ng-container content>
                <div class="bar-chart-content">
                    <app-chart [chartData]="marktwertChartData" type="bar"></app-chart>
                </div>
            </ng-container>
            <ng-container footer>
                <div class="bar-chart-footer">
                    <p>Anzahl / € pro m²</p>
                    <p class="count">{{ marktwertChartData.count.used }} von {{ marktwertChartData.count.all }}</p>
                </div>
            </ng-container>
        </app-chart-box>

        <app-chart-box *ngIf="marktwertAbsolutHistogramm$ | async as marktwertChartData" [pdfStyle]="pdfStyle">
            <ng-container header>Marktwert in €</ng-container>
            <ng-container content>
                <div class="bar-chart-content">
                    <app-chart [chartData]="marktwertChartData" type="bar"></app-chart>
                </div>
            </ng-container>
            <ng-container footer>
                <div class="bar-chart-footer">
                    <p>Anzahl / Marktwert absolut</p>
                    <p class="count">{{ marktwertChartData.count.used }} von {{ marktwertChartData.count.all }}</p>
                </div>
            </ng-container>
        </app-chart-box>

        <app-chart-box *ngIf="xfachesHistogramm$ | async as xfachesChartData" [pdfStyle]="pdfStyle">
            <ng-container header>X-Faches</ng-container>
            <ng-container content>
                <div class="bar-chart-content">
                    <app-chart [chartData]="xfachesChartData" type="bar"></app-chart>
                </div>
            </ng-container>
            <ng-container footer>
                <div class="bar-chart-footer">
                    <p>Anzahl / X-Faches</p>
                    <p class="count">{{ xfachesChartData.count.used }} von {{ xfachesChartData.count.all }}</p>
                </div>
            </ng-container>
        </app-chart-box>

        <app-chart-box
            *ngIf="switchAnsatzActive && (renditeAnsatzHistogramm$ | async) as xfachesChartData"
            [pdfStyle]="pdfStyle"
        >
            <ng-container header>Rendite Ansatz</ng-container>
            <ng-container content>
                <div class="bar-chart-content">
                    <app-chart [chartData]="xfachesChartData" type="bar"></app-chart>
                </div>
            </ng-container>
            <ng-container footer>
                <div class="bar-chart-footer">
                    <p>Anzahl / Rendite</p>
                    <p class="count">{{ xfachesChartData.count.used }} von {{ xfachesChartData.count.all }}</p>
                </div>
            </ng-container>
        </app-chart-box>

        <app-chart-box
            *ngIf="!switchAnsatzActive && (renditeIstHistogramm$ | async) as xfachesChartData"
            [pdfStyle]="pdfStyle"
        >
            <ng-container header>Rendite Ist</ng-container>
            <ng-container content>
                <div class="bar-chart-content">
                    <app-chart [chartData]="xfachesChartData" type="bar"></app-chart>
                </div>
            </ng-container>
            <ng-container footer>
                <div class="bar-chart-footer">
                    <p>Anzahl / Rendite</p>
                    <p class="count">{{ xfachesChartData.count.used }} von {{ xfachesChartData.count.all }}</p>
                </div>
            </ng-container>
        </app-chart-box>

        <app-chart-box *ngIf="lzsHistogramm$ | async as lzsChartData" [pdfStyle]="pdfStyle">
            <ng-container header>LZS</ng-container>
            <ng-container content>
                <div class="bar-chart-content">
                    <app-chart [chartData]="lzsChartData" type="bar"></app-chart>
                </div>
            </ng-container>
            <ng-container footer>
                <div class="bar-chart-footer">
                    <p>Anzahl / LZS</p>
                    <p class="count">{{ lzsChartData.count.used }} von {{ lzsChartData.count.all }}</p>
                </div>
            </ng-container>
        </app-chart-box>

        <app-chart-box *ngIf="wnfHistogramm$ | async as wnfChartData" [pdfStyle]="pdfStyle">
            <ng-container header>WNF</ng-container>
            <ng-container content>
                <div class="bar-chart-content">
                    <app-chart [chartData]="wnfChartData" type="bar"></app-chart>
                </div>
            </ng-container>
            <ng-container footer>
                <div class="bar-chart-footer">
                    <p>Anzahl / WNF</p>
                    <p class="count">{{ wnfChartData.count.used }} von {{ wnfChartData.count.all }}</p>
                </div>
            </ng-container>
        </app-chart-box>

        <app-chart-box *ngIf="ausstattungHistogramm$ | async as ausstChartData" [pdfStyle]="pdfStyle">
            <ng-container header>Ausstattung</ng-container>
            <ng-container content>
                <div class="bar-chart-content">
                    <app-chart [chartData]="ausstChartData" type="bar"></app-chart>
                </div>
            </ng-container>
            <ng-container footer>
                <div class="bar-chart-footer">
                    <p>Anzahl / Ausstattung</p>
                    <p class="count">{{ ausstChartData.count.used }} von {{ ausstChartData.count.all }}</p>
                </div>
            </ng-container>
        </app-chart-box>

        <app-chart-box *ngIf="baujahrHistogramm$ | async as baujahrChartData" [pdfStyle]="pdfStyle">
            <ng-container header>Baujahresklassen</ng-container>
            <ng-container content>
                <div class="bar-chart-content">
                    <app-chart [chartData]="baujahrChartData" type="bar"></app-chart>
                </div>
            </ng-container>
            <ng-container footer>
                <div class="bar-chart-footer">
                    <p>Anzahl / Baujahresklasse</p>
                    <p class="count">{{ baujahrChartData.count.used }} von {{ baujahrChartData.count.all }}</p>
                </div>
            </ng-container>
        </app-chart-box>

        <app-chart-box *ngIf="mikrolageHistogramm$ | async as mikrolageChartData" [pdfStyle]="pdfStyle">
            <ng-container header>Mikrolage</ng-container>
            <ng-container content>
                <div class="bar-chart-content">
                    <app-chart [chartData]="mikrolageChartData" type="bar"></app-chart>
                </div>
            </ng-container>
            <ng-container footer>
                <div class="bar-chart-footer">
                    <p>Anzahl / Lagequalität</p>
                    <p class="count">{{ mikrolageChartData.count.used }} von {{ mikrolageChartData.count.all }}</p>
                </div>
            </ng-container>
        </app-chart-box>
    </div>
</div>
