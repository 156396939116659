<div class="left-sidebar column-component">
    <ng-container>
        <div class="divider range"></div>

        <app-expansion-panel>
            <ng-container header>
                <span class="title">Titel</span>
            </ng-container>
            <ng-container content>
                <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>Titel</mat-label>
                    <input
                        name="Titel"
                        type="text"
                        placeholder="Titel eingeben"
                        matInput
                        [(ngModel)]="selectedTitle"
                        (ngModelChange)="setTitleFilter()"
                    />
                </mat-form-field>
            </ng-container>
        </app-expansion-panel>

        <app-expansion-panel>
            <ng-container header>
                <span class="title">Kategorie</span>
            </ng-container>
            <ng-container content>
                <div class="inrow">
                    <app-select
                        (selectionChange)="setCategoryFilter($event)"
                        [selection]="dropKategorieCurrentSelection"
                        [options]="DropKategorie"
                    >
                        <ng-container defaultText>Kategorie</ng-container>
                    </app-select>
                </div>
            </ng-container>
        </app-expansion-panel>
        <div class="divider range"></div>
        <app-expansion-panel>
            <ng-container header>
                <span class="title">Nutzung</span>
            </ng-container>
            <ng-container content>
                <div class="inrow">
                    <app-multi-select
                        (currentSelectionChange)="setNutzungFilter($event)"
                        [currentSelectionIds]="selectedNutzungsArten"
                        [options]="nutzungsartenSelectOption"
                    >
                        <ng-container defaultText>Nutzungsarten</ng-container>
                    </app-multi-select>
                </div>

                <div class="spacer"></div>

                <ng-container content *ngIf="filteredObjektarten.length">
                    <app-multi-select
                        (currentSelectionChange)="setObjektArtenFilter($event)"
                        [currentSelectionIds]="selectedObjektarten"
                        [options]="filteredObjektarten"
                    >
                        <ng-container defaultText>Objektarten</ng-container>
                    </app-multi-select>
                </ng-container>
            </ng-container>
        </app-expansion-panel>
        <div class="divider range"></div>
        <app-expansion-panel>
            <ng-container header>
                <span class="title">Lage</span>
            </ng-container>
            <ng-container content>
                <app-lage-filter *ngIf="locationData" [data]="locationData" (valueChanged)="lageChanged($event)">
                </app-lage-filter>
            </ng-container>
        </app-expansion-panel>
        <div class="divider range"></div>
        <app-expansion-panel>
            <ng-container header>
                <span class="title">Herausgabedatum</span>
            </ng-container>

            <ng-container content>
                <div class="inrow">
                    <mat-form-field appearance="fill" class="datePicker">
                        <mat-label>Start</mat-label>
                        <input
                            matInput
                            [matDatepicker]="start_picker"
                            [(ngModel)]="publicationsDateStart"
                            (ngModelChange)="setPublicationDateFilterStart($event)"
                            name="publicationsDateStart"
                        />
                        <mat-datepicker-toggle matIconSuffix [for]="start_picker">
                            <mat-icon matDatepickerToggleIcon>
                                <span class="material-symbols-outlined"> calendar_month </span></mat-icon
                            >
                        </mat-datepicker-toggle>
                        <mat-datepicker #start_picker></mat-datepicker>
                    </mat-form-field>

                    <button
                        mat-icon-button
                        *ngIf="!!publicationsDateStart"
                        style="margin-top: 6px"
                        (click)="setPublicationDateFilterStart(null)"
                    >
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
                <div class="inrow">
                    <mat-form-field appearance="fill" class="datePicker">
                        <mat-label>Ende</mat-label>
                        <input
                            matInput
                            [matDatepicker]="end_picker"
                            [(ngModel)]="publicationsDateEnd"
                            (ngModelChange)="setPublicationDateFilterEnd($event)"
                            name="publicationsDateEnd"
                        />
                        <mat-datepicker-toggle matIconSuffix [for]="end_picker">
                            <mat-icon matDatepickerToggleIcon>
                                <span class="material-symbols-outlined"> calendar_month </span></mat-icon
                            >
                        </mat-datepicker-toggle>
                        <mat-datepicker #end_picker></mat-datepicker>
                    </mat-form-field>

                    <button
                        mat-icon-button
                        *ngIf="!!publicationsDateEnd"
                        style="margin-top: 6px"
                        (click)="setPublicationDateFilterEnd(null)"
                    >
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </ng-container>
        </app-expansion-panel>
        <div class="divider range"></div>
        <app-expansion-panel>
            <ng-container header>
                <span class="title">Publikationsturnus</span>
            </ng-container>
            <ng-container content>
                <div class="inrow">
                    <app-select
                        (selectionChange)="setTurnusFilter($event)"
                        [options]="DropPublikationsturnus"
                        [selection]="DropPublikationsTurnusSelection"
                    >
                        <ng-container defaultText>Publikationsturnus</ng-container>
                    </app-select>
                </div>
            </ng-container>
        </app-expansion-panel>
        <div class="divider range"></div>
        <app-expansion-panel>
            <ng-container header>
                <span class="title">Herausgeber</span>
            </ng-container>
            <ng-container content>
                <div class="inrow">

                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Herausgeber*</mat-label>
                        <input
                            type="text"
                            placeholder="Herausgeber"
                            matInput
                            [matAutocomplete]="herausgeber"
                            [formControl]="herausgeberControl"
                        />
                        <mat-autocomplete
                            [panelWidth]="'500px'"
                            [displayWith]="displayFn.bind(this)"
                            autoActiveFirstOption
                            #herausgeber="matAutocomplete"
                            (optionSelected)="setHerausgeber($event)"
                        >
                            <mat-option
                                *ngFor="let herausgeber of filteredHerausgeberSelectOption$ | async"
                                [value]="herausgeber.value"
                            >
                                <span>{{ herausgeber.label }}</span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                </div>
            </ng-container>
        </app-expansion-panel>

        <div class="divider range"></div>

        <app-expansion-panel>
            <ng-container header>
                <span class="title">Offenes Feedback</span>
            </ng-container>
            <ng-container content>
                <mat-checkbox
                    [(ngModel)]="openFeedbackOnly"
                    (change)="onOpenFeedbackOnlySelected($event)" class="checkbox"
                    >nur Sammlungen mit <br />
                    offenem Feedback anzeigen
                </mat-checkbox>
            </ng-container>
        </app-expansion-panel>

        <app-expansion-panel *ngIf="isUploader">
            <ng-container header>
                <span class="title">Ablaufende Berichte</span>
            </ng-container>
            <ng-container content>
                <mat-checkbox
                    [(ngModel)]="overdueOnly"
                    (change)="onOverdueOnlySelected($event)" class="checkbox"
                >nur Sammlungen anzeigen <br/>
                    die demnächst ablaufen
                </mat-checkbox>
            </ng-container>
        </app-expansion-panel>
    </ng-container>

    <button
        (click)="resetFilter()"
        *ngIf="!collectionService.noFilter"
        class="active-btn default btn-switch btn-ansatz margin-small"
    >
        Filter zurücksetzen
    </button>
</div>
