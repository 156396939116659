import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppraisalTextService } from './appraisal-text.service';
import { AppraisalText } from '../interfaces/AppraisalTextTypes';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
   providedIn: 'root'
})
export class DetailResolverService implements Resolve<AppraisalText> {

   constructor(
      private appraisalTextService: AppraisalTextService,
      private snackbarService: SnackbarService
   ) { }

   resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AppraisalText> {
      const appraisalTextId: number = Number(route.paramMap.get("appraisalTextId"));
      return this.appraisalTextService.loadOne(appraisalTextId)
         .pipe(
            map(res => res.data),
            catchError(err => {
               if(err instanceof HttpErrorResponse) {
                  this.snackbarService.showError(err.error.message);
               }
               return throwError(err);
            })
         );
   }
}
