import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { AppraisalModule } from '../appraisal-detail/appraisal.module';
import { GridListItemComponent } from '../shared/components/list-item/grid-list-item/grid-list-item.component';
import { SharedModule } from '../shared/shared.module';
import { FilterSidebarComponent } from './components/filter-sidebar/filter-sidebar.component';
import { ListViewComponent } from './components/list-view/list-view.component';
import { MapViewComponent } from './components/map-view/map-view.component';
import { LayerControlComponent } from './components/map-view/map/controls/layer-control/layer-control.component';
import { ZoomControlComponent } from './components/map-view/map/controls/zoom-control/zoom-control.component';
import { MapComponent } from './components/map-view/map/map.component';
import { PopupComponent } from './components/map-view/map/popup/popup.component';
import { SegmentSearchComponent } from './components/segment-search/segment-search.component';
import { SearchRoutingModule } from './search-routing.module';

const declarations = [
    MapComponent,
    PopupComponent,
    ListViewComponent,
    MapViewComponent,
    ZoomControlComponent,
    LayerControlComponent,
    SegmentSearchComponent,
    FilterSidebarComponent,
    GridListItemComponent
];

@NgModule({
    declarations,
    imports: [
        CommonModule,
        SearchRoutingModule,
        SharedModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        AppraisalModule,
        LeafletModule,
        LeafletMarkerClusterModule,
        FormsModule,
        MatTooltipModule
    ],
    exports: [...declarations]
})
export class SearchModule {}
