<ng-container *ngIf="appraisal$ | async as appraisal; else notFound">
    <app-img [id]="appraisal.gutachtenId" size="70" type="big" class="centered"></app-img>
    <h4 class="centered">{{ appraisal.objektUnterArt?.name || appraisal.objektArt.name }}</h4>

    <div id="content-box">
        <div>
            Lora/Giks: <span>{{ appraisal.loraAuftragsNummer || appraisal.giksId }}</span>
        </div>
        <div>
            Marktwert:
            <span>
                <app-number-display
                    [value]="appraisal.marktwert"
                    [placeHolderParams]="{ suffix: '€' }"
                ></app-number-display>
            </span>
        </div>
        <div>
            Marktwert pro m²:
            <span>
                <app-number-display
                    [value]="appraisal.marktwertProQuadratmeter"
                    [placeHolderParams]="{ suffix: '€/m²' }"
                ></app-number-display>
            </span>
        </div>
        <div>
            Baujahr: <span>{{ appraisal.baujahr | gibtBaujahreRange: appraisal.baujahrFlaechengewichtet }}</span>
        </div>
        <div>
            Wertertmittlung: <span>{{ appraisal.bewertungsstichtag | date: 'yyyy' }}</span>
        </div>
        <div>
            WNFl.:
            <span
                ><app-number-display
                    [value]="appraisal.wohnNutzflaeche"
                    [placeHolderParams]="{ suffix: 'm²' }"
                ></app-number-display
            ></span>
        </div>
        <div>
            Auftraggeber: <span>{{ appraisal.auftraggeber.loraAuftraggeberName }}</span>
        </div>
        <div>
            Auftragsart: <span>{{ appraisal.auftragsart?.name ?? '-' }}</span>
        </div>
    </div>

    <div class="button-row-popup centered">
        <button [routerLink]="['/detail', appraisal.gutachtenId]" class="primary">Ansehen</button>
        <button
            (click)="addComparableObject(appraisal.gutachtenId)"
            [ngClass]="(comparableAppraisals$ | async).includes(appraisal.gutachtenId) ? 'accent' : 'default'"
            class="default"
        >
            Vergleichen
        </button>
    </div>
</ng-container>

<ng-template #notFound>
    <h3>Kein Objekt gefunden</h3>
</ng-template>
