import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-chart-box',
  templateUrl: './chart-box.component.html',
  styleUrls: ['./chart-box.component.scss']
})
export class ChartBoxComponent {

  @Input() pdfStyle: boolean;

}
