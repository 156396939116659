<button
    #trigger="cdkOverlayOrigin"
    (click)="revertOpen()"
    cdkOverlayOrigin
    class="default row-component centered"
    type="button"
    [style.height.px]="height"
>
    <span>
        <ng-content select="[text]"></ng-content>
    </span>
    <fa-icon
        [ngClass]="{ 'fa-icon-when-required': showRequired, 'fa-icon-when-not-required': showRequired }"
        *ngIf="isOpen"
        [icon]="faChevronUp"
    ></fa-icon>
    <fa-icon
        [ngClass]="{ 'fa-icon-when-required': showRequired, 'fa-icon-when-not-required': showRequired }"
        *ngIf="!isOpen"
        [icon]="faChevronDown"
    ></fa-icon>
    <label *ngIf="showRequired" id="requiredField">*</label>
    <ng-content select="[title]"></ng-content>
</button>

<ng-template
    (backdropClick)="isOpen = false"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayOpen]="isOpen"
    [cdkConnectedOverlayOrigin]="trigger"
    cdkConnectedOverlay
>
    <ng-content select="[content]"></ng-content>
</ng-template>
