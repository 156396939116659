import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class FileService {
    excelSub: Subscription;
    private file$: BehaviorSubject<File> = new BehaviorSubject(null);

    constructor(private apiService: ApiService) {}

    get file(): Observable<File> {
        return this.file$.asObservable();
    }

    nextFile(file: File) {
        this.file$.next(file);
    }

    fetchExcelFile(ids: number[]): Observable<Blob> {
        const httpParams = new HttpParams({
            fromObject: {
                ids: ids.map(String)
            }
        });
        return this.apiService.get<Blob>(environment.fileUrl + '/ExcelAppraisals', httpParams, 'blob');
    }

    downloadExcelFile(response: any, fileName?: string) {
        const blob = new Blob([response], { type: 'application/vnd.ms.excel' });
        const file = new File([blob], fileName + '.xlsx', { type: 'application/vnd.ms.excel' });
        saveAs(file);
    }
}
