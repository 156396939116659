<form [formGroup]="form">

    <textarea formControlName="description"
              [rows]="10"
              placeholder="Beschreiben Sie das Problem mit dieser Collection">

    </textarea>

    <div class="row-component" id="btns">
        <button
            (click)="submit()"
            type="submit"
            class="primary btns"
            id="btn-submit"
            [disabled]="!form.valid">
            {{ 'Speichern' }}
        </button>
        <button (click)="cancel()" class="default btns">Abbrechen</button>
    </div>
</form>
