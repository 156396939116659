import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter, takeUntil } from 'rxjs/operators';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { DialogType } from 'src/app/shared/models/Dialog';
import { Destroyable } from '../../../shared/directives/destroyable';
import { AuthService } from '../../guards/auth.service';
import { SidebarNavigationService } from '../../services/sidebar-navigation.service';

@Component({
   selector: 'app-navbar',
   templateUrl: './navbar.component.html',
   styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent extends Destroyable {

   constructor(
      private authService: AuthService,
      private dialog: MatDialog,
      public sidebarService: SidebarNavigationService,
   ) {
      super();
   }

   onLogout() {
      const dialogRef = this.dialog.open(DialogComponent, {
         maxWidth: '400px',
         data: {
            dialogType: {
               type: DialogType.Confirm,
               title: 'Logout',
               text: 'Wollen Sie sich wirklich abmelden?',
               primaryBtnTitle: 'Ausloggen'
            }
         }
      });

      dialogRef
         .afterClosed()
         .pipe(
            takeUntil(this._destroyed$),
            filter((shouldLogout: boolean) => shouldLogout)
         )
         .subscribe(() => this.authService.startLogoutProcess());
   }

}
