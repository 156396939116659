import { Component, Input } from '@angular/core';
import { Appraisal } from '../../../../models/Appraisal';

@Component({
    selector: 'app-appraisal-nutzungsart',
    templateUrl: './appraisal-nutzungsart.component.html',
    styleUrls: ['./appraisal-nutzungsart.component.scss', '../appraisal-standards.scss']
})
export class AppraisalNutzungsartComponent {
    @Input() appraisal: Appraisal;
}
