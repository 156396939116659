<div *ngIf="file; else noFile" class="box">
    <div *ngIf="file.mimeType === MimeTypes.URL" class="container container-link">
        <div class="btn-box">
            <button class="dark" (click)="openLink()" [cdkCopyToClipboard]="file.url">
                {{ file.url }}
            </button>
        </div>
    </div>

    <div *ngIf="file.mimeType === MimeTypes.PDF" class="container">
        <pdf-viewer
            [src]="file.url.tempFileLink"
            (after-load-complete)="callBackFn($event)"
            [render-text]="true"
            [original-size]="false"
            [fit-to-page]="true"
            [autoresize]="true"
            [zoom]="ZOOM"
        ></pdf-viewer>
        <div id="controls">
            <button class="default" (click)="zoomIn()" id="plus">+</button>
            <button class="default" (click)="zoomOut()" id="minus">-</button>
        </div>
    </div>

    <div *ngIf="file.mimeType === MimeTypes.DOCX || file.mimeType === MimeTypes.XLSX" class="container container-link">
        <div class="btn-box">
            <button class="dark">Dateityp wird aktuell noch nicht unterstützt</button>
        </div>
    </div>
</div>
<ng-template #noFile id="no-file">
    <div class="btn-box box">Kein Dokument gewählt</div>
</ng-template>
