export const environmentDefaults = {
    appraisalUrl: 'Appraisals',
    appraisalsByIdUrl: 'Appraisals/appraisalByIds',
    fileUrl: 'File',
    objektArtUrl: 'Appraisals/objectType',
    nutzungsArtUrl: 'Appraisals/nutzungsArten',
    lageQualitaetenUrl: 'Appraisals/lageQualitaeten',
    baujahresKlassenUrl: 'Appraisals/baujahresKlassen',
    ausstatungsQualitaetenUrl: 'Appraisals/ausstattungsQualitaeten',
    nachhaltikeitsZertifikatUrl: 'Appraisals/nachhaltigkeitsZertifikate',
    energieeffizienzKlasseUrl: 'Appraisals/energieeffizienzKlassen',
    besonderheitenUrl: 'Appraisals/besonderheiten',
    objektzustandUrl: 'Appraisals/objektzustaende',
    auftragsartenUrl: 'Appraisals/auftragsarten',
    auftraggeberUrl: 'Appraisals/auftraggeber',
    filteredAppraisalUrl: 'Appraisals/filtered',
    addressUrl: 'Address',
    collectionUrl: 'collection',
    collectionByIdUrl: 'collection',
    collectionFeedbackUrl: 'collectionFeedback',
    collectionLageContainerUrl: 'collection/communal',
    collectionCreateUrl: 'collection/create',
    collectionUpdateUrl: 'collection/update',
    collectionHerausgeberUrl: 'collection/herausgeber',
    collectionFileUploadUrl: 'collection/file/create',
    collectionFileByIdUrl: 'collection/file/get-temp-access-url',
    collectionDeleteFileByIdUrl: 'collection/file/delete',
    collectionUrlUploadUrl: 'collection/file/create-url',
    collectionFileIncrementUrlViewsUrl: 'collection/file/increment-url-views',
    collectionNutzungsartUndObjektartUrl: 'collection/nutzung-and-objektart/create',
    collectionBewertungUrl: 'collection/bewertung',
    collectionNutzungenContainerUrl: 'collection/nutzung-container/get-creation-resources',
    collectionListUrl: 'collection/list-view',
    collectionMapListUrl: 'collection/list-view/map',
    appraisalTextsUrl: 'appraisalTexts',
    appraisalTextsOrderTypesUrl: 'appraisalTexts/ordertypes',
    appraisalTextsOptionsUrl: 'appraisalTexts/options',
    appraisalTextsOrderersUrl: 'appraisalTexts/orderers',
    appraisalTextDeleteUrl: 'appraisalTexts/delete',
    appraisalTextCreateUrl: 'appraisalTexts/create',
    appraisalTextDownloadUrl: 'appraisalTexts/download',
    brickGetBrickUrl: 'bricks/get-brick',
    brickUpdateBrickUrl: 'bricks/update-brick',
    brickGetRegionsUrl: 'bricks/get-regions',
    brickGetObjectTypesUrl: 'bricks/get-objecttypes',
    brickGetSubTypesUrl: 'bricks/get-objectsubtypes',
    brickGetProductionCostsObjectUrl: 'bricks/get-production-costs-object',
    brickDeleteProductionCostsObjectUrl: 'bricks/delete-production-costs-object',
    brickGetReferenceUnitsUrl: 'bricks/get-reference-units',
    brickGetExcerptConstructionPriceIndices: 'bricks/get-excerpt-construction-price-indices',
    brickGetExcerptConstructionCostsUrl: 'bricks/get-excerpt-construction-costs',
    brickCreateUpdateProductionCostsObjectUrl: 'bricks/create-update-production-costs-object',
    brickSearchLocationByZipCodeUrl: `bricks/search-location-by-zip-code`
};

export const OidcDefaults = {
    response_mode: 'query',
    response_type: 'code',
    extraQueryParams: {}
};
