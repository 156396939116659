<app-filter-sidebar [isOpen]="leftSidebarOpen"></app-filter-sidebar>

<div class="content-component">
    <router-outlet></router-outlet>
    <div class="button-row">
        <!--
        <button (click)="leftSidebarOpen = !leftSidebarOpen" [ngClass]="leftSidebarOpen ? 'primary' : 'default'">
            Filter
        </button>
        -->
        <app-segmented-control [segments]="segments"></app-segmented-control>
        <button class="default" (click)="rightSidebarOpen = !rightSidebarOpen">Weitere Infos</button>
    </div>

    <button
        [disabled]="comparableAppraisalCount < 2"
        [ngClass]="comparableAppraisalCount > 1 ? 'primary' : 'default'"
        [routerLink]="['/compare/overview']"
        class="comparable-button button">
        <span> {{ comparableAppraisalCount }} Vergleichsobjekte </span>
        <fa-icon
            (click)="clearCompareBasket(); $event.stopPropagation()"
            *ngIf="comparableAppraisalCount > 0"
            [icon]="faTimesCircle"
        ></fa-icon>
    </button>
</div>

<app-collection-sidebar [isOpen]="rightSidebarOpen"></app-collection-sidebar>
