import { Component, ContentChildren, QueryList } from '@angular/core';
import { TabComponent } from './tab.component';

@Component({
    selector: 'app-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss']
})
export class TabsComponent {
    @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
    currentActiveTab: TabComponent;

    selectTab(tab: TabComponent) {
        this.tabs.toArray().forEach((tab) => (tab.active = false));
        this.currentActiveTab = tab;
        tab.active = true;
    }

    ngAfterContentInit() {
        let activeTabs = this.tabs.filter((tab) => tab.active);
        if (activeTabs.length === 0) {
            this.selectTab(this.tabs.first);
        }
    }
}
