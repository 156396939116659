import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-dialog-confirm',
    templateUrl: './dialog-confirm.component.html',
    styleUrls: ['./dialog-confirm.component.scss']
})
export class DialogConfirmComponent {
    @Input() data: any;
    @Output() closed = new EventEmitter<any>();

    submit(): void {
        this.closed.emit(true);
    }

    cancel(): void {
        this.closed.emit(null);
    }
}
