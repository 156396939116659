import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GenericSelectOption } from '../radio-group/SelectOption';

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss']
})
export class SelectComponent<T> {
    selectedOption: GenericSelectOption<T>;
    isOpen = false;
    showTitle = false;

    @Input() isResettable = true;
    @Input() options: GenericSelectOption<T>[] = [];
    @Input() height = 35;
    @Input() showRequired = false;
    @Input() placeholder = null;

    @Output() selectionChange: EventEmitter<T | null> = new EventEmitter();

    @Input()
    set selection(id: T) {
        this.selectedOption = this.options?.find((option: GenericSelectOption<T>) => option.value === id);
    }

    onSelect(selection: T): void {
        this.selectedOption = this.options.find((option: GenericSelectOption<T>) => option.value === selection);
        this.selectionChange.emit(selection);
        this.isOpen = false;
        this.showTitle = selection != null;
    }
}
