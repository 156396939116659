import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import _ from 'lodash';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { Appraisal } from '../../../models/Appraisal';
import { ChartConfigurationWithCount, ChartContentType } from '../../../models/Chart';
import { ChartService } from '../../../services/chart.service';

@Component({
    selector: 'app-appraisal-chart-view',
    templateUrl: './appraisal-chart-view.component.html',
    styleUrls: ['./appraisal-chart-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppraisalChartViewComponent implements OnInit, OnDestroy {
    @Input() set appraisals(appr: Appraisal[]) {
        this._appraisals = appr;
        this.objektArtChartData$ = this.chartService.setupObjektart(appr);
        this.anzahlObjekte = appr.length.toString();
    }

    public _appraisals: Appraisal[];
    @Input() pdfStyle: boolean;
    @Input() switchAnsatzActive: boolean;
    @Input() showHistograms: boolean;
    @Input() showScatters: boolean;
    @Output() showSwitchAnsatzIst = new EventEmitter<boolean>();

    anzahlObjekte: string;
    objektArtChartData$: Observable<ChartConfiguration>;

    renditeAnsatzSub: Subscription;

    kaufpreisEuroQmHistogramm$: Observable<ChartConfigurationWithCount>;
    kaufpreisEuroQmScatter$: Observable<ChartConfigurationWithCount>;
    kaufpreisAbsolutHistogramm$: Observable<ChartConfigurationWithCount>;
    kaufpreisAbsolutScatter$: Observable<ChartConfigurationWithCount>;
    marktwertEuroQmHistogramm$: Observable<ChartConfigurationWithCount>;
    marktwertEuroQmScatter$: Observable<ChartConfigurationWithCount>;
    marktwertAbsolutHistogramm$: Observable<ChartConfigurationWithCount>;
    marktwertAbsolutScatter$: Observable<ChartConfigurationWithCount>;
    xfachesHistogramm$: Observable<ChartConfigurationWithCount>;
    xfachesScatter$: Observable<ChartConfigurationWithCount>;
    renditeIstHistogramm$: Observable<ChartConfigurationWithCount>;
    renditeIstScatter$: Observable<ChartConfigurationWithCount>;
    renditeAnsatzHistogramm$: Observable<ChartConfigurationWithCount>;
    renditeAnsatzScatter$: Observable<ChartConfigurationWithCount>;
    wnfHistogramm$: Observable<ChartConfigurationWithCount>;
    lzsHistogramm$: Observable<ChartConfigurationWithCount>;
    ausstattungHistogramm$: Observable<ChartConfigurationWithCount>;
    baujahrHistogramm$: Observable<ChartConfigurationWithCount>;
    mikrolageHistogramm$: Observable<ChartConfigurationWithCount>;

    constructor(private chartService: ChartService) {}

    ngOnInit(): void {
        this.kaufpreisEuroQmHistogramm$ = this.chartService.setupHistogram(
            this._appraisals,
            ChartContentType.KAUFPREIS_PRO_QM,
            100
        );
        this.kaufpreisAbsolutHistogramm$ = this.chartService.setupHistogram(
            this._appraisals,
            ChartContentType.KAUFPREIS,
            10000
        );
        this.marktwertEuroQmHistogramm$ = this.chartService.setupHistogram(
            this._appraisals,
            ChartContentType.MARKTWERT_PRO_QM,
            100
        );
        this.marktwertAbsolutHistogramm$ = this.chartService.setupHistogram(
            this._appraisals,
            ChartContentType.MARKTWERT,
            10000
        );
        this.xfachesHistogramm$ = this.chartService.setupHistogram(
            this._appraisals,
            ChartContentType.KAUFPREIS_X_FACH,
            1
        );
        this.renditeAnsatzHistogramm$ = this.chartService.setupHistogram(
            this._appraisals,
            ChartContentType.RENDITE_ANSATZ,
            1
        );
        this.renditeIstHistogramm$ = this.chartService.setupHistogram(
            this._appraisals,
            ChartContentType.RENDITE_IST,
            1
        );
        this.lzsHistogramm$ = this.chartService.setupHistogram(this._appraisals, ChartContentType.LIEGENSCHAFTSZINS, 1);
        this.wnfHistogramm$ = this.chartService.setupHistogram(this._appraisals, ChartContentType.WNF, 10);
        this.ausstattungHistogramm$ = this.chartService.setupHistogrammAusstattung(this._appraisals);
        this.baujahrHistogramm$ = this.chartService.setupHistogrammBaujahr(this._appraisals);
        this.mikrolageHistogramm$ = this.chartService.setupHistogrammMikrolage(this._appraisals);

        this.kaufpreisEuroQmScatter$ = this.chartService.setupScatter(ChartContentType.KAUFPREIS_PRO_QM, 90000);
        this.kaufpreisAbsolutScatter$ = this.chartService.setupScatter(ChartContentType.KAUFPREIS, 90000000);
        this.marktwertEuroQmScatter$ = this.chartService.setupScatter(ChartContentType.MARKTWERT_PRO_QM, 90000);
        this.marktwertAbsolutScatter$ = this.chartService.setupScatter(ChartContentType.MARKTWERT, 90000000);
        this.xfachesScatter$ = this.chartService.setupScatter(ChartContentType.KAUFPREIS_X_FACH, 900);
        this.renditeAnsatzScatter$ = this.chartService.setupScatter(ChartContentType.RENDITE_ANSATZ, 90000);
        this.renditeIstScatter$ = this.chartService.setupScatter(ChartContentType.RENDITE_IST, 90000);

        this.evaluateIfAnsatzButtonShouldBeShown();
    }

    /**
     * This function evaluates the necessity of the ansatz/ist switch button, depending on what charts should be shown
     * @private
     */
    private evaluateIfAnsatzButtonShouldBeShown(): void {
        const chartConfigs$: Observable<ChartConfigurationWithCount>[] = [];

        // Histograms are only interesting if more than 1 appraisal-detail-boxes is selected
        if (this._appraisals.length > 1 && this.showHistograms) {
            chartConfigs$.push(this.renditeAnsatzHistogramm$);
            chartConfigs$.push(this.renditeIstHistogramm$);
        }

        if (this.showScatters) {
            chartConfigs$.push(this.renditeAnsatzScatter$);
            chartConfigs$.push(this.renditeIstScatter$);
        }

        this.renditeAnsatzSub = combineLatest(chartConfigs$)
            .pipe(distinctUntilChanged(_.isEqual))
            .subscribe((configs: ChartConfigurationWithCount[]) => {
                // If a chart has not enough data we are interested in, chart setup functions return null.
                const someConfigExist = configs.some((config) => config != null);

                // If we have at least one config, we want to switch between ansatz and ist
                this.showSwitchAnsatzIst.emit(someConfigExist);
            });
    }

    ngOnDestroy(): void {
        this.renditeAnsatzSub?.unsubscribe();
    }
}
