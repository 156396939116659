import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {PagedData, RequestData} from '../../appraisal-detail/models/Appraisal';
import {ApiService} from '../../core/services/api.service';
import {LoadingService} from '../../core/services/loading.service';
import {Pagination} from '../../shared/models/Pagination';
import {
    CollectionFilter,
    CollectionNutzungContainer,
    ICollectionListView,
    CollectionUploadSorting,
    CollectionFile,
    HerausgeberDialogResult,
    Collection,
    CollectionFeedback,
    CollectionFeedbackInput,
    CollectionMapFilter,
    IMapCollection, FilteredCollection
} from '../models/Collection';

// Thought: Maybe lat lon should not be part of the filter, because it only indirectly indicates which appraisals to load
const InitialFilter: CollectionFilter = {
    title: '',
    createdOnFrom: null,
    createdOnTo: null,
    herausgeber: null,
    publikationsturnus: null,
    publikationsdatumFrom: null,
    publikationsdatumTo: null,
    kostenpflichtig: null,
    kategorie: null,
    nutzungsarten: [],
    objektarten: [],
    bundeslaender: [],
    landkreise: [],
    gemeinden: [],
    bewertungAverageFrom: null,
    bewertungAverageTo: null,
    pageNumber: Pagination.FirstPageIndex,
    pageSize: 5,
    feedbackStatusOpenOnly: false,
    countryWide: false,
    overDue: false
};

@Injectable({
    providedIn: 'root'
})
export class CollectionService {
    private filterSubject$: BehaviorSubject<CollectionFilter> = new BehaviorSubject(InitialFilter);
    public emptyFilter$: Subject<void> = new Subject<void>();
    private listPageFilterSubject$: BehaviorSubject<Pagination> = new BehaviorSubject(
        new Pagination(Pagination.FirstPageIndex, 15)
    );
    public sortingSubject$: BehaviorSubject<CollectionUploadSorting> = new BehaviorSubject<CollectionUploadSorting>({
        active: 'titel',
        direction: 'asc'
    });

    private currentNutzungsarten$: BehaviorSubject<number[]> = new BehaviorSubject([]);

    public loadData$ = new Subject<void>();
    public noFilter = true;

    constructor(private apiService: ApiService, private loadingService: LoadingService) {
    }

    getCollectionById(id: string): Observable<Collection> {
        return this.apiService
            .get<RequestData<Collection>>(`${environment.collectionByIdUrl}/${id}`)
            .pipe(map(({data}) => data));
    }

    getCurrentNutzungsarten(): Observable<number[]> {
        return this.currentNutzungsarten$ as Observable<number[]>;
    }

    setCurrentNutzungsarten(value: number[]): void {
        this.currentNutzungsarten$.next(value);
    }

    resetFilter(): void {
        this.updateFilter(InitialFilter);
        this.emptyFilter$.next();
    }

    createCollectionFeedback(feedback: CollectionFeedbackInput): Observable<CollectionFeedback> {
        return this.apiService
            .post<RequestData<CollectionFeedback>>(
                `${environment.collectionFeedbackUrl}/create/${feedback.collectionId}`,
                feedback
            )
            .pipe(map((data) => data.data));
    }

    closeCollectionFeedback(collectionFeedbackId: string): Observable<CollectionFeedback> {
        return this.apiService
            .post<RequestData<CollectionFeedback>>(
                `${environment.collectionFeedbackUrl}/close/${collectionFeedbackId}`,
                {}
            )
            .pipe(map((data) => data.data));
    }

    getCollectionsFiltered(): Observable<FilteredCollection> {
        let queryParams = new HttpParams();

        queryParams = queryParams
            .append('pageNumber', this.listPageFilterSubject$.value.page)
            .append('pageSize', this.listPageFilterSubject$.value.size);

        const filter = this.filterSubject$.value;

        filter.objektarten.forEach((n) => (queryParams = queryParams.append('Objektarten', n)));

        if (filter.kategorie !== null) {
            queryParams = queryParams.append('Kategorie', filter.kategorie);
        }
        filter.nutzungsarten.forEach((n) => {
            queryParams = queryParams.append('Nutzungsarten', n);
        });
        if (filter.nutzungsarten.length > 0) {
            this.currentNutzungsarten$.next(filter.nutzungsarten);
        }

        if (filter.publikationsturnus !== null) {
            queryParams = queryParams.append('Publikationsturnus', filter.publikationsturnus);
        }
        if (filter.herausgeber !== null) {
            queryParams = queryParams.append('herausgeber', filter.herausgeber);
        }
        if (!!filter.title) {
            queryParams = queryParams.append('titel', filter.title);
        }

        if (!!filter.publikationsdatumFrom) {
            queryParams = queryParams.append('publikationsdatumFrom', filter.publikationsdatumFrom);
        }
        if (!!filter.publikationsdatumTo) {
            queryParams = queryParams.append('publikationsdatumTo', filter.publikationsdatumTo);
        }
        if (filter.bundeslaender.length) {
            queryParams = queryParams.append('bundeslaender', filter.bundeslaender.toString());
        }
        if (filter.landkreise.length) {
            queryParams = queryParams.append('landkreise', filter.landkreise.toString());
        }
        if (filter.gemeinden.length) {
            queryParams = queryParams.append('gemeinden', filter.gemeinden.toString());
        }
        if (filter.feedbackStatusOpenOnly) {
            queryParams = queryParams.append('feedbackStatusOpenOnly', true);
        }
        if (!!filter.countryWide) {
            queryParams = queryParams.append('countryWide', true);
        }

        if (!!filter.overDue) {
            queryParams = queryParams.append('overDue', true);
        }

        const filterSet = queryParams.keys().length > 2;

        if (this.sortingSubject$.value.active) {
            queryParams = queryParams.append('orderBy', this.sortingSubject$.value.active);
        }

        if (this.sortingSubject$.value.direction) {
            queryParams = queryParams.append('SortDirection', this.sortingSubject$.value.direction);
        }

        if (!filterSet) {
            this.noFilter = true;
            const emptyPage = {
                data: [],
                page: 0,
                pageSize: 0,
                size: 0,
                pageNumber: 0,
                totalPages: 0,
                totalRecords: 0
            };
            return of({
                byFilter: emptyPage,
                byBundesland: emptyPage,
                byLandkreis: emptyPage,
                countryWide: emptyPage,
                bundesland: null,
                gemeinde: null,
                landkreis: null,
                hasData: false
            });
        } else {
            this.loadingService.setLoading(true);

            return this.apiService.get<FilteredCollection>(environment.collectionListUrl, queryParams).pipe(
                map((data) => data),
                tap(() => {
                    this.noFilter = false;
                    this.loadingService.setLoading(false);
                })
            );
        }
    }

    getMapCollectionsFiltered(filter: CollectionMapFilter): Observable<IMapCollection> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append('lat', filter.lat);
        queryParams = queryParams.append('lon', filter.lon);

        filter.objektUnterArtIds.forEach((n) => (queryParams = queryParams.append('objektUnterArtIds', n)));
        filter.nutzungsArtIds.forEach((n) => (queryParams = queryParams.append('nutzungsArtIds', n)));

        return this.apiService.get<IMapCollection>(environment.collectionMapListUrl, queryParams);
    }

    updateFilter(filter: Partial<CollectionFilter>): void {
        const newVal = {
            ...this.filterSubject$.value,
            ...filter
        };
        this.filterSubject$.next(newVal);
        this.loadData$.next();
    }

    setSorting(sort: CollectionUploadSorting): void {
        this.sortingSubject$.next(sort);
        this.loadData$.next();
    }

    updateListPagination(page: number, size: number): void {
        this.listPageFilterSubject$.next(new Pagination(page, size));
        this.loadData$.next();
    }

    //Basic Operations
    addCollection(collection: any): Observable<any> {
        return this.apiService.post(environment.collectionCreateUrl, collection);
    }

    editCollection(collection: any): Observable<any> {
        return this.apiService.put(environment.collectionUpdateUrl, collection);
    }

    getLocationData<ILocationData>(): Observable<ILocationData> {
        return this.apiService
            .get<RequestData<ILocationData>>(environment.collectionLageContainerUrl)
            .pipe(map(({data}) => data));
    }

    addFile(file: FormData): Observable<CollectionFile> {
        return this.apiService
            .post<RequestData<CollectionFile>>(environment.collectionFileUploadUrl, file)
            .pipe(map((r: RequestData<CollectionFile>) => r.data));
    }

    getFileById<T>(id: string): Observable<T> {
        return this.apiService
            .get<RequestData<T>>(`${environment.collectionFileByIdUrl}/${id}`)
            .pipe(map(({data}) => data));
    }

    deleteFileById(id: string): Observable<any> {
        return this.apiService.delete(`${environment.collectionDeleteFileByIdUrl}/${id}`);
    }

    addUrl(url: FormData): Observable<any> {
        return this.apiService.post(environment.collectionUrlUploadUrl, url);
    }

    getHerausgeber<T>(): Observable<T> {
        return this.apiService.get<RequestData<T>>(environment.collectionHerausgeberUrl).pipe(map(({data}) => data));
    }

    getNutzungen(): Observable<CollectionNutzungContainer> {
        return this.apiService
            .get<RequestData<CollectionNutzungContainer>>(environment.collectionNutzungenContainerUrl)
            .pipe(map(({data}) => data));
    }

    editHerausgeberItems(items: HerausgeberDialogResult): Observable<any> {
        return this.apiService.post(`${environment.collectionHerausgeberUrl}/create`, items);
    }

    addNewNutzungenAndObjektartenItems(items: any): Observable<any> {
        return this.apiService.post(`${environment.collectionNutzungsartUndObjektartUrl}`, items);
    }

    setRating(bewertungNumerisch: FormData, id: string): Observable<any> {
        return this.apiService.post(`${environment.collectionBewertungUrl}/add/${id}`, bewertungNumerisch);
    }

    incrementUrl(id: string): Observable<any> {
        return this.apiService.post(`${environment.collectionFileIncrementUrlViewsUrl}/${id}`, id);
    }
}
