import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {SelectOptionNumber} from '../radio-group/SelectOption';

type RowClass = 'row' | 'multi-row';

@Component({
    selector: 'app-multi-select',
    templateUrl: './multi-select.component.html',
    styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent implements OnChanges {
    currentSelectionString: string;
    currentSelection: SelectOptionNumber[] = [];
    isOpen = false;

    @Input() rowClass: RowClass = 'row';
    @Input() options: SelectOptionNumber[] = [];
    @Input() currentSelectionIds: number[] = [];
    @Output() currentSelectionChange: EventEmitter<SelectOptionNumber[]> = new EventEmitter();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.currentSelectionIds && this.options.length > 0) {
            const selectionIds = changes.currentSelectionIds.currentValue as number[];
            const selection = this.options.filter(o => selectionIds.includes(+o.value));
            this.setSelection(selection);
        }
    }

    onSelectionSubmit(currentSelections: SelectOptionNumber[]): void {
        this.emitSelection(currentSelections);
        this.isOpen = false;
    }

    onSelectionReset(): void {
        this.emitSelection([]);
        this.isOpen = false;
    }

    private emitSelection(currentSelections: SelectOptionNumber[]): void {
        this.currentSelectionChange.emit(currentSelections);
    }

    private setSelection(selection: SelectOptionNumber[]): void {
        this.currentSelection = selection;
        this.currentSelectionString = selection.map(option => option.label).join(', ');
    }
}
