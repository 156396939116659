<app-dropdown [(isOpen)]="isOpen">
    <ng-container text>
        <ng-container *ngIf="currentSelectionString?.length; else zeroSelection">
            <div class="column-component centered filter-active">{{ currentSelectionString }}</div>
        </ng-container>
        <ng-template #zeroSelection>
            <div>
                <ng-content select="[defaultText]"></ng-content>
            </div>
        </ng-template>
    </ng-container>
    <div class="overlay-element padding" content>
        <app-radio-group
            [(currentSelection)]="currentSelection"
            [multi]="true"
            [options]="options"
            class="{{ rowClass }} list"
        >
        </app-radio-group>
        <div class="row-component centered button-row-small">
            <button (click)="onSelectionSubmit(currentSelection)" class="primary">Auswählen</button>
            <button (click)="onSelectionReset()" class="default">Zurücksetzen</button>
        </div>
    </div>
</app-dropdown>
