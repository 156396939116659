// see backend EGutachtenOhneBaulastenauskunftErstellenMoeglich
export enum GutachtenErstellungTyp {
    Firmenkunde = 1,
    PrivatkundeMitGenehmigung = 2,
    PrivatkundeOhneGenehmigung = 3
 };
 
 export type GutachtenErstellungTypName = {
    [key in GutachtenErstellungTyp]: string;
 };
 
 export const GutachtenErstellungTypNames: GutachtenErstellungTypName = {
    [GutachtenErstellungTyp.Firmenkunde]: 'Ja, für Firmenkunde',
    [GutachtenErstellungTyp.PrivatkundeMitGenehmigung]: 'Ja, für Privatkunde mit Genehmigung',
    [GutachtenErstellungTyp.PrivatkundeOhneGenehmigung]: ' Nein, für Privatkunde ohne Genehmigung'
 };
