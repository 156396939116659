import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'booleanToLanguage'
})
export class BooleanToLanguage implements PipeTransform {
    transform(value: boolean): string {
        if (value == null) {
            return null;
        }

        return value ? 'Ja' : 'Nein';
    }
}
