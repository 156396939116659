import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListViewComponent } from './appraisal-search/components/list-view/list-view.component';
import { OidcCallbackGuard } from './core/guards/oidc-callback.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/search/map',
        pathMatch: 'full'
    },
    {
        path: 'search',
        loadChildren: async () => (await import('./appraisal-search/search.module')).SearchModule
    },
    {
        path: 'appraisals',
        loadChildren: async () => (await import('./appraisal-detail/appraisal.module')).AppraisalModule
    },
    {
        path: 'upload',
        loadChildren: async () => (await import('./collection-upload/upload.module')).UploadModule
    },
    {
        path: 'textgenerator',
        loadChildren: async () => (await import('./appraisal-textgenerator/textgenerator.module')).TextgeneratorModule
    },
    {
        path: 'auth/callback',
        component: ListViewComponent,
        canActivate: [OidcCallbackGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
