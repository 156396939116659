<app-dropdown [(isOpen)]="isOpen" [height]="height" [showRequired]="showRequired">
    <ng-container text>
        <ng-container *ngIf="selectedOption; else zeroSelection">
            <div class="row-component centered filter-active">{{ selectedOption.label }}</div>
        </ng-container>
        <ng-template #zeroSelection>
            <div class="placeholder-component">
                <ng-content select="[defaultText]"></ng-content>
            </div>
        </ng-template>
    </ng-container>
    <ng-container title *ngIf="selectedOption"
        ><div id="title">{{ placeholder }}</div></ng-container
    >
    <div class="overlay-element overflow-auto" content>
        <app-list>
            <app-list-item (click)="onSelect(option.value)" *ngFor="let option of options">
                {{ option.label }}
            </app-list-item>
            <app-list-item (click)="onSelect(null)" *ngIf="selectedOption && isResettable">
                Auswahl zurücksetzen
            </app-list-item>
        </app-list>
    </div>
</app-dropdown>
