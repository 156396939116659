import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../shared/shared.module';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { SidebarItemComponent } from './components/sidebar-item/sidebar-item.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { SnackComponent } from './components/snack/snack.component';


@NgModule({
   declarations: [
      NavbarComponent,
      BreadcrumbsComponent,
      SidebarComponent,
      SidebarItemComponent,
      SnackbarComponent,
      SnackComponent
   ],
   imports: [
      SharedModule,
      BrowserModule,
      CommonModule,
      MatToolbarModule,
      MatSidenavModule,
      MatListModule,
      MatIconModule,
      RouterModule // do not remove; relevant for routerLink to work in sidebar
   ],
   exports: [
      NavbarComponent,
      SidebarComponent,
      SnackbarComponent
   ]
})
export class CoreModule { }
