import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ChartConfiguration, ChartOptions, ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Color } from 'ng2-charts';
import { DefaultChartSettings } from '../../../../../models/Chart';

@Component({
    selector: 'app-chart',
    templateUrl: 'chart.component.html',
    styleUrls: ['./chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartComponent implements OnInit {
    public colors: Color[] = [];
    public chartOptions: ChartOptions;

    @Input() chartData: ChartConfiguration;
    @Input() type: ChartType;
    @Input() plugins = [pluginDataLabels];
    @Input() enableLegend = false;

    @Input() height = '100%';
    @Input() width = '100%';

    ngOnInit(): void {
        this.chartOptions = {
            ...DefaultChartSettings,
            ...(this.chartData.options || {})
        };
    }
}
