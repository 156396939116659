import {Appraisal} from '../../../models/Appraisal';
import {IAppraisalHeaderColumn} from '../../../models/AppraisalTableColumn';

const sortByBaujahrFunction = (a: Appraisal, b: Appraisal) =>
    Math.min(...a.baujahr.map((bj) => bj.baujahrValue)) > Math.min(...b.baujahr.map((bj) => bj.baujahrValue)) ? 1 : -1;

const sortObjektArt = (a: Appraisal, b: Appraisal) =>
    (a.objektUnterArt.name || a.objektArt.name).localeCompare(b.objektUnterArt.name || b.objektArt.name);

const sortAuftraggeber = (a: Appraisal, b: Appraisal) =>
    a.auftraggeber.loraAuftraggeberName.localeCompare(b.auftraggeber.loraAuftraggeberName);
const sortBesonderheiten = (a: Appraisal, b: Appraisal) =>
    (a.besonderheiten.some((b) => b.value) ? 'Ja' : 'Nein').localeCompare(
        b.besonderheiten.some((b) => b.value) ? 'Ja' : 'Nein'
    );

const sortLage = (a: Appraisal, b: Appraisal) => {
    if (!a.lage?.lageQualitaet?.onGeoLagestufe) {
        return -1;
    }
    return a.lage?.lageQualitaet?.onGeoLagestufe > b.lage?.lageQualitaet?.onGeoLagestufe ? -1 : 1;
}

const sortObjektZustand = (a: Appraisal, b: Appraisal) => {
    if (!a.objektzustand) {
        return -1;
    }
    return a.objektzustand?.id > b.objektzustand?.id ? -1 : 1;
};

export const AppraisalTableHeaderData: IAppraisalHeaderColumn[] = [
    {
        title: 'Auftrag',
        columns: [
            {title: 'DataSuite', key: 'id', unit: '', show: false},
            {title: 'Lora/Giks', key: 'datenQuelle', unit: '', show: false},
            {title: 'Objektunterart/Objektart', key: 'objektArt', unit: '', show: true, sortFunction: sortObjektArt},
            {title: 'Auftraggeber', key: 'auftraggeber', unit: '', show: false, sortFunction: sortAuftraggeber},
            {title: 'Auftragsart', key: 'auftragsart', unit: '', show: false},
            {title: 'Stichtag', key: 'bewertungsstichtag', unit: '', show: false},
            {title: 'Adresse', key: 'adresse', unit: '', show: false},
            {title: 'Entfernung zum Objekt', key: 'entfernung', unit: 'km', show: false}
        ]
    },
    {
        title: 'Wertübersicht',
        columns: [
            {title: 'Marktwert', key: 'marktwert', unit: '€', show: false, calcAverage: true},
            {title: 'Marktw. RoE-Vv', key: 'rohertragsvervielfaeltiger', unit: '', show: false, calcAverage: true},
            {
                title: 'Marktwert pro m²',
                key: 'marktwertProQuadratmeter',
                unit: ' €/m²',
                show: false,
                calcAverage: true
            },

            {title: 'Kaufpreis', key: 'kaufpreis', unit: '€', show: false, calcAverage: true},
            {title: 'Kaufpreisdatum', key: 'kaufpreisDatum', unit: '', show: false},
            {title: 'WNF', key: 'wohnNutzflaeche', unit: 'm²', show: false, calcAverage: true},
            {title: 'Ø-Ist-Miete', key: 'mieteDurchschnitt', unit: '€/m²', show: false, calcAverage: true},
            {title: 'Ø-Ansatz-Miete', key: 'mieteAnsatzDurchschnitt', unit: '€/m²', show: false, calcAverage: true},
            {title: 'Ø-LZS', key: 'liegenschaftszinsDurchschnitt', unit: '%', show: false, calcAverage: true},
            {title: 'NAR', key: 'nettoanfangsrenditeMwt', unit: '', show: false, calcAverage: false},
            {title: 'Bodenwertanteil', key: 'bodenwertanteil', unit: '', show: false, calcAverage: false}
        ]
    },
    {
        title: 'Objekt- und Lageeigenschaften',
        columns: [
            {
                title: 'Baujahr(e)',
                key: 'baujahr',
                unit: '',
                show: false,
                sortFunction: sortByBaujahrFunction,
                calcAverage: true
            },
            {title: 'Fiktives Baujahr', key: 'fiktivesBaujahr', unit: '', show: false},
            {title: 'Ø-Ausstattung', key: 'ausstattungsQualitaet', unit: '', show: false},
            {title: 'Ø-Objektzustand', key: 'objektzustand', unit: '', show: false, sortFunction: sortObjektZustand},
            {title: 'Lagequalität', key: 'lage', unit: '', show: false, sortFunction: sortLage}
        ]
    },
    {
        title: 'Ertragswert',
        columns: [
            {title: 'WF', key: 'wohnflaeche', unit: 'm²', show: false, calcAverage: true},
            {title: 'Ø-Miete IST W', key: 'mieteIstWohnenDurchschnitt', unit: '€/m²', show: true, calcAverage: true},
            {
                title: 'Ø-Miete Ansatz W',
                key: 'mieteAnsatzWohnenDurchschnitt',
                unit: '€/m²',
                show: true,
                calcAverage: true
            },
            {title: 'Ø-LZS W', key: 'liegenschaftzinsWohnenDurchschnitt', unit: '%', show: false, calcAverage: true},
            {title: 'NF gesamt', key: 'nutzflaeche', unit: 'm²', show: false, calcAverage: true},
            {
                title: 'Ø-Miete IST G ges.',
                key: 'mieteIstGewerbeDurchschnitt',
                unit: '€/m²',
                show: true,
                calcAverage: true
            },
            {
                title: 'Ø-Miete Ansatz G ges.',
                key: 'mieteAnsatzGewerbeDurchschnitt',
                unit: '€/m²',
                show: false,
                calcAverage: true
            },
            {
                title: 'Ø-LZS G ges.',
                key: 'liegenschaftzinsGewerbeDurchschnitt',
                unit: '%',
                show: false,
                calcAverage: true
            },
            {title: 'NF Büro/Praxis', key: 'nutzflaecheBuero', unit: 'm²', show: true},
            {
                title: 'Ø-Miete IST Büro/Praxis',
                key: 'mieteIstBueroDurchschnitt',
                unit: '€/m²',
                show: true,
                calcAverage: true
            },
            {title: 'Ø-Miete Ansatz Büro/Praxis', key: 'mieteAnsatzBueroDurchschnitt', unit: '€/m²', show: false},
            {title: 'Ø-LZS G Büro/Praxis', key: 'liegenschaftzinsBueroDurchschnitt', unit: '%', show: false},
            {title: 'NF Handel', key: 'nutzflaecheHandel', unit: 'm²', show: false},

            {
                title: 'Ø-Miete IST Handel',
                key: 'mieteIstHandelDurchschnitt',
                unit: '€/m²',
                show: true,
                calcAverage: true
            },
            {title: 'Ø-Miete Ansatz Handel', key: 'mieteAnsatzHandelDurchschnitt', unit: '€/m²', show: false},
            {title: 'Ø-LZS Handel', key: 'liegenschaftzinsHandelDurchschnitt', unit: '%', show: false},
            {title: 'NF Sonstiges', key: 'nutzflaecheSonstiges', unit: 'm²', show: false},
            {title: 'Ø-Miete IST Sonstiges', key: 'mieteIstSonstigesDurchschnitt', unit: '€/m²', show: true},
            {title: 'Ø-Miete Ansatz Sonstiges', key: 'mieteAnsatzSonstigesDurchschnitt', unit: '€/m²', show: false},
            {title: 'Ø-LZS Sonstiges', key: 'liegenschaftzinsSonstigesDurchschnitt', unit: '%', show: false},

            {title: 'Einheiten gesamt', key: 'anzahlMieteinheiten', unit: ' Stk.', show: false},
            {title: 'Stellplätze', key: 'anzahlStellplaetze', unit: ' Stk.', show: false},
            {title: 'Ø-Miete IST Stpl. ges.', key: 'mieteIstStellplaetzeGesamtDurchschnitt', unit: '', show: false},
            {
                title: 'Ø-Miete Ansatz Stpl. ges.',
                key: 'mieteAnsatzStellplaetzeGesamtDurchschnitt',
                unit: '',
                show: false
            },
            {title: 'BWK', key: 'angesetzteBewirtschaftungskosten', unit: '%', show: false, calcAverage: true}
        ]
    },
    {
        title: 'Bodenwert',
        columns: [
            {title: 'Grundstücksfläche', key: 'summeFlaechen', unit: 'm²', show: true, calcAverage: true},
            {
                title: 'Bodenwertansatz',
                key: 'bodenwertAnsatzDurchschnitt',
                unit: '€/m²',
                show: false,
                calcAverage: true
            }
        ]
    },
    {
        title: 'Sachwert',
        columns: [
            {
                title: 'Ø-Baukosten WNF (inkl. BNK)',
                key: 'baukostenWohnflaecheDurchschnitt',
                unit: '€/m²',
                show: false,
                calcAverage: true
            },
            {title: 'Sachwertfaktor', key: 'sachwertfaktor', unit: '', show: false, calcAverage: true}
        ]
    },
    {
        title: 'Vergleichswert',
        columns: [
            {title: 'Ø-Kaufpreis HN', key: 'kaufpreisHauptnutzungProQm', unit: '€/m²', show: false},
            {
                title: 'Ø-angemessener Wert HN',
                key: 'angemessenerWertHauptnutzungDurchschnitt',
                unit: '€',
                show: false,
                calcAverage: true
            },
            {
                title: 'Ø-Kaufpreis Stpl.',
                key: 'kaufpreisStellplaetzeInEuroProStueck',
                unit: '€/Stk.',
                show: false,
                calcAverage: true
            },
            {
                title: 'Ø-angemessener Wert Stpl.',
                key: 'angemessenerWertStellplaetzeDurchschnitt',
                unit: '€',
                show: false,
                calcAverage: true
            }
        ]
    }
];
