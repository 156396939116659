<div class="column-component">
    <div (click)="changeOpenState()" class="row-component space-between centered header">
        <span>
            <ng-content select="[header]"></ng-content>
        </span>
        <fa-icon [@rotate]="state" [icon]="faChevronRight"></fa-icon>
    </div>
    <div [@slideInOut]="panelOpen">
        <div class="content-component">
            <ng-content select="[content]"></ng-content>
        </div>
    </div>
</div>
