import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SidebarNavigationEventType } from '../enums/SidebarNavigationEventType';
import { MatDrawer } from '@angular/material/sidenav';

@Injectable({
   providedIn: 'root'
})

// service for sidebar navigation menu
export class SidebarNavigationService {

   private eventSubject: Subject<SidebarNavigationEventType> = new Subject<SidebarNavigationEventType>();

   constructor() { }

   open(): void {
      this.eventSubject.next(SidebarNavigationEventType.Open);
   }

   close(): void {
      this.eventSubject.next(SidebarNavigationEventType.Close);
   }

   toggle(): void {
      this.eventSubject.next(SidebarNavigationEventType.Toggle);
   }

   // handle all sidebar navigation events
   handleEvents(drawer: MatDrawer): void {
      this.eventSubject.asObservable().subscribe(event => {
         switch (event) {
            case SidebarNavigationEventType.Open:
               drawer.open();
               break;
            case SidebarNavigationEventType.Close:
               drawer.close();
               break;
            case SidebarNavigationEventType.Toggle:
               drawer.toggle();
               break;
         }
      });
   }
}
