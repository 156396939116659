import {IBundeslandData, IGemeindeData, ILandkreisData} from '../../shared/interfaces/LocationData';
import {PagedData} from "../../appraisal-detail/models/Appraisal";

export type Collection = Partial<{
    id: string;
    createdBy: string;
    createdOn: string;
    beschreibung: string;
    herausgeber: CollectionHerausgeber;
    reportname: string;
    publikationsturnus: number;
    aktualitaet: Date;
    publikationsdatum: PublikationsDatum;
    kostenpflichtig?: boolean;
    hinweis: string;
    collectionNutzungContainers: CollectionNutzung[];
    kategorie: number;
    collectionLagen: CollectionLage[];
    files: CollectionFile[];
    bewertung: CollectionBewertung;
    bewertungAverage: number;
    bewertungCurrentUser: number;
    titel: string;
    feedback: CollectionFeedback[];
    herausgabedatum: Date;
}>;

export interface CollectionFeedback {
    id: string;
    createdBy: string;
    createdByName: string;
    createdOn: Date;
    closedBy?: string;
    closedByName?: string;
    closedOn?: Date;
    collectionId: string;
    description: string;
    link: string;
    status: CollectionStatus;
}

export interface CollectionFeedbackInput {
    description: string;
    link: string;
    collectionId: string;
}

export enum CollectionStatus {
    OPEN = 1,
    CLOSED = 2
}

//Herausgeber
export type CollectionHerausgeber = {
    id: number;
    name: string;
    url: string;
};

export type Herausgeber = {
    id: number;
    name: string;
    url: string;
};

//Nutzung
export type CollectionNutzung = {
    id: number;
    name: string;
    collectionNutzungsart: CollectionNutzungsart;
    collectionObjektart: CollectionObjektart;
};

export type CollectionNutzungsart = {
    id: number;
    name: string;
};

export type CollectionObjektart = {
    id: number;
    name: string;
    collectionNutzungsart: CollectionNutzungsart;
};

export type CollectionLageShort = {
    lage: {
        id: number;
        bundesland: { id: number; name: string };
        gemeinde: { id: number; name: string };
        landkreis: { id: number; name: string };
        countryWide: boolean;
    };
};

export type CollectionNutzungContainer = {
    nutzungsarten: CollectionNutzungsart[];
    objektarten: CollectionObjektart[];
};

//Lage
export type CollectionLage = {
    id: number;
    bundesland: IBundeslandData;
    landkreis: ILandkreisData;
    gemeinde: IGemeindeData;
    countryWide: boolean;
};

export type PublikationsDatum = {
    id: number;
    datum: Date;
    collection: Collection;
};

//File
export type CollectionFile = {
    aufrufe: number;
    fileExtension: string;
    herausgabedatum: Date;
    id: string;
    mimeType: string;
    originalFileName: string;
    quartal: string;
    titel: string;
    uploadDateTime: Date;
    url: string;
    user: User;
};

export type CollectionFileShort = {
    mimeType: string;
    url: string;
};

export type CollectionBewertung = {
    id: number;
    user: User;
    collectionId: number;
    collectionDb: number;
    bewertungNumerisch: number;
};

export type User = {
    nachname: string;
    vorname: string;
    id: string;
};

export interface IMapCollection {
    byGemeinde: ICollectionListView[];
    gemeinde: IGemeindeData;
    byLandkreis: ICollectionListView[];
    landkreis: ILandkreisData;
    byBundesland: ICollectionListView[];
    bundesland: IBundeslandData;
    countryWide: ICollectionListView[];
    hasData: boolean;
}

export interface ICollectionListView {
    id: string;
    titel: string;
    herausgeberName: string;
    reportname: string;
    publikationsturnus: number;
    publikationsdatum: PublikationsDatum;
    kategorie: number;
    bewertungAverage: number;
    lagen: CollectionLageShort[];
    nutzungen: number[];
    mimeType: string | null;
}

export interface FilteredCollection {
    byFilter: PagedData<ICollectionListView>;
    gemeinde: IGemeindeData;
    byLandkreis: PagedData<ICollectionListView>;
    landkreis: ILandkreisData;
    byBundesland: PagedData<ICollectionListView>;
    bundesland: IBundeslandData;
    countryWide: PagedData<ICollectionListView>;
    hasData: boolean;
}

export interface CollectionUploadSorting {
    active: keyof ICollectionListView;
    direction: SortDirection;
}

export type SortDirection = 'asc' | 'desc' | '';

//Results
export interface IResultNutzungContainerToAdd {
    nutzungsartId: number;
    objektartId: number;
}

export interface IResultNutzungContainerToUpdate {
    id: number;
    nutzungsartId: number;
    objektartId: number;
}

export interface IResultLagenToAdd {
    bundeslandId: number | null;
    landkreisId: number | null;
    gemeindeId: number | null;
    countryWide: boolean;
}

export interface IResultLagenToUpdate {
    id: number;
    bundeslandId: number | null;
    landkreisId: number | null;
    gemeindeId: number | null;
    countryWide: boolean;
}

export interface CollectionMapFilter {
    objektArtIds: number[];
    objektUnterArtIds: number[];
    nutzungsArtIds: number[];
    lat: number;
    lon: number;
}

export interface CollectionFilter {
    title: string;
    createdOnFrom: string;
    createdOnTo: string;
    herausgeber: number;
    publikationsturnus: number;
    publikationsdatumFrom: string;
    publikationsdatumTo: string;
    kostenpflichtig: boolean;
    kategorie: number;
    nutzungsarten: number[];
    objektarten: number[];
    bundeslaender: number[];
    landkreise: number[];
    gemeinden: number[];
    bewertungAverageFrom: number;
    bewertungAverageTo: number;
    pageNumber: number;
    pageSize: number;
    feedbackStatusOpenOnly: boolean;
    countryWide: boolean;
    overDue: boolean;
}


export interface HerausgeberItem {
    name: string;
    url: string | null;
    id?: number | null;
    editMode?: boolean;
}

export interface HerausgeberDialogResult {
    newEntries: HerausgeberItem[];
    edited: HerausgeberItem[];
}
