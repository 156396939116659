import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, UntypedFormGroup} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {Observable} from 'rxjs';
import {debounceTime, map, startWith, tap} from 'rxjs/operators';
import {checkValidSelection} from '../../../../core/utils/validators';
import {
    IBundeslandData,
    IGemeindeData,
    IGemeindeKennzifferData,
    ILandkreisData,
    ILocationData,
    ILocationItem,
    IPlzData
} from '../../../../shared/interfaces/LocationData';
import {CollectionService} from '../../../services/collection.service';

@Component({
    selector: 'app-lage-filter',
    templateUrl: './lage-filter.component.html',
    styleUrls: ['./lage-filter.component.scss']
})
export class LageFilterComponent implements OnInit {
    @Output() valueChanged = new EventEmitter<ILocationItem>();
    @Input() data: ILocationData;

    formGroup: UntypedFormGroup;
    lage: UntypedFormGroup;

    bundeslaender: IBundeslandData[];
    landkreise: ILandkreisData[];
    plzs: IPlzData[];
    gemeinden: IGemeindeData[];

    filteredBundeslandOptions: Observable<IBundeslandData[]>;
    filteredLandkreisOptions: Observable<ILandkreisData[]>;
    filteredGemeindeOptions: Observable<IGemeindeData[]>;
    filteredPlzOptions: Observable<IPlzData[]>;
    filteredGemeindekennzifferOptions: Observable<IGemeindeKennzifferData[]>;

    selectedGemeinde: IGemeindeData;
    currentPlz: IPlzData;
    currentLandkreis: ILandkreisData;

    constructor(private collectionService: CollectionService) {
    }

    ngOnInit(): void {
        if (this.data != null) {
            this.bundeslaender = this.data.bundeslaender;
            this.landkreise = this.data.landkreise;
            this.plzs = this.data.plzGemeinde;
            this.gemeinden = this.data.gemeinden;
        }

        this.formGroup = new UntypedFormGroup({
            bundesland: new FormControl('', [checkValidSelection(() => this.bundeslaender)]),
            landkreis: new FormControl('', [checkValidSelection(() => this.landkreise)]),
            gemeinde: new FormControl('', [checkValidSelection(() => this.gemeinden)]),
            postleitzahl: new FormControl(
                '',
                checkValidSelection(() => this.plzs.map((plz) => ({name: plz.plz})))
            ),
            gemeindekennziffer: new FormControl(''),
            countryWide: new FormControl(false)
        });

        this.collectionService.emptyFilter$.subscribe(() =>
            this.formGroup.patchValue({
                landkreis: '',
                gemeinde: '',
                bundesland: '',
                postleitzahl: '',
                gemeindekennziffer: '',
                countryWide: false
            })
        );

        this.formGroup.valueChanges
            .pipe(debounceTime(300))
            .subscribe(() => this.valueChanged.emit(this.getLocationDataFromInput()));

        this.filteredBundeslandOptions = this.formGroup.get('bundesland').valueChanges.pipe(
            startWith(''),
            map((value) => this.filterBundeslaender(value || ''))
        );
        this.filteredLandkreisOptions = this.formGroup.get('landkreis').valueChanges.pipe(
            tap((n) => {
                if (
                    this.formGroup.get('landkreis').value?.length > 3 &&
                    this.currentLandkreis != this.formGroup.get('landkreis').value
                ) {
                    this.currentLandkreis = this.formGroup.get('landkreis').value;
                    this.setBundeslandByLandkreisName(this.formGroup.get('landkreis').value);
                }
            }),
            startWith({}),
            map(() =>
                this.filterLandkreise(
                    this.formGroup.get('bundesland').value,
                    this.formGroup.get('landkreis').value
                ).slice(0, 100)
            )
        );

        this.filteredGemeindeOptions = this.formGroup.get('gemeinde').valueChanges.pipe(
            startWith(''),
            map(() =>
                this.filterGemeinden(this.formGroup.get('landkreis').value, this.formGroup.get('gemeinde').value).slice(
                    0,
                    100
                )
            ),
        );

        this.filteredGemeindekennzifferOptions = this.formGroup.get('gemeindekennziffer').valueChanges.pipe(
            startWith(''),
            map((g) =>
                this.gemeinden
                    .filter((g) => g.kreisGemeindeSchluessel === this.formGroup.get('gemeindekennziffer').value)
                    .map((g) => ({kreisGemeindeSchluessel: g.kreisGemeindeSchluessel}))
            )
        );

        this.filteredPlzOptions = this.formGroup.valueChanges.pipe(
            tap((n) => {
                if (
                    this.formGroup.get('postleitzahl').value?.length === 5 &&
                    this.currentPlz != this.formGroup.get('postleitzahl').value
                ) {
                    this.currentPlz = this.formGroup.get('postleitzahl').value;
                    this.setGemeindeByPlz(this.formGroup.get('postleitzahl').value);
                }
            }),
            startWith({}),
            map(() =>
                this.formGroup.get('postleitzahl').value?.length === 5
                    ? this.filterPostalCodes(
                        this.formGroup.get('landkreis').value,
                        this.formGroup.get('postleitzahl').value
                    )
                    : []
            )
        );
    }

    onOptionSelected(event: MatAutocompleteSelectedEvent): void {
        const value: IGemeindeData = event.option.value;
        this.formGroup.get('gemeinde').setValue(value.name);
        this.formGroup.get('gemeindekennziffer').setValue(value.kreisGemeindeSchluessel);
        this.setPlzByGemeinde(value.id);
        this.setLandkreisByGemeinde(value.landkreisId);
    }

    private setGemeindeByPlz(plz: string) {
        const allPlz: IPlzData[] = this.plzs.filter((pc) => pc.plz === plz);

        if (allPlz.length > 1 && this.formGroup.get('gemeinde').value !== null) {
            // dont override existing gemeinde when multiple are found, unless gemeinde is empty
            return;
        }
        const filteredPlz = allPlz.length > 0 ? allPlz[0] : null;
        const filteredGemeinde = this.gemeinden.find((commune) => commune.id === filteredPlz?.id);
        if (filteredGemeinde) {
            this.formGroup.get('gemeinde').setValue(filteredGemeinde.name);
            this.setLandkreisByGemeinde(filteredGemeinde.landkreisId);
            this.setBundeslandByLandkreisId(filteredGemeinde.landkreisId);
            this.setGemeindekennzifferByGemeinde(filteredGemeinde);
        }
    }

    private setPlzByGemeinde(gemeindeId: number) {
        const filteredPlz = this.plzs.find((plz) => plz.id === gemeindeId);
        this.formGroup.get('postleitzahl').setValue(filteredPlz?.plz);
    }

    private setGemeindekennzifferByGemeinde(gemeinde: IGemeindeData) {
        this.formGroup.get('gemeindekennziffer').setValue(gemeinde.kreisGemeindeSchluessel);
    }

    private setLandkreisByGemeinde(landkreisId: number) {
        const filteredLandkreis = this.landkreise.find((c) => c.id === landkreisId)?.name;
        this.formGroup.get('landkreis').setValue(filteredLandkreis);
    }

    private setBundeslandByLandkreisName(landkreis: string) {
        const filteredLandkreis: ILandkreisData = this.landkreise.find((lk) => lk.name === landkreis);
        this.setBundeslandByLandkreis(filteredLandkreis);
    }

    private setBundeslandByLandkreisId(landkreisId: number) {
        const filteredLandkreis: ILandkreisData = this.landkreise.find((lk) => lk.id === landkreisId);
        this.setBundeslandByLandkreis(filteredLandkreis);
    }

    private setBundeslandByLandkreis(landkreis: ILandkreisData) {
        const filteredBundeland = this.bundeslaender.find((c) => c.id === landkreis?.bundeslandId)?.name;
        this.formGroup.get('bundesland').setValue(filteredBundeland);
    }

    private filterBundeslaender(value: string): IBundeslandData[] {
        const filterValue = value.toLowerCase();
        this.filterCountiesByState(value, this.bundeslaender, this.landkreise);
        return filterValue.length > 0
            ? this.bundeslaender?.filter((option) => option.name.toLowerCase().includes(filterValue))
            : [];
    }

    private filterLandkreise(state: string, county: string): ILandkreisData[] {
        const filterValue = county?.toLowerCase() || '';

        const counties = this.filterCountiesByState(state, this.bundeslaender, this.landkreise);

        return filterValue.length > 0
            ? counties.filter((option) => option.name.toLowerCase().includes(filterValue))
            : [];
    }

    private filterCountiesByState(
        state: string,
        states: IBundeslandData[],
        counties: ILandkreisData[]
    ): ILandkreisData[] {
        if (state == null) {
            return counties;
        }
        const selectedState = states.find((o) => o.name === state);
        return counties.filter((o) => o.bundeslandId === selectedState?.id);
    }

    private filterGemeinden(landkreis: string, gemeinde: string): IGemeindeData[] {
        if (typeof gemeinde !== 'string') {
            return [];
        }
        const filterValue = gemeinde?.toLowerCase() || '';

        // const gemeinden = this.filterCommuneByCounty(landkreis, this.landkreise, this.gemeinden);

        return filterValue.length > 0
            ? this.data.gemeinden.filter((gemeinde) => gemeinde.name?.toLowerCase().includes(filterValue))
            : [];
    }

    trackCounty(index: number, item: ILandkreisData) {
        return item.id;
    }

    private filterCommuneByCounty(
        county: string,
        counties: ILandkreisData[],
        communes: IGemeindeData[]
    ): IGemeindeData[] {
        if (county == null) {
            return communes;
        }
        const selectedCounty = counties.find((o) => o.name === county);
        return communes.filter((commune) => commune.landkreisId === selectedCounty?.id);
    }

    private filterPostalCodes(county: string, postalCode: string): IPlzData[] {
        const filterValue = postalCode?.toLowerCase() || '';
        return this.plzs.filter((option) => option.plz.toLowerCase().startsWith(filterValue));
    }

    getLocationDataFromInput(): ILocationItem {
        // find selected locations
        const selectedState = this.bundeslaender.find((state) => state.name === this.formGroup.get('bundesland').value);
        const selectedCounty = this.landkreise.find((county) => county.name === this.formGroup.get('landkreis').value);
        const selectedCommune = this.gemeinden.find(
            (gemeinde) => gemeinde.name === this.formGroup.get('gemeinde').value
        );

        const selectedPostalCode = this.plzs.find(
            (postalCode) => postalCode.plz === this.formGroup.get('postleitzahl').value
        );

        const selectedMunicipalCode = this.formGroup.get('gemeindekennziffer').value;
        const countryWide = this.formGroup.get('countryWide').value;
        // find selectedCommune based on input
        if (selectedCommune) {
            this.selectedGemeinde = this.gemeinden.find((commune) => selectedCommune.id === commune.id);
            return {
                bundesland: selectedState,
                landkreis: selectedCounty,
                gemeinde: this.selectedGemeinde,
                postleitzahl: this.currentPlz,
                gemeindekennziffer: selectedMunicipalCode,
                countryWide: countryWide
            };
        }

        if (!selectedCommune && !selectedMunicipalCode && selectedPostalCode) {
            this.selectedGemeinde = this.formGroup.get('gemeinde').value;

            return {
                bundesland: selectedState,
                landkreis: selectedCounty,
                gemeinde: this.selectedGemeinde,
                postleitzahl: this.currentPlz,
                gemeindekennziffer: null,
                countryWide: countryWide
            };
        }
        return {
            bundesland: selectedState,
            landkreis: selectedCounty,
            gemeinde: null,
            postleitzahl: null,
            gemeindekennziffer: null,
            countryWide: countryWide
        };
    }
}
