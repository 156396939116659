import { gql } from '@apollo/client/core';

const AppraisalQueries = {
    getAppraisalsForListView: gql`
        query appraisals($filter: AppraisalFilterInput!, $pageOptions: PaginationInput!, $sortOptions: SortInput) {
            appraisals(filter: $filter, pageOptions: $pageOptions, sortOptions: $sortOptions) {
                totalRecords
                page
                size
                data {
                    gutachtenId
                    bewertungsstichtag
                    entfernung
                    marktwert
                    marktwertProQuadratmeter
                    auftraggeberName
                    besonderheitenVorhanden
                    baujahr {
                        baujahrValue
                    }
                    baujahrFlaechengewichtet
                    auftragsart {
                        name
                    }
                    ausstattungsQualitaetName
                    lageQualitaetName
                    objektUnterArtName
                    pLZ
                    ort
                    strasse
                    hausnummer
                }
            }
        }
    `,
    getAppraisalsForMapView: gql`
        query appraisals($filter: AppraisalFilterInput!, $pageOptions: PaginationInput!) {
            appraisals(filter: $filter, pageOptions: $pageOptions) {
                totalRecords
                page
                size
                data {
                    gutachtenId
                    laengengrad
                    breitengrad
                }
            }
        }
    `,
    getAppraisalForMapView: gql`
        query appraisal($id: Int!) {
            appraisal(id: $id) {
                gutachtenId
                bewertungsstichtag
                marktwert
                auftraggeber {
                    loraAuftraggeberName
                }
                auftragsart {
                    id
                    name
                }
                objektArt {
                    name
                }
                objektUnterArt {
                    name
                }
                marktwertProQuadratmeter
                baujahr {
                    id
                    baujahrValue
                }
                baujahrFlaechengewichtet
                loraAuftragsNummer
                giksId
                wohnNutzflaeche
            }
        }
    `,
    getAppraisalsForEntwicklungen: gql`
        query entwicklungen($filter: EntwicklungsFilterInput!) {
            entwicklungen(filter: $filter) {
                kaufpreis
                kaufpreisProQm
                marktwert
                marktwertProQuadratmeter
                kaufpreisDatum
                rohertragsvervielfaeltiger
                renditeIst
                renditeAnsatz
            }
        }
    `
};

export { AppraisalQueries };
