import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    constructor(private http: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {}

    public get<T>(url: string, params?: HttpParams, responseType?): Observable<T> {
        return this.http.get<T>(`${this.baseUrl}${url}`, { params, responseType });
    }
    public delete<T>(url: string, params?: HttpParams, responseType?): Observable<T> {
        return this.http.delete<T>(`${this.baseUrl}${url}`, { params, responseType });
    }

    public post<T>(url: string, body: Object): Observable<T> {
        return this.http.post<T>(`${this.baseUrl}${url}`, body);
    }

    public put<T>(url: string, body: Object): Observable<T> {
        return this.http.put<T>(`${this.baseUrl}${url}`, body);
    }
}
