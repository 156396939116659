import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {MimeTypes} from '../../../../core/enums/MimeTypes';
import {AuthService} from '../../../../core/guards/auth.service';
import {DialogComponent} from '../../../../shared/components/dialog/dialog.component';
import {DialogContentType, DialogType} from '../../../../shared/models/Dialog';
import {
    Collection,
    CollectionFeedback,
    CollectionFeedbackInput,
    CollectionFile,
    CollectionStatus
} from '../../../models/Collection';
import {CollectionService} from '../../../services/collection.service';
import {UploadDropdownKategorie, UploadDropdownPublikaionsturnus} from '../../../UploadDropdownData';
import {LoadingService} from "../../../../core/services/loading.service";

@Component({
    selector: 'app-upload-collection-detail',
    templateUrl: './upload-collection-detail.component.html',
    styleUrls: ['./upload-collection-detail.component.scss']
})
export class UploadCollectionDetailComponent implements OnInit, OnDestroy {
    @Output() file = new EventEmitter<any>();
    showRating = true;
    collectionKategorie = UploadDropdownKategorie;
    collectionPublikationsturnus = UploadDropdownPublikaionsturnus;
    collection$: Observable<Collection>;
    files: CollectionFile[] = [];
    feedback: CollectionFeedback[] = [];
    rating = null;
    collectionId: string;
    collectionSub: Subscription;
    ratingSub: Subscription;
    collectionFilePdfSub: Subscription;
    ratingResponseSub: Subscription;
    dialogRefSub: Subscription;
    urlIncrementSub: Subscription;
    sortedNutzungen: { nutzungsart: string; objektarten: string[] }[] = [];

    DialogContentType = DialogContentType;
    MimeTypes = MimeTypes;

    isDataUploader = false;

    urlType = MimeTypes.URL;

    constructor(
        private dialog: MatDialog,
        private collectionService: CollectionService,
        private route: ActivatedRoute,
        private router: Router,
        private http: HttpClient,
        private loadingService: LoadingService,
        private authService: AuthService
    ) {
    }

    ngOnInit(): void {
        this.isDataUploader = this.authService.isUploader();

        this.collection$ = this.route.params.pipe(
            map(({id}) => id),
            distinctUntilChanged(),
            tap((id: string) => (this.collectionId = id)),
            switchMap((id: string) => this.collectionService.getCollectionById(id)),
            shareReplay(1)
        );
        this.ratingSub = this.collection$.subscribe((c) => (this.rating = c.bewertungAverage));
        this.collection$.subscribe((c) => {
            this.files = c.files;
            this.feedback = c.feedback;
            const mappedNutzungen = c.collectionNutzungContainers.map((nutzung) => ({
                nutzungsart: nutzung.collectionNutzungsart?.name,
                objektart: nutzung.collectionObjektart?.name || null
            }));
            this.sortedNutzungen = Object.entries(
                mappedNutzungen.reduce<Record<string, string[]>>(
                    (acc, el) => ((acc[el.nutzungsart] ??= []).push(el.objektart), acc),
                    {}
                )
            ).map(([nutzungsart, objektart]) => ({nutzungsart, objektarten: objektart}));
        });
    }

    openDialog(contentType: DialogContentType, id: string, content: any | null): void {
        const dialogRef = this.dialog.open(DialogComponent, {
            data: {
                dialogType: {
                    type: DialogType.Form,
                    contentType: contentType,
                    content: content
                }
            }
        });
        this.dialogRefSub = dialogRef.afterClosed().subscribe((result) => {
            if (contentType === DialogContentType.UploadRating && result != false && result != undefined) {
                let formData = new FormData();
                formData.append('bewertungNumerisch', result.rating);
                this.ratingResponseSub = this.collectionService
                    .setRating(formData, id)
                    .subscribe((response) => (this.rating = response.data));
            }
            if (contentType === DialogContentType.UploadFile && !!result) {
                this.files.push({
                    aufrufe: 0,
                    fileExtension: result.fileExtension,
                    herausgabedatum: result.herausgabedatum,
                    id: result.id,
                    mimeType: result.type,
                    originalFileName: result.originalFileName,
                    quartal: result.quartal,
                    titel: result.titel,
                    uploadDateTime: null,
                    url: result.type === MimeTypes.URL ? result.url : null,
                    user: null
                });
            }

            if (contentType === DialogContentType.UploadFeedback && !!result) {
                this.collection$
                    .pipe(
                        map((collection: Collection) => ({
                            ...result,
                            link: window.location.href,
                            collectionId: collection.id
                        })),
                        switchMap((input: CollectionFeedbackInput) =>
                            this.collectionService.createCollectionFeedback(input)
                        )
                    )
                    .subscribe({
                        next: (feedback: CollectionFeedback) => this.feedback.unshift(feedback),
                        error: (err) => {
                            console.log(err);
                        }
                    });
            }
        });
    }

    closeFeedback(feedback: CollectionFeedback, index: number): void {
        if (!this.isDataUploader || feedback.status === CollectionStatus.CLOSED) {
            return;
        }
        const dialogRef = this.dialog.open(DialogComponent, {
            maxWidth: '400px',
            data: {
                dialogType: {
                    type: DialogType.Confirm,
                    title: 'Feedback schließen',
                    text: 'Wollen Sie das Feedback als erledigt markieren?',
                    primaryBtnTitle: 'Ok'
                }
            }
        });
        dialogRef
            .afterClosed()
            .pipe(
                filter((result) => !!result),
                switchMap(() => this.collectionService.closeCollectionFeedback(feedback.id))
            )
            .subscribe((feedback: CollectionFeedback) => {
                this.feedback[index] = feedback;
            });
    }

    openLink(url: string) {
        window.open(url);
    }

    editCollection(id: string) {
        this.router.navigate(['upload/create', id]);
    }

    documentClicked(file: CollectionFile): void {
        if (file.mimeType === MimeTypes.PDF.toString()) {
            this.loadingService.setLoading(true);

            this.collectionSub = this.collectionService
                .getFileById(file.id)
                .subscribe((f) => this.file.emit({url: f, mimeType: file.mimeType}));
        } else if (file.mimeType === MimeTypes.URL.toString()) {
            this.incrementUrl(file);
            this.file.emit(file);
        } else {
            this.file.emit(file);
        }
    }

    downloadFile(file: CollectionFile): void {
        this.collectionSub = this.collectionService
            .getFileById(file.id)
            .pipe(
                switchMap((r: { tempFileLink: string }) =>
                    this.http.get(r.tempFileLink, {
                        responseType: 'arraybuffer',
                        headers: new HttpHeaders().append('Content-Type', file.mimeType)
                    })
                )
            )
            .subscribe((response: ArrayBuffer) =>
                this.download(response, file.originalFileName + file.fileExtension, file.mimeType)
            );
    }

    public download(binaryData: ArrayBuffer, fileName: string, fileType: string) {
        const blob: Blob = new Blob([binaryData], {type: fileType});
        const a = document.createElement('a');
        const url = URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);
    }

    documentDeleteClicked(file: CollectionFile) {
        const dialogRef = this.dialog.open(DialogComponent, {
            maxWidth: '400px',
            data: {
                dialogType: {
                    type: DialogType.Confirm,
                    title: 'Dokument löschen',
                    text: 'Wollen Sie das Dokument "' + file.titel + '" endgültig löschen?',
                    primaryBtnTitle: 'Ok'
                }
            }
        });

        dialogRef
            .afterClosed()
            .pipe(
                filter((result) => !!result),
                switchMap(() => this.collectionService.deleteFileById(file.id)),
                // If successfully deleted remove from list.
                filter((deleteResult) => !!deleteResult.succeeded)
            )
            .subscribe(() => this.removeFile(file));
    }

    removeFile(file: CollectionFile) {
        this.files.forEach((item, index) => {
            if (item === file) {
                this.files.splice(index, 1);
            }
        });
    }

    incrementUrl(file: CollectionFile): void {
        this.urlIncrementSub = this.collectionService.incrementUrl(file.id).subscribe();
    }

    ngOnDestroy(): void {
        this.collectionSub?.unsubscribe();
        this.ratingSub?.unsubscribe();
        this.collectionFilePdfSub?.unsubscribe();
        this.ratingResponseSub?.unsubscribe();
        this.dialogRefSub?.unsubscribe();
        this.urlIncrementSub?.unsubscribe();
    }
}
