import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectOptionNumber } from './SelectOption';

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
})
export class RadioGroupComponent {
  @Input() currentSelection: SelectOptionNumber[] = [];

  @Input() multi = false;
  @Input() options: SelectOptionNumber[] = [];
  @Output() currentSelectionChange: EventEmitter<SelectOptionNumber | SelectOptionNumber[]> = new EventEmitter();

  isOptionSelected(option: SelectOptionNumber):boolean{
      return this.currentSelection.map(s => s.value).includes(option.value);
  }
  onChange(selectedOption: SelectOptionNumber): void {
    if (this.currentSelection.find(o => o.value === selectedOption.value)) {
      if (!this.multi) {
        return;
      }

      this.currentSelection = this.currentSelection.filter(o => o.value !== selectedOption.value);
    } else {
      if (this.multi) {
        this.currentSelection = [...this.currentSelection, selectedOption];
      } else {
        this.currentSelection = [selectedOption];
        this.currentSelectionChange.emit([selectedOption]);
        return;
      }
    }

    this.currentSelectionChange.emit(this.currentSelection);
  }
}
