import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogType, IConfirmDialog } from '../../models/Dialog';

@Component({
    selector: 'app-upload-create-lage',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
    dialogData;
    DialogType = DialogType;
    action: string;

    constructor(public dialogRef: MatDialogRef<DialogComponent>, @Inject(MAT_DIALOG_DATA) public data: IConfirmDialog) {
        this.dialogData = data.dialogType;
        if (this.dialogData.contentType) {
            this.action = this.dialogData.content != null ? 'bearbeiten' : 'hinzufügen';
        }
    }

    close(value: any): void {
        value != null ? this.submit(value) : this.cancel();
    }

    submit(value: any): void {
        this.dialogRef.close(value);
    }

    cancel(): void {
        this.dialogRef.close(false);
    }
}
