import { Injectable } from '@angular/core';
import { NavigationExtras, Router, UrlSegment, UrlSerializer } from '@angular/router';
import { BrickNavigation, BrickRoutes } from '../interfaces/BrickTypes';
import { BreadcrumbDefault } from 'src/app/core/services/breadcrumbs.service';
import { Breadcrumb } from 'src/app/core/types/Breadcrumbs';

@Injectable({
   providedIn: 'root'
})
export class BrickNavigationService {
   constructor(
      private router: Router,
      private serializer: UrlSerializer
   ) { }

   getLastSegment(url: UrlSegment[]): string {
      const last: string = url[url.length - 1].path;
      return last;
   }

   // Determines the client type name from last segment of a URL.
   getClientType(url: UrlSegment[]): string {
      const lastSegment: string = this.getLastSegment(url);
      var brickNavigation: BrickNavigation = BrickRoutes.find(x => x.editSegment === lastSegment);

      return brickNavigation.clientTypeName;
   }

   // Determines the URL segment name for editing the brick by the brick client type.
   getEditSegment(clientType: string) {
      var brickNavigation: BrickNavigation = BrickRoutes.find(x => x.clientTypeName === clientType);

      return brickNavigation.editSegment;
   }

   getOverviewCommands(appraisalTextId: number): any[] {
      return ['/textgenerator/list', appraisalTextId];
   }

   // Get URL for navigation to the overview (without query params, i.e. no tab selection for recently edited brick).
   getOverviewUrl(appraisalTextId: number, url: UrlSegment[]): string {
      const commands: any[] = this.getOverviewCommands(appraisalTextId);
      const tree = this.router.createUrlTree(commands);
      return this.serializer.serialize(tree);
   }

   // Get a Breadcrumb for navigation to the overview.
   getOverviewBreadcrumb(appraisalTextId: number, url: UrlSegment[], clientType: string = this.getClientType(url)): Breadcrumb {
      return {
         ...BreadcrumbDefault.TextgeneratorDetailLabel,  // label
         url: this.getOverviewUrl(appraisalTextId, url), // url
         ...this.getOverviewQueryParams(url, clientType) // queryParams
      };
   }

   getOverviewQueryParams(url: UrlSegment[], clientType: string = this.getClientType(url)) {
      return { queryParams: { selectedBrick: clientType } };
   }

   // Navigates to overview, i.e. appraisal text detail page, and selects tab for recently edited brick.
   navigateToOverview(appraisalTextId: number, url: UrlSegment[], reload: boolean = false, clientType: string = this.getClientType(url)): Promise<boolean | void> {

      const commands: any[] = this.getOverviewCommands(appraisalTextId);
      const extras: NavigationExtras = this.getOverviewQueryParams(url, clientType);

      if (reload) {
         return this.router.navigate(commands, extras)
            .then(() => { window.location.reload(); }); // we need to reload as in some weird cases the data will not refresh on target page
      }
      else {
         return this.router.navigate(commands, extras);
      }
   }

}
