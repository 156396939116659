import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import _ from 'lodash';
import { SnackbarService } from '../../../../core/services/snackbar.service';
import { DialogComponent } from '../../../../shared/components/dialog/dialog.component';
import { DialogType } from '../../../../shared/models/Dialog';
import { Appraisal } from '../../../models/Appraisal';
import { IAppraisalHeaderColumn, IAppraisalTableColumn } from '../../../models/AppraisalTableColumn';
import { AppraisalCompareService } from '../../../services/appraisal-compare.service';
import { AppraisalTableHeaderData } from './AppraisalTableViewColumnData';

interface Sort {
   direction: 'asc' | 'desc';
}

@Component({
   selector: 'app-appraisal-table-view',
   templateUrl: './appraisal-table-view.component.html',
   styleUrls: ['./appraisal-table-view.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppraisalTableViewComponent {
   columnHeader: IAppraisalHeaderColumn[] = AppraisalTableHeaderData;

   @Input() set appraisals(appraisals: Appraisal[]) {
      this._appraisals = appraisals;
      this.columnHeader.forEach((h) => {
         h.columns.forEach((column: IAppraisalTableColumn) => {
            column.show = this.checkColumnNotEmpty(column.key, this._appraisals);
            const titleWords = Math.max(...column.title.split(' ').map((w) => w.length));
            column.width = titleWords == 0 ? 200 : titleWords * 5 + 70;
            this.calculateAverage(column, appraisals);
         });
      });
      this.columnHeader = this.columnHeader.filter(c => c.columns.some(f => f.show));
      const temp = [...this.columnHeader];
      this.columnHeader.forEach((h) =>
         h.columns = h.columns.filter((c) => c.show));
      this.columnHeader = temp;
      this.cdr.detectChanges();
   }

   public _appraisals: Appraisal[];
   @Input() pdfStyle: boolean;

   arrowMap: { [key: string]: Sort } = {};

   constructor(
      private cdr: ChangeDetectorRef,
      private appraisalCmpService: AppraisalCompareService,
      private dialog: MatDialog,
      private snackbarService: SnackbarService
   ) {
   }

   calculateAverage(data: IAppraisalTableColumn, appraisals: Appraisal[]): void {
      if (data.key === 'baujahr') {
         const allValues = _.flatMap(appraisals.map((a: Appraisal) => a.baujahr)).map((b) => b.baujahrValue);
         const min = Math.min(...allValues);
         data.min = new Date(min + '-01-01T00:00:00');
         const max = Math.max(...allValues);
         data.max = new Date(max + '-01-01T00:00:00');

         const avgs = [];
         for (const a of appraisals) {
            const avg = a.baujahr.map((b) => b.baujahrValue).reduce((p, c) => p + c, 0) / a.baujahr.length;
            avgs.push(avg);
         }
         data.average = avgs.reduce((p, c) => p + c, 0) / avgs.length;
      } else {
         const withValue = appraisals.map((a) => a[data.key]).filter((a) => a !== null && a !== undefined);

         if (withValue.length === 0) {
            data.average = 0;
            data.min = 0;
            data.max = 0;
         } else {
            data.average = withValue.reduce((p, c) => p + c, 0) / withValue.length;
            data.min = Math.min(...withValue);
            data.max = Math.max(...withValue);
         }
      }
   }

   sort(col: IAppraisalTableColumn, sort: 'asc' | 'desc'): void {
      this.arrowMap = {};
      this.arrowMap[col.key] = { direction: sort };
      const sorted = this._appraisals.sort((a: Appraisal, b: Appraisal) => {
         if (!!col.sortFunction) {
            return col.sortFunction(a, b);
         }
         if (typeof a[col.key] === 'number') {
            return a[col.key] > b[col.key] ? 1 : -1;
         }
         if (!!a[col.key]?.name) {
            return (a[col.key]?.name || '').toString().localeCompare((b[col.key]?.name || '').toString());
         }
         return (a[col.key] || '').toString().localeCompare((b[col.key] || '').toString());
      });
      this._appraisals = sort === 'desc' ? sorted.reverse() : sorted;
   }

   removeAppraisal(id: number): void {
      this.appraisalCmpService.addOrRemoveAppraisal(id);
   }

   openDialog(titel: number, text: string): void {
      this.dialog.open(DialogComponent, {
         data: {
            dialogType: {
               type: DialogType.Html,
               title: `DataSuite Nr. ${titel} - Fazit`,
               text: text
            }
         }
      });
   }

   showSnackbar(message: string) {
      this.snackbarService.showInfo(message);
   }

   private checkColumnNotEmpty(key: string, appraisals: Appraisal[]): boolean {
      switch (key) {
         case 'lage':
            return appraisals.some((a) => a[key]?.lageQualitaet != null);
         case 'sachwertfaktor':
            return appraisals.some((a) => a[key] >= 0 && a[key] != null);
         case 'kaufpreis':
            return appraisals.some((a) => a[key] > 0 && a[key] != null);
         case 'adresse':
            return appraisals.some((a) => (a.strasse && a.hausnummer) || a.plz);
         case 'objektArt':
            return appraisals.some((a) => a['objektArt'] || a['objektUnterArt']);
         default:
            return appraisals.some((a) => a[key] != null && a[key] != 0);
      }
   }
}
