import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BarNotification } from '../../../core/enums/BarNotificaton';
import { NotificationbarService } from '../../../core/services/notificationbar.service';

@Component({
    selector: 'app-notificationbar',
    templateUrl: './notificationbar.component.html',
    styleUrls: ['./notificationbar.component.scss']
})
export class NotificationbarComponent implements OnInit, OnDestroy {
    show = false;
    message: string;
    type = BarNotification.DEFAULT;

    private notificationSub: Subscription;

    constructor(private notificationbarService: NotificationbarService) {}

    ngOnInit(): void {
        this.notificationSub = this.notificationbarService.notificationState.subscribe((state) => {
            this.type = state.type || BarNotification.DEFAULT;
            this.message = state.message;
            this.show = state.show;
        });
    }

    ngOnDestroy() {
        this.notificationSub?.unsubscribe();
    }
}
