<div class="frame">
    <ng-container *ngFor="let col of columns">
        <app-appraisal-info-column
            *ngIf="currentSelection[col]"
            [appraisal]="currentSelection[col]"
            [comparableAppraisals]="comparableAppraisals"
            [viewOptions]="viewOptions$ | async"
            (idChange)="onIdChange($event, col)"
        >
        </app-appraisal-info-column>
    </ng-container>
</div>
