export enum BebauungsPlan {
   GrundstueckInBPlanEnthalten = 1,
   Paragraph34BauGbGreift = 2,
   GrundstueckLiegtImAussenbereich = 3
};

export type BebauungsPlanName = {
   [key in BebauungsPlan]: string;
};

export const BebauungsPlanNames: BebauungsPlanName = {
   [BebauungsPlan.GrundstueckInBPlanEnthalten]: 'Grundstück in B-Plan enthalten',
   [BebauungsPlan.Paragraph34BauGbGreift]: '§ 34 BauGB greift',
   [BebauungsPlan.GrundstueckLiegtImAussenbereich]: 'Grundstück liegt im Außenbereich'
};