import { Component, Input } from '@angular/core';
import { Appraisal } from '../../../../models/Appraisal';
import {KaufpreisQualitaetName, KaufpreisQualitaetNames} from "../../../../../core/enums/KaufpreisQualitaet";

@Component({
    selector: 'app-appraisal-objektbeschreibung',
    templateUrl: './appraisal-objektbeschreibung.component.html',
    styleUrls: ['./appraisal-objektbeschreibung.component.scss', '../appraisal-standards.scss']
})
export class AppraisalObjektbeschreibungComponent {
    @Input() appraisal: Appraisal;

    public KaufpreisQualitaetNames = KaufpreisQualitaetNames;
}


