import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute } from '@angular/router';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { BrickMacroLocation } from 'src/app/appraisal-textgenerator/interfaces/BrickTypes';
import { BrickNavigationService } from 'src/app/appraisal-textgenerator/services/brick-navigation.service';
import { BrickService } from 'src/app/appraisal-textgenerator/services/brick.service';
import { BreadcrumbDefault, BreadcrumbsService } from 'src/app/core/services/breadcrumbs.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { ILocationItem } from 'src/app/shared/interfaces/LocationData';

@Component({
   selector: 'app-brick-macro-location-edit',
   templateUrl: './brick-macro-location-edit.component.html',
   styleUrls: ['./brick-macro-location-edit.component.scss']
})
export class BrickMacroLocationEditComponent implements OnInit {

   form = this.fb.group({
      postleitzahl: ['', [Validators.required, this.selectedItemValidator()]]
   });

   appraisalTextId: number;
   brickData: BrickMacroLocation;
   filteredLocations: ILocationItem[] = [];
   selectedLocation: ILocationItem; // location selected via autocomplete

   constructor(
      private activatedRoute: ActivatedRoute,
      private fb: FormBuilder,
      private brickService: BrickService,
      private brickNavigationService: BrickNavigationService,
      private snackbarService: SnackbarService,
      private breadcrumbsService: BreadcrumbsService
   ) { }

   ngOnInit(): void {
      this.appraisalTextId = this.activatedRoute.snapshot.params.appraisalTextId;
      this.brickData = this.activatedRoute.snapshot.data['brickData'];
      // console.log(this.brickData);

      this.initFormData();
      this.zipCodeSearch();

      this.breadcrumbsService.Apply([
         { ...BreadcrumbDefault.TextgeneratorList },
         { ...this.brickNavigationService.getOverviewBreadcrumb(this.appraisalTextId, this.activatedRoute.parent.snapshot.url) },
         { label: 'Makrolage' }
      ]);
   }

   initFormData() {
      this.form.controls.postleitzahl.setValue(this.brickData.postleitzahl?.plz);
   }

   onFocusOut(event: any) {
      if (!this.form.valid) { // we rely on proper validation
         this.selectedLocation = null; // unset selection
      }
   }

   displayFn(locationItem: ILocationItem): string {
      return locationItem?.postleitzahl?.plz // on user selection
         ?? <string><unknown>locationItem;   // when loaded initially, param "locationItem" is passed as a string (zip code), see initFormData()
   }

   locationSelected(event: MatAutocompleteSelectedEvent) {
      this.selectedLocation = event.option.value;
      // console.log('Selected location:', this.selectedLocation);
   }

   // validator implementation: https://blog.angular-university.io/angular-custom-validators/
   selectedItemValidator(): ValidatorFn {
      // force user to select a value from search (filter)
      // input without selection is invalid
      return (control: AbstractControl): ValidationErrors | null => {

         const value = control.value;

         if (!value) { // Validators.required shall care for empty values
            return null;
         }

         // valid zip code
         const regex = new RegExp('[0-9]{5}?');
         let msg = 'not a zip code';
         if (typeof (value) === 'string' && !regex.test(value)) {
            // console.log(msg);
            return { error: msg };
         }

         // contained in search (filter)
         msg = 'not contained in search (filter)';
         if (!this.filteredLocations.includes(value)) {
            // console.log(msg);
            // console.log(this.filteredLocations);
            return { error: msg };
         }

         return null; // all Ok, input is valid
      }
   }

   zipCodeSearch() {
      this.form.controls.postleitzahl.valueChanges
         .pipe(
            filter(val => !!val),   // do not emit empty/null values
            debounceTime(500),      // wait some time before emitting value
            distinctUntilChanged(), // avoid emitting duplicate values
            switchMap(searchTerm => this.brickService.searchLocationByZipCode(searchTerm))
         )
         .subscribe(items => {
            this.filteredLocations = items; // we save the filtered items for later zip code validation
            // console.log('Filtered locations:', this.filteredLocations);
         });
   }

   cancel(): void {
      this.brickNavigationService.navigateToOverview(this.appraisalTextId, this.activatedRoute.parent.snapshot.url);
   }

   save(): void {
      if (this.form.valid) {
         let instance: any = { ...this.brickData, ...this.selectedLocation };
         // console.log(instance);

         const clientType: string = this.brickNavigationService.getClientType(this.activatedRoute.parent.snapshot.url);
         this.brickService.updateBrickData(this.appraisalTextId, clientType, instance)
            .subscribe();

         this.snackbarService.showSuccess('Speichern erfolgreich');
      }
   }

   saveAndExit(): void {
      if (this.form.valid) {
         this.save();
         this.brickNavigationService.navigateToOverview(this.appraisalTextId, this.activatedRoute.parent.snapshot.url, true); // with reload
      }
   }
}