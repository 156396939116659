import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { AppraisalTextObjectCategory, AppraisalTextObjectSubType, AppraisalTextObjectType, BrickProductionCostsObject, ExcerptConstructionCosts as ExcerptConstructionCosts, ExcerptConstructionPriceIndices as ExcerptConstructionPriceIndices, ReferenceUnit, Region } from '../interfaces/BrickTypesProductionCosts';
import { environment } from 'src/environments/environment';
import { RequestData } from 'src/app/appraisal-detail/models/Appraisal';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { BrickUpdate } from '../interfaces/BrickTypes';
import { ILocationItem } from 'src/app/shared/interfaces/LocationData';

@Injectable({
   providedIn: 'root'
})
export class BrickService {

   constructor(private apiService: ApiService) { }

   loadBrickData(appraisalTextId: number, brickClientType: string): Observable<RequestData<any>> {
      const params = new HttpParams()
         .set('clientType', brickClientType);
      const url = `${environment.brickGetBrickUrl}/${appraisalTextId}`;
      return this.apiService.get<RequestData<any>>(url, params);
   }

   updateBrickData(appraisalTextId: number, clientType: string, data: any): Observable<RequestData<any>> {
      const url = `${environment.brickUpdateBrickUrl}/${appraisalTextId}`;
      const body: BrickUpdate = { clientType: clientType, data: data };
      return this.apiService.put(url, body);
   }

   updateProductionCostsObject(appraisalTextId: number, data: BrickProductionCostsObject): Observable<RequestData<any>> {
      const url = `${environment.brickCreateUpdateProductionCostsObjectUrl}/${appraisalTextId}`;
      return this.apiService.put(url, data);
   }

   loadAllRegions(): Observable<RequestData<Region[]>> {
      const url = `${environment.brickGetRegionsUrl}`;
      return this.apiService.get<RequestData<Region[]>>(url);
   }

   loadAllObjectTypes() {
      const url = `${environment.brickGetObjectTypesUrl}`;
      return this.apiService.get<RequestData<AppraisalTextObjectType[]>>(url);
   }

   loadAllObjectSubTypes() {
      const url = `${environment.brickGetSubTypesUrl}`;
      return this.apiService.get<RequestData<AppraisalTextObjectSubType[]>>(url);
   }

   loadAllReferenceUnits() {
      const url = `${environment.brickGetReferenceUnitsUrl}`;
      return this.apiService.get<RequestData<ReferenceUnit[]>>(url);
   }

   loadProductionCostsObject(appraisalTextId: number, objectId: number): Observable<RequestData<BrickProductionCostsObject>> {
      const url = `${environment.brickGetProductionCostsObjectUrl}/${appraisalTextId}/${objectId}`;
      return this.apiService.get<RequestData<BrickProductionCostsObject>>(url);
   }

   DeleteProductionCostsObject(objectId: number): Observable<RequestData<BrickProductionCostsObject>> {
      const url = `${environment.brickDeleteProductionCostsObjectUrl}/${objectId}`;
      return this.apiService.get<RequestData<BrickProductionCostsObject>>(url);
   }

   loadExcerptConstructionPriceIndices(
      appraisalTextId: number,
      objectCategoryId: number
   ): Observable<RequestData<ExcerptConstructionPriceIndices>> {
      const params = new HttpParams()
         .set('objectCategoryId', objectCategoryId);
      const url = `${environment.brickGetExcerptConstructionPriceIndices}/${appraisalTextId}`;
      return this.apiService.get<RequestData<ExcerptConstructionPriceIndices>>(url, params);
   }

   loadExcerptConstructionCosts(
      appraisalTextId: number,
      objectSubTypeId: number,
      objectCategoryId: number,
      referenceUnitId: number
   ): Observable<RequestData<ExcerptConstructionCosts>> {

      const params = new HttpParams()
         .set('objectSubTypeId', objectSubTypeId)
         .set('objectCategoryId', objectCategoryId)
         .set('referenceUnitId', referenceUnitId);
      const url = `${environment.brickGetExcerptConstructionCostsUrl}/${appraisalTextId}`;
      return this.apiService.get<RequestData<ExcerptConstructionCosts>>(url, params);
   }

   searchLocationByZipCode(term: string): Observable<ILocationItem[]> {
      const params = new HttpParams()
         .set('term', term);
      const url = environment.brickSearchLocationByZipCodeUrl;
      return this.apiService.get<ILocationItem[]>(url, params);
   }

}
