import { Injectable } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})

// helper class for handling form specific tasks
export class FormService {

  constructor() { }

  // On provided condition this function activates a form control and set it required.
  // Otherwise the form control is deactivated.
  handleCondition(activate: boolean, formControl: AbstractControl, setRequired: boolean) {
    if (activate) {
      formControl.enable();
      if (setRequired) formControl.addValidators(Validators.required);
    } else {
      formControl.disable();
      if (setRequired) formControl.clearValidators();
      formControl.setValue(null);
    }
    formControl.updateValueAndValidity()
  }
}
