export interface IConfirmDialog {
    dialogType: DialogForm | DialogHtml | DialogText;
}

type DialogDefault = {
    type: DialogType;
    primaryBtnTitle?: string;
};

export type DialogForm = DialogDefault & {
    contentType: DialogContentType;
    content: any;
};

export type DialogHtml = DialogDefault & {
    title: string;
    text: string;
};

export type DialogText = DialogHtml;

export enum DialogContentType {
    UploadNutzung = 'Nutzung',
    UploadLage = 'Lage',
    UploadBeschreibung = 'Beschreibung',
    UploadFile = 'Dokument',
    UploadHint = 'Hinweis',
    UploadRating = 'Bewertung',
    UploadAddItemNutzung = 'Nutzung Item',
    UploadAddItemHerausgeber = 'Herausgeber Item',
    UploadFeedback = 'Feedback'
}

export enum DialogType {
    Text = 'Text',
    Html = 'HTML',
    Form = 'Form',
    Confirm = 'Confirm'
}
