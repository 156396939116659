import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SnackbarService } from '../../../../core/services/snackbar.service';
import { Appraisal } from '../../../models/Appraisal';
import { BreadcrumbDefault, BreadcrumbsService } from 'src/app/core/services/breadcrumbs.service';

@Component({
   selector: 'app-appraisal-detail',
   templateUrl: './appraisal-info-view.component.html',
   styleUrls: ['./appraisal-info-view.component.scss']
})
export class AppraisalInfoViewComponent implements OnInit {
   appraisal$: Observable<Appraisal>;

   constructor(
      private route: ActivatedRoute,
      private snackbarService: SnackbarService,
      private breadcrumbsService: BreadcrumbsService
   ) {
      this.appraisal$ = this.route.parent.data.pipe(map(({ appraisal }) => appraisal));
   }

   ngOnInit(): void {
      this.breadcrumbsService.Apply([
         { ...BreadcrumbDefault.SearchMap },
         { label: 'Gutachten Detail' }
      ]);
   }

   showSnackbar(message: string) {
      this.snackbarService.showInfo(message);
   }
}
