import { Baugenehmigung } from "../enums/Baugenehmigung";
import { GebrauchsAbnahmeNachweis } from "../enums/GebrauchsAbnahmeNachweis";
import { GutachtenErstellungTyp } from "../enums/GutachtenErstellungTyp";
import { ConstructionPriceIndexState, BrickProductionCostsObject, Region } from "./BrickTypesProductionCosts";
import { AppraisalText } from "./AppraisalTextTypes";
import { BebauungsPlan } from "../enums/BebauungsPlan";
import { IZipCode, ILocation, BrickMacroLocationMetric } from "./BrickTypesMacroLocation";

// define all
export const BrickRoutes: BrickNavigation[] = [
   { editSegment: "brick-construction-loads", clientTypeName: "BrickConstructionLoads" },
   { editSegment: "brick-construction-law", clientTypeName: "BrickConstructionLaw" },
   { editSegment: "brick-production-costs", clientTypeName: "BrickProductionCosts" },
   { editSegment: "brick-macro-location", clientTypeName: "BrickMacroLocation" },
];

// for navigation to  brick edit components
export interface BrickNavigation {
   editSegment: string,
   clientTypeName: string
}

// for passing brick specific data for update in api
export interface BrickUpdate {
   clientType: string,
   data: any
}

export interface BaseBrick {
   gutachtenText: AppraisalText,
   bearbeitetAm: Date,
   ersetzen: boolean,
   notes: string[]
}

// specific brick types must extend BaseBrick
export interface BrickConstructionLoads extends BaseBrick {

   inBayern: boolean,
   baulastenAuskunftVorhanden: boolean,
   baulastenVorhanden: boolean,
   wertrelevanz: boolean,
   gutachterlicheEinschaetzung: string,
   baulastKurztext: string,
   gutachtenOhneBaulastenauskunftErstellenMoeglich: GutachtenErstellungTyp
}

export interface BrickConstructionLaw extends BaseBrick {
   bebauungsPlan: BebauungsPlan,
   bebauungsplanTitel: string,
   bebauungsplanDatum: Date,
   festsetzungen: string,
   baugenehmigung: Baugenehmigung,
   gebrauchsAbnahmeNachweis: GebrauchsAbnahmeNachweis
}

export interface BrickProductionCosts extends BaseBrick {
   region: Region,
   bpiStandAktuell: ConstructionPriceIndexState,
   bpiStandBeiVeroeffentlichung: ConstructionPriceIndexState,
   mehrWertSteuerProzent: number,
   objekte: BrickProductionCostsObject[]
}

export interface BrickMacroLocation extends BaseBrick {
   postleitzahl: IZipCode;
   ort: ILocation;
   kennzahlen: BrickMacroLocationMetric;
}