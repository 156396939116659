<div class="row gap-2">
   <app-field label="Postleitzahl" class="col-md">
      {{data.postleitzahl.plz}}
   </app-field>
   <app-field label="Ort" class="col-md">
      {{data.ort | locationItem}}
   </app-field>
   <app-field label="Gemeinde" class="col-md">
      {{data.ort.gemeindePolitisch.name}}
   </app-field>
   <div class="col-md"></div>
</div>
<div class="row gap-2 mt-4">
   <app-field label="Kreisgemeindeschlüssel" class="col-md">
      {{data.ort.kreisGemeindeSchluessel}}
   </app-field>
   <div class="col-md">
      <div class="hstack gap-2">
         <app-field label="Gemeindeverband">
            {{data.ort.gemeindePolitisch.gemeindeVerband.name}}
         </app-field>
         <app-info-display-icon tooltip="Aufgaben und Organisation der Gemeinden sind in jedem Bundesland durch eine Kommunal- oder Gemeindeverfassung geregelt.
Die Kommunalverfassungen legen in der Regel auch fest, wie groß eine Gemeinde sein sollte, um die Pflichtaufgaben zu erledigen.
Unterschreitet die Gemeinde diese Größe, kann sie sich in den meisten Bundesländern mit benachbarten Gemeinden zu einem Gemeindeverband bzw. einer Verwaltungsgemeinschaft zusammenschließen.
Nur in Hessen, Nordrhein-Westfalen und dem Saarland gibt es keine solchen Zusammenschlüsse.
Die Mitgliedsgemeinden eines Gemeindeverbandes bleiben rechtlich selbstständig, wobei ihre Pflicht- und Auftragsaufgaben gegenüber verbandsfreien Gemeinden deutlich reduziert sind.
Die Bezeichnungen für Gemeindeverbände unterscheiden sich in den Ländern (bspw. Ämter, Samtgemeinden, Verbandsgemeinden, Verwaltungsgemeinschaften).">
         </app-info-display-icon>
      </div>
   </div>
   <app-field label="Kreis" class="col-md">
      {{data.ort.kreis.name}}
   </app-field>
   <app-field label="Bundesland" class="col-md">
      {{data.ort.kreis.bundesland.name}}
   </app-field>
</div>
<div class="row mt-4">
   <app-brick-macro-location-display [data]="data"></app-brick-macro-location-display>
</div>