import { Injectable } from '@angular/core';
import { ApolloLink, ApolloQueryResult, FetchResult } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { Apollo, QueryRef } from 'apollo-angular';
import { DocumentNode } from 'graphql';
import { SigninRequest } from 'oidc-client';
import { Observable, throwError } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../guards/auth.service';

@Injectable({
    providedIn: 'root'
})
export class GraphqlService {
    constructor(private apollo: Apollo, private authService: AuthService) {}

    public query(query: DocumentNode, variables: object = null): Observable<ApolloQueryResult<any>> {
        return this.apollo.query<any>({ query, variables });
    }

    public watchQuery(query: DocumentNode, variables: object = null): Observable<ApolloQueryResult<any>> {
        return this.watchQueryRef(query, variables).valueChanges;
    }

    public watchQueryRef(query: DocumentNode, variables: object = null): QueryRef<any> {
        return this.apollo.watchQuery<any>({ query, variables });
    }

    public mutation(mutation: DocumentNode, variables: object = null): Observable<FetchResult<any>> {
        return this.apollo.mutate<any>({ mutation, variables });
    }

    /*
     * Here starts auth and error handling for apollo requests
     ******************************************************************************************************************/
    public getApolloAuth(): ApolloLink {
        return setContext(() => {
            // Get the authentication token from local storage if it exists
            const token = localStorage.getItem('token');
            return {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
        });
    }

    public getApolloOnError(): ApolloLink {
        return onError(({ graphQLErrors }) => {
            graphQLErrors?.map(({ extensions }) => {
                if (extensions?.code === 'authorization') {
                    localStorage.clear();
                    this.authService
                        .startSignInProcess()
                        .pipe(
                            filter((x) => !!x),
                            tap((signInRequest: SigninRequest) => window.location.assign(signInRequest.url)), // redirect to OpenId Provider
                            switchMap(() => throwError('Unauthorized'))
                        )
                        .subscribe();
                }
            });
        });
    }
}
