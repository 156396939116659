import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

@Component({
    selector: 'app-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit, OnDestroy {
    position: string;

    valueControl: UntypedFormControl = new UntypedFormControl(0);

    @Input() min = 0;
    @Input() max = 100;
    @Input() step = 1;
    minValueShowMin = 30;
    maxValueShowMax = 405;
    @Output() valueChange: EventEmitter<number> = new EventEmitter();
    private valueSub: Subscription;

    @Input()
    set disabled(bool: boolean) {
        if (bool) {
            this.valueControl.disable();
            this.valueControl.setValue(0);
        } else {
            this.valueControl.enable();
        }
    }

    @Input() set value(val: number) {
        this.valueControl.setValue(val);
        this.updatePosition(val);
    }

    ngOnInit(): void {
        this.valueSub = this.valueControl.valueChanges
            .pipe(
                tap((v) => this.updatePosition(v)),
                debounceTime(500)
            )
            .subscribe((v) => this.emit(v));
    }

    ngOnDestroy(): void {
        this.valueSub?.unsubscribe();
    }

    private emit(v: number): void {
        this.valueChange.emit(v);
    }

    private updatePosition(v: number): void {
        this.position = this.calcPosition(v);
    }

    private calcPosition(value: number): string {
        const currentValueAsPercentage = value / (this.max / 100);
        const offset: number = Math.round(currentValueAsPercentage / 4 + 6);
        return `calc(${currentValueAsPercentage}% - ${offset}px`;
    }
}
