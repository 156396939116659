import { Component, Input } from '@angular/core';
import { Appraisal } from '../../../../models/Appraisal';

@Component({
    selector: 'app-appraisal-ausstattung-lage',
    templateUrl: './appraisal-ausstattung-lage.component.html',
    styleUrls: ['./appraisal-ausstattung-lage.component.scss', '../appraisal-standards.scss']
})
export class AppraisalAusstattungLageComponent {
    @Input() appraisal: Appraisal;
}
