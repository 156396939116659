import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-textgenerator',
  templateUrl: './textgenerator.component.html',
  styleUrls: ['./textgenerator.component.scss']
})
export class TextgeneratorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
