<app-expansion-panel [panelOpen]="'open'" class="expansion-panel">
    <ng-container header>
        <span class="title">{{ title }}</span>
    </ng-container>
    <ng-container content>
        <ng-container *ngIf="isOpen">
            <div *ngFor="let collection of data" class="entry">
                <img
                    src="../../../assets/icons/{{ MimeTypes[collection.mimeType]?.toLowerCase() || 'nomime' }}.png"
                    class="doc-icon"
                />
                <a class="link-label" [routerLink]="['../', 'upload', 'collection', collection.id]">{{
                    collection.titel
                }}</a>
            </div>
        </ng-container>
    </ng-container>
</app-expansion-panel>
