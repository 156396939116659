<div *ngIf="appraisal" class="div-content">
    <!-- under construction
    <b>Entfernung</b> <span>{{appraisal-detail-boxes.entfernung | value | placeholder: { suffix: 'km' } }}</span>
    -->
    <b>Baujahr(e)</b> <span>{{ appraisal.baujahr | gibtBaujahreRange: appraisal.baujahrFlaechengewichtet }}</span>
    <b>Entfernung zum Objekt</b>
    <span>{{ appraisal.entfernung | number: '1.2-2' | placeholder: { suffix: 'km' } }}</span>
    <b>Lagequalität</b> <span>{{ appraisal.lage?.lageQualitaet?.name | placeholder }}</span>
    <b>Ausstattungsstandard</b>
    <span *ngIf="appraisal.ausstattungsQualitaet?.name; else noAusstattung">
        {{ appraisal.ausstattungsQualitaet?.name | placeholder }}
        <p>{{ appraisal.ausstattungsQualitaet?.id | placeholder }}</p>
    </span>
    <ng-template #noAusstattung>-</ng-template>
    <b>Anzahl Mieteinheiten</b> <span>{{ appraisal.anzahlMieteinheiten | placeholder: { suffix: 'Stk.' } }}</span>
    <b>Anzahl Stellplätze</b> <span>{{ appraisal.anzahlStellplaetze | placeholder: { suffix: 'Stk.' } }}</span>

    <h4 class="header">Mietvertrag</h4>
    <div id="placeholder"></div>
    <b class="sub-header">BWK</b>
    <span class="sub-header">{{ appraisal.angesetzteBewirtschaftungskosten | placeholder: { suffix: '%' } }}</span>
</div>
