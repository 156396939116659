<p class="mt-5 display-6">Baulasten</p>

<app-card class="mt-4">
   <div body>
      <form [formGroup]="form">
         <div class="row">
            <mat-form-field class="col-md">
               <mat-label>Objekt in Bayern?</mat-label>
               <mat-select formControlName="inBayern" [(ngModel)]="brickData.inBayern">
                  <mat-option [value]="true">Ja</mat-option>
                  <mat-option [value]="false">Nein</mat-option>
               </mat-select>
            </mat-form-field>
            <mat-form-field class="col-md">
               <mat-label>Baulastenauskunft vorhanden?</mat-label>
               <mat-select formControlName="baulastenAuskunftVorhanden" [(ngModel)]="brickData.baulastenAuskunftVorhanden">
                  <mat-option [value]="true">Ja</mat-option>
                  <mat-option [value]="false">Nein</mat-option>
               </mat-select>
            </mat-form-field>
            <mat-form-field class="col-md">
               <mat-label>Gutachten ohne Baulastenauskunft erstellen?</mat-label>
               <mat-select formControlName="gutachtenOhneBaulastenauskunftErstellenMoeglich" class="d-flex flex-column" [(ngModel)]="brickData.gutachtenOhneBaulastenauskunftErstellenMoeglich">
                  <mat-option *ngFor="let option of gutachtenErstellungsOptions" [value]="option">
                     {{GutachtenErstellungTypNames[option]}}
                  </mat-option>
               </mat-select>
            </mat-form-field>
         </div>
         <div class="row">
            <mat-form-field class="col-md">
               <mat-label>Baulasten vorhanden?</mat-label>
               <mat-select formControlName="baulastenVorhanden" [(ngModel)]="brickData.baulastenVorhanden">
                  <mat-option [value]="true">Ja</mat-option>
                  <mat-option [value]="false">Nein</mat-option>
               </mat-select>
            </mat-form-field>
            <mat-form-field class="col-md">
               <mat-label>Wertrelevanz?</mat-label>
               <mat-select formControlName="wertrelevanz" [(ngModel)]="brickData.wertrelevanz">
                  <mat-option [value]="true">Ja</mat-option>
                  <mat-option [value]="false">Nein</mat-option>
               </mat-select>
            </mat-form-field>
         </div>
         <div class="row">
            <mat-form-field class="col-md">
               <mat-label>Text der Baulast in Kurzform</mat-label>
               <textarea matInput id="baulastKurztext" formControlName="baulastKurztext" [(ngModel)]="brickData.baulastKurztext"></textarea>
            </mat-form-field>
            <mat-form-field class="col-md">
               <mat-label>Gutachterliche Einschätzung der Baulast</mat-label>
               <textarea matInput formControlName="gutachterlicheEinschaetzung" class="example-full-width" [(ngModel)]="brickData.gutachterlicheEinschaetzung"></textarea>
            </mat-form-field>
         </div>
         <div class="hstack gap-2 justify-content-end mt-4">
            <button class="btn btn-light" (click)="cancel()">Abbrechen</button>
            <button class="btn btn-primary" (click)="save()">Speichern</button>
            <button class="btn btn-light" (click)="saveAndExit()">Speichern & Schließen</button>
         </div>
      </form>
   </div>
</app-card>
