import { Component, OnDestroy, OnInit } from '@angular/core';
import {
   AbstractControl,
   FormControl,
   UntypedFormControl,
   UntypedFormGroup,
   ValidationErrors,
   ValidatorFn,
   Validators
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { SnackbarService } from '../../../core/services/snackbar.service';
import { DialogComponent } from '../../../shared/components/dialog/dialog.component';
import { SelectOption, SelectOptionNumber } from '../../../shared/components/radio-group/SelectOption';
import { DialogContentType, DialogType } from '../../../shared/models/Dialog';
import { ILocationData } from '../../../shared/interfaces/LocationData';
import {
   Collection,
   CollectionHerausgeber,
   CollectionNutzungContainer,
   Herausgeber,
   IResultLagenToAdd,
   IResultLagenToUpdate,
   IResultNutzungContainerToAdd,
   IResultNutzungContainerToUpdate
} from '../../models/Collection';
import { CollectionService } from '../../services/collection.service';
import {
   UploadDropdownKategorie,
   UploadDropdownKostenpflichtig,
   UploadDropdownPublikaionsturnus
} from '../../UploadDropdownData';
import { BreadcrumbDefault, BreadcrumbsService } from 'src/app/core/services/breadcrumbs.service';

@Component({
   selector: 'app-upload-create',
   templateUrl: './upload-create.component.html',
   styleUrls: ['./upload-create.component.scss']
})
export class UploadCreateComponent implements OnInit, OnDestroy {
   DialogContentType = DialogContentType;
   DropKategorie = UploadDropdownKategorie;
   DropPublikationsturnus = UploadDropdownPublikaionsturnus;
   DropKostenpflichtig = UploadDropdownKostenpflichtig;

   dropKategorieCurrentSelection: number = null;
   dropHerausgeberCurrentSelection: number = null;
   dropPublikationsturnusCurrentSelection: number = null;
   dropKategorieKostenpflichtigSelection: number = null;

   //Select Options
   herausgeberSelectOption: SelectOptionNumber[];

   filteredHerausgeberSelectOption$: BehaviorSubject<SelectOptionNumber[]> = new BehaviorSubject<SelectOptionNumber[]>(
      []
   );
   herausgeberControl: FormControl = new FormControl('');
   objektartenSelectOption: SelectOption[] = [];
   nutzungsartenSelectOption: SelectOption[] = [];

   //Form Groups & Controls
   formGroup: UntypedFormGroup;
   nutzung: UntypedFormControl;
   lage: UntypedFormControl;
   hinweise: UntypedFormControl;
   lizenz: UntypedFormControl;

   //Subscriptions
   dialogRefSub: Subscription;
   routingSub: Subscription;
   collectionSub: Subscription;

   //Result Lagen
   resultLagenToAdd: IResultLagenToAdd[] = [];
   resultLagenToUpdate: IResultLagenToUpdate[] = [];
   resultLagenToRemove: number[] = [];

   //Result Nutzungen
   resultNutzungContainerToAdd: IResultNutzungContainerToAdd[] = [];
   resultNutzungContainerToUpdate: IResultNutzungContainerToUpdate[] = [];
   resultNutzungContainerToRemove: number[] = [];

   //Herausgeber
   herausgeber: CollectionHerausgeber[];
   nutzungObjektartContainer: CollectionNutzungContainer;

   collection: Collection;
   locationData: ILocationData;
   showEditMode = false;
   id = null;
   buttonTexts = {
      beschreibung: 'Beschreibung',
      hinweis: 'Hinweis'
   };

   public loading = false;

   constructor(
      private dialog: MatDialog,
      private collectionService: CollectionService,
      private router: Router,
      private route: ActivatedRoute,
      private snackbarService: SnackbarService,
      private breadcrumbsService: BreadcrumbsService
   ) { }

   ngOnInit(): void {
      if (!this.showEditMode) {
         this.setupForm();
      }
      this.loadHerausgeber();
      this.collectionService.getNutzungen().subscribe((n) => {
         this.nutzungObjektartContainer = n;
         this.objektartenSelectOption = n.objektarten.map((objektarten) => ({
            value: objektarten.id,
            label: objektarten.name
         }));
         this.nutzungsartenSelectOption = n.nutzungsarten.map((nutzungsarten) => ({
            value: nutzungsarten.id,
            label: nutzungsarten.name
         }));
      });
      this.collectionService
         .getLocationData()
         .subscribe((locationData: ILocationData) => (this.locationData = locationData));

      this.collectionSub = this.route.params.subscribe((params) => {
         if (params['id']) {
            this.showEditMode = true;
            this.collectionService.getCollectionById(params['id']).subscribe((col: Collection) => {
               this.id = col.id;
               this.collection = col;
               this.setupForm(col);

               // breadcrumbs in edit mode
               const urlDetail = `collection/${this.id}`;
               this.breadcrumbsService.Apply([
                  { ...BreadcrumbDefault.UploadList },
                  { ...BreadcrumbDefault.CollectionLabel, url: urlDetail },
                  { label: 'Sammlung bearbeiten' }
               ]);
            });
         }
      });

      this.herausgeberControl.valueChanges
         .pipe(
            filter((x) => typeof x === 'string'),
            map((x) => x.trim()),
            tap((searchVal: string) => {
               let filtered = this.herausgeberSelectOption?.filter((o) =>
                  o.label?.toLowerCase().includes(searchVal?.toLowerCase())
               );
               this.filteredHerausgeberSelectOption$.next(filtered);
            })
         )
         .subscribe();

      // breadcrumbs in create mode
      if (!this.showEditMode) { // edit
         this.breadcrumbsService.Apply([
            { label: 'Sammlung erstellen' },
         ]);
      }
   }

   displayFn(value?: number) {
      return value ? this.herausgeberSelectOption.find((h) => h.value === value).label : '';
   }

   private loadHerausgeber(): void {
      this.collectionService
         .getHerausgeber()
         .pipe(map((h: Herausgeber[]) => h.map((x) => ({ id: x.id, name: x.name, url: x.url }))))
         .subscribe((h) => {
            this.herausgeber = h.sort((a, b) => a.name.localeCompare(b.name));
            this.herausgeberSelectOption = this.herausgeber
               .map((h) => ({
                  label: h.name,
                  value: h.id
               }))
               .sort((a, b) => a.label.localeCompare(b.label));
            this.filteredHerausgeberSelectOption$.next(this.herausgeberSelectOption);
         });
   }

   //Dialog Operations
   openDialog(contentType: DialogContentType, primaryBtnTitle: string, index?: number): void {
      //Setup dialog
      const dialogClosed = this.setupDialog(contentType, primaryBtnTitle, index);
      //When dialog closed
      this.resolveDialog(dialogClosed.control, dialogClosed.editingId, dialogClosed.dialogRef, contentType);
   }

   //Pre Setup Dialog
   setupDialog(
      contentType: DialogContentType,
      primaryBtnTitle: string,
      index?: number
   ): { control: AbstractControl; editingId: number; dialogRef: any } {
      let content;
      let dialogTitle = null;
      let editingId = null;
      const autofocus =
         contentType === DialogContentType.UploadBeschreibung || contentType === DialogContentType.UploadHint;
      const control = this.formGroup.get(contentType.toLowerCase());

      const indexAvailable = index != null && control.value.length > 0;
      switch (contentType) {
         case DialogContentType.UploadAddItemNutzung:
            dialogTitle = 'Nutzungs- und Objektartenliste erweitern';
            content = {
               nutzungObjektartContainer: this.nutzungObjektartContainer
            };
            break;
         case DialogContentType.UploadAddItemHerausgeber:
            dialogTitle = 'Herausgeberliste erweitern';
            content = of(this.herausgeber);
            break;
         case DialogContentType.UploadNutzung:
            if (indexAvailable) {
               editingId = control.value[index].id;
            }
            content = {
               index: indexAvailable ? index : null,
               value:
                  control.value[index] && control.value.length > 0
                     ? {
                        nutzungsartId: control.value[index]?.nutzungsartId,
                        objektartId: control.value[index]?.objektartId
                     }
                     : null,
               nutzungObjektartContainer: this.nutzungObjektartContainer
            };
            break;
         case DialogContentType.UploadLage:
            if (indexAvailable) {
               editingId = control.value[index].id;
            }
            content = {
               index: indexAvailable ? index : null,
               value: control.value != null && control.value.length > 0 ? control.value[index] : null,
               locationData: this.locationData
            };
            break;
         default:
            content = control.value;
            break;
      }
      let dialogRef = null;

      const config = {
         autoFocus: autofocus,
         data: {
            dialogType: {
               title: dialogTitle,
               type: DialogType.Form,
               contentType: contentType,
               primaryBtnTitle: primaryBtnTitle,
               content: content
            }
         }
      };

      if (
         !(contentType === DialogContentType.UploadLage && !this.locationData) &&
         !(contentType === DialogContentType.UploadNutzung && !this.nutzungObjektartContainer)
      ) {
         dialogRef = this.dialog.open(DialogComponent, config);
      }

      return { control: control, editingId: editingId, dialogRef: dialogRef };
   }

   //When Dialog Is Closed
   resolveDialog(control: AbstractControl, editingId: number, dialogRef: any, contentType: DialogContentType): void {
      if (dialogRef != null) {
         this.dialogRefSub = dialogRef.afterClosed().subscribe((result) => {
            if (result) {
               switch (contentType) {
                  case DialogContentType.UploadAddItemNutzung:
                     if (result.nutzungsart.length > 0 || result.objektart.length > 0) {
                        this.collectionService
                           .addNewNutzungenAndObjektartenItems(result)
                           .subscribe((response) => {
                              response.data.collectionNutzungsart.map((nutzungsart) =>
                                 this.nutzungObjektartContainer.nutzungsarten.push({
                                    id: nutzungsart.id,
                                    name: nutzungsart.name
                                 })
                              );
                              response.data.collectionObjektart.map((objektart) =>
                                 this.nutzungObjektartContainer.objektarten.push({
                                    id: objektart.id,
                                    name: objektart.name,
                                    collectionNutzungsart: objektart.collectionNutzungsart
                                 })
                              );
                           });
                     }
                     break;
                  case DialogContentType.UploadLage:
                     result.index != null ? this.editLage(result, editingId) : this.addLage(result);
                     control.updateValueAndValidity();
                     break;
                  case DialogContentType.UploadNutzung:
                     result.index != null ? this.editNutzung(result) : this.addNutzung(result);
                     control.updateValueAndValidity();
                     break;
                  case DialogContentType.UploadHint:
                  case DialogContentType.UploadBeschreibung:
                     control.setValue(result.text);
                     this.buttonTexts[contentType.toLowerCase()] = result.text.slice(0, 20).concat('...');
                     break;
                  case DialogContentType.UploadAddItemHerausgeber:
                     this.collectionService.editHerausgeberItems(result).subscribe(() => this.loadHerausgeber());
                     break;
               }
            }
         });
      }
   }

   addNutzung(result): void {
      const formControlName = 'nutzung';
      //Juggling Check '==' is needed to compare null with undefined
      result.selectedNutzungenData.map((nutzung) => {
         const foundExistingNutzung = this.formGroup
            .get(formControlName)
            .value.find(
               (existingNutzung) =>
                  existingNutzung.nutzungsartId === nutzung.nutzungsartId &&
                  existingNutzung.objektartId == nutzung.objektartId
            );
         if (!foundExistingNutzung) {
            this.formGroup.get(formControlName).value.push({
               nutzungsartId: nutzung.nutzungsartId,
               objektartId: nutzung.objektartId || null
            });
            this.resultNutzungContainerToAdd.push(nutzung);
         }
      });
   }

   addLage(result): void {
      if (
         this.formGroup
            .get('lage')
            .value.find(
               (lage) =>
                  (lage.bundesland?.id === result.selectedLocationData?.bundesland?.id &&
                     lage.landkreis?.id === result.selectedLocationData?.landkreis?.id &&
                     lage.gemeinde?.id === result.selectedLocationData?.gemeinde?.id) ||
                  (lage.countryWide && lage.countryWide === result.selectedLocationData?.countryWide)
            )
      ) {
         this.snackbarService.showWarning('Lage bereits vorhanden!');
      } else {
         this.formGroup.get('lage').value.push(result.selectedLocationData);
         this.resultLagenToAdd.push(<IResultLagenToAdd>{
            bundeslandId: result.selectedLocationData?.bundesland?.id,
            gemeindeId: result.selectedLocationData?.gemeinde?.id,
            landkreisId: result.selectedLocationData?.landkreis?.id,
            countryWide: result.selectedLocationData?.countryWide || false
         });
      }
   }

   editLage(result, editingId: number): void {
      const formControlName = 'lage';
      let editingLage;
      const existingEntry = this.formGroup
         .get(formControlName)
         .value.find(
            (lage) =>
               (lage.bundesland?.id === result.selectedLocationData?.bundesland?.id &&
                  lage.landkreis?.id === result.selectedLocationData?.landkreis?.id &&
                  lage.gemeinde?.id === result.selectedLocationData?.gemeinde?.id) ||
               (lage.countryWide && lage.countryWide === result.selectedLocationData?.countryWide)
         );

      const index = this.formGroup.get(formControlName).value.indexOf(existingEntry);

      if (existingEntry && index != result.index) {
         this.deleteItem(null, formControlName, result.index);
         this.snackbarService.showWarning('Lage bereits vorhanden! Eintrag wurde entfernt.');
      } else {
         if (editingId) {
            editingLage = <IResultLagenToUpdate>{
               id: editingId,
               bundeslandId: result.selectedLocationData.bundesland?.id,
               landkreisId: result.selectedLocationData.landkreis?.id,
               gemeindeId: result.selectedLocationData.gemeinde?.id,
               countryWide: result.selectedLocationData?.countryWide || false
            };
            if (this.showEditMode) {
               this.resultLagenToUpdate.push(editingLage);
            }
         } else {
            editingLage = <IResultLagenToAdd>{
               bundeslandId: result.selectedLocationData.bundesland?.id,
               landkreisId: result.selectedLocationData.landkreis?.id,
               gemeindeId: result.selectedLocationData.gemeinde?.id,
               countryWide: result.selectedLocationData?.countryWide || false
            };
            if (this.showEditMode) {
               this.resultLagenToAdd.push(editingLage);
            }
         }
         this.formGroup.get('lage').value[result.index] = result.selectedLocationData;
      }
   }

   editNutzung(result): void {
      const formControlName = 'nutzung';
      const existingNutzung: IResultNutzungContainerToUpdate =
         this.formGroup.get(formControlName).value[result.index];
      //Juggling Check '==' is needed to compare null with undefined
      const nothingChanged = result.selectedNutzungenData.find(
         (nutzung) =>
            nutzung.nutzungsartId === existingNutzung.nutzungsartId &&
            nutzung.objektartId == existingNutzung.objektartId
      );
      //When Nutzung was removed
      if (!nothingChanged) {
         this.deleteItem(null, formControlName, result.index);
      }
      //Remove existing Nutzung before adding
      const filteredData: IResultNutzungContainerToAdd[] = result.selectedNutzungenData.filter(
         (nutzung) =>
            !(
               nutzung.nutzungsartId === existingNutzung.nutzungsartId &&
               nutzung.objektartId === existingNutzung.objektartId
            )
      );
      //Add (not existing) Nutzungen
      if (!nothingChanged && result.selectedNutzungenData.length === 1) {
         filteredData.map((nutzung) => {
            const foundExistingNutzung = this.formGroup
               .get(formControlName)
               .value.find(
                  (existingNutzung) =>
                     existingNutzung.nutzungsartId === nutzung.nutzungsartId &&
                     existingNutzung.objektartId === nutzung.objektartId
               );
            if (!foundExistingNutzung) {
               this.formGroup.get(formControlName).value.push({
                  nutzungsartId: nutzung.nutzungsartId,
                  objektartId: nutzung.objektartId || null
               });
               this.resultNutzungContainerToAdd.push(nutzung);
            }
         });
      }
   }

   //Submit to BE
   submit(): void {
      this.loading = true;
      let data = {
         titel: this.formGroup.get('titel').value,
         beschreibung: this.formGroup.get('beschreibung').value,
         reportname: '',
         publikationsturnus: this.formGroup.get('publikationsturnus').value,
         kostenpflichtig: this.formGroup.get('kostenpflichtig').value,
         hinweis: this.formGroup.get('hinweis').value,
         kategorie: this.formGroup.get('kategorie').value,
         herausgeberId: this.formGroup.get('herausgeber').value,
         nutzungContainerToAdd: this.resultNutzungContainerToAdd.map((nutzung) => ({
            nutzungsartId: nutzung.nutzungsartId,
            objektartId: nutzung.objektartId
         })),
         lagenToAdd: this.resultLagenToAdd.map((lage) => ({
            bundeslandId: lage.bundeslandId || null,
            landkreisId: lage.landkreisId || null,
            gemeindeId: lage.gemeindeId || null,
            countryWide: lage.countryWide || false
         })),
         herausgabedatum: this.formGroup.get('herausgabedatum').value
      };
      //When Editing Mode is active
      if (this.showEditMode) {
         data['id'] = this.id;
         data['lagenToUpdate'] = this.resultLagenToUpdate;
         data['lagenToRemove'] = this.resultLagenToRemove;
         data['nutzungContainerToUpdate'] = this.resultNutzungContainerToUpdate;
         data['nutzungContainerToRemove'] = this.resultNutzungContainerToRemove;
      }
      //Check Response
      if (this.showEditMode) {
         this.routingSub = this.collectionService.editCollection(data).subscribe((response) => {
            if (response.succeeded) {
               this.router.navigate(['../collection', this.id]);
            } else {
               this.snackbarService.showError('Bibliothek konnte nicht gespeichert werden!');
            }
            this.loading = false;
         });
      } else {
         this.routingSub = this.collectionService.addCollection(data).subscribe((response) => {
            if (response.succeeded) {
               this.router.navigate(['../collection', response.data]);
            } else {
               this.snackbarService.showError('Bibliothek konnte nicht gespeichert werden!');
            }
            this.loading = false;
         });
      }
   }

   setHerausgeber(event: MatAutocompleteSelectedEvent): void {
      this.setSelect(event.option.value, 'herausgeber');
   }

   //Form Operations
   setSelect(value: number, name: string): void {
      if (name === 'kostenpflichtig') {
         this.formGroup.get(name)?.setValue(value === 0);
      } else {
         this.formGroup.get(name)?.setValue(value);
      }
   }

   deleteItem(event: Event, formControlName: string, index: number) {
      if (event) {
         event.stopPropagation();
      }
      if (index > -1) {
         if (formControlName === 'nutzung') {
            if (this.showEditMode) {
               const idToRemove = this.formGroup.get(formControlName).value[index]?.id;
               if (idToRemove) {
                  this.resultNutzungContainerToRemove.push(idToRemove);
               }
            } else {
               this.resultNutzungContainerToAdd.splice(index, 1);
            }
         } else if (formControlName === 'lage') {
            this.showEditMode
               ? this.resultLagenToRemove.push(this.formGroup.get(formControlName).value[index]?.id)
               : this.resultLagenToAdd.splice(index, 1);
         }
         this.formGroup.get(formControlName).value.splice(index, 1);
      }
      this.formGroup.get(formControlName).updateValueAndValidity();
   }

   //Setup Form + Population (when editing)
   setupForm(collection?: Collection) {
      this.formGroup = new UntypedFormGroup({
         titel: new UntypedFormControl(this.showEditMode ? collection?.titel : '', Validators.required),
         beschreibung: new UntypedFormControl(null),
         kategorie: new UntypedFormControl(null, Validators.required),
         herausgeber: new UntypedFormControl(this.showEditMode ? collection.herausgeber : null),
         publikationsturnus: new UntypedFormControl(null, Validators.required),
         kostenpflichtig: new UntypedFormControl(null, Validators.required),
         hinweis: new UntypedFormControl(null),
         nutzung: (this.nutzung = new UntypedFormControl([], this.checkArrayIsEmpty())),
         lage: (this.lage = new UntypedFormControl([], this.checkArrayIsEmpty())),
         hinweise: (this.hinweise = new UntypedFormControl(null)),
         lizenz: (this.lizenz = new UntypedFormControl(null)),
         herausgabedatum: new UntypedFormControl(null)
      });

      this.formGroup.get('kategorie').valueChanges.subscribe((val: number) => {
         // If Marktdaten
         if (val === 0) {
            this.formGroup.get('nutzung').setValidators([this.checkArrayIsEmpty()]);
            this.formGroup.get('lage').setValidators([this.checkArrayIsEmpty()]);
            this.formGroup.get('kostenpflichtig').setValidators([Validators.required]);

            this.formGroup.get('nutzung').updateValueAndValidity();
            this.formGroup.get('lage').updateValueAndValidity();
            this.formGroup.get('kostenpflichtig').updateValueAndValidity();
         } else {
            this.formGroup.get('nutzung').clearValidators();
            this.formGroup.get('lage').clearValidators();
            this.formGroup.get('kostenpflichtig').clearValidators();

            this.formGroup.get('nutzung').updateValueAndValidity();
            this.formGroup.get('lage').updateValueAndValidity();
            this.formGroup.get('kostenpflichtig').updateValueAndValidity();
         }
      });

      //Population
      if (this.showEditMode) {
         this.formGroup.get('beschreibung').setValue(collection.beschreibung);
         if (collection.beschreibung) {
            this.buttonTexts.beschreibung = collection.beschreibung.substring(0, 17) + '...';
         }
         this.dropKategorieCurrentSelection = collection.kategorie;
         this.setSelect(collection.kategorie, 'kategorie');
         this.dropHerausgeberCurrentSelection = collection.herausgeber.id;
         this.setSelect(collection.herausgeber.id, 'herausgeber');
         this.dropPublikationsturnusCurrentSelection = collection.publikationsturnus;
         this.setSelect(collection.publikationsturnus, 'publikationsturnus');
         this.dropKategorieKostenpflichtigSelection = collection.kostenpflichtig ? 0 : 1;
         this.setSelect(collection.kostenpflichtig ? 0 : 1, 'kostenpflichtig');
         this.formGroup.get('hinweis').setValue(collection.hinweis);
         if (collection.hinweis) {
            this.buttonTexts.hinweis = collection.hinweis.substring(0, 17) + '...';
         }
         this.formGroup.get('nutzung').setValue(
            collection.collectionNutzungContainers.map((nutzung) => ({
               id: nutzung.id,
               nutzungsartId: nutzung.collectionNutzungsart?.id,
               objektartId: nutzung.collectionObjektart?.id
            }))
         );
         this.formGroup.get('lage').setValue(
            collection.collectionLagen.map((col) => ({
               id: col.id,
               bundesland: col.bundesland,
               landkreis: col.landkreis,
               gemeinde: col.gemeinde,
               countryWide: col.countryWide || false
            }))
         );
      }
   }

   //Validators
   checkArrayIsEmpty(): ValidatorFn {
      return (control: AbstractControl): ValidationErrors | null => {
         const correct = control.value.length > 0;
         return correct ? null : { notFilled: { value: control.value } };
      };
   }

   ngOnDestroy(): void {
      this.dialogRefSub?.unsubscribe();
      this.routingSub?.unsubscribe();
      this.collectionSub?.unsubscribe();
   }
}
