<div class="row g-2">
   <app-field label="Region" class="col-md">
      {{this.data.region?.name}}
   </app-field>
   <app-field label="Regionalfaktor" class="col-md">
      {{this.data.region?.regionalFaktor | number:'':'de-DE'}}
   </app-field>
   <app-field label="MwSt." class="col-md">
      {{this.data.mehrWertSteuerProzent + '%'}}
   </app-field>
</div>
<div class="row mt-4 g-2">
   <app-field label="Stand BPI aktuell" class="col-md-4">
      {{this.data.bpiStandAktuell.caption}}
   </app-field>
   <app-field label="Stand BPI bei Veröffentlichung" class="col-md">
      {{this.data.bpiStandBeiVeroeffentlichung.caption}}
   </app-field>
</div>

<app-card class="mt-4">
   <div header class="hstack">
      <span class="fs-6">Objekte</span>
      <button class="btn btn-secondary ms-auto" matTooltip="Objekt hinzufügen" (click)="navigateToBrickObjectCreate()">Hinzufügen</button>
   </div>
   <div body>
      <mat-tab-group>
         <mat-tab [label]="'Objekt ' + brickObject.objektNummer" *ngFor="let brickObject of data.objekte">
            <div class="container">
               <div class="hstack my-2 gap-2 justify-content-end">
                  <button class="btn btn-light" (click)="deleteBrickObjectById(brickObject)" matTooltip="Objekt löschen">Löschen</button>
                  <button class="btn btn-primary" (click)="navigateToBrickObjectEdit(brickObject)" matTooltip="Objekt bearbeiten">Bearbeiten</button>
               </div>
               <app-brick-production-costs-object-detail [data]="brickObject"></app-brick-production-costs-object-detail>
            </div>
         </mat-tab>
      </mat-tab-group>
   </div>
</app-card>