import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newlineToHtml'
})
export class NewlineToHtmlPipe implements PipeTransform {

  transform(text: string): string {
    if (text) {
      // see: https://www.itsolutionstuff.com/post/angular-nl2br-pipe-exampleexample.html?utm_content=cmp-true
      return text.replace(/\n/g, '<br/>');
    }
    return null;
  }

}
