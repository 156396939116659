<!--Lage-->
<app-dialog-upload-lage
    *ngIf="data.contentType === DialogContentType.UploadLage"
    class="box"
    (closed)="onClose($event)"
    [data]="data"
></app-dialog-upload-lage>

<!--Nutzung-->
<app-dialog-upload-nutzung
    *ngIf="data.contentType === DialogContentType.UploadNutzung"
    class="box"
    (closed)="onClose($event)"
    [data]="data"
></app-dialog-upload-nutzung>

<!--Beschreibung, Hinweise-->
<app-dialog-upload-textfield
    *ngIf="
        data.contentType === DialogContentType.UploadBeschreibung || data.contentType === DialogContentType.UploadHint
    "
    class="box"
    [data]="data"
    (closed)="onClose($event)"
></app-dialog-upload-textfield>

<!--Add File-->
<app-dialog-upload-file
    *ngIf="data.contentType === DialogContentType.UploadFile"
    [data]="data"
    class="box"
    (closed)="onClose($event)"
></app-dialog-upload-file>

<!--Rating-->
<app-dialog-upload-rating
    (onChange)="onClose($event)"
    *ngIf="data.contentType === DialogContentType.UploadRating"
    [data]="data"
    class="box"
></app-dialog-upload-rating>

<!--NewItemNutzung-->
<app-dialog-upload-item-nutzung
    (closed)="onClose($event)"
    *ngIf="data.contentType === DialogContentType.UploadAddItemNutzung"
    [data]="data"
    class="box"
></app-dialog-upload-item-nutzung>

<!--NewItemHerausgeber-->
<app-dialog-upload-item-herausgeber
    (closed)="onClose($event)"
    *ngIf="data.contentType === DialogContentType.UploadAddItemHerausgeber"
    [data]="data"
    class="box"
></app-dialog-upload-item-herausgeber>

<app-dialog-feedback
    (closed)="onClose($event)"
    *ngIf="data.contentType === DialogContentType.UploadFeedback"
    [data]="data"
    class="box"
>
</app-dialog-feedback>
