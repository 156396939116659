<p class="mt-5 display-6">Makrolage</p>

<app-card class="mt-4">
   <div body>
      <form [formGroup]="form">
         <div class="row gap-2">
            <mat-form-field class="col-md">
               <mat-label>Postleitzahl</mat-label>
               <input type="text" matInput formControlName="postleitzahl" [matAutocomplete]="autoPostleitzahl" (focusout)="onFocusOut($event)" maxlength="5">
               <mat-autocomplete #autoPostleitzahl="matAutocomplete" panelWidth="auto" [displayWith]="displayFn" (optionSelected)="locationSelected($event)">
                  <mat-option *ngFor="let location of filteredLocations" [value]="location">
                     {{ location.postleitzahl.plz }}
                     {{ location.ort | locationItem }}
                  </mat-option>
               </mat-autocomplete>
            </mat-form-field>
            <mat-form-field class="col-md">
               <mat-label>Ort</mat-label>
               <input matInput [value]="(form.dirty ? selectedLocation?.ort : brickData?.ort) | locationItem" disabled="true">
            </mat-form-field>
            <mat-form-field class="col-md">
               <mat-label>Gemeinde</mat-label>
               <input matInput [value]="form.dirty ? selectedLocation?.gemeinde?.name : brickData?.ort?.gemeindePolitisch?.name" disabled="true">
            </mat-form-field>
            <div class="col-md"></div>
         </div>
         <div class="row gap-2">
            <mat-form-field class="col-md">
               <mat-label>Kreisgemeindeschlüssel</mat-label>
               <input matInput [value]="form.dirty ? selectedLocation?.gemeinde?.kreisGemeindeSchluessel : brickData?.ort?.kreisGemeindeSchluessel" disabled="true">
            </mat-form-field>
            <mat-form-field class="col-md">
               <mat-label>Gemeindeverband</mat-label>
               <input matInput [value]="form.dirty ? selectedLocation?.gemeindeVerband?.name : brickData?.ort?.gemeindePolitisch?.gemeindeVerband?.name" disabled="true">
            </mat-form-field>
            <mat-form-field class="col-md">
               <mat-label>Kreis</mat-label>
               <input matInput [value]="form.dirty ? selectedLocation?.landkreis?.name : brickData?.ort?.kreis?.name" disabled="true">
            </mat-form-field>
            <mat-form-field class="col-md">
               <mat-label>Bundesland</mat-label>
               <input matInput [value]="form.dirty ? selectedLocation?.bundesland?.name : brickData?.ort?.kreis?.bundesland?.name" disabled="true">
            </mat-form-field>
         </div>
         <div class="hstack gap-2 justify-content-end mt-4">
            <button class="btn btn-light" (click)="cancel()">Abbrechen</button>
            <button class="btn btn-primary" (click)="save()" [disabled]="!this.form.valid">Speichern</button>
            <button class="btn btn-light" (click)="saveAndExit()" [disabled]="!this.form.valid">Speichern & Schließen</button>
         </div>
      </form>
   </div>
</app-card>