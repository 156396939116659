import {Component, OnInit} from '@angular/core';
import {filter, map, tap} from 'rxjs/operators';
import {
    GenericSelectOption,
    SelectOption,
    SelectOptionNumber
} from '../../../../shared/components/radio-group/SelectOption';
import {ILocationData, ILocationItem} from '../../../../shared/interfaces/LocationData';
import {CollectionFilter, CollectionNutzungContainer, Herausgeber} from '../../../models/Collection';
import {CollectionService} from '../../../services/collection.service';
import {UploadDropdownKategorie, UploadDropdownPublikaionsturnus} from '../../../UploadDropdownData';
import {MatCheckboxChange} from "@angular/material/checkbox";
import {AuthService} from "../../../../core/guards/auth.service";
import {FormControl} from "@angular/forms";
import {BehaviorSubject} from "rxjs";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";

@Component({
    selector: 'app-upload-list-sidebar',
    templateUrl: './upload-list-sidebar.component.html',
    styleUrls: ['./upload-list-sidebar.component.scss']
})
export class UploadListSidebarComponent implements OnInit {
    options = [];
    DropKategorie = UploadDropdownKategorie;
    dropKategorieCurrentSelection: number = null;

    DropPublikationsturnus = UploadDropdownPublikaionsturnus;
    DropPublikationsTurnusSelection: number = null;

    herausgeberSelectOption: SelectOptionNumber[] = [];
    filteredHerausgeberSelectOption$: BehaviorSubject<SelectOptionNumber[]> = new BehaviorSubject<SelectOptionNumber[]>(
        []
    );
    herausgeberControl: FormControl = new FormControl('');

    herausgeberSelectOptionSelected: number = null;

    publicationsDateStart: Date = null;
    publicationsDateEnd: Date = null;

    nutzungsartenSelectOption: SelectOption[] = [];

    selectedNutzungsArten: number[] = [];

    objektartenSelectOption: ObjektArtSelectOption[] = [];
    filteredObjektarten: ObjektArtSelectOption[] = [];
    selectedObjektarten: number[] = [];

    selectedTitle: string = null;
    locationData: ILocationData;

    openFeedbackOnly = false;
    overdueOnly = false;
    public isUploader = false;

    constructor(public collectionService: CollectionService, private auth: AuthService) {
        this.isUploader = auth.isUploader();
    }

    resetFilter(): void {
        this.selectedTitle = null;
        this.selectedObjektarten = [];
        this.selectedNutzungsArten = [];
        this.dropKategorieCurrentSelection = null;
        this.DropPublikationsTurnusSelection = null;
        this.herausgeberSelectOptionSelected = null;
        this.publicationsDateEnd = null;
        this.publicationsDateStart = null;
        this.openFeedbackOnly = false;
        this.overdueOnly = false;
        this.collectionService.resetFilter();
    }

    lageChanged(location: ILocationItem): void {
        const updateFilter: Partial<CollectionFilter> = {};
        updateFilter.bundeslaender = location.bundesland?.id ? [location.bundesland?.id] : [];
        updateFilter.landkreise = location.landkreis?.id ? [location.landkreis?.id] : [];
        updateFilter.gemeinden = location.gemeinde?.id ? [location.gemeinde?.id] : [];
        updateFilter.countryWide = location.countryWide || false;
        this.collectionService.updateFilter(updateFilter);
    }

    setTitleFilter(): void {
        this.collectionService.updateFilter({title: this.selectedTitle});
    }

    setCategoryFilter(values: number): void {
        this.dropKategorieCurrentSelection = values;
        this.collectionService.updateFilter({kategorie: this.dropKategorieCurrentSelection});
    }

    setNutzungFilter(values: SelectOption[]): void {
        this.selectedNutzungsArten = values.map((v) => v.value);
        this.collectionService.updateFilter({nutzungsarten: this.selectedNutzungsArten});
        if (values.length) {
            this.filteredObjektarten = this.objektartenSelectOption.filter((o: ObjektArtSelectOption) =>
                this.selectedNutzungsArten.includes(o.nutzungsartId)
            );
        } else {
            this.filteredObjektarten = this.objektartenSelectOption;
            this.collectionService.setCurrentNutzungsarten([]);
        }
    }

    displayFn(value?: number) {
        return value ? this.herausgeberSelectOption.find((h) => h.value === value).label : '';
    }

    setObjektArtenFilter(values: SelectOption[]): void {
        this.selectedObjektarten = values.map((v) => v.value);
        this.collectionService.updateFilter({objektarten: this.selectedObjektarten});
    }

    setTurnusFilter(values: number): void {
        this.DropPublikationsTurnusSelection = values;
        this.collectionService.updateFilter({publikationsturnus: this.DropPublikationsTurnusSelection});
    }

    setHerausgeber(event: MatAutocompleteSelectedEvent): void {
        this.setHerausgeberFilter(event.option.value);
    }
    setHerausgeberFilter(value: number): void {
        this.herausgeberSelectOptionSelected = value;
        this.collectionService.updateFilter({herausgeber: this.herausgeberSelectOptionSelected});
    }

    setPublicationDateFilterStart(value: Date | null): void {
        this.publicationsDateStart = value;
        this.collectionService.updateFilter({publikationsdatumFrom: value?.toISOString()});
    }

    setPublicationDateFilterEnd(value: Date | null): void {
        this.publicationsDateEnd = value;
        this.collectionService.updateFilter({publikationsdatumTo: value?.toISOString()});
    }

    onOpenFeedbackOnlySelected($event: MatCheckboxChange): void {
        this.collectionService.updateFilter({feedbackStatusOpenOnly: $event.checked});
    }

    onOverdueOnlySelected($event: MatCheckboxChange): void {
        this.collectionService.updateFilter({overDue: $event.checked});
    }

    ngOnInit(): void {
        this.collectionService
            .getLocationData()
            .subscribe((locationData: ILocationData) => (this.locationData = locationData));

        this.collectionService
            .getHerausgeber<Herausgeber[]>()
            .pipe(map((h: Herausgeber[]) => h.map((x) => ({id: x.id, name: x.name, url: x.url}))))
            .subscribe((herausgeber) => {
                this.herausgeberSelectOption = herausgeber.map((h) => ({label: h.name, value: h.id}));
                this.filteredHerausgeberSelectOption$.next(this.herausgeberSelectOption);
            });

        this.collectionService.getNutzungen().subscribe((nutzungen: CollectionNutzungContainer) => {
            this.nutzungsartenSelectOption = nutzungen.nutzungsarten.map((nutzungsarten) => ({
                value: nutzungsarten.id,
                label: nutzungsarten.name
            }));

            this.objektartenSelectOption = nutzungen.objektarten.map((objektarten) => ({
                nutzungsartId: objektarten.collectionNutzungsart.id,
                value: objektarten.id,
                label: objektarten.name
            }));
            this.filteredObjektarten = this.objektartenSelectOption;
        });

        this.herausgeberControl.valueChanges
            .pipe(
                filter((x) => typeof x === 'string'),
                map((x) => x.trim()),
                tap((searchVal: string) => {
                    let filtered = this.herausgeberSelectOption?.filter((o) =>
                        o.label?.toLowerCase().includes(searchVal?.toLowerCase())
                    );
                    this.filteredHerausgeberSelectOption$.next(filtered);
                })
            )
            .subscribe();
        this.resetFilter();
    }
}

export interface ObjektArtSelectOption extends GenericSelectOption<number> {
    nutzungsartId: number;
}
