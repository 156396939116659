import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Appraisal } from '../../appraisal-detail/models/Appraisal';
import { AppraisalService } from '../../appraisal-detail/services/appraisal.service';

@Injectable({ providedIn: 'root' })
export class AppraisalResolver implements Resolve<Appraisal> {
    constructor(private appraisalService: AppraisalService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<Appraisal> | Promise<Appraisal> | Appraisal {
        if (!route.paramMap.has('id')) {
            return null;
        }

        const id = +route.paramMap.get('id');
        return this.appraisalService.getAppraisalById(id);
    }
}
