import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { map } from 'rxjs/operators';
import { BrickService } from './brick.service';
import { BrickNavigationService } from './brick-navigation.service';

@Injectable({
  providedIn: 'root'
})
export class BrickResolverService implements Resolve<any> {

  constructor(
    private brickService: BrickService,
    private brickNavigationService: BrickNavigationService
  ) { }

  resolve(route: ActivatedRouteSnapshot) {
    const appraisalTextId: number = Number(route.paramMap.get("appraisalTextId"));
    const clientType: string = this.brickNavigationService.getClientType(route.url);

    if(clientType == null) {
      throw('Brick client type could not be determined by URL.\n' +
        'It must be defined in BrickNavigation array, see BrickTypes.ts.\n' +
        `URL: ${route.url.join('/')}`);
    }

    return this.brickService.loadBrickData(appraisalTextId, clientType)
      .pipe(
        map(res => res.data)
      );
  }
}
