import { Component, OnInit } from '@angular/core';
import { AppraisalService } from '../../../appraisal-detail/services/appraisal.service';
import { BreadcrumbDefault, BreadcrumbsService } from 'src/app/core/services/breadcrumbs.service';

@Component({
   selector: 'app-map-view',
   templateUrl: './map-view.component.html',
   styleUrls: ['./map-view.component.scss']
})
export class MapViewComponent implements OnInit {
   constructor(
      private appraisalService: AppraisalService,
      private breadcrumbsService: BreadcrumbsService
   ) { }

   ngOnInit(): void {
      this.appraisalService.listToFilterChangesMapView();

      this.breadcrumbsService.Apply([
         { ...BreadcrumbDefault.SearchMapLabel },
      ]);
   }
}
