import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { IAppraisalMapPopupView } from '../../../../../appraisal-detail/models/Appraisal';
import { AppraisalCompareService } from '../../../../../appraisal-detail/services/appraisal-compare.service';
import { AppraisalService } from '../../../../../appraisal-detail/services/appraisal.service';

@Component({
    selector: 'app-popup',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
    @Input() appraisalId: number;

    appraisal$: Observable<IAppraisalMapPopupView>;
    comparableAppraisals$: Observable<number[]>;

    constructor(private appraisalCmpService: AppraisalCompareService, private appraisalService: AppraisalService) {
        this.comparableAppraisals$ = this.appraisalCmpService.comparableAppraisalIds.pipe(share());
    }

    ngOnInit(): void {
        this.appraisal$ = this.appraisalService.getAppraisalForMapById(this.appraisalId);
    }

    addComparableObject(id: number): void {
        this.appraisalCmpService.addOrRemoveAppraisal(id);
    }
}
