<div>

   <h3>Neuen Gutachtentext erstellen</h3>

   <mat-dialog-content [formGroup]="form" class="mt-4">
      <mat-form-field>
         <input #auftragsnummer matInput placeholder="Auftragsnummer" formControlName="auftragsnummer">
      </mat-form-field>
      <mat-form-field>
         <mat-select #auftraggeber placeholder="Auftraggeber" formControlName="auftraggeber">
            <mat-option *ngFor="let orderer of orderers"
               [value]="orderer.id"
               [matTooltip]="orderer.loraAuftraggeberName" matTooltipPosition="after">   
                  {{orderer.loraAuftraggeberName}}
            </mat-option>
         </mat-select>
      </mat-form-field>
      <mat-form-field>
         <mat-select #auftragsart placeholder="Auftragsart" formControlName="auftragsart">
            <mat-option *ngFor="let orderType of orderTypes" 
               [value]="orderType.id"
               [matTooltip]="orderType.name" matTooltipPosition="after">
                  {{ orderType.name }}
            </mat-option>
         </mat-select>
      </mat-form-field>
      <mat-form-field>
         <mat-select #wertbestimmendesVerfahren placeholder="Wertbestimmendes Verfahren" formControlName="wertbestimmendesVerfahren">
            <mat-option *ngFor="let option of verfahrenOptions"
               [value]="option">{{WertbestimmendesVerfahrenNames[option]}}</mat-option>
         </mat-select>
      </mat-form-field>
   </mat-dialog-content>
   <div class="hstack gap-2 mt-4">
      <button class="btn btn btn-light ms-auto" (click)="close()">Abbrechen</button>
      <button class="btn btn btn-primary" (click)="create(auftragsnummer.value, auftraggeber.value, auftragsart.value, wertbestimmendesVerfahren.value)">Erstellen</button>
   </div>

</div>