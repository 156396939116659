<mat-tab-group [selectedIndex]="selectedIndex" (selectedIndexChange)="selectedIndexChange($event)">
   <mat-tab *ngFor="let brick of bricks">
      <div class="container">
         <ng-template mat-tab-label>
            <span [matBadge]="brick.data.notes?.length" [matBadgeHidden]="brick.data.notes == null || brick.data.notes.length == 0" matBadgeColor="warn" matBadgeOverlap="false">
               {{brick.name}}
            </span>
         </ng-template>
         <div class="hstack mt-2 justify-content-end">
            <button class="btn btn-primary" (click)="navigateToBrickEdit(brick)" matTooltip="Baustein bearbeiten">Bearbeiten</button>
         </div>
         <p *ngIf="brick.data.bearbeitetAm == null" class="fst-italic">
            - noch nicht bearbeitet -
         </p>
         <ng-container [ngSwitch]="brick.clientType" *ngIf="brick.data.bearbeitetAm">
            <app-brick-construction-loads-detail *ngSwitchCase="'BrickConstructionLoads'" [data]="brick.data"></app-brick-construction-loads-detail>
            <app-brick-macro-location-detail *ngSwitchCase="'BrickMacroLocation'" [data]="brick.data"></app-brick-macro-location-detail>
            <app-brick-construction-law-detail *ngSwitchCase="'BrickConstructionLaw'" [data]="brick.data"></app-brick-construction-law-detail>
            <app-brick-production-costs-detail *ngSwitchCase="'BrickProductionCosts'" [data]="brick.data"></app-brick-production-costs-detail>
            <!-- next brick component here ... -->

            <div class="mt-5" *ngIf="brick.data.notes && brick.data.notes.length > 0">
               <app-brick-notes [notes]="brick.data.notes"></app-brick-notes>
            </div>
         </ng-container>
      </div>
   </mat-tab>
</mat-tab-group>